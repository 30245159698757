import { Placeholder, VisitorIdentification } from '@sitecore-jss/sitecore-jss-react';
import { ConsentLevel, ScrollIndicator, useCookieConsent } from 'db-npm-rdui';
import React from 'react';
import Helmet from 'react-helmet';
import { fieldReferences } from './constants';
import { EditorIncludes, LayoutBackground, Monitoring } from './shared/components';
import { useAdobePageLoadTracking, useAdobeTrackingData, useContextSettings } from './shared/hooks';

/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/

const Layout = ({ route }) => {
  const contextSettings = useContextSettings();
  const cookieConsent = useCookieConsent();

  const metaTitle = route.fields?.[fieldReferences.basePage.metaTitle]?.value || '';
  const metaDescription = route.fields?.[fieldReferences.basePage.metaDescription]?.value || '';
  const metaKeywords = route.fields?.[fieldReferences.basePage.metaKeywords]?.value || '';
  const metaNoindex = route.fields?.[fieldReferences.basePage.metaNoindex]?.value || false;
  const canonicalUrl = route.fields?.[fieldReferences.basePage.canonicalUrl]?.value || null;
  const googleSiteVerification = contextSettings.googleSiteVerification || '';
  const facebookDomainVerification = contextSettings.facebookDomainVerification || '';
  const sharingImage = route.fields?.[fieldReferences.basePage.sharingImage]?.value || '';
  const greyBackground = route.fields?.[fieldReferences.basePage.greyBackground]?.value || false;

  useAdobePageLoadTracking(metaTitle);
  const trackingAllowed = cookieConsent.isAllowed(ConsentLevel.Statistics);
  useAdobeTrackingData(metaTitle, trackingAllowed);

  return (
    <React.Fragment>
      {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
      <Helmet>
        <title>{metaTitle}</title>
        {metaNoindex && <meta name="robots" content="noindex" />}
        {metaDescription !== '' && <meta name="description" content={metaDescription} />}
        {metaKeywords !== '' && <meta name="keywords" content={metaKeywords} />}
        {googleSiteVerification !== '' && <meta name="google-site-verification" content={googleSiteVerification} />}
        {facebookDomainVerification !== '' && (
          <meta name="facebook-domain-verification" content={facebookDomainVerification} />
        )}
        {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
        <meta property="og:type" content="website" />
        {sharingImage !== '' && <meta property="og:image" content={sharingImage} />}
      </Helmet>
      {/*
      VisitorIdentification is necessary for Sitecore Analytics to determine if the visitor is a robot.
      If Sitecore XP (with xConnect/xDB) is used, this is required or else analytics will not be collected for the JSS app.
      For XM (CMS-only) apps, this should be removed.

      VI detection only runs once for a given analytics ID, so this is not a recurring operation once cookies are established.
    */}
      <VisitorIdentification />

      <ScrollIndicator />

      <Placeholder name="jss-header" rendering={route} />

      <LayoutBackground greyPattern={greyBackground}>
        <Placeholder name="jss-content" rendering={route} />
      </LayoutBackground>

      <Placeholder name="jss-footer" rendering={route} />

      <EditorIncludes />
      <Monitoring appKey={contextSettings.monitoring.appKey} beaconUrl={contextSettings.monitoring.beaconUrl} />
    </React.Fragment>
  );
};

export default Layout;
