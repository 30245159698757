import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { TextAccordionItem as TextAccordionItemComp, Typography } from 'db-npm-rdui';
import React from 'react';
import styled from 'styled-components';
import { ContentParser } from '../../shared/components';
import { useIsEditor } from '../../shared/hooks';
import { richTextStyles } from '../RichText/richTextStyles';

const StyledRichText = styled(RichText)`
  ${richTextStyles}
  margin-bottom: 30px;
`;

type TextAccordionItemProps = {
  fields: any;
  itemId: any;
};

const TextAccordionItem: React.FC<TextAccordionItemProps> = ({ itemId, fields = {} }) => {
  const isEditor = useIsEditor();

  return isEditor ? (
    <>
      <Typography type="h6">
        <Text field={fields['Title']} />
      </Typography>
      <ContentParser>
        <StyledRichText field={fields['Text']} />
      </ContentParser>
    </>
  ) : (
    <TextAccordionItemComp title={fields['Title']?.value} displayAsLink={true} itemId={itemId}>
      <ContentParser>
        <StyledRichText field={fields['Text']} />
      </ContentParser>
    </TextAccordionItemComp>
  );
};

export default TextAccordionItem;
