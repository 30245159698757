import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { applyTextType, Col, Color, Container, mediaQuery, Row, Typography } from 'db-npm-rdui';
import React from 'react';
import styled from 'styled-components';
import { FieldChecker, Section } from '../../shared/components';

const Columns = styled.div`
  padding-top: 30px;

  h6 {
    ${applyTextType('h6')}
    margin: 20px 0 15px 0;
    ${mediaQuery.lg} {
      margin: 0 0 20px 0;
    }
  }

  p {
    margin: 15px 0 30px 0;
  }

  a {
    display: inline-block;
    position: relative;
    margin: 5px 0 10px;
    font-size: 14px;
    letter-spacing: 0.3px;
    color: ${Color.rdPureBlack};
    text-decoration: underline;
    white-space: nowrap;
    transition: color 0.5s;
    font-weight: 600;
  }
`;

export type ShortcutLinkProps = {
  fields: any;
};

const ShortcutLink: React.FC<ShortcutLinkProps> = ({ fields = {} }) => {
  return (
    <Section componentName="ShortcutLink" margin="large">
      <Container>
        <Columns>
          <Row>
            <FieldChecker type="text" field={fields['Title']}>
              <Col lg={12}>
                <Typography type="h3">
                  <Text field={fields['Title']} />
                </Typography>
              </Col>
            </FieldChecker>
            <FieldChecker type="text" field={fields['Description']}>
              <Col lg={12}>
                <Typography type="paragraph">
                  <Text field={fields['Description']} />
                </Typography>
              </Col>
            </FieldChecker>
          </Row>
          <Row>
            <FieldChecker type="text" field={fields['Column 1 Text']}>
              <Col md={6} lg={3}>
                <RichText field={fields['Column 1 Text']} editable={false} />
              </Col>
            </FieldChecker>
            <FieldChecker type="text" field={fields['Column 2 Text']}>
              <Col md={6} lg={3}>
                <RichText field={fields['Column 2 Text']} editable={false} />
              </Col>
            </FieldChecker>
            <FieldChecker type="text" field={fields['Column 3 Text']}>
              <Col md={6} lg={3}>
                <RichText field={fields['Column 3 Text']} editable={false} />
              </Col>
            </FieldChecker>
            <FieldChecker type="text" field={fields['Column 3 Text']}>
              <Col md={6} lg={3}>
                <RichText field={fields['Column 4 Text']} editable={false} />
              </Col>
            </FieldChecker>
          </Row>
        </Columns>
      </Container>
    </Section>
  );
};

export default ShortcutLink;
