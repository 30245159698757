import { useState } from 'react';
import { useBetween } from 'use-between';
import {
  QUERY_KEY_LOANS,
  QUERY_KEY_OWNER_CATEGORY,
  QUERY_KEY_OWN_PAYMENT,
  QUERY_KEY_PROPERTY_TYPE,
  QUERY_KEY_PROPERTY_VALUE,
  TABLE_FIELDKEYS_REALKREDIT_1,
  TABLE_FIELDKEYS_REALKREDIT_2,
} from '../config';
import { getQueryFloatOrNull, getQueryString } from '../helpers/query';
import { LoanStateItem } from '../types/LoanStateItem';
import { ViewType } from '../types/ViewType';

const useInputsStateFn = () => {
  const [loanType, setLoanType] = useState(getQueryString(QUERY_KEY_LOANS));
  const [propertyType, setPropertyType] = useState(getQueryString(QUERY_KEY_PROPERTY_TYPE));
  const [ownerCategory, setOwnerCategory] = useState(getQueryString(QUERY_KEY_OWNER_CATEGORY));
  const [propertyValue, setPropertyValue] = useState<number | null>(getQueryFloatOrNull(QUERY_KEY_PROPERTY_VALUE));
  const [ownPayment, setOwnPayment] = useState<number | null>(getQueryFloatOrNull(QUERY_KEY_OWN_PAYMENT));
  const [viewType, setViewType] = useState<ViewType>('realkredit');
  const [showModal, setShowModal] = useState(false);
  const [activeLoan, setActiveLoan] = useState<null | LoanStateItem>(null);
  const [selectedLoan, setSelectedLoan] = useState<any>('');
  const [propertyCategory, setPropertyCategory] = useState<any>('');
  const [ownPaymentVisible, setOwnPaymentVisible] = useState(true);
  const [tableFieldSet1Keys, setTableFieldSet1Keys] = useState<any>(TABLE_FIELDKEYS_REALKREDIT_1);
  const [tableFieldSet2Keys, setTableFieldSet2Keys] = useState<any>(TABLE_FIELDKEYS_REALKREDIT_2);
  const [wantedLoanAmount, setWantedLoanAmount] = useState<number | null>(null);
  const [remainingDebt, setRemainingDebt] = useState<number | null>(null);
  const [hasError, setError] = useState(false);
  const [submitCounter, setSubmitCounter] = useState(0);
  const [recalculate, setRecalculate] = useState(false);
  const [loanChanged, setLoanChanged] = useState(false);

  return {
    loanType,
    propertyType,
    ownerCategory,
    propertyValue,
    wantedLoanAmount,
    ownPayment,
    viewType,
    showModal,
    activeLoan,
    selectedLoan,
    loanChanged,
    propertyCategory,
    ownPaymentVisible,
    tableFieldSet1Keys,
    tableFieldSet2Keys,
    remainingDebt,
    hasError,
    submitCounter,
    recalculate,
    setRecalculate,
    setLoanChanged,
    setSubmitCounter,
    setError,
    setLoanType,
    setPropertyType,
    setOwnerCategory,
    setPropertyValue,
    setWantedLoanAmount,
    setOwnPayment,
    setRemainingDebt,
    setViewType,
    setShowModal,
    setActiveLoan,
    setSelectedLoan,
    setPropertyCategory,
    setOwnPaymentVisible,
    setTableFieldSet1Keys,
    setTableFieldSet2Keys,
  };
};

export const useInputsState = () => useBetween(useInputsStateFn);
