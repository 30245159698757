import { Modal } from 'db-npm-rdui';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '../../shared/components/ErrorMessage/ErrorMessage';
import { ThankYouMessage } from '../../shared/components/ThankYouMessage/ThankYouMessage';
import { submitFormData } from '../../shared/forms/submitFormData';
import { LaunchData, useAdobeTracking } from '../../shared/hooks';
import NemKontoForm from './NemKontoForm';

export type NemKontoProps = {
  fields: any;
};

const FormNemKonto: React.FC<NemKontoProps> = ({ fields = {} }) => {
  const [page, setPage] = useState(0);
  const { t } = useTranslation();
  const [showModal, setShowModal] = React.useState(false);
  const toggleModal = () => setShowModal(!showModal);

  const launchData: LaunchData = {
    form_product: 'Nemkonto',
    form_type: 'Opt out of nemKonto payment',
    form_name: 'NemKonto opt out',
    form_step: 'Initial step',
    form_status: ['loaded', 'view step'],
  };

  const { trackFormSubmitSuccess, trackFormSubmitFail } = useAdobeTracking(launchData);

  const goToNextPage = () => {
    setPage(page + 1);
    window.scrollTo(0, 0);
  };

  const handleSubmit = async (formData) => {
    const response = await submitFormData(
      {
        apiUrl: fields['API Endpoint URL']?.value,
        apiKey: fields['API Key']?.value,
        egainDsid: fields['Egain DSID']?.value,
      },
      formData
    );
    if (response) {
      trackFormSubmitSuccess();
      goToNextPage();
    } else {
      trackFormSubmitFail();

      //Error Handling
      setShowModal(true);
    }
  };

  /**
   * conditional rendering
   */
  switch (page) {
    case 0:
      return (
        <>
          <NemKontoForm fields={fields} onSubmit={handleSubmit} />
          <Modal open={showModal} handleClose={toggleModal} ariaCloseLabel={'Close modal'} overlayColor="#1f2532">
            <ErrorMessage message={t('form-submit-error')} />
          </Modal>
        </>
      );
    case 1: //Thank You Message
      return <ThankYouMessage fields={fields} />;
  }

  return null;
};

export default FormNemKonto;
