import React from 'react';
import styled from 'styled-components';

const CheckboxWrapper = styled.div`
  padding: 17px 20px;
  border-radius: 5px;
  background: #fff;
  border: 1px solid #9e9e9e;
  color: #000;
`;

const CheckboxInput = styled.input.attrs({
  type: 'checkbox',
})`
  cursor: pointer;
  float: right;
  margin-top: 4px;
`;

const Label = styled.label`
  font-weight: bold;
`;

export type CheckboxProps = {
  label: string;
  name?: string;
  value: any;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const Checkbox: React.FC<CheckboxProps> = ({ label, name, value, onChange, ...rest }) => (
  <CheckboxWrapper>
    <CheckboxInput id={name} name={name} value={value} onChange={onChange} {...rest} />
    <Label htmlFor={name}>{label}</Label>
  </CheckboxWrapper>
);
