import { Modal } from 'db-npm-rdui';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '../../shared/components/ErrorMessage/ErrorMessage';
import { ThankYouMessage } from '../../shared/components/ThankYouMessage/ThankYouMessage';
import { submitFormDataV4 } from '../../shared/forms/submitFormDataV4';
import { LaunchData, useAdobeTracking } from '../../shared/hooks';
import PpiForm from './PpiForm';

export type FormPpiProps = {
  fields: any;
};

const FormPpi: React.FC<FormPpiProps> = ({ fields = {} }) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [showModal, setShowModal] = React.useState(false);
  const toggleModal = () => setShowModal(!showModal);
  const webformName = 'lånesikring RD';
  const launchData: LaunchData = {
    form_product: webformName + ' request',
    form_type: webformName + ' information request',
    form_name: webformName,
    form_step: 'Initial step',
    form_status: ['loaded', 'view step'],
  };

  const { trackFormSubmitSuccess, trackFormSubmitFail } = useAdobeTracking(launchData);

  const goToNextPage = () => {
    setPage(page + 1);
    window.scrollTo(0, 0);
  };

  const onSubmitData = async (formData) => {
    const response = await submitFormDataV4(
      {
        apiUrl: fields['API Endpoint URL']?.value,
        apiKey: fields['API Key']?.value,
        egainDsid: fields['Egain DSID']?.value,
        webFormName: webformName,
      },
      formData
    );
    if (response) {
      goToNextPage();
      trackFormSubmitSuccess();
    } else {
      trackFormSubmitFail();

      //Error Handling
      setShowModal(true);
    }
  };

  /* conditional rendering  */
  switch (page) {
    case 0:
      return (
        <>
          <PpiForm fields={fields} onSubmitData={onSubmitData} />
          <Modal open={showModal} handleClose={toggleModal} ariaCloseLabel={'Close modal'} overlayColor="#1f2532">
            <ErrorMessage message={t('form-submit-error')} />
          </Modal>
        </>
      );
    case 1: //Thank You Message
      return <ThankYouMessage fields={fields} />;
  }

  return null;
};

export default FormPpi;
