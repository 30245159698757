import { Row } from 'db-npm-rdui';
import React from 'react';
import styled from 'styled-components';
import { useIsEditor } from '../../hooks';

const EditorRow = styled(Row)`
  .scEmptyPlaceholder {
    flex-grow: 1;
  }
`;

type ExpEditorRowProps = {
  children: any;
  style?: React.CSSProperties & object;
};

export const ExpEditorRow: React.FC<ExpEditorRowProps> = ({ children, style }): JSX.Element => {
  const isEditor = useIsEditor();
  if (isEditor) {
    return <EditorRow style={style}>{children}</EditorRow>;
  }
  return <Row style={style}>{children}</Row>;
};
