import { Text } from '@sitecore-jss/sitecore-jss-react';
import {
  BottomShadow,
  Box,
  Col,
  Container,
  mediaQuery,
  PopoutBox,
  Row,
  SlantedBackgroundImage,
  Typography,
} from 'db-npm-rdui';
import React from 'react';
import styled from 'styled-components';
import { FieldChecker, LinkAnimatedUnderline, Section } from '../../shared/components';
import imageScaler from '../../shared/utils/imageScaler';

const BgImage = styled(SlantedBackgroundImage)`
  width: 90%;
  position: absolute !important;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;

  ${mediaQuery.md} {
    width: 80%;
  }
`;

export type EntranceSinglePopoutLeftProps = {
  fields: any;
};

const EntranceSinglePopoutLeft: React.FC<EntranceSinglePopoutLeftProps> = ({ fields = {} }) => (
  <Section componentName="EntranceSinglePopoutLeft" margin="large">
    <FieldChecker type="image" field={fields['Image']}>
      <BgImage src={imageScaler(fields['Image']?.value?.src)} slantPosition="left" />
    </FieldChecker>

    <Container>
      <Row>
        <Col md={8} lg={6} xxl={4}>
          <Box marginTop={10} marginBottom={10} style={{ position: 'relative', zIndex: 1 }}>
            <BottomShadow>
              <PopoutBox>
                <FieldChecker type="text" field={fields['Headline']}>
                  <Typography type="h4">
                    <Text field={fields['Headline']} />
                  </Typography>
                </FieldChecker>

                <FieldChecker type="text" field={fields['Description']}>
                  <Typography type="manchetSmall" mt={4} mb={4}>
                    <Text field={fields['Description']} />
                  </Typography>
                </FieldChecker>

                <FieldChecker type="link" field={fields['Link']}>
                  <LinkAnimatedUnderline field={fields['Link']} />
                </FieldChecker>
              </PopoutBox>
            </BottomShadow>
          </Box>
        </Col>
      </Row>
    </Container>
  </Section>
);

export default EntranceSinglePopoutLeft;
