import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Box, ButtonGroup, Col, Container, mediaQuery, Row, Typography } from 'db-npm-rdui';
import * as React from 'react';
import styled from 'styled-components';
import { FieldChecker } from '../FieldChecker/FieldChecker';
import { Button } from '../SitecoreButtons';

const ContainerRow = styled(Row)`
  align-items: center !important;
`;
const ImageColWrapper = styled(Col)`
  ${mediaQuery.mdDown} {
    padding: 0 !important;
    margin-bottom: 30px;
  }
`;
const BackgroundImage = styled.div<{ imagePath: string }>`
  position: relative;
  background-image: url(${(props) => props.imagePath});
  background-position: center;
  background-size: cover;
  max-width: 100%;
  height: 380px;

  ${mediaQuery.xxl} {
    height: 420px;
  }

  ${mediaQuery.mdDown} {
    margin: 0;
    height: 200px;
    width: 100%;
  }
`;

type ImageContainerProps = {
  fields: any;
  direction: 'row' | 'row-reverse';
};

export const ImageContainer: React.FC<ImageContainerProps> = ({ fields = {}, direction }) => {
  return (
    <Container>
      <ContainerRow direction={direction}>
        <ImageColWrapper md={6}>
          <FieldChecker type="image" field={fields['Image']}>
            <BackgroundImage imagePath={fields['Image']?.value?.src} />
          </FieldChecker>
        </ImageColWrapper>
        {direction === 'row-reverse' ? <Col md={2}></Col> : <Col md={1}></Col>}
        <Col md={4}>
          <FieldChecker type="text" field={fields['Headline']}>
            <Typography type="h4">
              <Text field={fields['Headline']} />
            </Typography>
          </FieldChecker>

          <FieldChecker type="text" field={fields['Description']}>
            <Box marginTop={4} marginBottom={5}>
              <Typography type="manchetSmall">
                <Text field={fields['Description']} />
              </Typography>
            </Box>
          </FieldChecker>

          <ButtonGroup>
            <FieldChecker type="link" field={fields['Button Primary']}>
              <Button field={fields['Button Primary']} />
            </FieldChecker>
            <FieldChecker type="link" field={fields['Button Secondary']}>
              <Button variant="secondary" field={fields['Button Secondary']} />
            </FieldChecker>
          </ButtonGroup>
        </Col>
        {direction === 'row' ? <Col md={1}></Col> : ''}
      </ContainerRow>
    </Container>
  );
};
