import { Image, Text } from '@sitecore-jss/sitecore-jss-react';
import { Box, Col, Container, Row, Typography } from 'db-npm-rdui';
import React from 'react';
import { FieldChecker, LinkAnimatedUnderline, Section } from '../../shared/components';

export type CenteredTextProps = {
  fields: any;
};

const CenteredText: React.FC<CenteredTextProps> = ({ fields = {} }) => (
  <Section componentName="CenteredText" margin="small">
    <Container>
      <Row justify="center">
        <Col sm={6}>
          <Box textAlign="center">
            <FieldChecker type="image" field={fields['Icon']}>
              <Box marginBottom={5}>
                <Image field={fields['Icon']} />
              </Box>
            </FieldChecker>

            <FieldChecker type="text" field={fields['Headline']}>
              <Typography type="h6">
                <Text field={fields['Headline']} />
              </Typography>
            </FieldChecker>

            <FieldChecker type="text" field={fields['Text']}>
              <Typography type="manchetSmall" mt={3}>
                <Text field={fields['Text']} />
              </Typography>
            </FieldChecker>

            <FieldChecker type="link" field={fields['Link']}>
              <Box marginTop={5}>
                <LinkAnimatedUnderline field={fields['Link']} />
              </Box>
            </FieldChecker>
          </Box>
        </Col>
      </Row>
    </Container>
  </Section>
);

export default CenteredText;
