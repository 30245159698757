import { Text, withPlaceholder } from '@sitecore-jss/sitecore-jss-react';
import { PlaceholderProps } from '@sitecore-jss/sitecore-jss-react/types/components/PlaceholderCommon';
import { BottomShadow, Box, Col, Container, mediaQuery, Row, Typography } from 'db-npm-rdui';
import * as React from 'react';
import styled from 'styled-components';
import { FieldChecker, LabelGrey, Section } from '../../shared/components';
import { useIsEditor } from '../../shared/hooks';

const ColumnsBox = styled.div`
  ${mediaQuery.ie11} {
    min-height: 290px;
  }
  position: relative;
  z-index: 1;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
  padding: 10px 20px;

  ${mediaQuery.md} {
    padding: 50px 20px;
  }
`;

export type HeroFrontpageProps = PlaceholderProps & {
  fields: any;
  columns: any;
};

const HeroFrontpage: React.FC<HeroFrontpageProps> = ({ fields = {}, columns }) => {
  const hasColumns = columns && columns.length > 0;
  const isEditor = useIsEditor();
  const showColumns = hasColumns || isEditor;

  return (
    <Section componentName="HeroFrontpage">
      <Box textAlign="center" paddingTop={{ _: 6, md: 8, lg: 10, xl: 12 }}>
        <Container>
          <Row justify="center">
            <Col sm={10} md={7}>
              <FieldChecker type="text" field={fields['Label']}>
                <Box marginBottom={5}>
                  <LabelGrey field={fields['Label']} />
                </Box>
              </FieldChecker>

              <FieldChecker type="text" field={fields['Headline']}>
                <Typography type="display">
                  <Text field={fields['Headline']} />
                </Typography>
              </FieldChecker>

              <FieldChecker type="text" field={fields['Description']}>
                <Box marginTop={5}>
                  <Typography type="manchetBig">
                    <Text field={fields['Description']} />
                  </Typography>
                </Box>
              </FieldChecker>
            </Col>
          </Row>
        </Container>
      </Box>

      {showColumns && (
        <Box marginTop={{ _: 6, md: 8 }}>
          <Container>
            <BottomShadow>
              <ColumnsBox>
                <Row justify="center">{columns}</Row>
              </ColumnsBox>
            </BottomShadow>
          </Container>
        </Box>
      )}
    </Section>
  );
};

export default withPlaceholder('columns')(HeroFrontpage);
