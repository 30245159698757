import styled from 'styled-components';
import { ComponentMarginSize, componentTopMargin } from '../../utils/componentTopMargin';

type Props = {
  componentName?: string;
  margin?: ComponentMarginSize;
};

export const Section = styled.div.attrs<Props>((props) => {
  if (process.env.NODE_ENV === 'development') {
    return {
      'data-component': props.componentName,
    };
  }
})<Props>`
  position: relative;
  ${(props) => props.margin && componentTopMargin(props.margin)}
`;
