import * as React from 'react';
import { Section } from '../../shared/components';
import { LaunchData, useAdobeTracking } from '../../shared/hooks';
import { CalculatorDetailsLinks } from './components/detailslinks/CalculatorDetailsLinks';
import { CalculatorInputs, CalculatorInputsOnSubmit } from './components/inputs/CalculatorInputs';
import { CalculatorModal } from './components/modal/CalculatorModal';
import { CalculatorStickyBar } from './components/stickybar/CalculatorStickyBar';
import { CalculatorTablesContainer } from './components/table/CalculatorTablesContainer';
import { CalculatorTabs } from './components/tabs/CalculatorTabs';
import { HTML_ID_SUBMIT_BUTTON, QUERY_KEY_OWN_PAYMENT, QUERY_KEY_PROPERTY_VALUE } from './config';
import { getQueryFloatOrNull } from './helpers/query';
import { scrollToFocusArea } from './helpers/scroll';
import { useInputsState } from './state/inputsState';
import { useLoansState } from './state/loansState';
import { useSettingsState } from './state/settingsState';

export type LoanCalculatorProps = {
  fields: any;
};

const LoanCalculator: React.FC<LoanCalculatorProps> = ({ fields = {} }) => {
  const { settings, setSettings, setApiUrl } = useSettingsState();
  const { setInitialLoansFromSettings, setLoansAfterChange, calculateLoansFromScratch } = useLoansState();
  const settingsRef = React.useRef({}) as any;
  if (!Object.keys(settingsRef.current).length) {
    settingsRef.current = JSON.parse(fields['Settings']?.value || '{}');
  }

  const launchData: LaunchData = {
    calculator_type: settingsRef.current?.calculatorType ?? 'Private loan',
    calculator_name: 'Loan calculator - ' + settingsRef.current?.calculatorType ?? 'Private loan',
    calculator_info: '',
    calculator_status: 'loaded',
  };
  const {
    getLaunchData,
    setLaunchData,
    trackCalculatorSuccess,
    trackCalculatorReCalculation,
    trackCalculatorFailed,
    trackCalculatorInteraction,
  } = useAdobeTracking(launchData);
  const {
    ownPayment,
    propertyValue,
    propertyType,
    propertyCategory,
    wantedLoanAmount,
    remainingDebt,
    submitCounter,
    selectedLoan,
    recalculate,
    loanChanged,
    setRecalculate,
    setLoanChanged,
  } = useInputsState();
  const { loans } = useLoansState();
  const prevTrackingState = React.useRef({});

  /**
   * Track on loan state changes
   */
  React.useLayoutEffect(() => {
    const loanList = loans.map((value) => value.type).join(' - ');

    loans.forEach((loan, i) => {
      if (!prevTrackingState.current[loan.type] && submitCounter <= 1) {
        if (
          loan.status === 'done' &&
          getLaunchData('calculator_status') !== 'completed' &&
          getLaunchData('calculator_status') !== 're-calculated'
        ) {
          trackCalculatorSuccess({
            calculator_amount: propertyValue || 0,
            calculator_info:
              'loan_product:' +
              loan.type +
              ',property_type:' +
              propertyType +
              ',property_category:' +
              propertyCategory +
              ',own_value:' +
              ownPayment +
              ',wanted_loan_amount:' +
              wantedLoanAmount +
              ',remaining_debt:' +
              remainingDebt,
            loan_calculate_type: loanList,
          });
        } else if (loan.status === 'error' && getLaunchData('calculator_status') !== 'failed') {
          trackCalculatorFailed('API error');
        }
      }
      if (prevTrackingState.current[loan.type] && submitCounter > 1 && recalculate) {
        if (
          loan.status === 'done' &&
          (getLaunchData('calculator_status') === 'completed' ||
            getLaunchData('calculator_status') === 're-calculated' ||
            getLaunchData('calculator_status') === 'failed')
        ) {
          setRecalculate(false);
          trackCalculatorReCalculation({
            calculator_amount: propertyValue || 0,
            calculator_info:
              'loan_product:' +
              loan.type +
              ',property_type:' +
              propertyType +
              ',property_category:' +
              propertyCategory +
              ',own_value:' +
              ownPayment +
              ',wanted_loan_amount:' +
              wantedLoanAmount +
              ',remaining_debt:' +
              remainingDebt,
            loan_calculate_type: loanList,
          });
        } else if (loan.status === 'error' && getLaunchData('calculator_status') !== 'failed') {
          trackCalculatorFailed('API error');
          setRecalculate(false);
        }
      }
      if (prevTrackingState.current[loan.type] && submitCounter > 1 && loanChanged) {
        if (
          loan.status === 'done' &&
          loan.type === selectedLoan &&
          (getLaunchData('calculator_status') === 'completed' ||
            getLaunchData('calculator_status') === 're-calculated' ||
            getLaunchData('calculator_status') === 'failed')
        ) {
          setLoanChanged(false);
          trackCalculatorReCalculation({
            calculator_amount: propertyValue || 0,
            calculator_info:
              'loan_product:' +
              loan.type +
              ',property_type:' +
              propertyType +
              ',property_category:' +
              propertyCategory +
              ',own_value:' +
              ownPayment +
              ',wanted_loan_amount:' +
              wantedLoanAmount +
              ',remaining_debt:' +
              remainingDebt,
            loan_calculate_type: loanList,
          });
        } else if (loan.status === 'error' && getLaunchData('calculator_status') !== 'failed') {
          trackCalculatorFailed('API error');
          setLoanChanged(false);
        }
      }
      if (!prevTrackingState.current[loan.type] && loan.status === 'done')
        prevTrackingState.current[loan.type] = loan.status;
    });
  }, [
    loans,
    propertyValue,
    propertyType,
    propertyCategory,
    wantedLoanAmount,
    remainingDebt,
    ownPayment,
    submitCounter,
    selectedLoan,
    loanChanged,
    recalculate,
    setRecalculate,
    setLoanChanged,
    getLaunchData,
    trackCalculatorFailed,
    trackCalculatorSuccess,
    trackCalculatorReCalculation,
  ]);

  /**
   * Set settings and initial loans
   */
  React.useEffect(() => {
    // const settings = JSON.parse(fields['Settings']?.value || '{}');
    const apiUrl = fields['API Endpoint URL']?.value || '';
    setSettings(settingsRef.current);
    setApiUrl(apiUrl);
    const url = new URL(window.location.href);
    const hasType = url.searchParams.get('type')?.toString();
    if (hasType) {
      setLoansAfterChange(settings, hasType);
    } else {
      setInitialLoansFromSettings(settingsRef.current);
    }
  }, [fields, settings, setSettings, setApiUrl, setInitialLoansFromSettings, setLoansAfterChange, settingsRef]);

  /**
   * On page load, try clicking the submit button and do a calculation
   */
  React.useEffect(() => {
    if (getQueryFloatOrNull(QUERY_KEY_PROPERTY_VALUE) !== null && getQueryFloatOrNull(QUERY_KEY_OWN_PAYMENT) !== null) {
      setLaunchData({ calculator_type: 'Hero module' });
      setTimeout(() => {
        document.getElementById(HTML_ID_SUBMIT_BUTTON)?.click();
      }, 300);
    }
  }, [setLaunchData]);

  /**
   * Track calcuation if directed from calculator hero module - keep in separate effect to avoid looping calculation submit click
   */
  React.useEffect(() => {
    if (getQueryFloatOrNull(QUERY_KEY_PROPERTY_VALUE) !== null && getQueryFloatOrNull(QUERY_KEY_OWN_PAYMENT) !== null) {
      setTimeout(() => {
        trackCalculatorInteraction();
      }, 300);
    }
  }, [trackCalculatorInteraction]);

  /**
   * Callback to run when submitting inputs on the calculator page
   */
  const onInputsSubmit: CalculatorInputsOnSubmit = () => {
    calculateLoansFromScratch();
    scrollToFocusArea();
  };

  return (
    <Section componentName="LoanCalculator" margin="small">
      <CalculatorInputs onSubmit={onInputsSubmit} />
      <CalculatorTabs />
      <CalculatorTablesContainer />
      <CalculatorDetailsLinks />
      <CalculatorStickyBar />
      <CalculatorModal title={fields['Modal Title']} />
    </Section>
  );
};

export default LoanCalculator;
