import { Button, ErrorMessage, InputField, Select, Typography } from 'db-npm-rdui';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { AutoTextArea } from '../../shared/components/AutoTextArea/AutoTextArea';
import { Checkbox } from '../../shared/components/Checkbox/Checkbox';
import { FormLayout, FormRow } from '../../shared/components/Form';
import { LaunchData, useAdobeTracking } from '../../shared/hooks';
import { getSchema } from './schema';

type Props = {
  fields: any;
  onSubmitData: (formData: any) => Promise<void>;
};

const BUSINESS_TOPIC_OPTIONS = [
  {
    label: 'Book møde',
    value: 'Book møde',
  },
  {
    label: 'Ny kunde',
    value: 'Ny kunde',
  },
  {
    label: 'Køb af ejendom',
    value: 'Køb af ejendom',
  },
  {
    label: 'Nyt lån/tillægslån',
    value: 'Nyt lån/tillægslån',
  },
  {
    label: 'Omlægning af lån',
    value: 'Omlægning af lån',
  },
  {
    label: 'Udløb af afdragsfrihed',
    value: 'Udløb af afdragsfrihed',
  },
  {
    label: 'Rentetilpasning',
    value: 'Rentetilpasning',
  },
  {
    label: 'Indfrielse af lån',
    value: 'Indfrielse af lån',
  },
  {
    label: 'Opsigelse af lån',
    value: 'Opsigelse af lån',
  },
  {
    label: 'Andet',
    value: 'Andet',
  },
];

const PRIVATE_TOPIC_OPTIONS = [
  {
    label: 'Ny kunde',
    value: 'Ny kunde',
  },
  {
    label: 'Køb af bolig',
    value: 'Køb af bolig',
  },
  {
    label: 'Salg af bolig',
    value: 'Salg af bolig',
  },
  {
    label: 'Nyt lån/tillægslån',
    value: 'Nyt lån/tillægslån',
  },
  {
    label: 'Omlægning af lån',
    value: 'Omlægning af lån',
  },
  {
    label: 'Udløb af afdragsfrihed',
    value: 'Udløb af afdragsfrihed',
  },
  {
    label: 'Rentetilpasning',
    value: 'Rentetilpasning',
  },
  {
    label: 'Indfrielse af lån',
    value: 'Indfrielse af lån',
  },
  {
    label: 'Opsigelse af lån',
    value: 'Opsigelse af lån',
  },
  {
    label: 'Andet',
    value: 'Andet',
  },
];

const WhiteBg = styled.div`
  background: #fff;
`;

const LabelHeader = styled(Typography)`
  display: block;
  margin-bottom: 10px;
`;

const CallMeForm: React.FC<Props> = ({ fields = {}, onSubmitData }) => {
  const privateMailboxId = fields['Private Mailbox ID']?.value || '';
  const businessMailboxId = fields['Business Mailbox ID']?.value || '';
  const partialMailSubject = fields['Mail Subject']?.value;
  const [TOPIC_OPTIONS, setTopic] = useState(PRIVATE_TOPIC_OPTIONS);
  const [mailBoxId, setmailBoxId] = useState(privateMailboxId);

  const { t } = useTranslation();
  const schema = getSchema(t);
  const launchData: LaunchData = {
    form_product: 'Contact form',
    form_type: 'Customer contact via telephone',
    form_name: 'Call me form',
    form_step: 'Initial step',
    form_status: ['loaded', 'view step'],
  };
  const { onFormInteraction } = useAdobeTracking(launchData);

  useEffect(() => {
    const url = new URL(window.location.href);
    const isBusiness = url.searchParams.get('formtype')?.toLowerCase() === 'business';
    if (isBusiness) {
      setTopic(BUSINESS_TOPIC_OPTIONS);
      setmailBoxId(businessMailboxId);
    }
  }, [businessMailboxId]);

  return (
    <FormLayout headline={fields['Headline']?.value} description={fields['Description']?.value}>
      <Formik
        initialValues={{
          topic: TOPIC_OPTIONS[0].value,
          isExistingCustomer: false,
          fullname: '',
          phone: '',
          email: '',
          comment: '',
          mailboxId: mailBoxId,
          emailsubject: 'Ny kunde - Er ikke kunde - ' + partialMailSubject,
        }}
        validationSchema={schema}
        onSubmit={(values) => {
          onSubmitData(values);
        }}
      >
        {({ values, errors, touched, handleChange }) => {
          const trackingChangeHandler = (e) => {
            onFormInteraction(e, handleChange);
          };

          const setSubjectFromTopic = (e) => {
            if (!showExistingCustomer(e.currentTarget.value)) {
              values.isExistingCustomer = false;
            }
            if (values.isExistingCustomer) {
              values.emailsubject = e.currentTarget.value + ' - Er kunde - ' + partialMailSubject;
            } else {
              values.emailsubject = e.currentTarget.value + ' - Er ikke kunde - ' + partialMailSubject;
            }
            trackingChangeHandler(e);
          };

          const setSubjectFromCheckbox = (e) => {
            if (e.currentTarget.checked) {
              values.emailsubject = values.topic + ' - Er kunde ' + partialMailSubject;
              e.currentTarget.dataset.stringvalue = 'Ja';
            } else {
              values.emailsubject = values.topic + ' - Er ikke kunde ' + partialMailSubject;
              e.currentTarget.dataset.stringvalue = 'Nej';
            }
            trackingChangeHandler(e);
          };

          const showExistingCustomer = (topic) =>
            topic !== BUSINESS_TOPIC_OPTIONS[1].value && topic !== PRIVATE_TOPIC_OPTIONS[0].value;

          return (
            <Form noValidate>
              <FormRow>
                <LabelHeader type="label" as="label" htmlFor="topic">
                  {fields['Form Title']?.value}
                </LabelHeader>
                <WhiteBg>
                  <Select
                    name="topic"
                    id="topic"
                    onChange={setSubjectFromTopic}
                    options={TOPIC_OPTIONS}
                    hasBorder
                    value={values.topic}
                    isBold
                    data-maptoegain="true"
                    data-egainfield="misc_one"
                    data-label={fields['Form Title']?.value}
                    data-includeinmailboxemail="true"
                  />
                </WhiteBg>
                {touched.topic && errors.topic && <ErrorMessage>{errors.topic}</ErrorMessage>}
              </FormRow>
              <FormRow>
                {showExistingCustomer(values.topic) && (
                  <Checkbox
                    label={t('form-checkbox-iscustomer-label')}
                    name="isExistingCustomer"
                    value={values.isExistingCustomer}
                    data-stringvalue="Nej"
                    data-maptoegain="true"
                    data-egainfield="misc_two"
                    data-label={t('form-checkbox-iscustomer-label')}
                    data-includeinmailboxemail="true"
                    onChange={setSubjectFromCheckbox}
                  />
                )}
              </FormRow>
              <FormRow>
                <InputField
                  name="fullname"
                  id="fullname"
                  label={t('form-input-name-label')}
                  layout="white"
                  value={values.fullname}
                  onChange={trackingChangeHandler}
                  maxLength={50}
                  data-maptoegain="true"
                  data-egainfield="customer_name"
                  data-label={t('form-input-name-label')}
                  data-includeinmailboxemail="true"
                  errorMessage={touched.fullname && errors.fullname ? errors.fullname : ''}
                  required
                />
              </FormRow>

              <FormRow>
                <InputField
                  name="phone"
                  id="phone"
                  label={t('form-input-phone-label')}
                  layout="white"
                  value={values.phone}
                  maxLength={20}
                  data-maptoegain="true"
                  data-includeinmailboxemail="true"
                  data-egainfield="customer_phone"
                  data-label={t('form-input-phone-label')}
                  onChange={trackingChangeHandler}
                  errorMessage={touched.phone && errors.phone ? errors.phone : ''}
                  required
                />
              </FormRow>

              <FormRow>
                <InputField
                  name="email"
                  id="email"
                  label={t('form-input-email-label')}
                  layout="white"
                  value={values.email}
                  maxLength={100}
                  data-maptoegain="true"
                  data-includeinmailboxemail="true"
                  data-egainfield="email"
                  data-label={t('form-input-email-label')}
                  onChange={trackingChangeHandler}
                  errorMessage={touched.email && errors.email ? errors.email : ''}
                  required
                />
              </FormRow>

              <FormRow>
                <AutoTextArea
                  label={t('form-textarea-comment-label')}
                  id="comment"
                  name="comment"
                  value={values.comment}
                  maxLength={250}
                  data-maptoegain="true"
                  data-includeinmailboxemail="true"
                  data-egainfield="misc_text"
                  data-label={t('form-textarea-comment-label')}
                  autosize={true}
                  onChange={trackingChangeHandler}
                />
              </FormRow>

              <FormRow>
                <Button onClick={trackingChangeHandler} id="submit" type="submit">
                  {t('form-button-send-label')}
                </Button>
              </FormRow>
            </Form>
          );
        }}
      </Formik>
    </FormLayout>
  );
};

export default CallMeForm;
