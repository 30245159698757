import { useEffect, useState } from 'react';
import type { Item } from './ExpandedTextAccordion';

export const useVisibleSection = (items: Item[]) => {
  const [visibleSection, setVisibleSection] = useState('');

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const uid = entry.target.getAttribute('data-uid');
          if (entry.isIntersecting && uid) {
            window.location.replace('#' + uid);
            setVisibleSection(uid);
          }
        });
      },
      { rootMargin: `0% 0% -80% 0%` }
    );

    items.forEach((item) => {
      if (item.ref.current) {
        observer.observe(item.ref.current);
      }
    });

    return () => {
      items.forEach((item) => {
        if (item.ref.current) {
          observer.unobserve(item.ref.current);
        }
      });
    };
  }, [items]);

  return visibleSection;
};
