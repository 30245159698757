import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { applyTextType, Box, Col, Container, mediaQuery, RddkTheme, Row } from 'db-npm-rdui';
import * as React from 'react';
import styled from 'styled-components';
import { FieldChecker, Section } from '../../shared/components';
import imageScaler from '../../shared/utils/imageScaler';

const IMAGE_SIZE_LARGE = 700;
const IMAGE_SIZE_SMALL = 450;

export type ImageHeaderProps = {
  fields: any;
};

const ImageHeader: React.FC<ImageHeaderProps> = ({ fields = {} }) => {
  const imageSize = fields['Large Image']?.value === true ? IMAGE_SIZE_LARGE : IMAGE_SIZE_SMALL;

  return (
    <Section componentName="ImageHeader">
      <FieldChecker type="image" field={fields['Image']}>
        <Box
          height={{
            _: 220,
            md: imageSize * 0.55,
            xl: imageSize * 0.7,
          }}
        >
          <CoverImage
            height={{
              _: 290,
              md: imageSize * 0.75,
              xl: imageSize,
            }}
            backgroundImage={imageScaler(fields['Image']?.value?.src)}
          />
        </Box>
      </FieldChecker>

      <TextContainer>
        <Row>
          <Col>
            <TextOverlayBox
              minHeight={{
                _: 290 - 220,
                md: imageSize * 0.75 - imageSize * 0.55,
                xl: imageSize - imageSize * 0.7,
              }}
              paddingTop={{
                _: 6,
                md: 7,
                xl: 9,
              }}
            >
              <FieldChecker type="text" field={fields['Label']}>
                <Label marginBottom={5}>
                  <Text field={fields['Label']} />
                </Label>
              </FieldChecker>

              <FieldChecker type="text" field={fields['Text']}>
                <RichText field={fields['Text']} />
              </FieldChecker>
            </TextOverlayBox>
          </Col>
        </Row>
      </TextContainer>
    </Section>
  );
};

export default ImageHeader;

const CoverImage = styled(Box)<{ backgroundImage?: string }>`
  background-image: url(${(props) => props.backgroundImage});
  background-position: center;
  background-size: cover;
`;

const TextContainer = styled(Container)`
  ${mediaQuery.smDown} {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
`;

const TextOverlayBox = styled(Box)`
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
  background: #fff;
  border-radius: 10px 10px 0 0;

  h1,
  h2,
  h3 {
    margin: 0;
    ${applyTextType('h2')}
  }

  p {
    ${applyTextType('manchetBig')}
    color: ${RddkTheme.colors.grey90};
  }

  a {
    color: ${RddkTheme.colors.red40};
  }
`;

const Label = styled(Box)`
  display: inline-block;
  border-radius: 3px;
  padding: 7px 17px 6px 18px;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.25;
  letter-spacing: 0.8px;
  text-align: center;
  border: solid 2px ${RddkTheme.colors.grey90};
`;
