import { getBankHolidays } from './getBankHolidays';
import dayjs from 'dayjs';

/**
 * This function takes a date and returns the first date of the next quarter.
 * Example: January 1st => April 1st.
 * @param date The input date.
 * @returns The date of the next quarter.
 */
export const snapDateToQuarter = (date: Date): Date => {
  const d = dayjs(date);

  if (d.month() > 8) {
    return d.add(1, 'year').set('month', 0).set('date', 1).toDate();
  }
  if (d.month() > 5) {
    return d.set('month', 9).set('date', 1).toDate();
  }
  if (d.month() > 2) {
    return d.set('month', 6).set('date', 1).toDate();
  }
  return d.set('month', 3).set('date', 1).toDate();
};

/** Formats a date object to a string in this format: YYYY-MM-DD */
export const formatPayloadDate = (date: Date): string => {
  return dayjs(date).format('YYYY-MM-DD');
};

/** Given a date object, this will return true if the date is a bank business day as a date object */
export const isBusinessDay = (date: Date): boolean => {
  const holidays = getBankHolidays(date.getFullYear());
  return !(formatPayloadDate(date) in holidays) && date.getDay() > 0 && date.getDay() < 6;
};

/** Given a date object, this will return the next available bank business day as a date object */
export const getNextAvailableBusinessDay = (date: Date): Date => {
  while (!isBusinessDay(date)) {
    return getNextAvailableBusinessDay(dayjs(date).add(1, 'day').toDate());
  }
  return date;
};
