import i18next from 'i18next';
import * as React from 'react';
import { formatAsCurrency } from '../../../../shared/utils/currency';
import { CalculatorTooltipAdministrationFee } from '../../components/tooltip/CalculatorTooltipAdministrationFee';
import { CITY_COMMERCIAL } from '../../config';
import { ValidateNetProceedsAmountTop } from '../../helpers/tableItemValidation';
import { CalculatorInputs } from '../../types/CalculatorInputs';
import { LoanStateItem } from '../../types/LoanStateItem';
import { TableItem } from '../../types/TableItem';
import { ObligationslaanResponse } from './response-mapper';

export const convertToTableItems = (
  response: ObligationslaanResponse,
  loan: LoanStateItem,
  inputs: CalculatorInputs
): TableItem[] => {
  const items: TableItem[] = [];
  let isHolidayHome = inputs.propertyType === 'HolidayHouse';
  const maturityYears = inputs.propertyType === CITY_COMMERCIAL ? loan.settings?.businessCommercialMaturity : 30;
  const displayType = loan.settings?.displayAsLending === true;
  const showIOSlider =
    loan.type === 'obligationslaan' && loan?.payload?.product?.maturity.years > 20 && isHolidayHome
      ? Math.round(response.loanToValuePercentage) < 60
      : !isHolidayHome;
  const propertyDepositDisclaimer = inputs.wantedLoanAmount
    ? i18next.t('calculator-row-business-propertyDepositDisclaimer-value')
    : i18next.t('calculator-row-propertyDepositDisclaimer-value');

  // Table: Fastforrentet 1
  if (response.bankloan > 0) {
    items.push({
      fieldKey: 'bankloan',
      inputType: 'static',
      value: formatAsCurrency(response.bankloan),
      suffix: ' kr.',
    });
  }

  items.push({
    fieldKey: 'ownpayment',
    inputType: 'static',
    value: formatAsCurrency(response.ownpayment),
    suffix: ' kr.',
  });

  if (displayType) {
    items.push({
      fieldKey: 'netProceedsAmountTop',
      inputType: 'input',
      validate: true,
      hasError: {
        error: false,
        errorMsg: '',
      },
      value: Math.floor(response.netProceedsAmount),
      suffix: ' kr.',
      onChange(loan, value) {
        const { payload, settings, tableItems } = loan;

        settings.modifiedNetProceeds = true;

        if (value > response.netProceedsAmount - 1000) {
          payload.basic.calculationType = 'Maximum';
          payload.basic.wantedAdditionalAmount = 0;
        } else {
          payload.basic.wantedAdditionalAmount = parseInt(value);
          payload.basic.calculationType = 'Proceeds';
        }

        const checkError = ValidateNetProceedsAmountTop(value, inputs.propertyValue, 'netProceedsAmountTop');
        if (checkError && checkError.error) {
          tableItems?.forEach((value) => {
            if (value.fieldKey === 'netProceedsAmountTop') {
              value.hasError = {
                error: true,
                errorMsg: checkError.errorMsg,
              };
            }
          });
        } else {
          tableItems?.forEach((value) => {
            if (value.fieldKey === 'netProceedsAmountTop') {
              value.hasError = {
                error: false,
                errorMsg: '',
              };
            }
          });
        }

        return loan;
      },
    });
  } else {
    items.push({
      fieldKey: 'netProceedsAmountTop',
      inputType: 'static',
      value: formatAsCurrency(response.netProceedsAmount),
      suffix: ' kr.',
    });
  }

  items.push({
    fieldKey: 'firstYearInstalmentMonthlyPaymentGrossTop',
    inputType: 'static',
    value: inputs.wantedLoanAmount
      ? formatAsCurrency(response.firstYearInstalmentMonthlyPaymentGross * 12)
      : formatAsCurrency(response.firstYearInstalmentMonthlyPaymentGross),
    suffix: ' kr.',
  });

  if (response.firstYearInterestOnlyMonthlyPayment > 0 && showIOSlider) {
    items.push({
      fieldKey: 'firstYearInterestOnlyMonthlyPaymentTop',
      inputType: 'static',
      value: inputs.wantedLoanAmount
        ? formatAsCurrency(response.firstYearInterestOnlyMonthlyPayment * 12)
        : formatAsCurrency(response.firstYearInterestOnlyMonthlyPayment),
      suffix: ' kr.',
    });
  }

  items.push({
    fieldKey: 'maturityTop',
    inputType: 'slider',
    value: response.maturity,
    suffix: ' år',
    min: response.firstYearInterestOnlyMonthlyPayment > 0 ? 15 : 10,
    max: maturityYears,
    onChange(loan, value) {
      const { payload } = loan;

      payload.product.maturity.years = value;

      payload.product.interestOnly.years = 0;

      payload.product.interestOnly.wantInterestOnlyCoupon = false;

      return payload;
    },
  });

  showIOSlider &&
    items.push({
      fieldKey: 'interestOnlyYearsStart',
      inputType: 'slider',
      value: response.interestOnlyYearsStart,
      min: 0,
      max: 10,
      suffix: ' år',
      onChange(loan, value) {
        const { payload } = loan;

        payload.product.interestOnly.years = value;
        payload.product.interestOnly.wantInterestOnlyCoupon = value > 0 ? true : false;

        if (payload.product.interestOnly.years > 0) {
          payload.product.maturity.years = Math.max(payload.product.maturity.years, 15);
        }

        return payload;
      },
    });

  items.push({
    fieldKey: 'rateType',
    inputType: 'static',
    value: response.rateType,
  });

  items.push({
    fieldKey: 'loanToValuePercentage',
    inputType: 'static',
    value: Math.round(response.loanToValuePercentage),
    suffix: ' %',
  });

  items.push({
    fieldKey: 'taxRate',
    inputType: 'static',
    value: formatAsCurrency(response.taxRate, 2),
    suffix: ' %',
  });

  // Table: Fastforrentet 2
  items.push({
    fieldKey: 'loanProvider',
    inputType: 'static',
    value: response.loanProvider,
  });

  items.push({
    fieldKey: 'netProceedsAmount',
    inputType: 'static',
    value: formatAsCurrency(response.netProceedsAmount),
    suffix: ' kr.',
  });

  items.push({
    fieldKey: 'principalAmount',
    inputType: 'static',
    value: formatAsCurrency(response.principalAmount),
    suffix: ' kr.',
  });

  items.push({
    fieldKey: 'maturityBottom',
    inputType: 'static',
    value: response.maturity,
    suffix: ' år',
  });

  items.push({
    fieldKey: 'monthlyGrossPaymentYearOneBottom',
    inputType: 'static',
    value: inputs.wantedLoanAmount
      ? formatAsCurrency(response.monthlyGrossPaymentYearOne * 12)
      : formatAsCurrency(response.monthlyGrossPaymentYearOne),
    suffix: ' kr.',
  });

  items.push({
    fieldKey: 'numberOfPayments',
    inputType: 'static',
    value: response.numberOfPayments,
  });

  items.push({
    fieldKey: 'debtorInterestRate',
    inputType: 'static',
    value: `(${response.rateType}) ${formatAsCurrency(response.debtorInterestRate, 2)}`,
    suffix: ' %',
  });

  items.push({
    fieldKey: 'weightedBondPrice',
    inputType: 'static',
    value: formatAsCurrency(response.weightedBondPrice, 10),
  });

  items.push({
    fieldKey: 'annualPercentageRate',
    inputType: 'static',
    value: formatAsCurrency(response.annualPercentageRate, 2),
    suffix: ' %',
  });

  items.push({
    fieldKey: 'totalAdministrationFee',
    inputType: 'static',
    value: formatAsCurrency(response.totalAdministrationFee),
    suffix: ' kr.',
    tooltip: <CalculatorTooltipAdministrationFee {...response} />,
  });

  items.push({
    fieldKey: 'totalRepaymentAmount',
    inputType: 'static',
    value: formatAsCurrency(response.totalRepaymentAmount),
    suffix: ' kr.',
  });

  items.push({
    fieldKey: 'propertyDepositDisclaimer',
    inputType: 'static',
    value: propertyDepositDisclaimer,
    tooltip: false,
  });

  items.push({
    fieldKey: 'paymentsPerYear',
    inputType: 'dropdown',
    value: response.paymentsPerYear,
    options: [
      {
        label: '4',
        value: '4',
      },
      {
        label: '12',
        value: '12',
      },
    ],
    onChange(loan, value) {
      const { payload } = loan;

      payload.product.paymentsPerYear = parseInt(value);

      return payload;
    },
  });

  items.push({
    fieldKey: 'monthlyPaymentWithInstallment',
    inputType: 'static',
    value: formatAsCurrency(response.firstYearInstalmentMonthlyPaymentNet),
    suffix: ' kr.',
  });

  if (response.firstYearWithOutInstalmentMonthlyPaymentNet > 0 && showIOSlider) {
    items.push({
      fieldKey: 'monthlyPaymentWithOutInstallment',
      inputType: 'static',
      value: formatAsCurrency(response.firstYearWithOutInstalmentMonthlyPaymentNet),
      suffix: ' kr.',
    });
  }

  items.push({
    fieldKey: 'businessMonthlyPaymentWithInstallment',
    inputType: 'static',
    value: formatAsCurrency(response.firstYearInstalmentMonthlyPaymentNet * 12),
    suffix: ' kr.',
  });

  if (response.firstYearWithOutInstalmentMonthlyPaymentNet > 0) {
    items.push({
      fieldKey: 'businessMonthlyPaymentWithOutInstallment',
      inputType: 'static',
      value: formatAsCurrency(response.firstYearWithOutInstalmentMonthlyPaymentNet * 12),
      suffix: ' kr.',
    });
  }

  return items;
};
