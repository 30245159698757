import { ConsentLevel, ConsentLevels } from 'db-npm-rdui';
import load from 'load-script';
import { ParserFunctionInterface } from '../../';
import { ContextSettings } from '../../../../../shared/hooks';
import './styles.css';

//TODO
// Support list type player
// Advanced sizing/resizing used for list type player

const EXT_SCRIPT_URL = 'https://play2.qbrick.com/framework/GoBrain.min.js';
const EXT_CONFIG_URL = '//video.qbrick.com/play2/api/v1/accounts/[accountId]/configurations/[player]';
const EXT_DATA_URL = '//video.qbrick.com/api/v1/public/accounts/[accountId]/medias/[videoId]';
const QUERY_SELECTOR = '[data-module="video-snippet"]';

export type videoParserConfig = {
  player: string;
  accountId?: string;
  sharing?: boolean;
  download?: boolean;
  showTitle?: boolean;
  cookies?: boolean;
};

const defaultConfig: videoParserConfig = {
  player: 'db-standard',
};

let videoParser: ParserFunctionInterface;
videoParser = (
  el: any,
  config: videoParserConfig,
  cookieConsentLevels: ConsentLevels = [0],
  contextSettings: ContextSettings | undefined
) => {
  config = config ?? defaultConfig;
  const arrVideoEl = el.querySelectorAll(QUERY_SELECTOR);
  const accountId = config.accountId || contextSettings?.videoAccountId || '';
  let goBrain = getGoBrain();

  function init() {
    if (arrVideoEl.length > 0) {
      if (typeof goBrain !== 'undefined') {
        processVideos(arrVideoEl, goBrain);
      } else {
        load(EXT_SCRIPT_URL, function (err) {
          if (err) {
            console.log('Qbrick error: Could not load script');
          } else {
            goBrain = getGoBrain();
            if (typeof goBrain !== 'undefined') {
              processVideos(arrVideoEl, goBrain);
            }
          }
        });
      }
    }
  }

  function onResize() {
    if (arrVideoEl.length > 0) {
      arrVideoEl.forEach((videoEl) => {
        setContainerHeight(videoEl);
      });
    }
  }

  function processVideos(arrVideoEl, goBrain) {
    arrVideoEl.forEach((videoEl) => {
      // Get config settings from video element
      const arrConfig = videoEl.innerText.replace(/ /g, '').split('#');
      const videoId = arrConfig[0];
      config.sharing = arrConfig.length > 1 && arrConfig[1] === '1' ? true : false;
      config.download = arrConfig.length > 2 && arrConfig[2] === '1' ? true : false;

      setContainerHeight(videoEl);

      // Clear contents of video element
      videoEl.innerHTML = '';

      // Create video player
      goBrain.create(videoEl, getEmbedSettings(videoId));
    });
  }

  function setContainerHeight(videoEl) {
    // Calculate height of video element based on available width to maintain 16/9
    const elW = videoEl.offsetWidth;
    const elH = Math.round(elW * 0.5625);
    videoEl.style.height = elH + 'px';
  }

  function getGoBrain() {
    return window['GoBrain'];
  }

  function getEmbedSettings(videoId: string) {
    return {
      autoplay: false,
      config: EXT_CONFIG_URL.replace('[accountId]', accountId).replace('[player]', config.player),
      data: EXT_DATA_URL.replace('[accountId]', accountId).replace('[videoId]', videoId),
      moduleSettings: {
        Controls: {
          sharing: { enabled: config.sharing },
          download: { enabled: config.download },
          settings: { showTitle: false },
        },
        Analytics: {
          cookies: { enabled: cookieConsentLevels.indexOf(ConsentLevel.Statistics) > -1 },
        },
      },
      repeat: false,
    };
  }

  return {
    init,
    onResize,
  };
};

export default videoParser;
