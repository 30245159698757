import dayjs from 'dayjs';
import 'dayjs/locale/da';

export const Languages = {
  English: 'en',
  Denmark: 'da',
  French: 'fr',
  Spanish: 'es',
};

function ConvertDate(dateValue: string, dateFormat: string, language = Languages.English) {
  let dateParseVal = Date.parse(dateValue);
  let localDate = dayjs(dateParseVal).locale(language).format(dateFormat);
  return localDate;
}

export default ConvertDate;
