import { Image, Text } from '@sitecore-jss/sitecore-jss-react';
import { mediaQuery, SlantedOverlay, Typography } from 'db-npm-rdui';
import React from 'react';
import styled from 'styled-components';
import { FieldChecker, Section } from '../../shared/components';
import imageScaler from '../../shared/utils/imageScaler';

const Wrapper = styled.div`
  width: 95%;

  ${mediaQuery.md} {
    width: 104%;
  }
`;

const ImageMaxHeightContainer = styled.div`
  overflow: hidden;
  max-height: 600px;
`;

const ImageBlock = styled(Image)`
  display: block;
  max-width: 100%;
  height: auto;
`;

const Description = styled.div`
  text-align: right;
  padding-top: 15px;
  padding-right: 18%;
  padding-left: 15px;

  ${mediaQuery.md} {
    padding-right: 16%;
  }

  ${mediaQuery.xl} {
    padding-top: 20px;
    padding-right: 12%;
  }
`;

export type ImageLeftProps = {
  fields: any;
};

const ImageLeft: React.FC<ImageLeftProps> = ({ fields = {} }) => (
  <Section componentName="ImageLeft" margin="large">
    <Wrapper>
      <SlantedOverlay slantPosition="right">
        <ImageMaxHeightContainer>
          <ImageBlock field={imageScaler(fields['Image'])} />
        </ImageMaxHeightContainer>

        <FieldChecker type="text" field={fields['Description']}>
          <Description>
            <Typography type="image">
              <Text field={fields['Description']} />
            </Typography>
          </Description>
        </FieldChecker>
      </SlantedOverlay>
    </Wrapper>
  </Section>
);

export default ImageLeft;
