/**
 * Static demo menu items for developmen
 */
export const DEMO_DATA = {
  megaMenu: [
    {
      name: 'Privat',
      url: '/privat',
      trackingcode: 'privat',
      isCurrent: true,
    },
    {
      name: 'Erhverv',
      url: '/erhverv',
      trackingcode: 'erhverv',
    },
    {
      name: 'Investor',
      url: '/investor',
      trackingcode: 'investor',
    },
  ],
  topMenu: [
    {
      name: 'Example pages',
      url: '/example',
      children: [
        {
          name: 'Pages',
          url: '#',
          children: [
            {
              name: 'Product page',
              url: '/da/privat/product-page',
              trackingcode: 'Product Page',
            },
            {
              name: 'Article page',
              url: '/da/privat/article-page',
              trackingcode: 'Article Page',
            },
            {
              name: 'Kurser & Renter page',
              url: '/da/privat/kurser',
            },
            {
              name: 'Calculator Private',
              url: '/da/privat/calculator-private',
              trackingcode: 'Calculator Private',
            },
            {
              name: 'Calculator Business',
              url: '/da/privat/calculator-business',
              trackingcode: 'Calculator Business',
            },
            {
              name: 'Calculator Lending',
              url: '/da/privat/calculator-lending',
              trackingcode: 'Calculator Lending',
            },
            {
              name: 'Form: GDPR',
              url: '/da/privat/form-gdpr',
            },
            {
              name: 'Form: Deselect NemKonto',
              url: '/da/privat/form-nemkonto',
            },
            {
              name: 'Form: NemKonto Business',
              url: '/da/privat/form-nemkonto?formtype=business',
            },
            {
              name: 'Form: Call Me',
              url: '/da/privat/form-callme',
            },
            {
              name: 'Form: Call Me Business',
              url: '/da/privat/form-callme?formtype=business',
            },
            {
              name: 'Form: CPR Upload',
              url: '/da/privat/form-cpr-upload',
            },
            {
              name: 'Form: CVR Upload',
              url: '/da/privat/form-cvr-upload',
            },
            {
              name: 'New Accordion Module',
              url: '/da/privat/new-accordion',
            },
            {
              name: 'Contact Page',
              url: '/da/privat/contact-page',
            },
            {
              name: 'New Frontpage Module',
              url: '/da/privat/new-frontpage-module',
            },
            {
              name: 'Form: Frameld markedsføring',
              url: '/da/privat/form-unsubscribe',
            },
            {
              name: 'Form: PPI',
              url: '/da/privat/form-ppi',
            },
          ],
        },
      ],
    },
    {
      name: 'Lånetyper',
      url: '/laantyper',
      children: [
        {
          name: 'Lånetyper',
          url: '#',
          children: [
            { name: 'Obligationslån', url: '#' },
            { name: 'Flexlife', url: '#' },
            {
              name: 'Flexlån K',
              url: '#',
              label: 'New',
              trackingcode: 'Flexlån K',
            },
            { name: 'Flexlån T', url: '#' },
            { name: 'Flexkort', url: '#' },
            { name: 'Kontantlån', url: '#' },
          ],
        },
        {
          name: 'Banklån',
          url: '#',
          children: [
            {
              name: 'Låneanbefalinger',
              url: '#',
            },
            {
              name: 'Hvilket lån skal jeg vælge?',
              url: '#',
            },
          ],
        },
      ],
    },
    {
      name: 'Kurser & priser',
      url: '/kurser',
      children: [
        {
          name: 'Kurser og priser',
          url: '#',
          children: [
            {
              name: 'Kurser & Renter',
              url: '/da/privat/kurser',
            },
            {
              name: 'Kurser & Renter Business',
              url: '/da/privat/kurser/#erhverv',
            },
            {
              name: 'Aktuelle priser',
              url: '#',
            },
            {
              name: 'Prisblad (vilkår)',
              url: '#',
            },
          ],
        },
      ],
    },
    {
      name: 'Aktuelt',
      url: '/aktuelt',
      children: [
        {
          name: 'Aktuelt',
          url: '#',
          children: [
            { name: 'Analyser', url: '#' },
            { name: 'Anbefalinger', url: '#' },
            { name: 'Nyheder', url: '#' },
            { name: 'Refinansiering *', url: '#' },
            {
              name: 'Rentetilpasning',
              url: '#',
            },
          ],
        },
      ],
    },
  ],
};
