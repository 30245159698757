import { Image, Text } from '@sitecore-jss/sitecore-jss-react';
import { Card as CardUi, Col } from 'db-npm-rdui';
import React from 'react';
import { Button, FieldChecker, LinkAnimatedUnderline } from '../../shared/components';
import { cleanLinkData } from '../../shared/utils/field-validation';
import { sitecoreBoolToBool } from '../../shared/utils/sitecoreBoolToBool';

type CardProps = {
  fields: any;
  params?: any;
};

const Card: React.FC<CardProps> = ({ fields = {}, params }) => {
  const centerContent = sitecoreBoolToBool(params?.centerContent);
  const ctaAsLink = sitecoreBoolToBool(params?.ctaAsLink);
  const imageFullWidth = sitecoreBoolToBool(params?.imageFullWidth);
  const noBorder = sitecoreBoolToBool(params?.noBorder);
  const cardLink = fields['Cta'] !== undefined && fields['Cta']?.value?.href !== '' ? fields['Cta']?.value : null;
  if (cardLink !== null) {
    fields['Cta'].value = cleanLinkData(fields['Cta'].value);
    cardLink['data-tracking-id'] = fields['Cta'].value.trackingcode;
  }

  const desktopCardAmount = Math.round(12 / parseInt(params.DesktopColumnsAmount) || 12);
  const tabletCardAmount = Math.round(12 / parseInt(params.TabletColumnsAmount) || 12);
  const mobileCardAmount = Math.round(12 / parseInt(params.MobileColumnsAmount) || 12);

  const ImageCoomponent = (
    <FieldChecker type="image" field={fields['Image']}>
      <Image field={fields['Image']} />
    </FieldChecker>
  );

  const HeadlineComponent = (
    <FieldChecker type="text" field={fields['Headline']}>
      <Text field={fields['Headline']} />
    </FieldChecker>
  );

  const TextComponent = (
    <FieldChecker type="text" field={fields['Text']}>
      <Text field={fields['Text']} />
    </FieldChecker>
  );

  const CtaComponent = (
    <FieldChecker type="link" field={fields['Cta']}>
      {ctaAsLink ? <LinkAnimatedUnderline field={fields['Cta']} /> : <Button field={fields['Cta']} />}
    </FieldChecker>
  );

  return (
    <Col sm={mobileCardAmount} md={tabletCardAmount} lg={desktopCardAmount}>
      <CardUi
        centerContent={centerContent}
        noBorder={noBorder}
        imageFullWidth={imageFullWidth}
        image={ImageCoomponent}
        headline={HeadlineComponent}
        text={TextComponent}
        cta={CtaComponent}
        link={cardLink}
        height="calc(100% - 24px)"
        marginBottom={5}
      ></CardUi>
    </Col>
  );
};

export default Card;
