import { Text, withPlaceholder } from '@sitecore-jss/sitecore-jss-react';
import { PlaceholderProps } from '@sitecore-jss/sitecore-jss-react/types/components/PlaceholderCommon';
import { Col, Container, Row, TextAccordion as TextAccordionComp, Typography } from 'db-npm-rdui';
import React from 'react';
import { Section } from '../../shared/components';
import { useIsEditor } from '../../shared/hooks';
import TextAccordionItem from '../TextAccordionItem';

export type TextAccordionProps = PlaceholderProps & {
  fields: any;
  accordionItems: any;
  rendering: any;
};

type Item = {
  uid: string;
  accordionItems: any;
  fields: any;
};

const TextAccordion: React.FC<TextAccordionProps> = ({ fields = {}, accordionItems, rendering }) => {
  // isEditor check needed for Sitecore Experience Editor to work
  const isEditor = useIsEditor();

  const items: Item[] = rendering?.placeholders?.accordionItems || [];

  return (
    <Section componentName="TextAccordion" margin="large">
      <Container>
        <Row justify="center">
          <Col md={8} xl={6}>
            {isEditor && (
              <Typography type={'h2'}>
                <Text field={fields['Title']} />
              </Typography>
            )}
            <TextAccordionComp title={isEditor ? '' : fields['Title']?.value} titleType={'h2'}>
              {isEditor
                ? accordionItems
                : items.map(({ uid, fields }) => <TextAccordionItem key={uid} fields={fields} itemId={uid} />)}
            </TextAccordionComp>
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

export default withPlaceholder('accordionItems')(TextAccordion);
