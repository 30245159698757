import { LOAN_AMOUNT_MAXIMUM, LOAN_AMOUNT_MINIMUM, MINIMUM_LTV } from '../config';

export type InputsValidationErrorCode =
  | 'invalid-inputs'
  | 'own-payment-too-low'
  | 'own-payment-too-high'
  | 'property-value-too-low'
  | 'property-value-too-high'
  | 'loan-amount-value-too-low'
  | 'loan-amount-value-too-high'
  | 'remaining-debt-too-high'
  | 'remaining-debt-too-low';

export type InputsValidationResult = {
  isValid: boolean;
  errorCode?: InputsValidationErrorCode;
};

/**
 * Check if the inputs are valid and can be submitted
 * @param propertyValue The property value
 * @param ownPayment The own payment amount
 * @param ownPaymentProcentage The own payment percentage
 */
export const validateInputs = (
  propertyValue: number | null,
  ownPayment: number | null,
  ownPaymentProcentage: number,
  wantedLoanAmount: number | null,
  remainingDebt: number | null,
  ownPaymentVisible: boolean,
  settings
): InputsValidationResult => {
  // Check for invalid inputs
  if (typeof propertyValue !== 'number' || typeof ownPayment !== 'number') {
    return {
      isValid: false,
      errorCode: 'invalid-inputs',
    };
  }

  // Check if loan amount is too low or too high
  const modifiedWantedLoanAmount = wantedLoanAmount !== null ? wantedLoanAmount : 0;
  //const modifiedRemainingDebt = remainingDebt !== null ? remainingDebt : 0;
  const showLendingType = settings?.inputs?.showLendingType === true;
  const loanAmount = ownPaymentVisible ? propertyValue * MINIMUM_LTV : modifiedWantedLoanAmount;
  const minimumLoanAmount = settings?.inputs?.minimumLoanAmount || LOAN_AMOUNT_MINIMUM;
  const maximumLoanAmount = settings?.inputs?.maxmumLoanAmount || LOAN_AMOUNT_MAXIMUM;
  if (loanAmount < minimumLoanAmount) {
    const errorCode = ownPaymentVisible ? 'property-value-too-low' : 'loan-amount-value-too-low';
    return {
      isValid: false,
      errorCode: errorCode,
    };
  }
  if (loanAmount > maximumLoanAmount) {
    const errorCode = ownPaymentVisible ? 'property-value-too-high' : 'loan-amount-value-too-high';
    return {
      isValid: false,
      errorCode: errorCode,
    };
  }

  // Check if own payment is larger than property value
  if (propertyValue < ownPayment + minimumLoanAmount) {
    return {
      isValid: false,
      errorCode: 'own-payment-too-high',
    };
  }
  // Check if own payment is too low
  if (ownPayment < propertyValue * ownPaymentProcentage) {
    return {
      isValid: false,
      errorCode: 'own-payment-too-low',
    };
  }

  // Check if remaining debt is larger than property value
  if (showLendingType && remainingDebt && remainingDebt > propertyValue * 0.8) {
    return {
      isValid: false,
      errorCode: 'remaining-debt-too-high',
    };
  }

  // Check if remaining debt is too low
  if (showLendingType && (remainingDebt === null || isNaN(remainingDebt) || remainingDebt < 0)) {
    return {
      isValid: false,
      errorCode: 'remaining-debt-too-low',
    };
  }

  return {
    isValid: true,
  };
};
