import { Col } from 'db-npm-rdui';
import React from 'react';
import ColorCard from './ColorCard';

type ColorCardProps = {
  fields: any;
  params?: any;
};

const ColorCardUI: React.FC<ColorCardProps> = ({ fields = {}, params = {} }) => {
  const desktopCardAmount = Math.round(12 / parseInt(params.DesktopColumnsAmount));
  const tabletCardAmount = Math.round(12 / parseInt(params.TabletColumnsAmount));
  const mobileCardAmount = Math.round(12 / parseInt(params.MobileColumnsAmount));

  return (
    <Col sm={mobileCardAmount} md={tabletCardAmount} lg={desktopCardAmount}>
      <ColorCard fields={fields} params={params} />
    </Col>
  );
};

export default ColorCardUI;
