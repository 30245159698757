import { Text, withPlaceholder } from '@sitecore-jss/sitecore-jss-react';
import { PlaceholderProps } from '@sitecore-jss/sitecore-jss-react/types/components/PlaceholderCommon';
import { Col, Container, Icons, Row, breakpointsBootstrap, useOnWindowResize } from 'db-npm-rdui';
import React, { createRef, useCallback, useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { Section } from '../../shared/components';
import { useIsEditor } from '../../shared/hooks';
import placeholderChildren from '../../shared/utils/placeholderChildren';
import { scrollTo } from '../../shared/utils/scrollTo';
import ExpandedTextAccordionItem from '../ExpandedTextAccordionItem';
import TextAccordionItem from '../TextAccordionItem';
import { useVisibleSection } from './useVisibleSection';

export type ExpandedTextAccordionProps = PlaceholderProps & {
  expandedAccordionItems: any;
  rendering: any;
};

export type Item = {
  uid: string;
  fields: any;
  ref: React.RefObject<HTMLDivElement>;
};

const ExpandedTextAccordion: React.FC<ExpandedTextAccordionProps> = ({ expandedAccordionItems, rendering }) => {
  // isEditor check needed for Sitecore Experience Editor to work
  const isEditor = useIsEditor();
  const [smUp, setSmUp] = React.useState<null | boolean>(null);
  const isSmUp = () => {
    const mql = window.matchMedia(`(min-width: ${breakpointsBootstrap.sm}px)`);
    return mql.matches;
  };

  useOnWindowResize(() => {
    setSmUp(isSmUp());
  });

  const items: Item[] = useMemo(
    () =>
      (rendering?.placeholders?.expandedAccordionItems || []).map((item: any) => ({
        ...item,
        ref: createRef<HTMLDivElement>(),
      })),
    [rendering]
  );

  useEffect(() => {
    const url = new URL(window.location.href);
    items
      .filter((item) => item.uid === url.hash.substring(1))
      .forEach((anchorsec) => {
        if (anchorsec.ref && anchorsec.ref.current) scrollTo(anchorsec.ref.current);
      });

    setSmUp(isSmUp());
  }, [items, smUp]);

  const visibleSection = useVisibleSection(items);

  const handleClick = useCallback(
    (ref: React.RefObject<HTMLDivElement>, uid) => () => {
      window.location.hash = '#' + uid;
      if (ref && ref.current) {
        scrollTo(ref.current);
      }
    },
    []
  );

  if (!isEditor && smUp === null) return <div />;
  return (
    <Section componentName="ExpandedTextAccordion" margin="large">
      <Container>
        <Row>
          {smUp ? (
            <>
              <Col sm={4}>
                <Sticky>
                  {placeholderChildren(isEditor, items).map(({ uid, ref, fields }) => (
                    <TitleButton key={uid} active={visibleSection === uid} onClick={handleClick(ref, uid)}>
                      <Text field={typeof fields === 'object' ? fields['Title'] : null} />
                      <IconWrapper>
                        <Icons.ArrowRight />
                      </IconWrapper>
                    </TitleButton>
                  ))}
                </Sticky>
              </Col>
              <Col sm={7} offset={{ sm: 1 }}>
                {isEditor
                  ? expandedAccordionItems
                  : items.map(({ uid, ref, fields }) => (
                      <ExpandedTextAccordionItem key={uid} uid={uid} innerRef={ref} fields={fields} />
                    ))}
              </Col>
            </>
          ) : (
            <Col sm={12}>
              {isEditor
                ? expandedAccordionItems
                : items.map(({ uid, fields }) => <TextAccordionItem key={uid} fields={fields} itemId={uid} />)}
            </Col>
          )}
        </Row>
      </Container>
    </Section>
  );
};

export default withPlaceholder('expandedAccordionItems')(ExpandedTextAccordion);

const Sticky = styled.div`
  position: sticky;
  top: 0;
`;

const TitleButton = styled.button<{ active?: boolean }>`
  display: block;
  width: 100%;
  margin: 0;
  padding: 15px;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #bdbdbd;
  background: transparent;
  text-align: left;
  cursor: pointer;
  font-weight: bold;

  ${(props) =>
    props.active &&
    css`
      background: #f5f5f5;
    `}
`;

const IconWrapper = styled.div`
  float: right;
`;
