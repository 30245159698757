import { LoanStateItem } from '../../types/LoanStateItem';

/**
 * This function is responsible for updating the internal payload we have in the state for the loan.
 * The values coming from the API response should override the values we have internally, so
 * that the next API request has the latest updated and most correct values.
 * @param loan The loan
 * @param apiResponse The API response
 */
export function updatePayload(loan: LoanStateItem, apiResponse: any): LoanStateItem {
  const { payload } = loan;

  // Update the changeOption object to the new one from the API response
  payload.product.changeOption.desiredPaymentAmount = parseInt(
    apiResponse.changeOption.calculatedPaymentAmountPerMonth
  );
  payload.product.changeOption.desiredDebtAtExpiry = parseInt(apiResponse.changeOption.calculatedDebtAtExpiry);
  payload.product.changeOption.desiredInterestOnlyStartDate =
    apiResponse.changeOption.calculatedInterestOnlyToMaturityStartDate;
  payload.basic.wantedAdditionalAmount = Math.round(parseFloat(apiResponse.loanKeyFigures.netProceedsAmount));

  // Update minimumYears and maximumYears to the new ones from the API response
  payload.product.minimumYears = parseInt(apiResponse.maturity.minimumYears);
  payload.product.maximumYears = parseInt(apiResponse.maturity.maximumYears);

  return loan;
}
