import { useState } from 'react';
import { useBetween } from 'use-between';

const useSettingsStateFn = () => {
  const [settings, setSettings] = useState<any>({});
  const [apiUrl, setApiUrl] = useState('');

  return {
    settings,
    apiUrl,
    setSettings,
    setApiUrl,
  };
};

export const useSettingsState = () => useBetween(useSettingsStateFn);
