import { BoxShadow, Col, Color, Container, mediaQuery, RddkTheme, Row, Select } from 'db-npm-rdui';
import uniq from 'lodash/uniq';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';
import { useAdobeTracking } from '../../../../shared/hooks';
import { handleTranslation } from '../../../../shared/utils/handleTranslation';
import { FORMATTING_DECIMAL_SEPATOR, FORMATTING_THOUSAND_SEPATOR, HTML_ID_SUBMIT_BUTTON } from '../../config';
import { validateInputs } from '../../helpers/inputs';
import { useInputsState } from '../../state/inputsState';
import { useLoansState } from '../../state/loansState';
import { useSettingsState } from '../../state/settingsState';
import { CalculatorInputsValidationMessage } from './CalculatorInputsValidationMessage';

export type CalculatorInputsOnSubmit = (data: {
  loanType: string;
  propertyType: string;
  ownerCategory: string;
  wantedLoanAmount: number | null;
  propertyValue: number | null;
  ownPayment: number | null;
  remainingDebt: number | null;
  propertyCategory: string;
}) => void;

export type CalculatorInputsProps = {
  onSubmit?: CalculatorInputsOnSubmit;
};

export const CalculatorInputs: React.FC<CalculatorInputsProps> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const { settings } = useSettingsState();
  const submitButtonRef = React.useRef<HTMLInputElement>(null);
  const { setLoansAfterChange, loans } = useLoansState();
  const {
    loanType,
    propertyType,
    ownerCategory,
    propertyValue,
    wantedLoanAmount,
    ownPayment,
    remainingDebt,
    ownPaymentVisible,
    propertyCategory,
    submitCounter,
    setRecalculate,
    setLoanType,
    setPropertyType,
    setOwnerCategory,
    setPropertyValue,
    setWantedLoanAmount,
    setRemainingDebt,
    setOwnPayment,
    setPropertyCategory,
    setOwnPaymentVisible,
    setSubmitCounter,
  } = useInputsState();
  const { setLaunchData, trackCalculatorInteraction } = useAdobeTracking();
  const [urlParam, setURLParam] = React.useState(false);
  const loanList = loans.map((value) => value.type).join(' - ');

  // Get "ownPaymentProcentage" from Sitecore dictionary
  const checkText = handleTranslation(`calculator-setting-ownPaymentProcentage-${propertyType}`);
  const ownPaymentProcentage =
    checkText !== null
      ? parseFloat(t(`calculator-setting-ownPaymentProcentage-${propertyType}`)) / 100
      : (parseFloat(t(`calculator-setting-ownPaymentProcentage`)) || 5) / 100;

  // Get "showLoanTypesDropdown" and "showPropertyTypesDropdown" from settings
  //const showLoanTypesDropdown = settings?.inputs?.showLoanTypesDropdown === true;
  const showPropertyTypesDropdown = settings?.inputs?.showPropertyTypesDropdown === true;
  const showLendingType = settings?.inputs?.showLendingType === true;

  // Check if the inputs are valid
  const validationResult = validateInputs(
    propertyValue,
    ownPayment,
    ownPaymentProcentage,
    wantedLoanAmount,
    remainingDebt,
    ownPaymentVisible,
    settings
  );
  const defaultPropertyTypeTypeSet = React.useRef(false);
  const ownerCategorySet = React.useRef(false);

  const propertyValuePropertyText = handleTranslation(`calculator-inputs-propertyValue-placeholder-${propertyType}`);
  const ownPaymentPropertyText = handleTranslation(`calculator-inputs-ownPayment-placeholder-${propertyType}`);

  const propertyValuePlaceholder =
    propertyType && propertyValuePropertyText !== null
      ? propertyValuePropertyText
      : t(`calculator-inputs-propertyValue-placeholder`);
  const wantedLoanAmountPlaceholder = t(`calculator-inputs-loan-amount-placeholder`);
  const remainingDebtPlaceholder = t(`calculator-inputs-remaining-debt-placeholder`);
  const ownPaymentPlaceholder =
    propertyType && ownPaymentPropertyText !== null
      ? ownPaymentPropertyText
      : t(`calculator-inputs-ownPayment-placeholder`);

  /**
   * LOAN TYPE
   */

  // Get "loanTypes" string array from settings and memo it
  const loanTypes = React.useMemo(() => {
    return String(settings?.inputs?.loanTypes || '')
      .split(',')
      .map((i) => i.trim());
  }, [settings]);

  // Handler for changing the loan type
  const onLoanTypeChange = React.useCallback(
    (val: string) => {
      setLoanType(val);
    },
    [setLoanType]
  );

  // Set default loan type
  React.useEffect(() => {
    if (loanType === '' && loanTypes.length > 0) {
      onLoanTypeChange(loanTypes[0]);
    }
  }, [loanType, loanTypes, onLoanTypeChange]);

  /**
   * PROPERTY TYPE
   */

  // Get "propertyTypes" string array from settings and memo it
  const propertyTypesInSettings = React.useMemo(() => {
    return String(settings?.inputs?.propertyTypes || '')
      .split(',')
      .map((i) => i.trim());
  }, [settings]);

  // Get unique array of property types for 1. level dropdown and memo it
  const propertyTypesInFirstDropdown = React.useMemo(() => {
    return uniq(propertyTypesInSettings.map((i) => i.split('/')[0]));
  }, [propertyTypesInSettings]);

  // Handler for changing the property type
  const onPropertyTypeChange = React.useCallback(
    (val: string) => {
      setPropertyType(val);
      const loanValue = loanType !== '' ? loanType : loans.length > 0 ? loans[0].type : '';
      // Check if the property type has owner categories defined. If it has, set the first one is active.
      const firstOwnerCategory = propertyTypesInSettings.find((i) => i.startsWith(val + '/'));
      if (firstOwnerCategory !== undefined) {
        const newValue = val + '/' + firstOwnerCategory.split('/')[1];
        setOwnerCategory(firstOwnerCategory.split('/')[1]);
        setPropertyCategory(firstOwnerCategory.split('/')[2]);
        setLoansAfterChange(settings, newValue);
        setOwnPaymentVisible(false);
        ownerCategorySet.current = true;
        if ((ownPayment && propertyValue) || (showLendingType && propertyValue && remainingDebt)) {
          setLaunchData({
            calculator_info:
              'loan_product:' +
              loanValue +
              ',property_type:' +
              val +
              ',property_category:' +
              firstOwnerCategory.split('/')[2] +
              ',own_value:' +
              ownPayment +
              ',wanted_loan_amount:' +
              wantedLoanAmount +
              ',remaining_debt:' +
              remainingDebt,
            loan_calculate_type: loanList,
          });
        }
      } else {
        setLoansAfterChange(settings, val);
        if ((ownPayment && propertyValue) || (showLendingType && propertyValue && remainingDebt)) {
          setLaunchData({
            calculator_info:
              'loan_product:' +
              loanValue +
              ',property_type:' +
              val +
              ',property_category:' +
              propertyCategory +
              ',own_value:' +
              ownPayment +
              ',wanted_loan_amount:' +
              wantedLoanAmount +
              ',remaining_debt:' +
              remainingDebt,
            loan_calculate_type: loanList,
          });
        }
        setWantedLoanAmount(0);
      }

      if (!showLendingType) {
        setRemainingDebt(0);
      }

      if (propertyValue) {
        const checkText = handleTranslation(`calculator-setting-ownPaymentProcentage-${val}`);
        const ownPaymentProcentage =
          checkText !== null
            ? parseFloat(t(`calculator-setting-ownPaymentProcentage-${val}`)) / 100
            : (parseFloat(t(`calculator-setting-ownPaymentProcentage`)) || 5) / 100;
        const newOwnPayment = Math.ceil(propertyValue * ownPaymentProcentage);
        setOwnPayment(Math.ceil(newOwnPayment / 100) * 100);
      }

      if ((ownPayment && propertyValue) || (showLendingType && propertyValue && remainingDebt)) {
        setTimeout(() => {
          if (submitButtonRef.current) submitButtonRef.current.click();
        }, 50);
      }
    },
    [
      ownPayment,
      propertyValue,
      settings,
      showLendingType,
      remainingDebt,
      propertyCategory,
      wantedLoanAmount,
      loanType,
      loans,
      loanList,
      setPropertyType,
      setOwnerCategory,
      setPropertyCategory,
      setOwnPayment,
      setLaunchData,
      propertyTypesInSettings,
      setLoansAfterChange,
      setOwnPaymentVisible,
      setWantedLoanAmount,
      setRemainingDebt,
      t,
    ]
  );

  // Set default property type
  React.useEffect(() => {
    if (defaultPropertyTypeTypeSet.current && ownerCategorySet.current) return;
    const url = new URL(window.location.href);
    const hasType = url.searchParams.get('type')?.toString();
    if (hasType && !urlParam) {
      setPropertyType(hasType);
      setWantedLoanAmount(0);
      setRemainingDebt(0);
      setURLParam(true);
    }
    if (showPropertyTypesDropdown && propertyType === '' && propertyTypesInFirstDropdown.length > 0) {
      onPropertyTypeChange(propertyTypesInFirstDropdown[0]);
      defaultPropertyTypeTypeSet.current = true;
    }
  }, [
    propertyType,
    propertyTypesInFirstDropdown,
    showPropertyTypesDropdown,
    urlParam,
    setPropertyType,
    setWantedLoanAmount,
    setRemainingDebt,
    setOwnPaymentVisible,
    onPropertyTypeChange,
    setURLParam,
  ]);

  /**
   * OWNER CATEGORY
   */

  // Get array of owner category for 2. level dropdown and memo it
  const ownerCategories = React.useMemo(() => {
    if (propertyType === '') {
      return [];
    }
    return propertyTypesInSettings
      .filter((i) => i.startsWith(propertyType) && i.indexOf('/') >= 0)
      .map((i) => i.split('/')[1]);
  }, [propertyType, propertyTypesInSettings]);

  // Handler for changing the owner category
  const onOwnerCategoryChange = React.useCallback(
    (val: string) => {
      setOwnerCategory(val);
      // Check if the property type has owner categories defined. If it has, set the property category.
      const firstOwnerCategory = propertyTypesInSettings.find((i) => i.startsWith(propertyType + '/'));
      if (firstOwnerCategory !== undefined) {
        setPropertyCategory(firstOwnerCategory.split('/')[2]);
        setLoansAfterChange(settings, propertyType + '/' + val);
        setLaunchData({
          calculator_info:
            'loan_product:' + loanType !== ''
              ? loanType
              : loans[0].type +
                ',property_type:' +
                propertyType +
                ',property_category:' +
                val +
                ',own_value:' +
                ownPayment +
                ',wanted_loan_amount:' +
                wantedLoanAmount +
                ',remaining_debt:' +
                remainingDebt,
          loan_calculate_type: loanList,
        });
        if (ownPayment && propertyValue) {
          setTimeout(() => {
            if (submitButtonRef.current) submitButtonRef.current.click();
          }, 50);
        }
      }
    },
    [
      settings,
      propertyType,
      ownPayment,
      wantedLoanAmount,
      remainingDebt,
      loanType,
      loans,
      propertyValue,
      submitButtonRef,
      loanList,
      setOwnerCategory,
      setPropertyCategory,
      propertyTypesInSettings,
      setLoansAfterChange,
      setLaunchData,
    ]
  );

  /**
   * PROPERTY VALUE
   */

  // Handler for changing the property value
  const onPropertyValueChange = React.useCallback(
    (val: number) => {
      setPropertyValue(val);

      // When changing this, we automatically update the "ownPayment" value also to a required minimum
      const newOwnPayment = Math.ceil(val * ownPaymentProcentage);
      setOwnPayment(Math.ceil(newOwnPayment / 100) * 100);
      trackCalculatorInteraction();
    },
    [ownPaymentProcentage, setOwnPayment, setPropertyValue, trackCalculatorInteraction]
  );

  /**
   * WANTED LOAN AMOUNT VALUE
   */

  // Handler for changing the property value
  const onLoanAmountChange = React.useCallback(
    (val: number) => {
      setWantedLoanAmount(val);
      onPropertyValueChange(2 * val);
      trackCalculatorInteraction();
    },
    [setWantedLoanAmount, onPropertyValueChange, trackCalculatorInteraction]
  );

  /**
   * OWN PAYMENT
   */

  // Handler for changing the own payment
  const onOwnPaymentChange = React.useCallback(
    (val: number) => {
      setOwnPayment(val);
      trackCalculatorInteraction();
    },
    [setOwnPayment, trackCalculatorInteraction]
  );

  /**
   * OWN PAYMENT
   */

  // Handler for changing the own payment
  const onRemainingDebtChange = React.useCallback(
    (val: number) => {
      setRemainingDebt(val);
      trackCalculatorInteraction();
    },
    [setRemainingDebt, trackCalculatorInteraction]
  );

  const checkDecimal = React.useCallback((event) => {
    if (event.key === '.' && !event.shiftKey) {
      event.preventDefault();
    }
  }, []);

  // Handler for rounding down the own payment to nearest 100 when leaving the input field to prevent API error
  const onBlurChange = React.useCallback(
    (e) => {
      const strVal = e.target?.value || '';
      const newVal = strVal.replace(/[\.,]+/g, ''); //eslint-disable-line
      const newOwnPayment = Math.floor(parseInt(newVal) / 100) * 100;
      setOwnPayment(newOwnPayment);
    },
    [setOwnPayment]
  );

  const onLoanAmountBlurChange = React.useCallback(() => {
    if (!ownPaymentVisible && wantedLoanAmount && wantedLoanAmount > 1000) {
      const modifiedPropertyValue = Math.round(wantedLoanAmount / 1000) * 1000;
      setWantedLoanAmount(modifiedPropertyValue);
      setPropertyValue(2 * modifiedPropertyValue);
    }
  }, [ownPaymentVisible, wantedLoanAmount, setWantedLoanAmount, setPropertyValue]);

  // Handler for rounding down the remaining debt to nearest 100 when leaving the input field
  const onRemainingDebtBlurChange = React.useCallback(
    (e) => {
      const strVal = e.target?.value || '';
      const newVal = strVal.replace(/[\.,]+/g, ''); //eslint-disable-line
      const newRemainingDebt = Math.floor(parseInt(newVal));
      setRemainingDebt(newRemainingDebt);
    },
    [setRemainingDebt]
  );

  /**
   * SUBMIT
   */

  // Handler for onSubmit event
  const onSubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // If inputs are invalid, then do nothing
    if (!validationResult.isValid) {
      return;
    }

    setSubmitCounter(submitCounter + 1);
    if (submitCounter >= 1) {
      setRecalculate(true);
    }

    // If a onSubmit function is provided, then call it
    if (typeof onSubmit === 'function') {
      onSubmit({
        loanType,
        ownerCategory,
        propertyType,
        propertyValue,
        wantedLoanAmount,
        ownPayment,
        remainingDebt,
        propertyCategory,
      });
    }
  };

  return (
    <Container>
      <Row>
        <Col>
          <Wrapper>
            <div>
              <FormContainer noValidate onSubmit={onSubmitHandler}>
                {showPropertyTypesDropdown && (
                  <InputColumn>
                    <label>
                      <Label>
                        {ownerCategories.length > 0
                          ? t('calculator-inputs-propertyType-label')
                          : t('calculator-inputs-housingType-label')}
                      </Label>
                      {propertyTypesInFirstDropdown.length > 1 ? (
                        <Select
                          options={propertyTypesInFirstDropdown.map((item) => ({
                            value: item,
                            label: t(`calculator-inputs-propertyType-${item}`).toString(),
                          }))}
                          value={propertyType}
                          onChange={(e) => onPropertyTypeChange(e.target.value)}
                          isBold
                          required
                        />
                      ) : (
                        <StaticValue>
                          {t(`calculator-inputs-propertyType-${propertyTypesInFirstDropdown[0]}`)}
                        </StaticValue>
                      )}
                    </label>
                  </InputColumn>
                )}

                {ownerCategories.length > 0 && (
                  <InputColumn>
                    <label>
                      <Label>{t('calculator-inputs-ownerCategory-label')}</Label>
                      {ownerCategories.length > 1 ? (
                        <Select
                          options={ownerCategories.map((item) => ({
                            value: item,
                            label: t(`calculator-inputs-ownerCategory-${item}`).toString(),
                          }))}
                          value={ownerCategory}
                          onChange={(e) => onOwnerCategoryChange(e.target.value)}
                          isBold
                          required
                        />
                      ) : (
                        <StaticValue>{t(`calculator-inputs-ownerCategory-${ownerCategories[0]}`)}</StaticValue>
                      )}
                    </label>
                  </InputColumn>
                )}

                {ownerCategories.length === 0 && (
                  <InputColumn>
                    <label>
                      <Label>{t('calculator-inputs-propertyValue-label')}</Label>
                      <Input
                        type="text"
                        thousandSeparator={FORMATTING_THOUSAND_SEPATOR}
                        decimalSeparator={FORMATTING_DECIMAL_SEPATOR}
                        decimalScale={2}
                        onKeyPress={(e) => checkDecimal(e)}
                        onValueChange={(e) => onPropertyValueChange(e.floatValue)}
                        value={propertyValue}
                        placeholder={propertyValuePlaceholder}
                        required
                      />
                    </label>
                  </InputColumn>
                )}

                {ownerCategories.length > 0 && (
                  <InputColumn>
                    <label>
                      <Label>{t('calculator-inputs-loan-amount-label')}</Label>
                      <Input
                        type="text"
                        thousandSeparator={FORMATTING_THOUSAND_SEPATOR}
                        decimalSeparator={FORMATTING_DECIMAL_SEPATOR}
                        decimalScale={2}
                        onKeyPress={(e) => checkDecimal(e)}
                        onValueChange={(e) => onLoanAmountChange(e.floatValue)}
                        onBlur={onLoanAmountBlurChange}
                        value={wantedLoanAmount}
                        placeholder={wantedLoanAmountPlaceholder}
                        required
                      />
                    </label>
                  </InputColumn>
                )}

                {!showLendingType && ownerCategories.length === 0 && (
                  <InputColumn>
                    <label>
                      <Label>{t('calculator-inputs-ownPayment-label')}</Label>
                      <Input
                        type="text"
                        thousandSeparator={FORMATTING_THOUSAND_SEPATOR}
                        decimalSeparator={FORMATTING_DECIMAL_SEPATOR}
                        decimalScale={2}
                        onKeyPress={(e) => checkDecimal(e)}
                        onValueChange={(e) => onOwnPaymentChange(e.floatValue)}
                        onBlur={(e) => onBlurChange(e)}
                        value={ownPayment}
                        placeholder={ownPaymentPlaceholder}
                        required
                      />
                    </label>
                  </InputColumn>
                )}

                {showLendingType && (
                  <InputColumn>
                    <label>
                      <Label>{t('calculator-inputs-remainingDebt-label')}</Label>
                      <Input
                        type="text"
                        thousandSeparator={FORMATTING_THOUSAND_SEPATOR}
                        decimalSeparator={FORMATTING_DECIMAL_SEPATOR}
                        decimalScale={2}
                        onKeyPress={(e) => checkDecimal(e)}
                        onValueChange={(e) => onRemainingDebtChange(e.floatValue)}
                        onBlur={(e) => onRemainingDebtBlurChange(e)}
                        value={remainingDebt}
                        placeholder={remainingDebtPlaceholder}
                        required
                      />
                    </label>
                  </InputColumn>
                )}

                <SubmitButton
                  type="submit"
                  id={HTML_ID_SUBMIT_BUTTON}
                  ref={submitButtonRef}
                  value={t('calculator-inputs-submit-label').toString()}
                />
              </FormContainer>
              <CalculatorInputsValidationMessage result={validationResult} />
            </div>
          </Wrapper>
        </Col>
      </Row>
    </Container>
  );
};

const Wrapper = styled.div`
  ${mediaQuery.md} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 6px;
  box-shadow: ${BoxShadow.medium};
  overflow: hidden;

  ${mediaQuery.md} {
    display: inline-flex;
    flex-direction: row;
  }
`;

const InputColumn = styled.div`
  width: 100%;
  margin-bottom: 18px;
  padding: 0 20px;

  :first-child {
    margin-top: 18px;
  }

  ${mediaQuery.md} {
    width: 140px;
    margin: 18px 0;
    border-left: 1px solid ${Color.grey20};

    :first-child {
      border-left: 0;
    }
  }

  ${mediaQuery.lg} {
    width: 180px;
  }

  ${mediaQuery.xl} {
    width: 200px;
  }
`;

const Label = styled.span`
  display: block;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.3px;
  color: ${Color.grey90};
  margin-bottom: 4px;
`;

const Input = styled(NumberFormat)`
  appearance: none;
  border: 0;
  border-radius: 0;
  outline: none;
  width: 100%;
  padding: 0;
  font-size: 1.063rem;
  font-weight: 600;
  color: #000;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::placeholder {
    color: ${RddkTheme.colors.grey50};
  }
`;

const StaticValue = styled.span`
  display: block;
  font-size: 1.063rem;
  font-weight: 600;
  color: #000;
`;

const SubmitButton = styled.input`
  width: 100%;
  border: 0;
  padding: 20px 0;
  background: #35a47a;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
  transition: background 0.3s;

  ${mediaQuery.md} {
    width: 140px;
    padding: 0;
  }

  ${mediaQuery.lg} {
    width: 180px;
  }

  ${mediaQuery.xl} {
    width: 200px;
  }

  :disabled {
    cursor: auto;
  }
`;
