import { RichText } from '@sitecore-jss/sitecore-jss-react';
import {
  ConsentLevel,
  Header as HeaderComponent,
  Icons,
  SearchResultsType,
  StickyCta,
  Typography,
  breakpointsBootstrap,
  useCookieConsent,
} from 'db-npm-rdui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { fieldReferences } from '../../constants';
import { LaunchData, useAdobeTracking, useSitecoreContext } from '../../shared/hooks';
import { DEMO_DATA } from './Header.demo-data';
import { doGoogleSearch } from './googleSearchApi';

export type HeaderProps = {
  fields: any;
};

const Header: React.FC<HeaderProps> = (props) => {
  // Use demo data links as default,
  // but override with real data from props.fields if any
  const fields = {
    ...DEMO_DATA,
    ...props.fields,
  };

  const googleApiUrl = fields['Search API Endpoint URL']?.value ?? '';
  const dsid = fields['Search API Sitecore Setting ID']?.value ?? '';
  const feedbackAllowed = useCookieConsent().isAllowed(ConsentLevel.Functional);
  const context = useSitecoreContext();
  const hideStickyCalculator = context?.route?.fields?.[fieldReferences.basePage.hideStickyCalculator]?.value || false;
  const hideStickyBookMeeting =
    context?.route?.fields?.[fieldReferences.basePage.hideStickyBookMeeting]?.value || false;
  const [searchResults, setSearchResults] = React.useState<SearchResultsType>({
    hits: null,
    results: [],
  });
  const [searchQuery, setSearchQuery] = React.useState('');
  const [customComponent, setCustomComponent] = React.useState<any>(null);
  const { t } = useTranslation();

  const launchData: LaunchData = {
    search_area: 'RD',
    search_results: '',
    search_term: '',
  };

  const cacheSearchResults = (searchResults) => {
    window.sessionStorage.setItem('last_search', JSON.stringify(searchResults));
  };

  const retrieveCachedResults = (): SearchResultsType =>
    JSON.parse(window.sessionStorage.getItem('last_search') as string);

  const clearCachedResults = () => window.sessionStorage.removeItem('last_search');

  const isNavigation = () => {
    const navigationType = (window.performance.getEntriesByType('navigation')[0] as PerformanceNavigationTiming)?.type;
    return navigationType === 'back_forward' || navigationType === 'navigate';
  };

  const {
    trackSearchOpened,
    trackSearchResultClick,
    trackSearchPerformed,
    trackSearchLoadMoreResults,
  } = useAdobeTracking(launchData);

  // Search handlers
  const searchHandler = (query: string) => {
    setCustomComponent(
      <Spinner>
        <Icons.Spinner />
      </Spinner>
    );
    doGoogleSearch(query, 1, googleApiUrl, dsid).then(
      (results: SearchResultsType) => {
        const trackingHits = Number(results?.hits) === 0 ? 'zero' : results?.hits ?? 0;
        trackSearchPerformed(query, trackingHits);
        setCustomComponent(Number(results?.hits) === 0 ? NoResults : null);
        setSearchResults(results);
        cacheSearchResults(results);
        if (window.innerWidth < breakpointsBootstrap.md) {
          const doc: any = document;
          doc?.activeElement?.blur();
        }
      },
      (error: any) => {
        console.log(error);
        setCustomComponent(<ApiError />);
      }
    );
  };

  const loadMoreResultsHandler = (query: string, startFrom: number, count: number, accumulate: boolean = false) => {
    setCustomComponent(
      <LoadMoreSpinner>
        <Icons.Spinner />
      </LoadMoreSpinner>
    );
    let startIndex = startFrom - 1;
    const start = startIndex * count + 1;
    trackSearchLoadMoreResults(document.title, startIndex + 1);
    doGoogleSearch(query, start, googleApiUrl, dsid).then(
      (resultData: SearchResultsType) => {
        setCustomComponent(Number(resultData?.hits) === 0 ? NoResults : null);
        if (accumulate) {
          resultData.results = [...(searchResults.results as Array<any>), ...(resultData.results as Array<any>)];
        }
        setSearchResults(resultData);
        cacheSearchResults(resultData);
      },
      (error: any) => {
        console.log(error);
        setCustomComponent(<ApiError />);
      }
    );
  };

  const NoResults: React.FC = () => <RichText field={fields['No Search Results Text']} editable={false} />;

  const ApiError: React.FC = () => (
    <ApiErrorWrapper>
      <Icons.Action.Broke></Icons.Action.Broke>
      <Typography type="h8">{t('search-error-title')}</Typography>
      <span
        dangerouslySetInnerHTML={{
          __html: t('search-error-text'),
        }}
      />
    </ApiErrorWrapper>
  );

  const Spinner = styled.div`
    margin-top: 50px;
    svg {
      width: 50px;
      height: 50px;
    }
  `;

  const LoadMoreSpinner = styled(Spinner)`
    position: fixed;
    bottom: 150px;
  `;

  const ApiErrorWrapper = styled.div`
    margin-top: 40px;
    gap: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    > svg {
      width: 40px;
      height: 40px;
    }
  `;

  // Create menu items array for "loginLinks"
  const loginLinks = [
    {
      name: fields['Login Button Text']?.value,
      url: '#',
      children: [
        {
          name: fields['Login NemID Link']?.value?.text,
          url: fields['Login NemID Link']?.value?.href,
          text: fields['Login NemID Description']?.value,
          trackingcode: fields['Login NemID Link']?.value?.trackingcode,
        },
        {
          name: fields['Login CVR Link']?.value?.text,
          url: fields['Login CVR Link']?.value?.href,
          text: fields['Login CVR Description']?.value,
          trackingcode: fields['Login CVR Link']?.value?.trackingcode,
        },
        {
          name: fields['Login Signing Link']?.value?.text,
          url: fields['Login Signing Link']?.value?.href,
          text: fields['Login Signing Description']?.value,
          trackingcode: fields['Login Signing Link']?.value?.trackingcode,
        },
      ],
    },
  ];

  return (
    <>
      {(!hideStickyCalculator || !hideStickyBookMeeting) && (
        <StickyCta
          bookMeetingText={fields['Sticky CTA Book Meeting Link']?.value?.text}
          bookMeetingLink={fields['Sticky CTA Book Meeting Link']?.value?.href}
          hideStickybookMeeting={hideStickyBookMeeting}
          bookMeetingTrackingId={fields['Sticky CTA Book Meeting Link']?.value?.trackingcode}
          calculateText={fields['Sticky CTA Calculator Link']?.value?.text}
          calculateLink={fields['Sticky CTA Calculator Link']?.value?.href}
          hideStickyCalculator={hideStickyCalculator}
          calculatorTrackingId={fields['Sticky CTA Calculator Link']?.value?.trackingcode}
        />
      )}

      <HeaderComponent
        areaLinks={fields.megaMenu}
        menuLinks={fields.topMenu}
        loginLinks={loginLinks}
        logoImage={fields['Logo Image']?.value.src}
        logoLink={fields['Logo Link']?.value.href}
        backLink={fields['Beta Banner Back Link']?.value?.href}
        backText={fields['Beta Banner Back Link']?.value?.text}
        feedbackContent={
          feedbackAllowed && (
            <span
              dangerouslySetInnerHTML={{
                __html: fields['Beta Banner Feedback Raw Content']?.value,
              }}
            />
          )
        }
        mobileCtaLink={fields['Book Meeting Link']?.value?.href}
        mobileCtaText={fields['Book Meeting Link']?.value?.text}
        hasSearchButton={fields['Show Search Icon']?.value}
        handleSearch={searchHandler}
        handleResultClick={(res) => {
          trackSearchResultClick(res);
        }}
        handleSearchButtonClick={(showing) => {
          if (showing) {
            trackSearchOpened(document.title);
            const cachedResults = retrieveCachedResults();
            if (cachedResults) {
              if (isNavigation()) {
                setSearchResults(cachedResults);
                const previousQuery = cachedResults?.results?.[0].searchTerms ?? '';
                setSearchQuery(previousQuery);
              } else {
                clearCachedResults();
              }
            }
          }
        }}
        handleLoadMoreResults={loadMoreResultsHandler}
        searchResults={searchResults}
        searchOverlayComponent={customComponent}
        searchLabels={{
          searchInputLabel: t('search-input-label'),
          hitsLabel: '',
          showMoreButtonLabel: t('show-more-button-label'),
        }}
        loadMoreStylePagination={true}
        searchQuery={searchQuery}
      />
    </>
  );
};

export default Header;
