import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { CprRegex, PhoneRegex } from '../../shared/regex';

export function getSchema(t: TFunction) {
  return Yup.object().shape({
    custtype: Yup.string(),
    iscompany: Yup.string(),

    cvr: Yup.number().when('custtype', {
      is: (value) => value && value === 'company',
      then: Yup.number()
        .test('len', t('form-input-cvr-error'), (val) => val?.toString().length === 8)
        .required(t('form-input-cvr-error')),
      otherwise: Yup.number(),
    }),

    cpr: Yup.string().when('custtype', {
      is: (value) => value === 'private' && value.length > 0,
      then: Yup.string().required(t('form-input-cpr-error')).matches(CprRegex, t('form-input-cpr-error')),
      otherwise: Yup.string(),
    }),

    fullname: Yup.string().when('custtype', {
      is: (value) => value && value === 'company',
      then: Yup.string().required(t('form-input-name-error')),
      otherwise: Yup.string(),
    }),

    fname: Yup.string().when('custtype', {
      is: (value) => value === 'private' && value.length > 0,
      then: Yup.string().required(t('form-input-first-name-error')),
      otherwise: Yup.string(),
    }),

    lname: Yup.string().when('custtype', {
      is: (value) => value === 'private' && value.length > 0,
      then: Yup.string().required(t('form-input-last-name-error')),
      otherwise: Yup.string(),
    }),

    phone: Yup.string()
      .required(t('form-input-phone-error'))
      .matches(PhoneRegex, t('form-input-phone-error'))
      .min(8, t('form-input-phone-error')),

    email: Yup.string().required(t('form-input-email-error')).email(t('form-input-email-error')),
  });
}
