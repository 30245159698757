import React from 'react';
import { useIsEditor } from '../../hooks';

export const EditorIncludes = () => {
  return useIsEditor() ? (
    <>
      <link rel="stylesheet" type="text/css" media="all" href="/css/author/rddk/edit.css" />
      <script src="/js/framework/DanskeBank_ChromeControls.js"></script>
      <script src="/js/framework/DanskeBank_Chrome.js"></script>
      <script src="/js/framework/DanskeBank_Browser.js"></script>
      <script src="/js/framework/DanskeBank_PlaceholderChromeType.js"></script>
      <script src="/js/framework/DanskeBank_PlaceholderInsertion.js"></script>
      <script src="/js/framework/DanskeBank_RenderingChromeType.js"></script>
      <script src="/js/framework/DanskeBank_StripFormatting.js"></script>
    </>
  ) : null;
};
