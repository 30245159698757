import { Color, mediaQuery } from 'db-npm-rdui';
import styled, { css } from 'styled-components';

type Props = {
  greyPattern?: boolean;
};

export const LayoutBackground = styled.div<Props>`
  ${(props) =>
    props.greyPattern &&
    css`
      overflow: hidden;
      background-color: ${Color.grey20};
      background-repeat: repeat-x;
      background-image: url("data:image/svg+xml,%3Csvg width='48' height='479' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3ClinearGradient x1='50%25' y1='99.871%25' x2='50%25' y2='3.298%25' id='a'%3E%3Cstop stop-color='%23E0E0E0' stop-opacity='0' offset='0%25'/%3E%3Cstop stop-color='%23C3C3C3' offset='100%25'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cpath d='M48 0v1H1v47h47v1H1v47h47v1H1v47h47v1H1v47h47v1H1v47h47v1H1v47h47v1H1v47h47v1H1v47h47v1H1v47h47v1H1v47h47v1H0V0h48z' fill='url(%23a)' fill-rule='nonzero'/%3E%3C/svg%3E");
      ${mediaQuery.lgDown} {
        background-size: 27px;
      }
    `}
`;
