import dayjs from 'dayjs';
import {
  Button,
  Cell,
  ChironDown,
  ChironUp,
  Col,
  Container,
  DynamicTable,
  Icons,
  mediaQuery,
  Modal,
  RddkTheme,
  Row,
  Select,
  Space,
  TableSelector,
  Visible,
} from 'db-npm-rdui';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { utils, write } from 'xlsx';
import { LabelDark } from '../../../../shared/components';
import { formatAsCurrency } from '../../../../shared/utils/currency';
import { useInputsState } from '../../state/inputsState';
import { useLoansState } from '../../state/loansState';
import { CalculatorTooltipAdministrationFee } from '../tooltip/CalculatorTooltipAdministrationFee';
import { useAmortExcelData } from './hooks/useAmortExcelData';

type CalculatorModalProps = {
  title: any;
};

const ModalHeader = styled.div`
  display: flex;
  padding-top: 120px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 60px;

  > select {
    font-size: 32px;
    font-family: 'IBM Plex Serif', serif;
    font-weight: 500;
    width: auto;
    margin-top: 10px;
    background-size: 20px;
    padding-right: 30px;
    background-position: 100% 70%;
    line-height: 40px;
    ${mediaQuery.md} {
      line-height: 60px;
      font-size: 41px;
    }
  }
`;

export const CalculatorModal: React.FC<CalculatorModalProps> = ({ title }) => {
  const { t } = useTranslation();
  const { setShowModal, showModal, activeLoan, setActiveLoan } = useInputsState();
  const { loans } = useLoansState();
  const [showData, setShowData] = React.useState(false);
  const [value, setValue] = React.useState(activeLoan?.type);
  const [activeYear, setActiveYear] = React.useState('');
  const [selectedTab, setTab] = React.useState(0);
  const mappedData = activeLoan?.mappedData;
  const excelData: (string | number)[][] = useAmortExcelData();

  const closeModal = React.useCallback(() => {
    setShowModal(false);
    setTab(0);
  }, [setShowModal, setTab]);

  const dropdownOptions = [
    ...loans.map((loan) => ({
      value: loan.type,
      label: t(`calculator-loan-${loan.type}-label`),
    })),
  ];

  const onChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newActiveloanValue = event.target.value;
    setValue(newActiveloanValue);
    const newActiveloanIndex = event.target.selectedIndex;
    const newActiveloan = loans[newActiveloanIndex];
    setActiveLoan(newActiveloan);
    setActiveYear('');
  };

  const amortisationDataTable = React.useMemo(() => {
    setShowData(false);
    const rows: Cell[][] = [];
    const showCashDebt: any =
      activeLoan !== null && activeLoan.amortisationData && activeLoan.amortisationData[0].data.cashDebt ? true : false;

    let tableHeader = [
      {
        value: t(`calculator-amortization-year-header`),
        minWidth: 110,
        width: 200,
      },
      {
        value: t(`calculator-amortization-grossPayment-header`),
        minWidth: 150,
      },
      {
        value: t(`calculator-amortization-netPayment-header`),
        minWidth: 150,
      },
      {
        value: t(`calculator-amortization-installments-header`),
        minWidth: showCashDebt ? 110 : 150,
      },
      {
        value: t(`calculator-amortization-interest-header`),
        minWidth: showCashDebt ? 110 : 150,
      },
      {
        value: t(`calculator-amortization-adminFee-header`),
        minWidth: 150,
      },
      {
        value: t(`calculator-amortization-bondDept-header`),
        minWidth: 150,
      },
    ];
    if (showCashDebt) {
      const cashDebt = {
        value: t(`calculator-amortization-cashDept-header`),
        minWidth: 150,
      };
      tableHeader.splice(6, 0, cashDebt);
    }

    // Add header row
    rows.push(tableHeader);

    if (activeLoan !== null) {
      // Add all body rows
      activeLoan.amortisationData?.forEach((row) => {
        const isActiveYear = row.year === activeYear;
        let rowData = [
          {
            // Year/termDate cell
            cellRender: () => {
              return (
                <>
                  <strong style={{ cursor: 'pointer' }} onClick={() => setActiveYear(isActiveYear ? '' : row.year)}>
                    {row.year} {isActiveYear ? <ChironUp /> : <ChironDown />}
                  </strong>
                  {isActiveYear && (
                    <div
                      style={{
                        marginTop: 20,
                        fontSize: 14,
                        lineHeight: '30px',
                      }}
                    >
                      {row.months.map((m) => (
                        <div key={m.month}>{dayjs(m.month).format('DD.MM.YYYY')}</div>
                      ))}
                    </div>
                  )}
                </>
              );
            },
          },
          {
            // grossPayment cell
            cellRender: () => {
              return (
                <>
                  <div>{formatAsCurrency(row.data.grossPayment)}</div>
                  {isActiveYear && (
                    <div
                      style={{
                        marginTop: 20,
                        fontSize: 14,
                        lineHeight: '30px',
                      }}
                    >
                      {row.months.map((m) => (
                        <div key={m.month}>{formatAsCurrency(m.data.grossPayment)}</div>
                      ))}
                    </div>
                  )}
                </>
              );
            },
          },
          {
            // netPayment cell
            cellRender: () => {
              return (
                <>
                  <div>{formatAsCurrency(row.data.netPayment)}</div>
                  {isActiveYear && (
                    <div
                      style={{
                        marginTop: 20,
                        fontSize: 14,
                        lineHeight: '30px',
                      }}
                    >
                      {row.months.map((m) => (
                        <div key={m.month}>{formatAsCurrency(m.data.netPayment)}</div>
                      ))}
                    </div>
                  )}
                </>
              );
            },
          },
          {
            // instalment cell
            cellRender: () => {
              return (
                <>
                  <div>{formatAsCurrency(row.data.instalment)}</div>
                  {isActiveYear && (
                    <div
                      style={{
                        marginTop: 20,
                        fontSize: 14,
                        lineHeight: '30px',
                      }}
                    >
                      {row.months.map((m) => (
                        <div key={m.month}>{formatAsCurrency(m.data.instalment)}</div>
                      ))}
                    </div>
                  )}
                </>
              );
            },
          },
          {
            // interest  cell
            cellRender: () => {
              return (
                <>
                  <div>{formatAsCurrency(row.data.interest)}</div>
                  {isActiveYear && (
                    <div
                      style={{
                        marginTop: 20,
                        fontSize: 14,
                        lineHeight: '30px',
                      }}
                    >
                      {row.months.map((m) => (
                        <div key={m.month}>{formatAsCurrency(m.data.interest)}</div>
                      ))}
                    </div>
                  )}
                </>
              );
            },
          },
          {
            // administrationFeeAmount cell
            cellRender: () => {
              return (
                <>
                  <div>{formatAsCurrency(row.data.administrationFeeAmount)}</div>
                  {isActiveYear && (
                    <div
                      style={{
                        marginTop: 20,
                        fontSize: 14,
                        lineHeight: '30px',
                      }}
                    >
                      {row.months.map((m) => (
                        <div key={m.month}>{formatAsCurrency(m.data.administrationFeeAmount)}</div>
                      ))}
                    </div>
                  )}
                </>
              );
            },
          },
          {
            // bondDebt cell
            cellRender: () => {
              return (
                <>
                  <div>{formatAsCurrency(row.months[row.months.length - 1].data.bondDebt)}</div>
                  {isActiveYear && (
                    <div
                      style={{
                        marginTop: 20,
                        fontSize: 14,
                        lineHeight: '30px',
                      }}
                    >
                      {row.months.map((m) => (
                        <div key={m.month}>{formatAsCurrency(m.data.bondDebt)}</div>
                      ))}
                    </div>
                  )}
                </>
              );
            },
          },
        ];

        if (showCashDebt) {
          const cashDebt: any = {
            // cashDebt cell
            cellRender: () => {
              return (
                <>
                  <div>{formatAsCurrency(row.months[row.months.length - 1].data.cashDebt)}</div>
                  {isActiveYear && (
                    <div
                      style={{
                        marginTop: 20,
                        fontSize: 14,
                        lineHeight: '30px',
                      }}
                    >
                      {row.months.map((m) => (
                        <div key={m.month}>{formatAsCurrency(m.data.cashDebt)}</div>
                      ))}
                    </div>
                  )}
                </>
              );
            },
          };
          rowData.splice(6, 0, cashDebt);
        }
        rows.push(rowData);
      });
    }
    setTimeout(() => {
      setShowData(true);
    }, 0);
    return rows;
  }, [activeLoan, activeYear, t, setShowData]);

  const downloadSpreadsheet = () => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

    const workSheet = utils.aoa_to_sheet(excelData);

    // Generate a Work Book containing the above sheet.
    const workBook = {
      Sheets: { Ydelsesforløb: workSheet, cols: [] },
      SheetNames: ['Ydelsesforløb'],
    };

    // Exporting the file with the desired name and extension.
    const excelBuffer = write(workBook, {
      bookType: 'xlsx',
      type: 'array',
    });

    const fileData = new Blob([excelBuffer], { type: fileType });

    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = today.getMonth() + 1; // Months start at 0!
    const dd = today.getDate();
    const formattedToday = `${dd}.${mm}.${yyyy}`;

    const loanType = t(`calculator-loan-${activeLoan?.type}-label`);
    const filename = `Ydelsesforløb for ${loanType} ${formattedToday}`;

    const downloadURL = URL.createObjectURL(fileData);
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);
    downloadLink.download = `${filename}.xlsx`;
    downloadLink.href = downloadURL;
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  React.useEffect(() => {
    setValue(activeLoan?.type);
  }, [activeLoan, setValue]);

  return (
    <Modal
      open={showModal}
      handleClose={closeModal}
      ariaCloseLabel={'Close'}
      backgroundColor={RddkTheme.colors.grey90}
      fullscreen
    >
      <ModalHeader>
        <LabelDark field={title} />
        <Select value={value} isDark={true} options={dropdownOptions || []} onChange={onChangeHandler} />
      </ModalHeader>
      <Container>
        <Row justify="center">
          <Col sm={10}>
            <StyledDiv>
              <TableSelector selectedIndex={0} isDarkTheme={true}>
                <div onClick={() => setTab(0)}>Ydelsesforløb</div>
                <div onClick={() => setTab(1)}>Omkostninger</div>
              </TableSelector>
              <Space top={16}></Space>
              {selectedTab === 0 && (
                <Button variant="secondary" onClick={downloadSpreadsheet} style={{ height: '35px', zIndex: 0 }}>
                  <React.Fragment key=".0">
                    {t(`calculator-amortization-download-button`)} <Icons.Download />
                  </React.Fragment>
                </Button>
              )}
            </StyledDiv>
          </Col>
        </Row>
      </Container>
      <ModalContainer>
        {showData && (
          <Container>
            {selectedTab === 0 && (
              <div>
                <Visible xs sm md>
                  <DynamicTable
                    bordered
                    data={amortisationDataTable}
                    firstRowIsHead
                    striped
                    firstColumnIsSticky
                    isFromDialog
                  />
                </Visible>
                <Visible lg xl xxl>
                  <DynamicTable
                    bordered
                    data={amortisationDataTable}
                    firstRowIsHead
                    striped
                    tableHeaderIsSticky
                    firstColumnIsSticky
                    isFromDialog
                  />
                </Visible>
              </div>
            )}
            {selectedTab === 1 && (
              <div>
                <CalculatorTooltipAdministrationFee {...mappedData} isFromOverlay={true} />
              </div>
            )}
          </Container>
        )}
      </ModalContainer>
    </Modal>
  );
};

const ModalContainer = styled.div`
  padding: 50px;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${mediaQuery.lgDown} {
    flex-direction: column;
  }
`;
