import { sleep } from '../helpers/sleep';
import { LoanTypeCalculatorFunction } from '../types/LoanTypeCalculatorFunction';
import { cibor6 } from './cibor6';
import { flexkort } from './flexkort';
import { flexlaank } from './flexlaank';
import { flexlaant } from './flexlaant';
import { flexlife } from './flexlife';
import { kontantlaan } from './kontantlaan';
import { obligationslaan } from './obligationslaan';

export const performCalculation: LoanTypeCalculatorFunction = async (loan, inputs, apiUrl) => {
  if (process.env.NODE_ENV === 'development') {
    console.log('performCalculation:', { loan, inputs, apiUrl });
  }

  switch (loan.type) {
    case 'flexlife':
      return flexlife(loan, inputs, apiUrl);

    case 'obligationslaan':
      return obligationslaan(loan, inputs, apiUrl);

    case 'flexlaank':
      return flexlaank(loan, inputs, apiUrl);

    case 'flexlaant':
      return flexlaant(loan, inputs, apiUrl);

    case 'flexkort':
      return flexkort(loan, inputs, apiUrl);

    case 'kontantlaan':
      return kontantlaan(loan, inputs, apiUrl);

    case 'cibor6':
      return cibor6(loan, inputs, apiUrl);

    default:
      console.warn('performCalculation not implemented for', loan.type);
      await sleep(300);
      return Promise.resolve(false);
  }
};
