import { Button, InputField } from 'db-npm-rdui';
import { Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormLayout, FormRow } from '../../shared/components/Form';
import { useAdobeTracking } from '../../shared/hooks';
import { getSchema } from './schema';

type Props = {
  fields: any;
  onSubmitData: (formData: any) => Promise<void>;
};

const PpiForm: React.FC<Props> = ({ fields = {}, onSubmitData }) => {
  const { t } = useTranslation();
  const schema = getSchema(t);
  const mailSubject = fields['Mail Subject']?.value;
  const { onFormInteraction } = useAdobeTracking();

  return (
    <FormLayout
      headline={fields['Headline']?.value}
      description={fields['Description']?.value}
      formTitle={fields['Form Title']?.value}
    >
      <Formik
        initialValues={{
          name: '',
          phone: '',
          cpr: '',
          email: '',
          emailsubject: mailSubject,
        }}
        validationSchema={schema}
        onSubmit={(values) => {
          onSubmitData(values);
        }}
      >
        {({ values, errors, touched, handleChange }) => {
          const trackingChangeHandler = (e) => {
            onFormInteraction(e, handleChange);
          };
          return (
            <Form noValidate>
              <FormRow>
                <InputField
                  name="name"
                  id="name"
                  label={t('form-input-name-label')}
                  layout="white"
                  value={values.name}
                  onChange={trackingChangeHandler}
                  data-maptoegain="true"
                  data-includeinmailboxemail="true"
                  data-egainfield="customer_name"
                  maxLength={50}
                  data-label={t('form-input-name-label')}
                  errorMessage={touched.name && errors.name ? errors.name : ''}
                  required
                />
              </FormRow>
              <FormRow>
                <InputField
                  name="phone"
                  id="phone"
                  label={t('form-input-phone-label')}
                  layout="white"
                  value={values.phone}
                  maxLength={20}
                  onChange={trackingChangeHandler}
                  data-maptoegain="true"
                  data-includeinmailboxemail="true"
                  data-egainfield="customer_phone"
                  data-label={t('form-input-phone-label')}
                  errorMessage={touched.phone && errors.phone ? errors.phone : ''}
                  required
                />
              </FormRow>
              <FormRow>
                <InputField
                  name="cpr"
                  id="cpr"
                  label={t('form-input-cpr-label')}
                  layout="white"
                  variant="numeric"
                  format="######-####"
                  value={values.cpr}
                  onChange={trackingChangeHandler}
                  data-maptoegain="true"
                  data-includeinmailboxemail="true"
                  data-egainfield="customer_number"
                  data-label={t('form-input-cpr-label')}
                  errorMessage={touched.cpr && errors.cpr ? errors.cpr : ''}
                  required
                />
              </FormRow>

              <FormRow>
                <InputField
                  name="email"
                  id="email"
                  label={t('form-input-email-label')}
                  layout="white"
                  value={values.email}
                  onChange={trackingChangeHandler}
                  maxLength={100}
                  data-maptoegain="true"
                  data-includeinmailboxemail="true"
                  data-egainfield="email"
                  data-label={t('form-input-email-label')}
                  errorMessage={touched.email && errors.email ? errors.email : ''}
                  required
                />
                <small>
                  <i>{fields['Email Field Description']?.value}</i>
                </small>
              </FormRow>

              <FormRow>
                <Button onClick={trackingChangeHandler} type="submit" id="submit">
                  {t('form-button-send-label')}
                </Button>
                <p>{fields['Submit Button Description']?.value}</p>
              </FormRow>
            </Form>
          );
        }}
      </Formik>
    </FormLayout>
  );
};

export default PpiForm;
