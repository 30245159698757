import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { applyTextType, Col, Color, Container, Effects, Icons, mediaQuery, Row } from 'db-npm-rdui';
import React from 'react';
import styled from 'styled-components';
import { FieldChecker } from '../../shared/components';
import { componentTopMargin } from '../../shared/utils/componentTopMargin';

const StyledFooter = styled.footer`
  margin-top: 70px;
  padding-bottom: 100px;
  background: #000;
  color: ${Color.white};

  ${mediaQuery.md} {
    margin-top: 90px;
  }

  ${mediaQuery.lg} {
    margin-top: 110px;
  }
`;

const Columns = styled.div`
  border-top: 1px solid #bdbdbd;
  padding-top: 10px;

  ${mediaQuery.md} {
    padding-top: 10px;
  }

  ${mediaQuery.lg} {
    padding-top: 100px;
  }

  ${mediaQuery.lg} {
    .border {
      ::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: -15px;
        bottom: 0;
        width: 1px;
        background: #eee;
      }
    }
  }

  h6 {
    ${applyTextType('h6')}
    margin: 20px 0 15px 0;
    color: ${Color.white};
    ${mediaQuery.lg} {
      margin: 0 0 20px 0;
    }
  }

  p {
    margin: 0;
  }

  a {
    display: inline-block;
    position: relative;
    margin: 5px 17px 10px 0;
    font-size: 14px;
    letter-spacing: 0.3px;
    color: ${Color.white};
    text-decoration: none;
    transition: color 0.5s;
    font-weight: 600;

    ${Effects.Underline()}

    &:hover,
    &:focus {
      color: ${Color.white};
      ${Effects.UnderlineHover}
    }
  }
`;

const SubFooter = styled.div`
  font-style: italic;
  color: ${Color.white};

  a {
    font-weight: 600;
    color: ${Color.white};
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  strong {
    color: ${Color.white};
  }
`;

const TermsText = styled(SubFooter)`
  ${componentTopMargin('small')}
  font-size: 15px;
  line-height: 1.4;
`;

const DisclaimerText = styled(SubFooter)`
  border-top: 1px solid #bdbdbd;
  padding-top: 30px;
  margin-top: 30px;
  font-size: 14px;
  line-height: 1.2;

  ${mediaQuery.lg} {
    padding-right: 200px;
  }
`;

const ImageRow = styled(Row)`
  display: flex;
  width: 130px;
  height: 70px;
  align-items: center;
  padding-top: 35px;
  margin-bottom: 30px;

  ${mediaQuery.lg} {
    padding-top: 105px;
  }

  a {
    display: block;
    text-decoration: none !important;

    &:hover,
    &:focus {
      text-decoration: none !important;
    }
  }

  svg {
    width: 130px;
    height: 32px;
  }

  img {
    display: block;
    max-width: 130px;
    max-height: 70px;
  }
`;

export type FooterProps = {
  fields: any;
};

const Footer: React.FC<FooterProps> = ({ fields = {} }) => {
  return (
    <StyledFooter>
      <Container>
        <Row>
          <Col lg={2.5}>
            <ImageRow>
              <Col>
                <a
                  href={fields['Logo Link']?.value.href}
                  aria-label={fields['Logo Link']?.value.text}
                  title={fields['Logo Link']?.value.text}
                >
                  {fields['Logo Image']?.value.src ? (
                    <img src={fields['Logo Image']?.value.src} alt={fields['Logo Image']?.value.href} />
                  ) : (
                    <Icons.LogoWhite />
                  )}
                </a>
              </Col>
            </ImageRow>
          </Col>
          <Col lg={9.5}>
            <Columns>
              <Row>
                <FieldChecker type="text" field={fields['Column 1 Text']}>
                  <Col lg={4} xxl={3}>
                    <RichText field={fields['Column 1 Text']} editable={false} />
                  </Col>
                </FieldChecker>
                <FieldChecker type="text" field={fields['Column 2 Text']}>
                  <Col lg={4} xxl={3} className="border">
                    <RichText field={fields['Column 2 Text']} editable={false} />
                  </Col>
                </FieldChecker>
                <FieldChecker type="text" field={fields['Column 3 Text']}>
                  <Col lg={4} xxl={3} className="border">
                    <RichText field={fields['Column 3 Text']} editable={false} />
                  </Col>
                </FieldChecker>
              </Row>
            </Columns>

            <Row>
              <Col lg={6}>
                <FieldChecker type="text" field={fields['Terms Text']}>
                  <TermsText>
                    <RichText field={fields['Terms Text']} editable={false} />
                  </TermsText>
                </FieldChecker>
              </Col>
            </Row>

            <FieldChecker type="text" field={fields['Disclaimer Text']}>
              <DisclaimerText>
                <RichText field={fields['Disclaimer Text']} editable={false} />
              </DisclaimerText>
            </FieldChecker>
          </Col>
        </Row>
      </Container>
    </StyledFooter>
  );
};

export default Footer;
