import { Box, Col, Container, Icons, mediaQuery, Row, Typography } from 'db-npm-rdui';
import React from 'react';
import styled from 'styled-components';

type Props = {
  message: any;
};

export const ErrorMessage: React.FC<Props> = ({ message }) => {
  const modalContent = (
    <Container>
      <Row justify="center">
        <Col md={8}>
          <Box textAlign="center" marginTop={12} marginBottom={12}>
            <CustomIconWrapper>
              <Icons.ErrorRed />
            </CustomIconWrapper>

            <Typography type="manchetBig" mt={20} mb={30}>
              {message}
            </Typography>
          </Box>
        </Col>
      </Row>
    </Container>
  );

  return modalContent;
};

const CustomIconWrapper = styled.div`
  margin-bottom: 20px;

  ${mediaQuery.md} {
    margin-bottom: 30px;
  }

  svg {
    width: 60px;
    height: 60px;
  }
`;
