/**
 * The minimum number of loans in the table. User can only remove loans when more than this number.
 */
export const MINIMUN_AMOUNT_OF_LOANS = 1;
/**
 * The maximum number of loans on mobile view.
 */
export const MAX_LOANS_MOBILE = 2;
/**
 * The maximum number of loans on desktop view.
 */
export const MAX_LOANS_DESKTOP = 3;
/**
 * The width of the label column on desktop
 */
export const DESKTOP_LABEL_COLUMN_WIDTH = 300;
/**
 * The min-width of the loan columns on desktop
 */
export const DESKTOP_LOAN_COLUMNS_MIN_WIDTH = 200;
/**
 * The delay for new calculations when changing input values.
 */
export const CALCULATION_DELAY_INPUT = 2000;
/**
 * The delay for new calculations when changing slider values.
 */
export const CALCULATION_DELAY_SLIDER = 600;
/**
 * The delay for new calculations when changing dropdown values.
 */
export const CALCULATION_DELAY_DROPDOWN = 100;
/**
 * LTV used for validating the inputs
 */
export const MINIMUM_LTV = 0.75;
/**
 * The minimum loan amount
 */
export const LOAN_AMOUNT_MINIMUM = 100000;
/**
 * The maximum loan amount
 */
export const LOAN_AMOUNT_MAXIMUM = 1000000000;
/**
 * The HTML ID of inputs submit button
 */
export const HTML_ID_SUBMIT_BUTTON = 'calculator-submit-button';
/**
 * The HTML ID of scroll-to area
 */
export const HTML_ID_SCROLLTO_AREA = 'calculator-scrollto-area';
/**
 * The local demo data for API calls is used if API Endpoint URL is set to this value
 */
export const API_LOCAL_VALUE = 'local';
/**
 * Key for query parameter to set loan types
 */
export const QUERY_KEY_LOANS = 'loan';
/**
 * Key for query parameter to set property type
 */
export const QUERY_KEY_PROPERTY_TYPE = 'type';
/**
 * Key for query parameter to set owner category
 */
export const QUERY_KEY_OWNER_CATEGORY = 'owner';
/**
 * Key for query parameter to set property type
 */
export const QUERY_KEY_PROPERTY_VALUE = 'price';
/**
 * Key for query parameter to set own payment
 */
export const QUERY_KEY_OWN_PAYMENT = 'payment';
/**
 * The char to use as thousand separator when formatting
 */
export const FORMATTING_THOUSAND_SEPATOR = '.';
/**
 * The char to use as decimal separator when formatting
 */
export const FORMATTING_DECIMAL_SEPATOR = ',';
/**
 * The field keys of table 1 "Realkredit"
 */
export const TABLE_FIELDKEYS_REALKREDIT_1 = [
  'bankloan',
  'ownpayment',
  'remainingDebt',
  'netProceedsAmountTop',
  'firstYearInterestOnlyMonthlyPaymentTop',
  'firstYearInstalmentMonthlyPaymentGrossTop',
  'monthlyPaymentWithOutInstallment',
  'monthlyPaymentWithInstallment',
  'businessMonthlyPaymentWithOutInstallment',
  'businessMonthlyPaymentWithInstallment',
  'maturityTop',
  'interestOnlyYearsStart',
  'interestOnlyYearsEnd',
  'rateType',
  'refinancingFrequency',
  'calculatedDebtAtExpiry',
  'loanToValuePercentage',
  'taxRate',
  'paymentsPerYear',
];
/**
 * The field keys of table 2 "Realkredit"
 */
export const TABLE_FIELDKEYS_REALKREDIT_2 = [
  'loanProvider',
  'netProceedsAmount',
  'principalAmount',
  'maturityBottom',
  'monthlyGrossPaymentYearOneBottom',
  'numberOfPayments',
  'debtorInterestRate',
  'weightedBondPrice',
  'annualPercentageRate',
  'totalAdministrationFee',
  'totalRepaymentAmount',
  'propertyDepositDisclaimer',
] as const;
/**
 * The field keys of table "Banklaan"
 */
export const TABLE_FIELDKEYS_BANKLOAN = ['dummy1', 'dummy2'] as const;
/**
 * The field keys of table "Samlet"
 */
export const TABLE_FIELDKEYS_OVERVIEW = ['monthlyGrossPaymentYearOneTop', 'dummy1', 'dummy2'] as const;

export const CITY_COMMERCIAL = 'CityCommercial';
