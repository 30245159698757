import { Skeleton } from 'db-npm-rdui';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import filter from 'lodash/filter';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { CALCULATION_DELAY_DROPDOWN, CALCULATION_DELAY_INPUT, CALCULATION_DELAY_SLIDER } from '../../config';
import { useInputsState } from '../../state/inputsState';
import { useLoansState } from '../../state/loansState';
import { LoanStateItem } from '../../types/LoanStateItem';
import { TableItem } from '../../types/TableItem';
import { CalculatorDropdownField } from '../fields/CalculatorDropdownField';
import { CalculatorInputField } from '../fields/CalculatorInputField';
import { CalculatorSliderField } from '../fields/CalculatorSliderField';
import { CalculatorStaticField } from '../fields/CalculatorStaticField';

type CalculatorTableBodyCellProps = {
  loanArrayIndex: number;
  tableItem: TableItem | undefined;
};

export const CalculatorTableBodyCell: React.FC<CalculatorTableBodyCellProps> = ({ loanArrayIndex, tableItem }) => {
  const { loans, setLoanPayloadAndCalculate } = useLoansState();
  const { hasError, setError } = useInputsState();
  const { t } = useTranslation();

  const loan: LoanStateItem = loans[loanArrayIndex];

  if (tableItem?.fieldKey === 'netProceedsAmountTop') {
    tableItem.additionalText = t(`calculator-inputs-lending-calculator-additional-lending-text`);
  }

  /**
   * This callback function handles the onChange event and updates the loan
   */
  const handleValueChange = React.useCallback(
    (delay: number) =>
      debounce((value: any) => {
        if (!tableItem) {
          return;
        }

        if ('min' in tableItem && typeof tableItem.min === 'number') {
          value = Math.max(value, tableItem.min);
        }
        if ('max' in tableItem && typeof tableItem.max === 'number') {
          value = Math.min(value, tableItem.max);
        }

        if ('onChange' in tableItem) {
          const newPayload = tableItem.onChange(cloneDeep(loan), value);
          setError(false);

          const currentTableItem = filter(newPayload.tableItems, { fieldKey: tableItem.fieldKey });

          // This condition has written inorder to check whether input field is valid or not
          if (newPayload && !tableItem.hasOwnProperty('hasError')) {
            if (newPayload.settings) {
              setLoanPayloadAndCalculate(loanArrayIndex, newPayload.payload, newPayload.settings);
            } else {
              setLoanPayloadAndCalculate(loanArrayIndex, newPayload);
            }
          } else {
            if (tableItem.hasOwnProperty('hasError')) {
              if (currentTableItem.length > 0 && currentTableItem[0].hasError && currentTableItem[0].hasError.error) {
                setLoanPayloadAndCalculate(
                  loanArrayIndex,
                  newPayload.payload,
                  newPayload.settings,
                  newPayload.tableItems,
                  true
                );
              } else {
                setLoanPayloadAndCalculate(loanArrayIndex, newPayload.payload, newPayload.settings);
              }
            }
          }
        }
      }, delay),
    [tableItem, loan, loanArrayIndex, setError, setLoanPayloadAndCalculate]
  );

  if (loan.status === 'loading') {
    return <Skeleton active />;
  }

  if (loan.status === 'error' || tableItem === undefined) {
    return null;
  }

  const renderSwitch = () => {
    switch (tableItem.inputType) {
      case 'input':
        return (
          <CalculatorInputField
            tableItem={tableItem}
            onChangeValue={handleValueChange(CALCULATION_DELAY_INPUT)}
            hasError={hasError}
          />
        );
      case 'dropdown':
        return (
          <CalculatorDropdownField
            tableItem={tableItem}
            onChangeValue={handleValueChange(CALCULATION_DELAY_DROPDOWN)}
          />
        );
      case 'slider':
        return (
          <CalculatorSliderField tableItem={tableItem} onChangeValue={handleValueChange(CALCULATION_DELAY_SLIDER)} />
        );
      case 'static':
        return <CalculatorStaticField tableItem={tableItem} />;
    }
  };

  return <>{renderSwitch()}</>;
};
