import { ConsentLevel, useCookieConsent } from 'db-npm-rdui';
import * as React from 'react';

type FacebookPixelProps = {
  fields: any;
};

const FacebookPixel: React.FC<FacebookPixelProps> = ({ fields = {} }) => {
  const facebookPixelId = fields['Facebook Pixel ID']?.value;
  const enableFacebookPixel = useCookieConsent().isAllowed(ConsentLevel.Marketing) && facebookPixelId !== '';

  React.useEffect(() => {
    if (!enableFacebookPixel) {
      return;
    }

    //eslint-disable-next-line no-new-func
    new Function(
      `!function(f,b,e,v,n,t,s){{if(f.fbq)return;n=f.fbq=function(){{n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)}};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version="2.0";n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}}(window,document,"script","//connect.facebook.net/en_US/fbevents.js");fbq("init","{facebookPixelId}");fbq("track","PageView");`.replace(
        '{facebookPixelId}',
        facebookPixelId
      )
    )();
  }, [enableFacebookPixel, facebookPixelId]);

  if (!enableFacebookPixel) {
    return null;
  }

  return (
    <span
      dangerouslySetInnerHTML={{
        __html: `<noscript><img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id={facebookPixelId}&ev=PageView&noscript=1" /></noscript>`.replace(
          '{facebookPixelId}',
          facebookPixelId
        ),
      }}
    ></span>
  );
};

export default FacebookPixel;
