import { withPlaceholder } from '@sitecore-jss/sitecore-jss-react';
import { PlaceholderProps } from '@sitecore-jss/sitecore-jss-react/types/components/PlaceholderCommon';
import { Container } from 'db-npm-rdui';
import React from 'react';
import { ExpEditorRow, Section } from '../../shared/components';

export type CardsProps = PlaceholderProps & {
  cards: any;
};

const Cards: React.FC<CardsProps> = ({ cards }) => {
  return (
    <Section componentName="Cards" margin="small">
      <Container>
        <ExpEditorRow>{cards}</ExpEditorRow>
      </Container>
    </Section>
  );
};

export default withPlaceholder('cards')(Cards);
