import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import 'db-npm-rdui/dist/fonts.css';
import 'db-npm-rdui/dist/normalize.css';
import 'db-npm-rdui/dist/styles.css';
import * as React from 'react';
import { ApolloProvider } from 'react-apollo';
import { Route, Switch } from 'react-router-dom';
import RouteHandler from './RouteHandler';
import { AppSetup } from './shared/components/AppSetup';

// This is the main JSX entry point of the app invoked by the renderer (server or client rendering).
// By default the app's normal rendering is delegated to <RouteHandler> that handles the loading of JSS route data.

// support languages in the URL prefix
// e.g. /da-DK/path, or /en/path, or /path
export const routePatterns = [
  // '/:lang([a-z]{2}-[A-Z]{2})/:sitecoreRoute*',
  // '/:lang([a-z]{2})/:sitecoreRoute*',
  '/:sitecoreRoute*',
];

// wrap the app with:
// ApolloProvider: provides an instance of Apollo GraphQL client to the app to make Connected GraphQL queries.
//    Not needed if not using connected GraphQL.
// SitecoreContext: provides component resolution and context services via withSitecoreContext
// Router: provides a basic routing setup that will resolve Sitecore item routes and allow for language URL prefixes.
const AppRoot = ({
  path,
  Router,
  graphQLClient,
}: {
  path: string;
  Router: any;
  graphQLClient: ApolloClient<NormalizedCacheObject>;
}) => {
  const routeRenderFunction = (props: any) => <RouteHandler route={props} />;
  return (
    <AppSetup>
      <ApolloProvider client={graphQLClient}>
        <Router location={path} context={{}}>
          <Switch>
            {routePatterns.map((routePattern) => (
              <Route key={routePattern} path={routePattern} render={routeRenderFunction} />
            ))}
          </Switch>
        </Router>
      </ApolloProvider>
    </AppSetup>
  );
};

export default AppRoot;
