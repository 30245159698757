import flatMap from 'lodash/flatMap';
import { FieldKey, FieldKeys } from '../types/FieldKey';
import { LoanStateItem } from '../types/LoanStateItem';

/**
 * Filter the fieldKeys based on whats being displayed from the loans
 */
export const getFieldKeysToDisplay = (fieldKeys: FieldKeys, loans: LoanStateItem[]): FieldKey[] => {
  const fieldKeysInUse = flatMap(loans, (l) => l.tableItems || []).map((item) => item.fieldKey);

  if (fieldKeysInUse.length <= 0) {
    return [...fieldKeys];
  }

  return [...fieldKeys].filter((key) => fieldKeysInUse.indexOf(key) >= 0);
};
