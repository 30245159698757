import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Color, Typography } from 'db-npm-rdui';
import React from 'react';
import styled from 'styled-components';

type Props = {
  field: any;
};

export const LabelDark: React.FC<Props> = ({ field }) => {
  return (
    <Label>
      <Typography type="label">
        <Text field={field} />
      </Typography>
    </Label>
  );
};

const Label = styled.span`
  display: inline-block;
  border-radius: 3px;
  padding: 6px 12px;
  border: 2px solid ${Color.grey40};
  ${Typography} {
    color: ${Color.grey10};
  }
`;
