type FormConfigSubmit = {
  /**
   * The API endpoint URL where the form data is submitted to
   */
  apiUrl: string;
  /**
   * The DSID key for the form in EGAIN system.
   * Please provide the string value WITHOUT brackets "{" and "}"
   */
  egainDsid: string;
  /**
   * The From mail box used when email was triggered (sender)
   */
  fromMailBox: string;
  /**
   * The To mail box used when email was triggered (receiver)
   */
  toMailBox: string;
};
type FormConfigUpload = {
  apiUrl: string;
  egainDsid: string;
};
export async function submitFormData(
  formConfig: FormConfigSubmit,
  subject: string,
  formData: any,
  fileIdData: any,
  attachId: any
): Promise<any> {
  if (formData && formData.phone && formData.phone.indexOf('+') !== -1) {
    const modifiedPhone = formData.phone.replace('+', '00');
    formData.phone = modifiedPhone;
  }
  if (formData && formData.cpr && formData.cpr.indexOf('-') !== -1) {
    const modifiedCPR = formData.cpr.replace('-', '');
    formData.cpr = modifiedCPR;
  }
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');

  const valueObj = Object.keys(formData);
  let fieldObj = valueObj.map((key) => `${key}:${formData[key]}`).join('!#');

  let techDetailsObj = `!#!#!#****************************!# Technical Details !#****************************!#!#  Attach Id : ${attachId}!#!#`;

  const fileIdObj = fileIdData.map((a, index) => `File id ${index + 1} : ${a} `).join('!#');

  const finalMailBody = fieldObj + techDetailsObj + fileIdObj;

  const raw = JSON.stringify({
    FldNavnH: formConfig.fromMailBox,
    FldSendTo: formConfig.toMailBox,
    FldSubjH: subject,
    FldText: finalMailBody,
    FileId: '',
    DSId: `{${formConfig.egainDsid}}`,
    DocumentFile0: '',
    Language: 'da',
    AttachId: attachId,
  });

  const response = await fetch(formConfig.apiUrl, {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.ErrorId === '0') {
        return true;
      } else {
        return false;
      }
    })
    .catch((error) => {
      console.warn('Form submit error:', error);
      return false;
    });
  return response;
}

export function attachFileGetUploadParams(formConfig: FormConfigUpload, attachId: any, hasCompress: any, subject: any) {
  const getUploadParams = () => ({
    url: formConfig.apiUrl,
    fields: {
      FldHasCompress: hasCompress,
      DSId: `{${formConfig.egainDsid}}`,
      Language: 'da',
      AttachId: attachId,
      FldSubjH: subject,
    },
  });
  return getUploadParams;
}

export async function removeFile(file: any, formConfig: FormConfigUpload, attachId: any): Promise<any> {
  if (file.status !== 1) {
    return true;
  }
  const responseObj = file.response;
  try {
    const result = await fetch(formConfig.apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        AttachId: attachId,
        FileId: responseObj.FileId,
        DSId: `{${formConfig.egainDsid}}`,
        Language: 'da',
      }),
    });
    const text = await result.text(); // Needed for Response stream to resolve
    const json = JSON.parse(text);
    const isSuccess = json.ErrorId === '0';
    return isSuccess;
  } catch {
    return false;
  }
}
