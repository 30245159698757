import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Box, Col, Container, Row, Typography } from 'db-npm-rdui';
import * as React from 'react';
import { FieldChecker, Section } from '../../shared/components';
import { LaunchData, useAdobeTracking } from '../../shared/hooks';
import { CalculatorInputs, CalculatorInputsOnSubmit } from '../LoanCalculator/components/inputs/CalculatorInputs';
import {
  QUERY_KEY_OWNER_CATEGORY,
  QUERY_KEY_OWN_PAYMENT,
  QUERY_KEY_PROPERTY_TYPE,
  QUERY_KEY_PROPERTY_VALUE,
} from '../LoanCalculator/config';
import { useSettingsState } from '../LoanCalculator/state/settingsState';

export type LoanCalculatorInputsOnlyProps = {
  fields: any;
};

const LoanCalculatorInputsOnly: React.FC<LoanCalculatorInputsOnlyProps> = ({ fields = {} }) => {
  const smallTopMargin = fields['Small Top Margin']?.value === true;
  const calculatorPageUrl = String(fields['Calculator Page URL']?.value || '');
  const launchData: LaunchData = {
    calculator_type: 'Hero module',
    calculator_name: 'Loan calculator',
    calculator_status: 'loaded',
  };
  useAdobeTracking(launchData);

  /**
   * Set settings in calculator state
   */
  const { setSettings } = useSettingsState();
  React.useEffect(() => {
    const settings = JSON.parse(fields['Settings']?.value || '{}');
    setSettings(settings);
  }, [fields, setSettings]);

  /**
   * When the user submits we use this function to redirect the user to the next page (calculator page).
   * All the submitted data will be set as query parameters.
   * @param propertyType The property type the user selected (eg SingleFamilyDetachedHouse)
   * @param propertyValue The property value the user entered
   * @param ownPayment The own payment amount the user entered
   */
  const onSubmit: CalculatorInputsOnSubmit = (data) => {
    const { propertyType, ownerCategory, propertyValue, ownPayment } = data;

    // Combine all data into a URLSearchParams object
    const params = new URLSearchParams({
      [QUERY_KEY_PROPERTY_TYPE]: propertyType || '',
      [QUERY_KEY_OWNER_CATEGORY]: ownerCategory || '',
      [QUERY_KEY_PROPERTY_VALUE]: (propertyValue || 0) + '',
      [QUERY_KEY_OWN_PAYMENT]: (ownPayment || 0) + '',
    });

    // Redirect the user to the new page
    if (!!calculatorPageUrl && window && window.location) {
      window.location.href = `${calculatorPageUrl}?${params}`;
    }
  };

  return (
    <Section componentName="LoanCalculatorInputsOnly" margin={smallTopMargin ? 'x-small' : 'large'}>
      <Container>
        <Row justify="center">
          <Col md={6}>
            <FieldChecker type="text" field={fields['Headline']}>
              <Box textAlign="center" marginBottom={5}>
                <Typography type="h2">
                  <Text field={fields['Headline']} />
                </Typography>
              </Box>
            </FieldChecker>

            <FieldChecker type="text" field={fields['Description']}>
              <Box textAlign="center" marginBottom={6}>
                <Typography type="manchetBig">
                  <Text field={fields['Description']} />
                </Typography>
              </Box>
            </FieldChecker>
          </Col>
        </Row>
      </Container>

      <CalculatorInputs onSubmit={onSubmit} />
    </Section>
  );
};

export default LoanCalculatorInputsOnly;
