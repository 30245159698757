import { useEffect, useState } from 'react';
import { filterClosedBonds, filterDataOnPrices, groupDrillSort } from '../Utils';
import { CashLoanInterestRates, OpenOffer, VariableLoans } from '../types';

import cashloandemodata from '../demo-data/getCashLoansInterestRates.json';

const useFormatCashLoan = (useFetchProps) => {
  const { inputdata, showAllLoans } = useFetchProps;
  const [formattedAPIData, setFormattedAPIData] = useState<any>('');

  const formatCashLoanData = (cashLoansAPIResponse, showAllLoans) => {
    let filteredData: (OpenOffer | VariableLoans | CashLoanInterestRates)[] = cashloandemodata;
    if (cashLoansAPIResponse?.length > 0) {
      let dataToProcess: (CashLoanInterestRates | OpenOffer)[] = cashLoansAPIResponse;
      if (!showAllLoans) dataToProcess = filterDataOnPrices(dataToProcess, 90, 99.75);
      filteredData = filterClosedBonds(dataToProcess);
      const sortedData = groupDrillSort(filteredData, [
        { key: 'termToMaturityYears', desc: true },
        { key: 'nominelInterestRate', desc: true },
        { key: 'numberOfTermsWithoutRepayment', desc: false },
      ]);
      filteredData = sortedData;
      return filteredData as CashLoanInterestRates[];
    }
  };

  useEffect(() => {
    if (inputdata && inputdata.length > 0) {
      setFormattedAPIData(formatCashLoanData(inputdata, showAllLoans));
    }
  }, [inputdata, showAllLoans]);
  return formattedAPIData;
};
export default useFormatCashLoan;
