import { SitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { CookiesProvider, RddkTheme, setGridConfiguration } from 'db-npm-rdui';
import 'db-npm-rdui/dist/fonts.css';
import 'db-npm-rdui/dist/normalize.css';
import 'db-npm-rdui/dist/styles.css';
import * as React from 'react';
import { ThemeProvider } from 'styled-components';
import SitecoreContextFactory from '../../lib/SitecoreContextFactory';
import componentFactory from '../../temp/componentFactory';

// Configure the Grid in RD UI
setGridConfiguration({ gutterWidth: 24 });

export type AppSetupProps = {
  children?: any;
};

export const AppSetup = ({ children }: AppSetupProps) => {
  return (
    <CookiesProvider>
      <ThemeProvider theme={RddkTheme}>
        <SitecoreContext componentFactory={componentFactory} contextFactory={SitecoreContextFactory}>
          {children}
        </SitecoreContext>
      </ThemeProvider>
    </CookiesProvider>
  );
};
