import dayjs from 'dayjs';
import { CITY_COMMERCIAL } from '../../config';
import { formatPayloadDate, getNextAvailableBusinessDay, isBusinessDay } from '../../helpers/dateHelpers';
import { CalculatorInputs } from '../../types/CalculatorInputs';
import { LoanStateItem } from '../../types/LoanStateItem';

/**
 * This function is responsible for preparing the payload before sending it to the API.
 * @param loan The loan
 * @param inputs The inputs from the user
 */
export function preparePayload(loan: LoanStateItem, inputs: CalculatorInputs): LoanStateItem {
  const { payload, settings } = loan;

  let ltv = (inputs.propertyValue - inputs.ownPayment) / inputs.propertyValue;
  if (inputs.wantedLoanAmount) {
    ltv = 0.5;
  }

  payload.property.type = inputs.propertyType;
  payload.property.value = inputs.propertyValue;
  if (!settings.modifiedNetProceeds) {
    payload.basic.wantedAdditionalAmount = inputs.propertyValue - inputs.ownPayment;
    payload.basic.calculationType = 'Proceeds';
  }

  // Special check for LTV over MaxLTV minus 10%
  if (ltv > settings.maxLTV * 0.9 && !settings.modifiedNetProceeds) {
    payload.basic.wantedAdditionalAmount = 0;
    payload.basic.calculationType = 'Maximum';
  }

  /* used for business calcualtor only */
  if (inputs.propertyCategory && inputs.propertyCategory !== '') {
    payload.property.category = inputs.propertyType;
    payload.property.type = inputs.propertyCategory;
    if (
      inputs.propertyType === CITY_COMMERCIAL &&
      payload.product.maximumYears >= settings?.businessCommercialMaturity
    ) {
      payload.product.minimumYears = settings?.businessCommercialMaturity;
      payload.product.maximumYears = settings?.businessCommercialMaturity;
    }
  }
  if (inputs.ownerCategory && inputs.ownerCategory !== '') {
    payload.basic.ownerCategory = inputs.ownerCategory;
  }
  if (inputs.wantedLoanAmount) {
    payload.basic.wantedAdditionalAmount = inputs.wantedLoanAmount;
    payload.basic.calculationType = 'Proceeds';
  }

  //used for lending calculator
  if (inputs.remainingDebt) {
    payload.basic.remainingDebt = inputs.remainingDebt;
  }

  //Dates handling
  const today = new Date();
  //Keeping this if we should support fixed price calculations in the future
  let calculationDate = dayjs(today).add(0, 'day').toDate();
  //Only businessdays are allowed and finding the next businessday
  if (!isBusinessDay(calculationDate)) {
    calculationDate = getNextAvailableBusinessDay(calculationDate);
  }
  payload.basic.calculationDate = formatPayloadDate(calculationDate);

  // Set values

  //Checking if no options has a null setting
  if (
    payload.product.changeOption.desiredDebtAtExpiry != null &&
    payload.product.changeOption.desiredInterestOnlyStartDate != null &&
    payload.product.changeOption.desiredPaymentAmount != null
  ) {
    settings.firstRun = true;
    payload.product.changeOption.desiredPaymentAmount = null;
  }

  // First run
  if (settings.firstRun) {
    if (ltv > settings.maxInterestOnlyLTV) {
      payload.product.changeOption.desiredDebtAtExpiry = inputs.propertyValue * settings.maxInterestOnlyLTV;
    } else {
      payload.product.changeOption.desiredDebtAtExpiry = inputs.propertyValue - inputs.ownPayment;
      if (inputs.wantedLoanAmount) {
        payload.product.changeOption.desiredDebtAtExpiry = inputs.wantedLoanAmount;
      }
    }
    settings.firstRun = false;
  }

  return loan;
}
