import { TFunction } from 'i18next';
import * as Yup from '../../shared/forms/customYup';
import { NameRegex, PartnerRegex, PhoneRegex } from '../../shared/regex';

export function getSchema(t: TFunction) {
  return Yup.object().shape({
    topic: Yup.string().required(t('form-select-required-error')),
    cpr: Yup.string().required(t('form-required-error')).cprValidation(t('form-input-cpr-error')),
    firstname: Yup.string().required(t('form-required-error')).matches(NameRegex, t('form-input-name-error')),
    lastname: Yup.string().required(t('form-required-error')).matches(NameRegex, t('form-input-name-error')),
    email: Yup.string().required(t('form-required-error')).email(t('form-input-email-error')),
    phone: Yup.string().matches(PhoneRegex, t('form-input-phone-error')).min(8, t('form-input-phone-error')),
    casenumber: Yup.string().matches(PartnerRegex, t('form-input-casenumber-error')),
    adviser: Yup.string().matches(NameRegex, t('form-input-adviser-error')),
  });
}
