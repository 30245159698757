import { SearchResultsType } from 'db-npm-rdui';

/**
 * Api function to call the search API
 * @param {string} query The search querye to send to the API
 * @param {number} start Start showing results from this number
 * @param {string} apiUrl Absolute url for the api
 * @param {string} dsid Id for the Sitecore item holding the api settings
 */
export async function doGoogleSearch(query, start, apiUrl, dsid) {
  const payload = {
    dsid: dsid,
    query: query,
    count: '10',
    startindex: start,
  };

  let googleResults: any = null;

  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    googleResults = await response.json();
  } catch (e) {
    console.log('fetch error', e);
  }

  const promiseExecutor = async (resolve, reject) => {
    const results: SearchResultsType = { hits: 0, results: [], currentPage: 1 };

    if (!googleResults) {
      reject('Error getting results from google');
      return;
    }
    const startIndex =
      typeof googleResults.queries?.nextPage === 'object' ? googleResults.queries?.nextPage[0].startIndex : 11;
    const currentPage = (startIndex - 1) / 10;
    results.currentPage = currentPage;
    results.hits = googleResults?.searchInformation?.totalResults ?? 0;
    const mappedResults = googleResults.items?.map((googleResult) => ({
      thumbnailUrl: '', //googleResult.pagemap?.cse_thumbnail?.length ? googleResult.pagemap?.cse_thumbnail[0].src : '',
      title: googleResult.htmlTitle,
      description: googleResult.htmlSnippet,
      link: googleResult.link,
      fileFormat: googleResult.fileFormat,
      searchTerms: googleResults.queries?.request?.[0]?.searchTerms || '',
    }));
    results.results = mappedResults ?? [];
    resolve(results);
  };

  const resultsPromise = new Promise<SearchResultsType>(promiseExecutor);

  return resultsPromise;
}
