import { mediaQuery } from 'db-npm-rdui';
import { css } from 'styled-components';

export type ComponentMarginSize = 'x-small' | 'small' | 'large';

export function componentTopMargin(size: ComponentMarginSize) {
  switch (size) {
    case 'x-small':
      return xsmallStyling;
    case 'small':
      return smallStyling;
    case 'large':
      return largeStyling;
  }
}

const xsmallStyling = css`
  margin-top: 16px;
  ${mediaQuery.md} {
    margin-top: 18px;
  }
  ${mediaQuery.lg} {
    margin-top: 20px;
  }
  ${mediaQuery.xl} {
    margin-top: 22px;
  }
`;

const smallStyling = css`
  margin-top: 45px;
  ${mediaQuery.md} {
    margin-top: 52px;
  }
  ${mediaQuery.lg} {
    margin-top: 58px;
  }
  ${mediaQuery.xl} {
    margin-top: 65px;
  }
`;

const largeStyling = css`
  margin-top: 80px;
  ${mediaQuery.md} {
    margin-top: 98px;
  }
  ${mediaQuery.lg} {
    margin-top: 112px;
  }
  ${mediaQuery.xl} {
    margin-top: 124px;
  }
`;
