import { Box, Button, Col, Container, Hidden, Row, TableSelector } from 'db-npm-rdui';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useAdobeTracking } from '../../../../shared/hooks/useAdobeTracking';
import { HTML_ID_SCROLLTO_AREA, MAX_LOANS_DESKTOP } from '../../config';
import { useInputsState } from '../../state/inputsState';
import { useLoansState } from '../../state/loansState';
import { useSettingsState } from '../../state/settingsState';

export const CalculatorTabs: React.FC = () => {
  const { t } = useTranslation();
  const { setViewType } = useInputsState();
  const { loansCount, addLoan } = useLoansState();
  const { settings } = useSettingsState();
  const { getLaunchData, trackCalculatorCustomEvent } = useAdobeTracking();

  // Check if "showBankloanTab" (in JSON settings) is true and tabs should be shown
  const showTabs = settings?.table?.showBankloanTab === true;

  // Disable "Add loan" button if max amount of loans is reached
  const disableAddButton = loansCount >= MAX_LOANS_DESKTOP;

  const addButtonTracker = React.useCallback(() => {
    const calculatorType = getLaunchData('calculator_type');
    trackCalculatorCustomEvent({ event: calculatorType + ' : Loan calculator : Tilfoej laan' });
  }, [trackCalculatorCustomEvent, getLaunchData]);

  return (
    <Container id={HTML_ID_SCROLLTO_AREA}>
      <Row>
        <Col>
          {showTabs && (
            <Box marginTop={4} marginBottom={4}>
              <TableSelector selectedIndex={0} size="small">
                <div onClick={() => setViewType('realkredit')}>{t('calculator-tabs-realkredit')}</div>
                <div onClick={() => setViewType('bankloan')}>{t('calculator-tabs-bankloan')}</div>
                <div onClick={() => setViewType('overview')}>{t('calculator-tabs-overview')}</div>
              </TableSelector>
            </Box>
          )}
        </Col>

        <Hidden xs sm md>
          <Col xs="content">
            <Box marginBottom={4}>
              <Button
                variant="secondary"
                onClick={() => {
                  addLoan();
                  addButtonTracker();
                }}
                disabled={disableAddButton}
              >
                {t('calculator-table-addloan-button')}
              </Button>
            </Box>
          </Col>
        </Hidden>
      </Row>
    </Container>
  );
};
