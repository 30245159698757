import * as React from 'react';
import { Section } from '../../shared/components';
import { ImageContainer } from '../../shared/components/ImageContainer/ImageContainer';

export type EntranceSingleRightProps = {
  fields: any;
};

const EntranceSingleRight: React.FC<EntranceSingleRightProps> = ({ fields = {} }) => {
  return (
    <Section componentName="EntranceSingleRight" margin="large">
      <ImageContainer fields={fields} direction="row" />
    </Section>
  );
};

export default EntranceSingleRight;
