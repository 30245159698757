import { CookieConsentBanner as BannerComponent, useCookieConsentBannerDefaultHandler } from 'db-npm-rdui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useContextSettings } from '../../shared/hooks';

type CookieConsentBannerProps = {
  fields: any;
};

const CookieConsentBanner: React.FC<CookieConsentBannerProps> = ({ fields }) => {
  const { t } = useTranslation();
  const disabled = useContextSettings().cookieBannerDisabled;

  // Extra callback for consent level submit that triggers Adobe Launch "_satellite.track" event
  const onSubmit = () => {
    const w = window as any;
    if (w && w._satellite && w._satellite.track) {
      w._satellite.track('consentupdate');
    }
  };

  // Create handler with extra onSubmit callback
  const handler = useCookieConsentBannerDefaultHandler({
    onSubmit,
    disabled,
  });

  // Make handler onReopen callback accessible from e.g. a link in rich text
  React.useEffect(() => {
    const w = window as any;
    if (w) {
      w.cookieConsentBanner = {
        reopen: handler.onReopen,
      };
    }
  }, [handler.onReopen]);

  if (!useContextSettings().cookiePolicyEnabled) {
    return null;
  }

  return (
    <BannerComponent
      handler={handler}
      title={fields['Title']?.value}
      description={(<span dangerouslySetInnerHTML={{ __html: fields['Text']?.value }} />) as any}
      buttonAllowNecessaryText={t('cookie-consent-buttons-oktonecessary')}
      buttonAllowSelectedText={t('cookie-consent-buttons-oktoselected')}
      buttonAllowAllText={t('cookie-consent-buttons-oktoall')}
      levels={[
        {
          name: t(`cookie-consent-categories-0-title`),
          description: t(`cookie-consent-categories-0-text`),
        },
        {
          name: t(`cookie-consent-categories-1-title`),
          description: t(`cookie-consent-categories-1-text`),
        },
        {
          name: t(`cookie-consent-categories-2-title`),
          description: t(`cookie-consent-categories-2-text`),
        },
        {
          name: t(`cookie-consent-categories-3-title`),
          description: t(`cookie-consent-categories-3-text`),
        },
      ]}
    />
  );
};

export default CookieConsentBanner;
