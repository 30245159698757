import dayjs from 'dayjs';
import {
  Cell,
  Color,
  DynamicTable,
  Icons,
  IconWrapper,
  LinkAnimatedUnderline,
  RddkTheme,
  TableData,
  Tooltip,
} from 'db-npm-rdui';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { formatAsCurrency } from '../../shared/utils/currency';
import { TableCellRender } from './TableCellRender';
import { CashLoanInterestRates } from './types';

type TableCashLoanProps = {
  data: CashLoanInterestRates[];
  handleClick: any;
};

export const TableCashLoan: React.FC<TableCashLoanProps> = ({ data, handleClick }) => {
  const { t } = useTranslation();
  const tableData = React.useMemo((): TableData => {
    if (!data || data.length <= 0) {
      return [];
    }
    const date1 = data[0]?.interestRates?.[1]?.date
      ? dayjs(data[0]?.interestRates?.[1]?.date).format('DD.MM.YYYY')
      : '';
    const date2 = data[0]?.interestRates?.[2]?.date
      ? dayjs(data[0]?.interestRates?.[2]?.date).format('DD.MM.YYYY')
      : '';
    const date3 = data[0]?.interestRates?.[3]?.date
      ? dayjs(data[0]?.interestRates?.[3]?.date).format('DD.MM.YYYY')
      : '';

    return [
      // Header row:
      [
        {
          style: {
            width: 250,
          },
          value: t(`kurser-cashLoan-LoanName-header`),
          alignVertically: 'middle',
          minWidth: 170,
        },
        // This column is here match "bond closed" column so sorting works (sorting functions uses the index of the header as the indes for the data column)
        {
          style: {
            borderLeft: 0,
            width: 120,
          },
          value: null,
          alignVertically: 'middle',
        },
        {
          value: 'Aktuel kontantrente',
          sortable: true,
          alignVertically: 'middle',
          minWidth: 160,
        },
        {
          value: (
            <Tooltip
              element={
                <IconWrapper size="24" color="Action">
                  <Icons.Action.Question />
                </IconWrapper>
              }
              content={t('kurser-fixedcashloan-tooltip-Aktuel-text')}
            />
          ),
          alignVertically: 'middle',
        },
        {
          value: 'Tilbudt kontantrente',
          sortable: true,
          alignVertically: 'middle',
          minWidth: 160,
        },
        {
          value: (
            <Tooltip
              element={
                <IconWrapper size="24" color="Action">
                  <Icons.Action.Question />
                </IconWrapper>
              }
              content={t('kurser-fixedcashloan-tooltip-Tilbudt-text')}
            />
          ),
          alignVertically: 'middle',
        },
        {
          value: 'Kontantrente',
          suffix: 'Fastkursaftale ' + date1,
          minWidth: 190,
        },
        {
          value: (
            <Tooltip
              element={
                <IconWrapper size="24" color="Action">
                  <Icons.Action.Question />
                </IconWrapper>
              }
              content={t('kurser-fixedcashloan-tooltip-Kontantrente-text')}
            />
          ),
          alignVertically: 'middle',
        },
        {
          value: 'Kontantrente',
          suffix: 'Fastkursaftale ' + date2,
          minWidth: 190,
        },
        {
          value: (
            <Tooltip
              element={
                <IconWrapper size="24" color="Action">
                  <Icons.Action.Question />
                </IconWrapper>
              }
              content={t('kurser-fixedcashloan-tooltip-Kontantrente-text')}
            />
          ),
          alignVertically: 'middle',
        },
        {
          value: 'Kontantrente',
          suffix: 'Fastkursaftale ' + date3,
          minWidth: 190,
        },
        {
          value: (
            <Tooltip
              element={
                <IconWrapper size="24" color="Action">
                  <Icons.Action.Question />
                </IconWrapper>
              }
              content={t('kurser-fixedcashloan-tooltip-Kontantrente-text')}
            />
          ),
          alignVertically: 'middle',
        },
      ],
      // Body rows:
      ...data.map((item): Cell[] => {
        return [
          {
            style: {
              width: 250,
            },
            prefix: item?.numberOfTermsWithoutRepayment
              ? parseFloat(item.numberOfTermsWithoutRepayment) === 0
                ? t('kurser-termsWithoutRepaymentEQ0-label') + `  (årgang ${item?.bondname.slice(-4)})`
                : parseFloat(item.numberOfTermsWithoutRepayment) === 40
                ? t('kurser-termsWithoutRepaymentEQ40-label') + `  (årgang ${item?.bondname.slice(-4)})`
                : t('kurser-termsWithoutRepaymentGT40-label') + `  (årgang ${item?.bondname.slice(-4)})`
              : '',
            value: `${item?.termToMaturityYears ? parseFloat(item.termToMaturityYears).toFixed(0) : 0} år / ${
              item?.nominelInterestRate ? formatAsCurrency(parseFloat(item.nominelInterestRate), 1, true) : 0
            } %`,
            suffix: item?.isinCode || '',
          },
          {
            style: {
              borderLeft: 0,
              minWidth: 110,
              position: 'relative',
            },
            value: (
              <>
                {item?.offerprice > 99.75 || item?.offerprice < 0 ? (
                  <ClosedBond>
                    {' '}
                    <span>{t('kurser-closedbond-information-label')}</span>{' '}
                  </ClosedBond>
                ) : null}
                <Link
                  href={void 0}
                  textColor={Color.red40}
                  underlineColorPrimary={Color.red40}
                  onClick={(e) => {
                    handleClick(item?.isinCode);
                  }}
                >
                  {t('kurser-showLoanDetails-label')}
                </Link>
              </>
            ),
            alignVertically: 'top',
          },
          {
            value: `${
              parseFloat(item?.interestRates?.[0]?.interestRate.replace(/,/, '.')) < 0 ||
              String(item?.interestRates?.[0]?.interestRate) === '' ||
              String(item?.interestRates?.[0]?.interestRate) === 'undefined'
                ? '-'
                : isNaN(Number(item?.interestRates?.[0]?.interestRate))
                ? item.interestRates?.[0].interestRate
                : formatAsCurrency(Number(item.interestRates?.[0].interestRate), 4, true) + ' %'
            }`,
            alignVertically: 'middle',
          },
          {
            style: {
              borderLeft: 0,
              maxWidth: '1px',
            },
            value: null,
          },
          {
            value: item?.offerrate,
            data: {
              text: `${
                String(item?.offerrate) === '' ||
                String(item?.offerrate) === 'undefined' ||
                parseFloat(String(item?.offerrate).replace(/,/, '.')) < 0
                  ? '-'
                  : isNaN(item?.offerrate)
                  ? item?.offerrate
                  : formatAsCurrency(item?.offerrate, 4, true) + ' %'
              }`,
            },
            cellValueRender: TableCellRender,
            alignVertically: 'middle',
          },
          {
            style: {
              borderLeft: 0,
              maxWidth: '1px',
            },
            value: null,
          },
          // code changes made to show '-' whenever we get -ve value from backend.
          // backend would send -ve value when the API could not get bond prices for future dates.
          {
            value:
              parseFloat(item?.interestRates?.[1]?.interestRate.replace(/,/, '.')) < 0 ||
              item?.interestRates?.[1]?.interestRate === '' ||
              item?.interestRates?.[1]?.interestRate === 'undefined'
                ? '-'
                : isNaN(Number(item?.interestRates?.[1]?.interestRate))
                ? item.interestRates?.[1].interestRate
                : formatAsCurrency(Number(item.interestRates?.[1].interestRate), 4, true) + ' %',
            alignVertically: 'middle',
          },
          {
            style: {
              borderLeft: 0,
              maxWidth: '1px',
            },
            value: null,
          },
          {
            value:
              parseFloat(item?.interestRates?.[2]?.interestRate.replace(/,/, '.')) < 0 ||
              item?.interestRates?.[2]?.interestRate === '' ||
              item?.interestRates?.[2]?.interestRate === 'undefined'
                ? '-'
                : isNaN(Number(item?.interestRates?.[2]?.interestRate))
                ? item.interestRates?.[2].interestRate
                : formatAsCurrency(Number(item.interestRates?.[2].interestRate), 4, true) + ' %',
            alignVertically: 'middle',
          },
          {
            style: {
              borderLeft: 0,
              maxWidth: '1px',
            },
            value: null,
          },
          {
            value:
              parseFloat(item?.interestRates?.[3]?.interestRate.replace(/,/, '.')) < 0 ||
              item?.interestRates?.[3]?.interestRate === '' ||
              item?.interestRates?.[3]?.interestRate === 'undefined'
                ? '-'
                : isNaN(Number(item?.interestRates?.[3]?.interestRate))
                ? item.interestRates?.[3].interestRate
                : formatAsCurrency(Number(item.interestRates?.[3].interestRate), 4, true) + ' %',
            alignVertically: 'middle',
          },
          {
            style: {
              borderLeft: 0,
              maxWidth: '1px',
            },
            value: null,
          },
        ];
      }),
    ];
  }, [data, t, handleClick]);

  return <DynamicTable striped={false} bordered={true} firstRowIsHead={true} data={tableData} />;
};

const ClosedBond = styled.div`
  width: 74px;
  height: 28px;
  padding: 3px;
  border-radius: 3px;
  text-align: center;
  border: solid 1px ${RddkTheme.colors.red50};
  background-color: rgba(226, 0, 26, 0.08);
  span {
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.8px;
    text-align: center;
    color: ${RddkTheme.colors.red50};
  }
`;

const Link = styled(LinkAnimatedUnderline)`
  cursor: pointer;
  position: absolute;
  bottom: 21%;
  right: 19%;
  font-size: 12px;
  color: ${RddkTheme.colors.red40};
`;
