import { CellRenderProps, Icons } from 'db-npm-rdui';
import * as React from 'react';
import styled, { css } from 'styled-components';

const IconWrapper = styled.span<{ isDown?: boolean }>`
  position: relative;
  top: 2px;
  margin-right: 5px;

  path {
    fill: #11b135;
  }

  ${(props) =>
    props.isDown &&
    css`
      svg {
        transform: rotate(180deg);
      }
      path {
        fill: red;
      }
    `}
`;

export const TableCellRender: React.FC<CellRenderProps> = (props) => {
  const { text, arrowDirection } = props.cell.data;

  return (
    <>
      {arrowDirection === 'up' && (
        <IconWrapper>
          <Icons.ArrowUp />
        </IconWrapper>
      )}
      {arrowDirection === 'down' && (
        <IconWrapper isDown={true}>
          <Icons.ArrowUp />
        </IconWrapper>
      )}
      {text}
    </>
  );
};
