import { Link } from '@sitecore-jss/sitecore-jss-react';
import { buttonBaseStyle, buttonCustomStyle, buttonPrimaryStyle, buttonSecondaryStyle, Color } from 'db-npm-rdui';
import React from 'react';
import styled, { css } from 'styled-components';
import { cleanLinkData } from '../../shared/utils/field-validation';

type ButtonProps = {
  variant?: 'primary' | 'secondary' | 'custom';
  field?: any;
};

const secondaryLink = css`
  a {
    color: ${Color.grey100};
  }
`;

export const Button: React.FC<ButtonProps> = ({ variant, field }) => {
  field.value = cleanLinkData(field.value);
  return <StyledButton data-tracking-id={field?.value?.trackingcode} field={field} variant={variant}></StyledButton>;
};

const StyledButton = styled(Link)<ButtonProps>`
  ${buttonBaseStyle}
  a {
    text-decoration: none;
    color: ${Color.white};
  }
  ${(props) => (!props.variant || props.variant === 'primary') && buttonPrimaryStyle}
  ${(props) => props.variant === 'secondary' && [buttonSecondaryStyle, secondaryLink]}
  ${(props) => props.variant === 'custom' && buttonCustomStyle}
  :empty {
    display: none;
  }
`;
