import { Icons, Tooltip } from 'db-npm-rdui';
import * as React from 'react';
import styled from 'styled-components';
import { TableItemStatic } from '../../types/TableItem';

type CalculatorStaticFieldProps = {
  tableItem: TableItemStatic;
};

export const CalculatorStaticField: React.FC<CalculatorStaticFieldProps> = ({ tableItem }) => {
  return (
    <>
      {tableItem.value}
      {tableItem.suffix ? tableItem.suffix : ''}
      {tableItem.tooltip && (
        <TooltipContainer>
          <Tooltip autoWidth element={<Icons.Question />} content={tableItem.tooltip} placement="top" />
        </TooltipContainer>
      )}
    </>
  );
};

const TooltipContainer = styled.span`
  margin-left: 12px;
`;
