/**
 * The char to use as thousand separator when formatting
 */
export const FORMATTING_THOUSAND_SEPATOR = '.';
/**
 * The char to use as decimal separator when formatting
 */
export const FORMATTING_DECIMAL_SEPATOR = ',';
/**
 * The field keys of table 1 "Realkredit"
 */

/**
 * Format a number to Danish currency format (without DKK symbol)
 * @param value The number to format
 * @param decimals Amount of decimals (default is 0)
 * @param trailingZeros Boolean (true / false). default is false. Need trailing zeros, to maintain uniformity in the presentation
 */
export const formatAsCurrency = (value: number, decimals = 0, trailingZeros = false): string => {
  return (trailingZeros
    ? new Intl.NumberFormat('da-DK', { minimumFractionDigits: decimals })
    : new Intl.NumberFormat('da-DK', { maximumFractionDigits: decimals })
  )
    .formatToParts(value)
    .map((p) => {
      if (p.type === 'group') return FORMATTING_THOUSAND_SEPATOR;
      if (p.type === 'decimal') return FORMATTING_DECIMAL_SEPATOR;
      return p.value;
    })
    .join('');
};
