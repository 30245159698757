import { Color, RddkTheme } from 'db-npm-rdui';
import React, { TextareaHTMLAttributes, useState } from 'react';
import styled from 'styled-components';

export type TextAreaProps = {
  label: string | React.ReactNode;
  disabled?: boolean;
  errorMessage?: string;
  value?: string;
  maxLength?: number;
  autosize?: boolean;
  placeholder?: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
};

const TextAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 9px 20px;
  position: relative;
  min-height: 56px;
  width: 100%;
  border-radius: 5px;
  background: #fff;
  border: 1px solid #9e9e9e;
  letter-spacing: 0.3px;
  color: #4f4f4f;
`;

const Label = styled.label<{
  hasValue: boolean;
}>`
  order: 1;
  pointer-events: none;
  text-shadow: none;
  transform-origin: left top;
  transform: scale(1) translate3d(0, 10px, 0);
  transition: 200ms ease all;
  font-size: 16px;
  font-family: ${RddkTheme.fonts.sans};
  font-style: italic;
  color: #4f4f4f;
  position: absolute;
  ${({ hasValue }) => (hasValue ? `transform: scale(0.8) translate3d(0, -2px, 0)` : '')}
`;

const InnerTextArea = styled.textarea`
  width: 100%;
  display: flex;
  flex: 1 1 auto;
  order: 2;
  font-family: ${RddkTheme.fonts.sans};
  color: #000000;
  border: none;
  outline: none;
  overflow: hidden;
  font-size: 15px;
  line-height: 19px;
  font-weight: 600;
  letter-spacing: -0.21px;
  background-color: transparent;
  padding: 0;
  resize: none;
  padding-top: 18px;

  ::-ms-clear {
    display: none;
  }

  &:disabled {
    color: ${Color.possumGrey};
    cursor: not-allowed;
  }

  &:focus + ${Label} {
    transform: scale(0.8) translate3d(0, -2px, 0);
  }
`;

export const AutoTextArea: React.FC<TextAreaProps & TextareaHTMLAttributes<HTMLTextAreaElement>> = ({
  label,
  disabled,
  errorMessage,
  value,
  autosize,
  onChange,
  maxLength,
  required,
  id,
  ...props
}) => {
  const [hasValue, sethasValue] = useState(false);

  const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    sethasValue(false);
    if (event.target.value !== '') {
      sethasValue(true);
    }

    if (maxLength && event.target.value.length > maxLength) {
      return event.stopPropagation();
    }

    if (autosize) {
      event.target.style.height = 'auto';
      event.target.style.height = event.target.scrollHeight + 'px';
    }

    if (onChange) {
      onChange(event);
    }
  };

  return (
    <TextAreaWrapper>
      <InnerTextArea
        id={id}
        disabled={disabled}
        value={value}
        maxLength={maxLength}
        onChange={handleOnChange}
        required={required}
        {...props}
        rows={1}
      />
      <Label hasValue={hasValue} htmlFor={id}>
        {label}
      </Label>
    </TextAreaWrapper>
  );
};

export default AutoTextArea;
