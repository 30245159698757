import * as React from 'react';

export const IconDownload = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14">
    <path
      d="M9.733 6.008L6.386 9.354 3.04 6.008m3.346 2.584L6.5 1.285M2 12.299h8.773"
      fill="none"
      fill-rule="evenodd"
      stroke="#000"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
    />
  </svg>
);
