import cloneDeep from 'lodash/cloneDeep';
import { useCallback, useState } from 'react';
import { useBetween } from 'use-between';
import { performCalculation } from '../calculators/performCalculation';
import { getInitialLoansFromQueryOrSettings } from '../helpers/initial-loans';
import { LoanStateItem } from '../types/LoanStateItem';
import { useInputsState } from './inputsState';
import { useSettingsState } from './settingsState';

const useLoansStateFn = () => {
  const [loans, setLoans] = useState<LoanStateItem[]>([]);
  const [lastCalculationDate, setLastCalculationDate] = useState(Date.now().toString());

  const { settings, apiUrl } = useSettingsState();
  const {
    propertyType,
    ownerCategory,
    propertyValue,
    ownPayment,
    remainingDebt,
    wantedLoanAmount,
    propertyCategory,
  } = useInputsState();

  /**
   * Add a new loan
   */
  const addLoan = useCallback(() => {
    setLoans((loans) => [...loans, { type: '', status: 'initial' }]);
  }, []);

  /**
   * Remove a loan from the array
   */
  const removeLoan = useCallback((loanIndex: number) => {
    setLoans((loans) => loans.filter((_, index) => index !== loanIndex));
  }, []);

  /**
   * Set single loan data
   */
  const setLoan = useCallback((loanIndex: number, data: LoanStateItem) => {
    setLoans((loans) => loans.map((loan, index) => (index === loanIndex ? data : loan)));
  }, []);

  /**
   * Calculate a single loan and update the loan status and data.
   */
  const doCalculation = useCallback(
    async (loanIndex: number, loanData: LoanStateItem) => {
      // Gather inputs
      if (propertyValue === null || ownPayment === null || wantedLoanAmount === null || remainingDebt === null) {
        // If invalid inputs, skip calculation and just set to 'done'
        setLoan(loanIndex, {
          ...loanData,
          status: 'initial',
        });
        return;
      }

      // Set loading
      setLoan(loanIndex, {
        ...loanData,
        status: 'loading',
      });

      // Get the result
      const result = await performCalculation(
        loanData,
        {
          propertyType,
          ownerCategory,
          propertyValue,
          wantedLoanAmount,
          ownPayment,
          remainingDebt,
          propertyCategory,
        },
        apiUrl
      );

      // Set the result if it isn't false
      if (result !== false) {
        setLoan(loanIndex, {
          ...result,
          status: 'done',
        });
      } else {
        // Set error status
        setLoan(loanIndex, {
          ...loanData,
          status: 'error',
        });
      }
    },
    [
      ownPayment,
      propertyType,
      propertyCategory,
      ownerCategory,
      propertyValue,
      remainingDebt,
      wantedLoanAmount,
      apiUrl,
      setLoan,
    ]
  );

  /**
   * Calculate single loan
   */
  const calculateSingleLoan = useCallback(
    async (loanIndex: number) => {
      // Get the loan
      const loanData = cloneDeep(loans[loanIndex] || {});

      // Do calculation
      await doCalculation(loanIndex, loanData);

      // Set new calculation date (triggers re-render of the tables)
      setLastCalculationDate(Date.now().toString());
    },
    [doCalculation, loans]
  );

  /**
   * Calculate all loans in the array
   */
  const calculateLoansFromScratch = useCallback(async () => {
    await Promise.all(
      loans.map(async (loan, loanIndex) => {
        if (!loan.type) {
          return;
        }

        // Get the default values from settings
        const dataFromSettings = cloneDeep(settings.loans[loan.type] || {});
        const loanData = {
          type: loan.type,
          ...dataFromSettings,
        };

        // Do calculation
        await doCalculation(loanIndex, loanData);
      })
    );

    // Set new calculation date (triggers re-render of the tables)
    setLastCalculationDate(Date.now().toString());
  }, [doCalculation, loans, settings.loans]);

  /**
   * Set loan type and do calculation
   */
  const setLoanTypeAndCalculate = useCallback(
    async (loanIndex: number, type: string) => {
      if (!type) {
        setLoan(loanIndex, {
          type,
          status: 'initial',
        });
        return;
      }

      // Get the loan
      const dataFromSettings = cloneDeep(settings.loans[type] || {});
      const loanData = {
        type,
        ...dataFromSettings,
      };

      // Do calculation
      await doCalculation(loanIndex, loanData);

      // Set new calculation date (triggers re-render of the tables)
      setLastCalculationDate(Date.now().toString());
    },
    [setLoan, doCalculation, settings.loans]
  );

  /**
   * Set loan type and do calculation
   */
  const setLoanPayloadAndCalculate = useCallback(
    async (loanIndex: number, payload: any, settings?: any, tableItems?: any, isStatic?: boolean) => {
      // Get the loan
      const loanFromArray = cloneDeep(loans[loanIndex] || {});
      let loanData = {
        ...loanFromArray,
        payload,
      };

      if (settings) {
        loanData = {
          ...loanFromArray,
          payload,
          settings,
        };
      }

      if (isStatic) {
        loanData = {
          ...loanFromArray,
          payload,
          settings,
          tableItems,
        };
        setLoan(loanIndex, {
          ...loanData,
          status: 'done',
        });
      } else {
        // Do calculation
        await doCalculation(loanIndex, loanData);

        // Set new calculation date (triggers re-render of the tables)
        setLastCalculationDate(Date.now().toString());
      }
    },
    [doCalculation, loans, setLoan]
  );

  /**
   * Get and set the initial loans from either settings or query parameter
   */
  const setInitialLoansFromSettings = useCallback((settings: any) => {
    const initialLoans = getInitialLoansFromQueryOrSettings(settings);
    setLoans(
      initialLoans.map((type) => {
        const dataFromSettings = cloneDeep(settings.loans[type] || {});
        return {
          ...dataFromSettings,
          type,
          status: 'initial',
        };
      })
    );
  }, []);

  /**
   * Get and set the initial loans from either settings or query parameter
   */
  const setLoansAfterChange = useCallback((settings, selectedLoan) => {
    if (settings.loans && Object.keys(settings).length > 0) {
      const defaultLoans = settings?.table?.defaultLoans[selectedLoan];
      setLoans(
        defaultLoans.map((type) => {
          const dataFromSettings = cloneDeep(settings.loans[type] || {});
          return {
            ...dataFromSettings,
            type,
            status: 'initial',
          };
        })
      );
    }
  }, []);

  return {
    loans,
    lastCalculationDate,
    loansCount: loans.length,
    addLoan,
    setLoans,
    removeLoan,
    calculateSingleLoan,
    calculateLoansFromScratch,
    setLoanTypeAndCalculate,
    setLoanPayloadAndCalculate,
    setInitialLoansFromSettings,
    setLoansAfterChange,
  };
};

export const useLoansState = () => useBetween(useLoansStateFn);
