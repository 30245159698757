import { Container, mediaQuery, Typography, Visible } from 'db-npm-rdui';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  TABLE_FIELDKEYS_BANKLOAN,
  TABLE_FIELDKEYS_OVERVIEW,
  TABLE_FIELDKEYS_REALKREDIT_1,
  TABLE_FIELDKEYS_REALKREDIT_2,
} from '../../config';
import { useInputsState } from '../../state/inputsState';
import { useSettingsState } from '../../state/settingsState';
import { CalculatorTableDesktop } from './CalculatorTableDesktop';
import { CalculatorTableMobile } from './CalculatorTableMobile';

export const CalculatorTablesContainer: React.FC = () => {
  return (
    <TablesContainer>
      <Visible xs sm md>
        <MobileTables />
      </Visible>

      <Visible lg xl xxl>
        <DesktopTables />
      </Visible>
    </TablesContainer>
  );
};

const DesktopTables = () => {
  const { t } = useTranslation();
  const {
    viewType,
    tableFieldSet1Keys,
    setTableFieldSet1Keys,
    tableFieldSet2Keys,
    setTableFieldSet2Keys,
  } = useInputsState();
  const { settings } = useSettingsState();
  const [isFieldsDisplayed, setFields] = React.useState(false);

  React.useEffect(() => {
    if (Object.keys(settings).length > 0 && !isFieldsDisplayed) {
      const ignoredFieldKeys = settings?.table?.fieldsToIgnore || [];
      const allowedFieldSet1Keys = tableFieldSet1Keys.filter((value) => !ignoredFieldKeys.includes(value));
      const allowedFieldSet2Keys = tableFieldSet2Keys.filter((value) => !ignoredFieldKeys.includes(value));

      const finalFieldSet1Keys = tableFieldSet1Keys.length > 0 ? allowedFieldSet1Keys : TABLE_FIELDKEYS_REALKREDIT_1;
      const finalFieldSet2Keys = tableFieldSet2Keys.length > 0 ? allowedFieldSet2Keys : TABLE_FIELDKEYS_REALKREDIT_2;

      setTableFieldSet1Keys(finalFieldSet1Keys);
      setTableFieldSet2Keys(finalFieldSet2Keys);
      setFields(true);
    }
  }, [
    tableFieldSet1Keys,
    tableFieldSet2Keys,
    settings,
    isFieldsDisplayed,
    setTableFieldSet1Keys,
    setTableFieldSet2Keys,
    setFields,
  ]);

  return (
    <>
      <Block show={viewType === 'realkredit'}>
        <CalculatorTableDesktop
          fieldKeys={tableFieldSet1Keys}
          showHeader={true}
          tableName={t('calculator-table-realkredit-title')}
          tableSlogan={t('calculator-table-realkredit-text')}
        />

        <Typography type="h6" marginTop={7} marginBottom={4}>
          {t('calculator-table-realkredit-secondtable-title')}
        </Typography>

        <CalculatorTableDesktop fieldKeys={tableFieldSet2Keys} showHeader={false} />
      </Block>

      <Block show={viewType === 'bankloan'}>
        <CalculatorTableDesktop
          fieldKeys={TABLE_FIELDKEYS_BANKLOAN}
          showHeader={true}
          tableName={'Tilpas banklånet'}
          tableSlogan={'Sammenlign realkredit lån her'}
        />
      </Block>

      <Block show={viewType === 'overview'}>
        <CalculatorTableDesktop
          fieldKeys={TABLE_FIELDKEYS_OVERVIEW}
          showHeader={true}
          tableName={'OVERVIEW'}
          tableSlogan={'Realkredit og banklån'}
        />
      </Block>
    </>
  );
};

const MobileTables = () => {
  const { t } = useTranslation();
  const {
    viewType,
    tableFieldSet1Keys,
    tableFieldSet2Keys,
    setTableFieldSet1Keys,
    setTableFieldSet2Keys,
  } = useInputsState();
  const { settings } = useSettingsState();
  const [isFieldsDisplayed, setFields] = React.useState(false);

  React.useEffect(() => {
    if (Object.keys(settings).length > 0 && !isFieldsDisplayed) {
      const ignoredFieldKeys = settings?.table?.fieldsToIgnore || [];
      const allowedFieldSet1Keys = tableFieldSet1Keys.filter((value) => !ignoredFieldKeys.includes(value));
      const allowedFieldSet2Keys = tableFieldSet2Keys.filter((value) => !ignoredFieldKeys.includes(value));

      const finalFieldSet1Keys = tableFieldSet1Keys.length > 0 ? allowedFieldSet1Keys : TABLE_FIELDKEYS_REALKREDIT_1;
      const finalFieldSet2Keys = tableFieldSet2Keys.length > 0 ? allowedFieldSet2Keys : TABLE_FIELDKEYS_REALKREDIT_2;

      setTableFieldSet1Keys(finalFieldSet1Keys);
      setTableFieldSet2Keys(finalFieldSet2Keys);
      setFields(true);
    }
  }, [
    tableFieldSet1Keys,
    tableFieldSet2Keys,
    settings,
    isFieldsDisplayed,
    setTableFieldSet1Keys,
    setTableFieldSet2Keys,
    setFields,
  ]);

  return (
    <>
      <Block show={viewType === 'realkredit'}>
        <CalculatorTableMobile fieldKeys={tableFieldSet1Keys} showHeader={true} />

        <Typography type="h6" marginTop={7} marginBottom={4} marginLeft={4}>
          {t('calculator-table-realkredit-secondtable-title')}
        </Typography>

        <CalculatorTableMobile fieldKeys={tableFieldSet2Keys} showHeader={false} />
      </Block>

      <Block show={viewType === 'bankloan'}>
        <CalculatorTableMobile fieldKeys={TABLE_FIELDKEYS_BANKLOAN} showHeader={true} />
      </Block>

      <Block show={viewType === 'overview'}>
        <CalculatorTableMobile fieldKeys={TABLE_FIELDKEYS_OVERVIEW} showHeader={true} />
      </Block>
    </>
  );
};

const TablesContainer = styled(Container)`
  ${mediaQuery.mdDown} {
    max-width: 100% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  table tbody .value {
    min-height: 18px;
    max-width: 200px;

    ${mediaQuery.sm} {
      max-width: 320px;
    }

    ${mediaQuery.lg} {
      min-height: 20px;
      max-width: 400px;
    }
  }
`;

const Block = styled.div<{ show?: boolean }>`
  display: ${(props) => (props.show ? 'block' : 'none')};
`;
