import { CashLoanInterestRates, GroupSortKey, OpenOffer, VariableLoans } from './types';
/**
 * Sorting function that takes and array of keys which will be used in a drilldown manner to firstly group data and
 * then sort it either ascending or descending.
 * No grouping will be performed on the last key of the array.
 * Note that the keys have to be able to be parsed as floats
 * @param {(OpenOffer | VariableLoans| CashLoanInterestRates)[]} data Array of OpenOffer or VariableLoans objects
 * @param {GroupSortKey[]} groupKeys Array of keys from an OpenOffer or VariableLoans object to be used for sorting
 * @returns Sorted araay of OpenOffer or VariableLoans objects
 */
export const groupDrillSort = (
  data: (OpenOffer | VariableLoans | CashLoanInterestRates)[],
  groupKeys: GroupSortKey[]
): (OpenOffer | VariableLoans | CashLoanInterestRates)[] => {
  let keyIndex = 0;
  let resultSet: (OpenOffer | VariableLoans | CashLoanInterestRates)[] = [];

  // Check if keys fail parseFloat
  const validateData = (obj: OpenOffer | VariableLoans | CashLoanInterestRates): GroupSortKey[] =>
    groupKeys.filter((gk) => obj[gk.key] === undefined || isNaN(parseFloat(obj[gk.key])));

  const sortGroup = (data, { key, desc }): (OpenOffer | VariableLoans | CashLoanInterestRates)[] => {
    if (key === groupKeys[groupKeys.length - 1].key) {
      const sorted = data.sort((firstObj, secondObj) => parseFloat(firstObj[key]) - parseFloat(secondObj[key]));
      if (desc) sorted.reverse();
      return sorted;
    }
    const groups = {};
    data.forEach((loan) => {
      if (!groups[loan[key]]) {
        groups[loan[key]] = [];
      }
      groups[loan[key]].push(loan);
    });
    const groupsArr = Object.keys(groups)
      .sort((firstObj, secondObj) => parseFloat(firstObj) - parseFloat(secondObj))
      .map((groupKey) => {
        return [...groups[groupKey]];
      });
    if (desc) groupsArr.reverse();
    keyIndex++;
    groupsArr.forEach((group) => {
      if (keyIndex < groupKeys.length) {
        const sortResult = sortGroup(group, groupKeys[keyIndex]);
        if (sortResult.length) resultSet.push(...sortResult);
      }
    });
    keyIndex = 0;
    return [];
  };

  if (!data.length) {
    console.warn('No table data to sort');
    return resultSet;
  }

  const validationCheck = validateData(data[0]);
  if (validationCheck.length) {
    console.warn(`Following key${validationCheck.length > 1 ? 's' : ''} cannot be used for sorting: `, validationCheck);
    return data;
  }

  sortGroup(data, groupKeys[0]);
  return resultSet;
};

export const filterDataOnPrices = (
  data: (OpenOffer | CashLoanInterestRates)[],
  fromPrice: number,
  toPrice: number
): (OpenOffer | CashLoanInterestRates)[] => {
  return data.filter((item) => item?.offerprice >= fromPrice && item?.offerprice <= toPrice);
};

// filterClosedBonds to be removed after PROD verification of closed bonds
export const filterClosedBonds = (
  data: (OpenOffer | CashLoanInterestRates)[]
): (OpenOffer | CashLoanInterestRates)[] => {
  return data.filter((item) => item?.offerprice > -1);
};

// filterClosedBonds to be removed after PROD verification of closed bonds
export const showClosedBonds = (data: (OpenOffer | CashLoanInterestRates)[]): (OpenOffer | CashLoanInterestRates)[] => {
  return data.filter((item) => item?.offerprice < 0);
};

export const filterDataOnProductType = (
  data: VariableLoans[],
  productOne: string,
  productTwo: string
): VariableLoans[] => {
  const filteredData: VariableLoans[] = data.filter((item) => item?.name !== productOne && item?.name !== productTwo);
  return filteredData;
};
