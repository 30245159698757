import { Icons, mediaQuery, RddkTheme, Select } from 'db-npm-rdui';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { MINIMUN_AMOUNT_OF_LOANS } from '../../config';
import { useInputsState } from '../../state/inputsState';
import { useLoansState } from '../../state/loansState';
import { useSettingsState } from '../../state/settingsState';
import { LoanStateItem } from '../../types/LoanStateItem';

type CalculatorTableHeadCellProps = {
  loanArrayIndex: number;
  loan: LoanStateItem;
};

export const CalculatorTableHeadCell: React.FC<CalculatorTableHeadCellProps> = ({ loanArrayIndex, loan }) => {
  const { t } = useTranslation();
  const { settings } = useSettingsState();
  const {
    propertyType,
    ownerCategory,
    submitCounter,
    setSubmitCounter,
    setSelectedLoan,
    setLoanChanged,
  } = useInputsState();
  const { loansCount, removeLoan, setLoanTypeAndCalculate } = useLoansState();

  const showRemoveButton = loansCount > MINIMUN_AMOUNT_OF_LOANS;

  /**
   * Callback for removing a loan/column
   */
  const onRemoveLoanClick = React.useCallback(() => {
    removeLoan(loanArrayIndex);
  }, [removeLoan, loanArrayIndex]);

  /**
   * Callback for selecting a loan in the dropdown
   */
  const onLoanSelected = React.useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setSelectedLoan(event.target.value);
      setLoanChanged(true);
      setLoanTypeAndCalculate(loanArrayIndex, event.target.value);
      setSubmitCounter(submitCounter + 1);
    },
    [setLoanTypeAndCalculate, loanArrayIndex, submitCounter, setSubmitCounter, setSelectedLoan, setLoanChanged]
  );

  const dropdownOptions = [
    {
      label: t('calculator-table-select-dropdown'),
      value: '',
    },
    ...Object.keys(settings.loans)
      .map(function (key) {
        const propertyTypes = settings.loans[key].settings.supportedPropertyTypes;
        let supportedPropertyType = propertyType;
        if (ownerCategory) {
          supportedPropertyType = propertyType + '/' + ownerCategory;
        }
        if (supportedPropertyType !== '' && propertyTypes.indexOf(supportedPropertyType) > -1) {
          return {
            value: key,
            label: t(`calculator-loan-${key}-label`),
          };
        } else {
          return {
            label: '',
            value: '',
          };
        }
      })
      .filter((elem) => elem.label !== ''),
  ];

  return (
    <HeaderCellValue>
      <Select
        aria-label={t('calculator-table-select-dropdown')}
        onChange={onLoanSelected}
        isDark
        isBold
        options={dropdownOptions}
        value={loan.type}
      />

      {showRemoveButton && (
        <CloseLink target="#" onClick={onRemoveLoanClick}>
          <Icons.Close />
        </CloseLink>
      )}
    </HeaderCellValue>
  );
};

const HeaderCellValue = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  select {
    width: auto;
    padding-right: 16px;
    font-size: 14px;

    ${mediaQuery.sm} {
      font-size: 16px;
    }
  }
`;

const CloseLink = styled.a`
  display: block;
  position: relative;
  cursor: pointer;

  ${mediaQuery.sm} {
    top: 2px;
  }

  svg {
    width: 10px !important;
    height: 10px !important;

    ${mediaQuery.sm} {
      width: 14px !important;
      height: 14px !important;
    }

    path {
      fill: ${RddkTheme.colors.grey0} !important;
    }
  }
`;
