import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { Box, mediaQuery, RddkTheme, Typography } from 'db-npm-rdui';
import React from 'react';
import styled, { css } from 'styled-components';
import { Button, FieldChecker } from '../../shared/components';
import { sitecoreBoolToBool } from '../../shared/utils/sitecoreBoolToBool';
import { richTextStyles } from '../RichText/richTextStyles';

const StyledRichText = styled(RichText)`
  ${richTextStyles}
  margin-bottom: 20px;
  margin-top: 20px;
`;

type Params = {
  useRedBackground?: string;
  CenterText?: string;
};

type ColorCardProps = {
  fields: any;
  params?: Params;
};

type ColorCardWrapperProps = {
  backgroundColor?: string;
  fontColor?: string;
};

const ColorCard: React.FC<ColorCardProps & ColorCardWrapperProps> = ({ fields = {}, params = {} }) => {
  const useRedBackground = sitecoreBoolToBool(params?.useRedBackground);
  const centerText = sitecoreBoolToBool(params?.CenterText);
  const bgColor = useRedBackground ? RddkTheme.colors.red50 : RddkTheme.colors.grey10;
  const color = useRedBackground ? '#fff' : '#000';

  return (
    <Container centerText={centerText} backgroundColor={bgColor}>
      <FieldChecker type="text" field={fields['Title']}>
        <Typography type="h3" color={color}>
          <Text field={fields['Title']} />
        </Typography>
      </FieldChecker>

      {useRedBackground ? (
        <TextComponent>
          <FieldChecker type="text" field={fields['Text']}>
            <Typography type="manchetSmall" mt={4} mb={4} color={color}>
              <Text field={fields['Text']} />
            </Typography>
          </FieldChecker>
          <FieldChecker type="link" field={fields['Button Link']}>
            <Button variant="secondary" field={fields['Button Link']} />
          </FieldChecker>
        </TextComponent>
      ) : (
        <FieldChecker type="text" field={fields['Default Card Description']}>
          <StyledRichText field={fields['Default Card Description']} />
        </FieldChecker>
      )}
    </Container>
  );
};

export default ColorCard;

type ContainerProps = {
  centerText: boolean;
};

const Container = styled(Box)<ContainerProps>`
  height: calc(100% - 24px);
  display: flex;
  flex-direction: column;

  text-decoration: none;
  transition: box-shadow 0.1s ease;
  border-radius: 5px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.15);
  border: solid 0.8px #eeeeee;
  &:hover {
    box-shadow: 0 22px 44px 0 rgba(0, 0, 0, 0.22);
  }

  overflow: hidden;
  margin-bottom: 24px;
  padding: 25px;

  ${mediaQuery.md} {
    padding: 40px;
  }
  ${(props) =>
    props?.centerText &&
    css`
      align-items: center;
      > ${TextComponent} {
        align-items: center;
      }
    `}
`;

const TextComponent = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1;
`;
