import { Text } from '@sitecore-jss/sitecore-jss-react';
import {
  BottomShadow,
  Box,
  Col,
  Container,
  mediaQuery,
  PopoutBox,
  Row,
  SlantedBackgroundImage,
  Typography,
} from 'db-npm-rdui';
import React from 'react';
import LazyLoad from 'react-lazyload';
import styled from 'styled-components';
import { FieldChecker, LinkAnimatedUnderline, Section, WithConditionWrapper } from '../../shared/components';
import imageScaler from '../../shared/utils/imageScaler';

const BgImage = styled(SlantedBackgroundImage)`
  width: 90%;
  position: absolute !important;
  z-index: 0;
  top: 0;
  left: 0;
  bottom: 0;

  ${mediaQuery.md} {
    width: 80%;
  }
`;

export type EntranceSinglePopoutRightProps = {
  fields: any;
};

const EntranceSinglePopoutRight: React.FC<EntranceSinglePopoutRightProps> = ({ fields = {} }) => {
  return (
    <Section componentName="EntranceSinglePopoutRight" margin="large">
      <WithConditionWrapper
        condition={process.env.REACT_APP_CM_BUILD === 'true'}
        Wrapper={LazyLoad}
        props={{ height: 300, once: true }}
      >
        <FieldChecker type="image" field={fields['Image']}>
          <BgImage src={imageScaler(fields['Image']?.value?.src)} slantPosition="right" />
        </FieldChecker>

        <Container>
          <Row>
            <Col md={8} lg={6} xxl={4} offset={{ md: 4, lg: 6, xxl: 8 }}>
              <Box marginTop={10} marginBottom={10} style={{ position: 'relative', zIndex: 1 }}>
                <BottomShadow>
                  <PopoutBox>
                    <FieldChecker type="text" field={fields['Headline']}>
                      <Typography type="h4">
                        <Text field={fields['Headline']} />
                      </Typography>
                    </FieldChecker>

                    <FieldChecker type="text" field={fields['Description']}>
                      <Typography type="manchetSmall" mt={4} mb={4}>
                        <Text field={fields['Description']} />
                      </Typography>
                    </FieldChecker>

                    <FieldChecker type="link" field={fields['Link']}>
                      <LinkAnimatedUnderline field={fields['Link']} />
                    </FieldChecker>
                  </PopoutBox>
                </BottomShadow>
              </Box>
            </Col>
          </Row>
        </Container>
      </WithConditionWrapper>
    </Section>
  );
};

export default EntranceSinglePopoutRight;
