import { Cell, DynamicTable, Icons, IconWrapper, Tooltip } from 'db-npm-rdui';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { formatAsCurrency } from '../../../../shared/utils/currency';
import { useSettingsState } from '../../state/settingsState';
import { CalculatorTooltipTable } from './CalculatorTooltipTable';

type CalculatorTooltipAdministrationFeeProps = {
  totalInterest: number;
  paymentTotalAdministrationFee: number;
  settlementFeeAmount: number;
  loanCaseFee: number;
  originationFeeAmount: number;
  registrationHandlingFee: number;
  variableRegistrationFee: number;
  fixedRegistrationFee: number;
  isFromOverlay?: boolean;
  totalAdministrationFee: number;
};

export const CalculatorTooltipAdministrationFee: React.FC<CalculatorTooltipAdministrationFeeProps> = (props) => {
  const { t } = useTranslation();
  const { settings } = useSettingsState();
  const isPrivateCalculator = settings.calculatorType === 'Private loan';
  const calculatorTooltipDetails = [
    {
      label: t('calculator-row-totalInterest-label'),
      value: formatAsCurrency(props.totalInterest),
      suffix: ' kr.',
    },
    {
      label: t('calculator-row-paymentTotalAdministrationFee-label'),
      value: formatAsCurrency(props.paymentTotalAdministrationFee),
      suffix: ' kr.',
    },
    {
      label: t('calculator-row-settlementFeeAmount-label'),
      value: formatAsCurrency(props.settlementFeeAmount),
      suffix: ' kr.',
    },
    {
      label: t('calculator-row-loanCaseFee-label'),
      value: formatAsCurrency(props.loanCaseFee),
      suffix: ' kr.',
    },
    {
      label: t('calculator-row-registrationHandlingFee-label'),
      value: formatAsCurrency(props.registrationHandlingFee),
      suffix: ' kr.',
    },
    isPrivateCalculator
      ? {
          label: (
            <>
              {t('calculator-row-variableRegistrationFee-label')}
              {'     '}
              <Tooltip
                element={
                  <IconWrapper size="24" color="Action">
                    <Icons.Action.Question />
                  </IconWrapper>
                }
                content={t('calculator-row-variableRegistrationFee-help')}
              />
            </>
          ),
          value: formatAsCurrency(props.variableRegistrationFee),
          suffix: ' kr.',
        }
      : {
          label: t('calculator-row-variableRegistrationFee-label'),
          value: formatAsCurrency(props.variableRegistrationFee),
          suffix: ' kr.',
        },
    {
      label: t('calculator-row-fixedRegistrationFee-label'),
      value: formatAsCurrency(props.fixedRegistrationFee),
      suffix: ' kr.',
    },
  ];
  if (props.originationFeeAmount) {
    const feeAmount = {
      label: t('calculator-row-originationFeeAmount-label'),
      value: formatAsCurrency(props.originationFeeAmount),
      suffix: ' kr.',
    };
    calculatorTooltipDetails.splice(4, 0, feeAmount);
  }

  if (props.isFromOverlay) {
    const totalCost = {
      label: t('calculator-row-totalAdministrationFee-label'),
      value: formatAsCurrency(props.totalAdministrationFee),
      suffix: ' kr.',
    };
    calculatorTooltipDetails.push(totalCost);
  }

  const costTable = React.useMemo(() => {
    const rows: Cell[][] = [];
    let tableHeader = [
      {
        value: 'Omkostning',
        minWidth: 110,
        width: 200,
      },
      {
        value: 'Beløb',
        minWidth: 150,
      },
    ];
    rows.push(tableHeader);

    calculatorTooltipDetails.forEach((row) => {
      let rowData = [
        {
          value: <LabelColumn>{row.label}</LabelColumn>,
          width: 200,
        },
        {
          value:
            row.label !== 'Omkostninger' ? (
              <Column>
                {row.value}
                {row.suffix}
              </Column>
            ) : (
              <LabelColumn>
                {row.value}
                {row.suffix}
              </LabelColumn>
            ),
          width: 200,
        },
      ];
      rows.push(rowData);
    });
    return rows;
  }, [calculatorTooltipDetails]);

  if (props.isFromOverlay) {
    return <DynamicTable bordered data={costTable} firstRowIsHead striped />;
  } else {
    return <CalculatorTooltipTable title={'De samlede omkostninger består af'} rows={calculatorTooltipDetails} />;
  }
};

const LabelColumn = styled.span`
  display: inline-block;
  font-weight: bold;
  margin-right: 8px;
`;

const Column = styled.span`
  display: inline-block;
  margin-right: 8px;
`;
