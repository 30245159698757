import * as Yup from 'yup';

declare module 'yup' {
  interface StringSchema {
    cprValidation(message: string): StringSchema;
  }
}

Yup.addMethod(Yup.string, 'cprValidation', function (message) {
  return this.test('cpr', message, function (value) {
    const cpr = value ? value.trim().replace('-', '') : '';

    // Check that input is exactly 10 characters long

    if (cpr.length !== 10) {
      return false;
    }

    //Check if CPR is a kindi number
    if (parseInt(cpr[0]) === 8) {
      return true;
    }

    // Check that birth date is valid
    const dobDay = cpr.substring(0, 2);
    const dobMonth = cpr.substring(2, 4);
    const dobYear = cpr.substring(4, 6);

    const day = parseInt(dobDay);
    const month = parseInt(dobMonth);

    const centuryChar = parseInt(cpr[6]);
    let fullYear = '19' + dobYear;
    if (centuryChar === 4 || centuryChar === 9) {
      if (parseInt(dobYear) <= 36) {
        fullYear = '20' + dobYear;
      }
    } else if (centuryChar > 4) {
      if (parseInt(dobYear) > 57) {
        fullYear = '19' + dobYear;
      } else {
        fullYear = '20' + dobYear;
      }
    }

    //setting  year
    const year = parseInt(fullYear);

    //Check the Date is Valid

    const givenDate = parseInt(fullYear + dobMonth + dobDay);

    const cprDate = new Date(year, month - 1, day);
    const modifiedMonth =
      cprDate.getMonth() + 1 < 10 ? '0' + (cprDate.getMonth() + 1).toString() : (cprDate.getMonth() + 1).toString();
    const modifiedDay = cprDate.getDate() < 10 ? '0' + cprDate.getDate().toString() : cprDate.getDate().toString();
    const modifiedDate = cprDate.getFullYear().toString() + modifiedMonth + modifiedDay;

    if (givenDate !== parseInt(modifiedDate)) {
      return false;
    }

    // Check that control digits are correct Mod11

    const weights = [4, 3, 2, 7, 6, 5, 4, 3, 2, 1];
    let sum = 0;

    for (let i = 0; i <= cpr.length - 1; i++) {
      sum += weights[i] * parseInt(cpr[i]);
    }

    const remainder = sum % 11;

    if (remainder !== 0) {
      const ignoreYearsWithMonths = [
        '01/01/1960',
        '01/01/1964',
        '01/01/1965',
        '01/01/1966',
        '01/01/1969',
        '01/01/1970',
        '01/01/1974',
        '01/01/1980',
        '01,01/1982',
        '01/01/1984',
        '01/01/1985',
        '01/01/1986',
        '01/01/1987',
        '01/01/1988',
        '01/01/1989',
        '01/01/1990',
        '01/01/1991',
        '01/01/1992',
        '01/01/1995',
      ];
      const selectedDate = day + '/' + month + '/' + year;
      if (ignoreYearsWithMonths.indexOf(selectedDate) !== -1) {
        return true;
      }
      return false;
    }

    // Input is valid CPR number
    return true;
  });
});

export * from 'yup';
