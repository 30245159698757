import { Button, Icons, mediaQuery, RddkTheme } from 'db-npm-rdui';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useLoansState } from '../../state/loansState';

type Props = {
  loanArrayIndex: number;
};

export const CalculatorTableErrorMessage: React.FC<Props> = ({ loanArrayIndex }) => {
  const { t } = useTranslation();
  const { calculateSingleLoan } = useLoansState();

  const onClick = () => calculateSingleLoan(loanArrayIndex);

  return (
    <Content>
      <Icons.NoConnection />
      <Title>{t('calculator-error-title')}</Title>
      <Text>{t('calculator-error-text')}</Text>
      <Button variant="secondary" onClick={onClick}>
        {t('calculator-error-button-label')}
      </Button>
    </Content>
  );
};

const Content = styled.div`
  text-align: center;

  ${mediaQuery.md} {
    max-width: 400px;
  }

  svg {
    width: 60px !important;
    height: 60px !important;
  }
`;

const Title = styled.div`
  font-weight: 800;
  font-size: 14px;
  color: ${RddkTheme.colors.black};
  margin-top: 6px;
`;

const Text = styled.div`
  font-size: 14px;
  color: ${RddkTheme.colors.black};
  margin-top: 3px;
  margin-bottom: 20px;
`;
