import dayjs from 'dayjs';
import { Cell, DynamicTable, Icons, IconWrapper, TableData, Tooltip } from 'db-npm-rdui';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { formatAsCurrency } from '../../shared/utils/currency';
import { VariableLoans } from './types';

type TableVariabelProps = {
  data: VariableLoans[];
};

export const TableVariabel: React.FC<TableVariabelProps> = ({ data }) => {
  const { t } = useTranslation();
  const tableData = React.useMemo((): TableData => {
    if (!data || data.length <= 0) {
      return [];
    }
    const date1 = data[0]?.prices?.[1]?.date ? dayjs(data[0].prices?.[1].date).format('DD.MM.YYYY') : '';
    const date2 = data[0]?.prices?.[2]?.date ? dayjs(data[0].prices?.[2].date).format('DD.MM.YYYY') : '';
    const date3 = data[0]?.prices?.[3]?.date ? dayjs(data[0].prices?.[3].date).format('DD.MM.YYYY') : '';
    return [
      // Header row:
      [
        {
          value: t(`kurser-variable-LoanName-header`),
          alignVertically: 'middle',
          minWidth: 170,
        },
        {
          value: 'Aktuel rente',
          alignVertically: 'middle',
          minWidth: 160,
        },
        {
          value: (
            <Tooltip
              element={
                <IconWrapper size="24" color="Action">
                  <Icons.Action.Question />
                </IconWrapper>
              }
              content={t('kurser-variable-tooltip-ActualRente-text')}
            />
          ),
          alignVertically: 'middle',
        },
        {
          value: 'Tilbudsrente',
          alignVertically: 'middle',
          minWidth: 160,
        },
        {
          value: (
            <Tooltip
              element={
                <IconWrapper size="24" color="Action">
                  <Icons.Action.Question />
                </IconWrapper>
              }
              content={t('kurser-variable-tooltip-OfferedCashLoan-text')}
            />
          ),
          alignVertically: 'middle',
        },
        {
          value: 'Kontantlånsrente',
          suffix: 'Fastkursaftale ' + date1,
          minWidth: 180,
        },
        {
          value: (
            <Tooltip
              element={
                <IconWrapper size="24" color="Action">
                  <Icons.Action.Question />
                </IconWrapper>
              }
              content={t('kurser-variable-tooltip-FixedRateCashLoan-text')}
            />
          ),
          alignVertically: 'middle',
        },
        {
          value: 'Kontantlånsrente',
          suffix: 'Fastkursaftale ' + date2,
          minWidth: 180,
        },
        {
          value: (
            <Tooltip
              element={
                <IconWrapper size="24" color="Action">
                  <Icons.Action.Question />
                </IconWrapper>
              }
              content={t('kurser-variable-tooltip-FixedRateCashLoan-text')}
            />
          ),
          alignVertically: 'middle',
        },
        {
          value: 'Kontantlånsrente',
          suffix: 'Fastkursaftale ' + date3,
          minWidth: 180,
        },
        {
          value: (
            <Tooltip
              element={
                <IconWrapper size="24" color="Action">
                  <Icons.Action.Question />
                </IconWrapper>
              }
              content={t('kurser-variable-tooltip-FixedRateCashLoan-text')}
            />
          ),
          alignVertically: 'middle',
        },
      ],
      // Body rows:
      ...data.map((item): Cell[] => {
        const bondName = item?.name.split('_');
        return [
          {
            prefix:
              bondName.length >= 2
                ? bondName[0] === 'FlexLife'
                  ? t(`kurser-variable-${bondName[0]}-header`)
                  : t(`kurser-variable-${bondName[0]}-header`) +
                    ' ' +
                    t(`kurser-variable-${bondName[bondName?.length - 1]}-asterix-label`)
                : t(`kurser-variable-${bondName[0]}-header`),
            value:
              bondName.length === 3
                ? bondName[1] + ' ' + t(`kurser-variable-${bondName[2]}-label`)
                : bondName.length === 2
                ? bondName[0] === 'FlexLife'
                  ? (bondName[1] === 'F1'
                      ? (bondName[1] = '1IR')
                      : bondName[1] === 'F3'
                      ? (bondName[1] = '3IR')
                      : bondName[1] === 'F5'
                      ? (bondName[1] = '5IR')
                      : (bondName[1] = '10IR')) +
                    ' ' +
                    t(`kurser-variable-${bondName[0]}-label`)
                  : t(`kurser-variable-${bondName[1]}-label`)
                : t(`kurser-variable-${bondName[0]}-label`),
            suffix: `30 år`,
          },
          //If API returns null value or undefined set the value as '-'
          {
            value: `${
              String(item?.prices?.[0]?.price) === '' || String(item?.prices?.[0]?.price) === 'undefined'
                ? '-'
                : isNaN(item?.prices?.[0]?.price)
                ? item.prices?.[0].price
                : formatAsCurrency(item.prices?.[0].price, 4, true) + ' %'
            }`,
            alignVertically: 'middle',
          },
          {
            style: {
              borderLeft: 0,
              maxWidth: '1px',
            },
            value: null,
          },
          {
            value: `${
              String(item?.offerrate) === '' || String(item?.offerrate) === 'undefined'
                ? '-'
                : isNaN(item?.offerrate)
                ? item?.offerrate
                : formatAsCurrency(item?.offerrate, 4, true) + ' %'
            }`,

            alignVertically: 'middle',
          },
          {
            style: {
              borderLeft: 0,
              maxWidth: '1px',
            },
            value: null,
          },
          {
            value: `${
              String(item?.prices?.[1]?.price) === '' || String(item?.prices?.[1]?.price) === 'undefined'
                ? '-'
                : isNaN(item?.prices?.[1]?.price)
                ? item.prices?.[1].price
                : formatAsCurrency(item.prices?.[1].price, 4, true) + ' %'
            } `,
            alignVertically: 'middle',
          },
          {
            style: {
              borderLeft: 0,
              maxWidth: '1px',
            },
            value: null,
          },
          {
            value: `${
              String(item?.prices?.[2]?.price) === '' || String(item?.prices?.[2]?.price) === 'undefined'
                ? '-'
                : isNaN(item?.prices?.[2]?.price)
                ? item.prices?.[2].price
                : formatAsCurrency(item.prices?.[2].price, 4, true) + ' %'
            }`,
            alignVertically: 'middle',
          },
          {
            style: {
              borderLeft: 0,
              maxWidth: '1px',
            },
            value: null,
          },
          {
            value: `${
              String(item?.prices?.[3]?.price) === '' || String(item?.prices?.[3]?.price) === 'undefined'
                ? '-'
                : isNaN(item?.prices?.[3]?.price)
                ? item.prices?.[3].price
                : formatAsCurrency(item.prices?.[3].price, 4, true) + ' %'
            }`,
            alignVertically: 'middle',
          },
          {
            style: {
              borderLeft: 0,
              maxWidth: '1px',
            },
            value: null,
          },
        ];
      }),
    ];
  }, [data, t]);

  return <DynamicTable striped={false} bordered={true} firstRowIsHead={true} data={tableData} />;
};
