import dayjs from 'dayjs';
import { CITY_COMMERCIAL } from '../../config';
import {
  // formatPayloadDate,
  getNextAvailableBusinessDay,
  isBusinessDay,
  snapDateToQuarter,
} from '../../helpers/dateHelpers';
import { CalculatorInputs } from '../../types/CalculatorInputs';
import { LoanStateItem } from '../../types/LoanStateItem';

export interface FlexlifeResponse {
  monthlyGrossPaymentYearOne: number;
  monthlyNetPaymentYearOne: number;
  maturity: number;
  rateType: string;
  refinancingFrequency: number;
  interestOnlyYearsEnd: number;
  calculatedDebtAtExpiry: number;
  approvedDebtAtExpiry: number;
  loanToValuePercentage: number;
  loanProvider: string;
  netProceedsAmount: number;
  propertyValue: number;
  bankloan: number;
  ownpayment: number;
  principalAmount: number;
  interestRatePercentage: number;
  debtorInterestRate: number;
  annualPercentageRate: number;
  totalRepaymentAmount: number;
  numberOfPayments: number;
  weightedBondPrice: number;
  totalAdministrationFee: number;
  taxRate: number;
  amortisation: any;
  firstYearInstalmentMonthlyPaymentGross: number;
  firstYearInterestOnlyMonthlyPayment: number;
  firstYearWithOutInstalmentMonthlyPaymentNet: number;
  firstYearInstalmentMonthlyPaymentNet: number;
  totalInterest: number;
  paymentTotalAdministrationFee: number;
  settlementFeeAmount: number;
  loanCaseFee: number;
  registrationHandlingFee: number;
  variableRegistrationFee: number;
  fixedRegistrationFee: number;
  originationFeeAmount: number;
  calculationDate: string;
  paymentsPerYear: string;
}

export function mapResponse(data: any, inputs: CalculatorInputs, loan: LoanStateItem): FlexlifeResponse {
  const maturityYears = inputs.propertyType === CITY_COMMERCIAL ? loan.settings?.businessCommercialMaturity : 30;
  const today = new Date();
  let calculationDateUsed = dayjs(today).add(0, 'day').toDate();

  if (!isBusinessDay(calculationDateUsed)) {
    calculationDateUsed = getNextAvailableBusinessDay(calculationDateUsed);
  }
  const nextTermDate = snapDateToQuarter(calculationDateUsed);

  const thisQuater = dayjs(nextTermDate).subtract(3, 'month').toDate();

  const monthlyGrossPaymentYearOne = Math.ceil(parseFloat(data.payment?.monthlyGrossPaymentYearOne)) || 0; // 1 års månedlig ydelse før skat

  const monthlyNetPaymentYearOne = parseFloat(data.payment?.monthlyNetPaymentYearOne) || 0; // 1. års mdl. ydelse efter skat m. afdrag

  const maturity =
    parseInt(data.maturity?.calculatedTimeToMaturityYears) +
      (parseInt(data.maturity?.calculatedTimeToMaturityMonths) > 0 ? 1 : 0) || 0; //Løbetid i år

  const rateType = data.interestRate?.rateType === 'Variable' ? 'Variable' : 'Fixed' || 'Fixed'; //Rentetype

  const refinancingFrequency =
    data.interestRate?.rateType === 'Variable' ? parseInt(data.refinancing?.refinancingFrequency) || 1 : 30 || 1; //Periode Hvor Renten Bindes i år

  const calculationDate = data.basic?.usedCalculationDate || ''; //Beregningsdato

  const interestOnlyStartYear = new Date(data.changeOption?.calculatedInterestOnlyToMaturityStartDate);

  const interestOnlyYearsEnd =
    maturityYears - (interestOnlyStartYear.getFullYear() - thisQuater.getFullYear()) < 0
      ? 0
      : maturityYears - (interestOnlyStartYear.getFullYear() - thisQuater.getFullYear()); //Afdragsfrihed år

  const calculatedDebtAtExpiry = Math.floor(parseFloat(data.changeOption?.calculatedDebtAtExpiry)) || 0; //Restgæld Ved Udløb

  const approvedDebtAtExpiry = parseFloat(data.changeOption?.approvedDebtAtExpiry) || 0; // Maximum value for Restgæld ved udløb

  const loanToValuePercentage =
    parseFloat(data.property?.loanToValuePercentage) > 0 ? parseFloat(data.property?.loanToValuePercentage) : 75 || 0; //LTV

  const loanProvider = 'Realkredit Danmark';

  const netProceedsAmount = Math.floor(parseFloat(data.loanKeyFigures?.netProceedsAmount)) || 0; // Udbetalt kreditbeløb

  const propertyValue = parseInt(data.property?.propertyValue) || 0;

  const bankloan = Math.ceil(propertyValue - netProceedsAmount - inputs.ownPayment);

  const ownpayment = inputs.ownPayment;

  const paymentsPerYear = data.payment?.paymentsPerYear;

  const principalAmount = parseFloat(data.basic?.principalAmount) || 0; //Hovedstol

  const interestRatePercentage = parseFloat(data.interestRate?.interestRatePercentage) || 0; //Rente

  const debtorInterestRate = parseFloat(data.interestRate?.debtorInterestRate) || 0; //Debitorrente

  const annualPercentageRate = parseFloat(data.loanKeyFigures?.annualPercentageRate) || 0; //ÅOP

  const totalRepaymentAmount = parseFloat(data.payment?.totalRepaymentAmount) || 0; //Tilbagebetaling

  const numberOfPayments = parseInt(data.amortisation?.numberOfPayments) || 0; //Antal ydelser

  const weightedBondPrice = parseFloat(data.bonds?.weightedBondPrice) || 0; //Kurs

  const totalAdministrationFee =
    parseFloat(data.payment?.totalAdministrationFee) +
      parseFloat(data.payment?.totalInterest) +
      parseFloat(data.feeLoanCaseCosts?.costsTotal) || 0; //Omkostninger

  const taxRate = parseFloat(data.customer?.appliedTaxRate) || 0; //Used taxrate

  const amortisation =
    typeof data.amortisation !== 'undefined'
      ? {
          numberOfPayments: data.amortisation.numberOfPayments,
          cashFlow: data.amortisation.cashFlow,
        }
      : []; //Ydelsesforløb (Object)

  let firstYearWithOutInstalmentMonthlyPaymentNet = 0;
  let firstYearInstalmentMonthlyPaymentNet = monthlyNetPaymentYearOne;
  //Find first term with interest only payment
  const firstYearInstalmentMonthlyPaymentGross = monthlyGrossPaymentYearOne;
  let firstYearInterestOnlyMonthlyPayment = 0;

  let interestOnlyTerms = [] as any;
  if (data.amortisation.cashFlow[0].instalment !== '0.00') {
    interestOnlyTerms = data.amortisation.cashFlow.filter((term) => term.instalment === '0.00');
    if (interestOnlyTerms.length > 11) {
      let firstYearOfInterestOnlyPayment = 0;
      let firstYearOfInstalmentPaymentNet = 0;
      for (let x = 0; x < 12; x++) {
        firstYearOfInterestOnlyPayment = firstYearOfInterestOnlyPayment + parseFloat(interestOnlyTerms[x].grossPayment);
        firstYearOfInstalmentPaymentNet = firstYearOfInstalmentPaymentNet + parseFloat(interestOnlyTerms[x].netPayment);
      }
      firstYearInterestOnlyMonthlyPayment = firstYearOfInterestOnlyPayment / 12;
      firstYearWithOutInstalmentMonthlyPaymentNet = firstYearOfInstalmentPaymentNet / 12;
    }
  } else {
    firstYearInterestOnlyMonthlyPayment = monthlyGrossPaymentYearOne;
    firstYearWithOutInstalmentMonthlyPaymentNet = monthlyNetPaymentYearOne;
  }

  // Values for "total administration fee" tooltip content
  const totalInterest = parseFloat(data.payment?.totalInterest) || 0;
  const paymentTotalAdministrationFee = parseFloat(data.payment?.totalAdministrationFee) || 0;
  const settlementFeeAmount = parseFloat(data.feeLoanCaseCosts?.settlementFeeAmount) || 0;
  const loanCaseFee = parseFloat(data.feeLoanCaseCosts?.loanCaseFee) || 0;
  const registrationHandlingFee = parseFloat(data.feeLoanCaseCosts?.registrationHandlingFee) || 0;
  const variableRegistrationFee = parseFloat(data.feeLoanCaseCosts?.variableRegistrationFee) || 0;
  const fixedRegistrationFee = parseFloat(data.feeLoanCaseCosts?.fixedRegistrationFee) || 0;
  const originationFeeAmount = parseFloat(data.feeLoanCaseCosts?.originationFeeAmount) || 0;

  return {
    monthlyGrossPaymentYearOne,
    monthlyNetPaymentYearOne,
    maturity,
    rateType,
    refinancingFrequency,
    interestOnlyYearsEnd,
    calculatedDebtAtExpiry,
    approvedDebtAtExpiry,
    loanToValuePercentage,
    loanProvider,
    netProceedsAmount,
    propertyValue,
    bankloan,
    ownpayment,
    principalAmount,
    interestRatePercentage,
    debtorInterestRate,
    annualPercentageRate,
    totalRepaymentAmount,
    numberOfPayments,
    weightedBondPrice,
    totalAdministrationFee,
    taxRate,
    amortisation,
    firstYearInstalmentMonthlyPaymentGross,
    firstYearInterestOnlyMonthlyPayment,
    firstYearInstalmentMonthlyPaymentNet,
    firstYearWithOutInstalmentMonthlyPaymentNet,
    totalInterest,
    paymentTotalAdministrationFee,
    settlementFeeAmount,
    loanCaseFee,
    registrationHandlingFee,
    variableRegistrationFee,
    fixedRegistrationFee,
    calculationDate,
    originationFeeAmount,
    paymentsPerYear,
  };
}
