import { Box, Container, DynamicTable, Hidden, Icons, mediaQuery, RddkTheme, Visible } from 'db-npm-rdui';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { formatAsCurrency } from '../../../../shared/utils/currency';
import { DESKTOP_LABEL_COLUMN_WIDTH, MAX_LOANS_MOBILE } from '../../config';
import { useLoansState } from '../../state/loansState';
import { LoanStateItem } from '../../types/LoanStateItem';

export const CalculatorStickyBar: React.FC = () => {
  const { t } = useTranslation();
  const { loans } = useLoansState();
  // const { setShowModal, setActiveLoan } = useInputsState();
  const [showBeforeTax, setShowBeforeTax] = React.useState(true);

  // Count loans with type set
  // Temporariliy commented out becuse of obscure legal reason - todo: create function that only shows sticky bar after the user has scrolled - note that you must distingues the automatic scroll that happens on calculate from this...
  // const hasLoans =loans.filter((l) => l.type && l.status === 'done').length > 0;
  const hasLoans = false;

  // Handler for clicking the flip arrows
  const toggleView = () => setShowBeforeTax(!showBeforeTax);

  // Buttons to change view
  const buttons = (
    <Buttons>
      <Button onClick={toggleView} disabled={showBeforeTax} title={t('calculator-bar-showBeforeTax')}>
        <UpIcon active={!showBeforeTax}>
          <Icons.Chiron />
        </UpIcon>
      </Button>
      <Button onClick={toggleView} disabled={!showBeforeTax} title={t('calculator-bar-showAfterTax')}>
        <DownIcon active={showBeforeTax}>
          <Icons.Chiron />
        </DownIcon>
      </Button>
    </Buttons>
  );

  // The label column on top (on mobile)
  const mobileLabelColumn = {
    value: (
      <Box position="relative" height={14}>
        {buttons}
        <Box position="relative">
          <Text show={showBeforeTax} up>
            {t('calculator-bar-monthlyPaymentBeforeTax')}
          </Text>
          <Text show={!showBeforeTax}>{t('calculator-bar-monthlyPaymentAfterTax')}</Text>
        </Box>
      </Box>
    ),
    colSpan: Math.min(loans.length, MAX_LOANS_MOBILE),
    style: {
      borderColor: RddkTheme.colors.grey60,
      backgroundColor: RddkTheme.colors.grey90,
    },
  };

  // The label column on the left (on desktop)
  const desktopLabelColumn = {
    value: (
      <Box position="relative">
        <Title>{t('calculator-bar-monthlyPayment')}</Title>
        {buttons}
        <Box position="relative">
          <Text show={showBeforeTax} up>
            {t('calculator-bar-beforeTax')}
          </Text>
          <Text show={!showBeforeTax}>{t('calculator-bar-afterTax')}</Text>
        </Box>
      </Box>
    ),
    width: DESKTOP_LABEL_COLUMN_WIDTH,
    style: {
      borderBottom: 0,
      borderColor: RddkTheme.colors.grey60,
      backgroundColor: RddkTheme.colors.grey90,
    },
  };

  /**
   * Function that renders a loan as the table cell value
   */
  const renderLoan = (loan: LoanStateItem) => {
    if (!loan.type) {
      return null;
    }

    // const onClickHandler = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    //   e.preventDefault();
    //   setActiveLoan(loan);
    //   setShowModal(true);
    // };

    const beforeTax = loan.stickyValues ? `${formatAsCurrency(loan.stickyValues.monthlyGrossPaymentYearOne)} DKK` : `-`;
    const afterTax = loan.stickyValues ? `${formatAsCurrency(loan.stickyValues.monthlyNetPaymentYearOne)} DKK` : `-`;

    return (
      <Box position="relative" height={{ _: 30, lg: 42 }} maxWidth={{ _: 200, sm: 320, lg: 400 }}>
        {/* <ArrowLink href="#" onClick={onClickHandler}>
          <Icons.ArrowLongRight />
        </ArrowLink> */}
        <Title>{t(`calculator-loan-${loan.type}-label`)}</Title>
        <Box position="relative">
          <Text show={showBeforeTax} up>
            {beforeTax}
          </Text>
          <Text show={!showBeforeTax}>{afterTax}</Text>
        </Box>
      </Box>
    );
  };

  /**
   * Iterate through all loans and render them
   */
  const loansRendered = loans.map((loan) => {
    return {
      value: renderLoan(loan),
      style: {
        borderBottom: 0,
        borderColor: RddkTheme.colors.grey60,
        backgroundColor: RddkTheme.colors.grey90,
      },
    };
  });

  return (
    <Wrapper show={hasLoans}>
      <StyledContainer>
        <Bar>
          <Visible xs sm md>
            <DynamicTable data={[[mobileLabelColumn], [...loansRendered]]} />
          </Visible>
          <Hidden xs sm md>
            <DynamicTable data={[[desktopLabelColumn, ...loansRendered]]} />
          </Hidden>
        </Bar>
      </StyledContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ show?: boolean }>`
  position: fixed;
  z-index: 500;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateY(500px);
  transition: transform 0.5s;

  ${(props) =>
    props.show &&
    css`
      transform: translateY(-5px);
      ${mediaQuery.lgDown} {
        transform: translateY(0);
      }
    `}

  table {
    table-layout: fixed; /* IMPORTANT */

    ${mediaQuery.lgDown} {
      td:nth-of-type(1n + ${MAX_LOANS_MOBILE + 1}) {
        display: none;
      }
    }
  }

  td {
    position: relative;
  }
`;

const StyledContainer = styled(Container)`
  ${mediaQuery.mdDown} {
    padding-left: 0 !important;
    padding-right: 0 !important;
    max-width: 100% !important;
  }
`;

const Bar = styled.div`
  background: ${RddkTheme.colors.grey30};
  border-radius: 5px 5px 0 0;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25), 0 3px 4px 0 rgba(0, 0, 0, 0.05);
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;

  ${mediaQuery.lg} {
    top: 2px;
    flex-direction: column;
  }
`;

const Button = styled.button`
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  outline: none;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    &:hover {
      cursor: auto;
    }
  }

  ${mediaQuery.lgDown} {
    margin-left: 15px;
  }
`;

const UpIcon = styled.span<{ active?: boolean }>`
  position: relative;
  left: 2px;
  display: inline-block;
  opacity: ${(props) => (props.active ? 1 : 0.4)};
  transform: rotate(-90deg);
  transition: opacity 0.3s;

  svg path {
    stroke: ${RddkTheme.colors.grey20};
  }

  ${mediaQuery.lgDown} {
    transform: rotate(-180deg);
    top: -4px;
  }
`;

const DownIcon = styled(UpIcon)`
  left: -2px;
  transform: rotate(90deg);

  ${mediaQuery.lgDown} {
    top: 0;
    transform: rotate(0);
  }
`;

const Title = styled.div`
  font-size: 11px;
  font-weight: 600;
  line-height: 1.25;
  letter-spacing: 0.8px;
  color: ${RddkTheme.colors.grey40};
  text-transform: uppercase;
  margin-bottom: 3px;

  ${mediaQuery.lg} {
    font-size: 12px;
  }
`;

const Text = styled.div<{ up?: boolean; show?: boolean }>`
  position: absolute;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.08;
  letter-spacing: -0.3px;
  color: ${RddkTheme.colors.white};
  transition: all 0.4s;
  opacity: 0;
  transform: ${(props) => (props.up ? 'translateY(10px)' : 'translateY(-10px)')};
  perspective: 100px;
  ${(props) =>
    props.show &&
    css`
      opacity: 1;
      transform: translateY(0);
    `}

  ${mediaQuery.lg} {
    font-size: 24px;
  }
`;

// const ArrowLink = styled.a`
//   display: block;
//   position: absolute;
//   top: 5px;
//   right: 0;

//   ${mediaQuery.lg} {
//     top: 10px;
//   }

//   svg {
//     width: 24px;
//     height: 24px;

//     path {
//       fill: ${RddkTheme.colors.grey20};
//     }
//   }
// `;
