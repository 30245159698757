import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useInputsState } from '../../../state/inputsState';

export function useAmortExcelData() {
  const { t } = useTranslation();
  const { activeLoan } = useInputsState();
  const showCashDebt =
    activeLoan !== null && activeLoan.amortisationData && activeLoan.amortisationData[0].data.cashDebt ? true : false;

  let tableHeader = [
    t(`calculator-amortization-year-header`),
    t(`calculator-amortization-grossPayment-header`),
    t(`calculator-amortization-netPayment-header`),
    t(`calculator-amortization-installments-header`),
    t(`calculator-amortization-interest-header`),
    t(`calculator-amortization-adminFee-header`),
    t(`calculator-amortization-bondDept-header`),
  ];

  if (showCashDebt) {
    tableHeader.splice(6, 0, t(`calculator-amortization-cashDept-header`));
  }

  let data: (string | number)[][] = [];

  data.push(tableHeader);

  if (activeLoan !== null) {
    activeLoan?.amortisationData?.forEach((row) => {
      row?.months?.map((month) => {
        const row = [
          dayjs(month.month).format('DD.MM.YYYY'),
          month.data.grossPayment,
          month.data.netPayment,
          month.data.instalment,
          month.data.interest,
          month.data.administrationFeeAmount,
          month.data.bondDebt,
        ];
        if (showCashDebt) {
          row.splice(6, 0, month.data.cashDebt);
        }
        data.push(row);
      });
    });
  }

  return data;
}
