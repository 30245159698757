import { CITY_COMMERCIAL } from '../../config';
import { CalculatorInputs } from '../../types/CalculatorInputs';
import { LoanStateItem } from '../../types/LoanStateItem';

/**
 * This function is responsible for preparing the payload before sending it to the API.
 * @param loan The loan
 * @param inputs The inputs from the user
 */
export function preparePayload(loan: LoanStateItem, inputs: CalculatorInputs): LoanStateItem {
  const { payload, settings } = loan;

  // Set values
  let ltv = (inputs.propertyValue - inputs.ownPayment) / inputs.propertyValue;
  if (inputs.wantedLoanAmount) {
    ltv = 0.5;
  }
  payload.property.type = inputs.propertyType;
  payload.property.value = inputs.propertyValue;

  if (!settings.modifiedNetProceeds) {
    if (ltv > settings.maxLTV * 0.9) {
      payload.basic.wantedAdditionalAmount = 0;
      payload.basic.calculationType = 'Maximum';
    } else {
      payload.basic.wantedAdditionalAmount = inputs.propertyValue - inputs.ownPayment;
      payload.basic.calculationType = 'Proceeds';
    }
  }

  /* used for business calcualtor only */
  if (inputs.propertyCategory && inputs.propertyCategory !== '') {
    payload.property.category = inputs.propertyType;
    payload.property.type = inputs.propertyCategory;
    if (
      inputs.propertyType === CITY_COMMERCIAL &&
      payload.product.maturity.years >= settings?.businessCommercialMaturity
    ) {
      payload.product.maturity.years = settings.businessCommercialMaturity;
    }
  }
  if (inputs.ownerCategory && inputs.ownerCategory !== '') {
    payload.basic.ownerCategory = inputs.ownerCategory;
  }
  if (inputs.wantedLoanAmount) {
    payload.basic.wantedAdditionalAmount = inputs.wantedLoanAmount;
    payload.basic.calculationType = 'Proceeds';
  }

  //used for lending calculator
  if (inputs.remainingDebt) {
    payload.basic.remainingDebt = inputs.remainingDebt;
  }

  return loan;
}
