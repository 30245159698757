import { Text } from '@sitecore-jss/sitecore-jss-react';
import { ConsentLevel, useCookieConsent } from 'db-npm-rdui';
import * as React from 'react';

type CustomInsertHtmlProps = {
  fields: any;
  params: any;
};

const CustomInsertHtml: React.FC<CustomInsertHtmlProps> = ({ fields = {}, params }) => {
  const cookieConsent = useCookieConsent();
  const consentLevels = cookieConsent.getConsentLevels();
  const render = params && params.position;
  const renderScript = render && params.position === 'Footer';

  React.useEffect(() => {
    if (!renderScript) {
      return;
    }
    if (cookieConsent.isAllowed(ConsentLevel.Statistics)) {
      //eslint-disable-next-line no-new-func
      new Function(fields['Script Analytical Cookie Accept']?.value)();
    }
    if (cookieConsent.isAllowed(ConsentLevel.Marketing)) {
      //eslint-disable-next-line no-new-func
      new Function(fields['Script Marketing Cookie Accept']?.value)();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(consentLevels)]);

  if (!render) {
    return null;
  }

  return (
    <>
      <Text field={fields[params.position + ' HTML']} encode={false} editable={false} />
    </>
  );
};

export default CustomInsertHtml;
