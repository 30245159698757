import { useEffect, useState } from 'react';
import { filterDataOnProductType } from '../Utils';

const useFormatVariableLoan = (useFormatVariableLoanProps) => {
  const { inputdata, isBusinessPage, sortOrder } = useFormatVariableLoanProps;
  const [formattedAPIData, setFormattedAPIData] = useState<any>('');

  const formatVariableLoanData = (variableLoanAPIResponse, isBusinessPage, sortOrder) => {
    const sortVariableLoans = (inputsortOrder, dataToSort) => {
      dataToSort.sort((a, b) => {
        return inputsortOrder.indexOf(a.name) - inputsortOrder.indexOf(b.name);
      });
    };
    sortVariableLoans(sortOrder, variableLoanAPIResponse);
    // Cibor products are hidden from non-business customers
    let filteredData = variableLoanAPIResponse;
    if (!isBusinessPage) {
      filteredData = filterDataOnProductType(
        variableLoanAPIResponse,
        'Cibor6_WithInstallment',
        'Cibor6_WithoutInstallment'
      );
    }
    return filteredData;
  };
  useEffect(() => {
    if (inputdata && inputdata.length > 0) {
      setFormattedAPIData(formatVariableLoanData(inputdata, isBusinessPage, sortOrder));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputdata, isBusinessPage]);
  return formattedAPIData;
};

export default useFormatVariableLoan;
