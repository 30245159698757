import { Color, Icons, Tooltip, Typography } from 'db-npm-rdui';
import * as React from 'react';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';
import { FORMATTING_DECIMAL_SEPATOR, FORMATTING_THOUSAND_SEPATOR } from '../../config';
import { TableItemInput } from '../../types/TableItem';

type CalculatorInputFieldProps = {
  tableItem: TableItemInput;
  onChangeValue: (value: any) => void;
  hasError?: any;
};

const shouldComponentUpdate = (prevProps: CalculatorInputFieldProps, nextProps: CalculatorInputFieldProps) => {
  return prevProps.tableItem.value !== nextProps.tableItem.value;
};

export const CalculatorInputField: React.FC<CalculatorInputFieldProps> = React.memo(
  ({ tableItem, onChangeValue, hasError }) => {
    const [value, setValue] = React.useState(tableItem.value);
    const inputFieldRef = React.useRef<HTMLInputElement>(null);
    const [showText, setShowText] = React.useState(
      typeof tableItem.additionalText === 'string' && tableItem.additionalText?.length
    ); // Text below additional lending value

    const onChangeHandler = (e: any) => {
      let newValue = e.floatValue;

      if (newValue === undefined) {
        return;
      }

      // Set value internally in this component for faster re-render
      setValue(newValue);

      // Trigger the change event for recalculation
      onChangeValue(newValue);

      // Remove the text below additional lending value
      setShowText(false);
    };

    const submitHandler = (event: any) => {
      event.preventDefault();
    };

    return (
      <Container>
        <form onSubmit={submitHandler}>
          <Input
            ref={inputFieldRef}
            className={tableItem.hasError && tableItem.hasError.error ? 'error' : ''}
            suffix={tableItem.suffix ? tableItem.suffix : ''}
            type="tel"
            thousandSeparator={FORMATTING_THOUSAND_SEPATOR}
            decimalSeparator={FORMATTING_DECIMAL_SEPATOR}
            decimalScale={2}
            onValueChange={onChangeHandler}
            value={value}
          />
          <IconButton>
            {tableItem.hasError && tableItem.hasError.error ? (
              <span className="tooltip">
                <Tooltip element={<Icons.Action.Alert />} content={tableItem.hasError.errorMsg} />
              </span>
            ) : (
              <span style={{ color: 'red' }}>
                Tilpas <span style={{ marginRight: '0.25rem' }}></span> <Icons.Action.Edit />
              </span>
            )}
          </IconButton>
        </form>
        {showText ? (
          <Typography type="image" style={{ marginTop: '5px', fontSize: '11px' }}>
            {' '}
            {tableItem.additionalText}{' '}
          </Typography>
        ) : (
          ''
        )}
      </Container>
    );
  },
  shouldComponentUpdate
);

const Container = styled.div`
  position: relative;
`;

const Input = styled(NumberFormat)`
  width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: 0;
  border-bottom: 1px dashed transparent;
  outline: none;
  background: transparent;
  transition: border-color 0.5s ease;

  :hover,
  :active,
  :focus {
    border-color: ${Color.grey50};
  }

  &.error {
    color: ${Color.red50};
    border-color: ${Color.red50};
  }
`;

const IconButton = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  pointer-events: none;

  .tooltip {
    pointer-events: auto;
    path {
      fill: ${Color.red50};
    }
  }

  path {
    stroke: ${Color.grey100};
  }
`;
