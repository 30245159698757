import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Box, Button, Col, Container, Icons, mediaQuery, Row, Typography } from 'db-npm-rdui';
import React from 'react';
import styled from 'styled-components';

type Props = {
  fields: any;
};
/* 
  This is a common file use for Tak for din henvendelse
 */
export const ThankYouMessage: React.FC<Props> = ({ fields = {} }) => {
  return (
    <Container>
      <Row justify="center">
        <Col md={8}>
          <Box textAlign="center" marginTop={12} marginBottom={12}>
            <CustomIconWrapper>
              <Icons.Confirm />
            </CustomIconWrapper>

            <Typography type="h3">
              <Text field={fields['Confirmation Title']} />
            </Typography>
            <Typography type="manchetBig" mt={20} mb={30}>
              <Text field={fields['Confirmation Description']} />
            </Typography>
            <ButtonLink href={fields['Confirmation Link']?.value?.href}>
              <Button>{fields['Confirmation Link']?.value?.text}</Button>
            </ButtonLink>
          </Box>
        </Col>
      </Row>
    </Container>
  );
};

export const ThankYouMessage1: React.FC<Props> = ({ fields = {} }) => {
  return (
    <Container>
      <Row justify="center">
        <Col md={8}>
          <Box textAlign="center" marginTop={12} marginBottom={12}>
            <CustomIconWrapper>
              <Icons.Confirm />
            </CustomIconWrapper>

            <Typography type="h3">{fields['Confirmation Title']}</Typography>
            <Typography type="manchetBig" mt={20} mb={30}>
              {fields['Confirmation Description']}
            </Typography>
            <ButtonLink href={fields['Confirmation Link'].href}>
              <Button>{fields['Confirmation Link'].text}</Button>
            </ButtonLink>
          </Box>
        </Col>
      </Row>
    </Container>
  );
};

const CustomIconWrapper = styled.div`
  margin-bottom: 20px;

  ${mediaQuery.md} {
    margin-bottom: 30px;
  }

  svg {
    width: 60px;
    height: 60px;
  }

  circle {
    stroke: #35a478;
  }
  path {
    fill: #35a478;
  }
`;

const ButtonLink = styled.a`
  text-decoration: none;
`;
