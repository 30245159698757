import React, { ReactNode } from 'react';

type Props = {
  condition: boolean;
  Wrapper: React.ElementType;
  props: Object;
  children: ReactNode[] | JSX.Element;
};

export const WithConditionWrapper: React.FC<Props> = ({ condition, Wrapper, props, children }): JSX.Element => (
  <>{condition ? children : <Wrapper {...props}>{children}</Wrapper>}</>
);
