import { Icons, RddkTheme, Tooltip } from 'db-npm-rdui';
import i18next from 'i18next';
import * as React from 'react';
import styled from 'styled-components';
import { handleTranslation } from '../../../../shared/utils/handleTranslation';
import { useInputsState } from '../../state/inputsState';
import { useSettingsState } from '../../state/settingsState';

type CalculatorTableBodyLabelCellProps = {
  fieldKey: string;
};

/**
 * This renders the label cell on the left. Only shown on desktop.
 */
export const CalculatorTableBodyLabelCell: React.FC<CalculatorTableBodyLabelCellProps> = ({ fieldKey }) => {
  const { propertyType, ownPaymentVisible } = useInputsState();
  const { settings } = useSettingsState();
  const showLendingType = settings?.inputs?.showLendingType === true;
  const labelPropertyText = handleTranslation(`calculator-row-${fieldKey}-${propertyType}-help`);
  const businessHelpText = !ownPaymentVisible ? handleTranslation(`calculator-row-business-${fieldKey}-help`) : null;
  const lendingHelpText = showLendingType ? handleTranslation(`calculator-row-${fieldKey}-lending-help`) : null;

  const labelText = i18next.t(`calculator-row-${fieldKey}-label`);
  const businessLabelText = !ownPaymentVisible ? handleTranslation(`calculator-row-business-${fieldKey}-label`) : null;
  const lendingLabelText = showLendingType ? handleTranslation(`calculator-row-${fieldKey}-lending-label`) : null;
  const modifiedLabeltext =
    lendingLabelText !== null ? lendingLabelText : businessLabelText !== null ? businessLabelText : labelText;

  const helpText =
    lendingHelpText !== null
      ? lendingHelpText
      : businessHelpText !== null
      ? businessHelpText
      : labelPropertyText !== null
      ? labelPropertyText
      : i18next.t(`calculator-row-${fieldKey}-help`);
  const checkHelpText =
    helpText ===
    (businessHelpText !== null
      ? `calculator-row-business-${fieldKey}-help`
      : labelPropertyText !== null
      ? `calculator-row-${fieldKey}-${propertyType}-help`
      : `calculator-row-${fieldKey}-help`);

  return (
    <TableLeftLabelColumn>
      <TableLeftLabelColumnLabel data-fieldlabel={fieldKey}>{modifiedLabeltext}</TableLeftLabelColumnLabel>
      {!!helpText && !checkHelpText && <Tooltip element={<Icons.Question />} content={helpText} placement="right" />}
    </TableLeftLabelColumn>
  );
};

const TableLeftLabelColumn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  /* Help icon */
  svg,
  circle {
    stroke: ${RddkTheme.colors.grey90};
  }
`;

const TableLeftLabelColumnLabel = styled.span`
  display: inline-block;
  font-weight: bold;
  margin-right: 8px;
`;
