import { withPlaceholder } from '@sitecore-jss/sitecore-jss-react';
import { PlaceholderProps } from '@sitecore-jss/sitecore-jss-react/types/components/PlaceholderCommon';
import { Container } from 'db-npm-rdui';
import React from 'react';
import { ExpEditorRow, Section } from '../../shared/components';
import { sitecoreBoolToBool } from '../../shared/utils/sitecoreBoolToBool';

export type ColorCardsProps = PlaceholderProps & {
  colorcards: any;
  params?: Params;
};

type Params = {
  CenterCards?: string;
};

const ColorCards: React.FC<ColorCardsProps> = ({ colorcards, params = {} }) => {
  const centerCards = sitecoreBoolToBool(params?.CenterCards);

  return (
    <Section componentName="ColorCards" margin="large">
      <Container>
        <ExpEditorRow style={centerCards ? { justifyContent: 'center' } : undefined}>{colorcards}</ExpEditorRow>
      </Container>
    </Section>
  );
};

export default withPlaceholder('colorcards')(ColorCards);
