import { FileWithMeta, Modal } from 'db-npm-rdui';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '../../shared/components/ErrorMessage/ErrorMessage';
import { ThankYouMessage } from '../../shared/components/ThankYouMessage/ThankYouMessage';
import { submitFormData } from '../../shared/forms/secureUploadHandling';
import { LaunchData, useAdobeTracking } from '../../shared/hooks';
import Page0Choose from './Page0Choose';
import Page1Form from './Page1Form';
import Page2Upload from './Page2Upload';

export type FormCprUploadProps = {
  fields: any;
};

export interface FileList {
  attachId: string;
  file: FileWithMeta;
}

export interface FilesDetails {
  fileList: FileList[];
}

const FormCprUpload: React.FC<FormCprUploadProps> = ({ fields = {} }) => {
  const [page, setPage] = useState(0);
  const [formData, setFormData] = useState({});
  const mailToList = fields['Mail Box To']?.value;
  const mailToListDetails = mailToList.split(',');
  const [selectedMailBox, setSelectedMailBox] = useState(mailToListDetails[0]);
  const [mailSubject, setMailSubject] = useState('');
  const [selectedTopic, setTopic] = useState('');
  const [fileList, setFileList] = React.useState<FilesDetails[]>([]);
  const [attachId, setAttachId] = React.useState('');
  const { t } = useTranslation();
  const [showModal, setShowModal] = React.useState(false);
  const toggleModal = () => setShowModal(!showModal);

  const launchData: LaunchData = {
    form_product: 'Document upload',
    form_type: 'Customer document upload',
    form_name: 'Cpr upload form',
    form_step: 'Initial step',
    form_status: ['loaded', 'view step'],
  };

  const { trackNavigatedToStep, trackFormSubmitSuccess, trackFormSubmitFail } = useAdobeTracking(launchData);

  /**
   * Use this function to go to the next page
   */
  const goToNextPage = () => {
    setPage(page + 1);
    window.scrollTo(0, 0);
  };

  /**
   * Use this function to go to the previous page
   */
  const goToPrevPage = () => {
    setPage(page - 1);
    window.scrollTo(0, 0);
  };

  /**
   * Function for submitting the form.
   * This function is used on page 2 (Upload files).
   */
  const onSubmitFiles = async (files: FileWithMeta[], attachId) => {
    const fileIdData = files.map((a) => a.response.FileId);

    const response = await submitFormData(
      {
        apiUrl: fields['API Endpoint URL Send']?.value,
        egainDsid: fields['Egain DSID']?.value,
        fromMailBox: fields['Mail Box From']?.value,
        toMailBox: selectedMailBox,
      },
      mailSubject,
      formData,
      fileIdData,
      attachId
    );

    if (response) {
      trackFormSubmitSuccess();
      goToNextPage();
    } else {
      trackFormSubmitFail();

      //Error Handling
      setShowModal(true);
    }
  };

  /**
   * Render the page depending on the "page" number
   */
  switch (page) {
    case 0: {
      return (
        <div aria-live="polite">
          <Page0Choose fields={fields} goToNextPage={goToNextPage} page={page} />
        </div>
      );
    }
    case 1: {
      trackNavigatedToStep('Contact information and cpr number');
      return (
        <div aria-live="polite">
          <Page1Form
            fields={fields}
            formData={formData}
            setFormData={setFormData}
            setSelectedMailBox={setSelectedMailBox}
            mailToBoxList={mailToListDetails}
            selectedTopic={selectedTopic}
            setTopic={setTopic}
            setMailSubject={setMailSubject}
            goToNextPage={goToNextPage}
            goToPrevPage={goToPrevPage}
            page={page}
          />
        </div>
      );
    }
    case 2: {
      trackNavigatedToStep('Cpr document upload');
      return (
        <div aria-live="polite">
          <Page2Upload
            fields={fields}
            topic={formData['topic'] || ''}
            subject={mailSubject}
            goToPrevPage={goToPrevPage}
            onSubmitFiles={onSubmitFiles}
            fileList={fileList}
            setFileList={setFileList}
            attachId={attachId}
            setAttachId={setAttachId}
            page={page}
          />
          <Modal open={showModal} handleClose={toggleModal} ariaCloseLabel={'Close modal'} overlayColor="#1f2532">
            <ErrorMessage message={t('form-submit-error')} />
          </Modal>
        </div>
      );
    }
    case 3: {
      return (
        <div aria-live="polite">
          <ThankYouMessage fields={fields} aria-live="polite" />
        </div>
      );
    }
  }

  return null;
};

export default FormCprUpload;
