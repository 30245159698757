import cloneDeep from 'lodash/cloneDeep';
import { mapAmortisationData } from '../../helpers/mapAmortisationData';
import { LoanTypeCalculatorFunction } from '../../types/LoanTypeCalculatorFunction';
import { preparePayload } from './payload-preparer';
import { updatePayload } from './payload-updater';
import { requestApi } from './request';
import { mapResponse } from './response-mapper';
import { convertToTableItems } from './table-converter';

export const flexlaank: LoanTypeCalculatorFunction = async (loan, inputs, apiUrl) => {
  let newLoan = cloneDeep(loan);

  // Prepare payload
  newLoan = preparePayload(loan, inputs);

  // Request the API
  const apiResponse = await requestApi(newLoan, inputs, apiUrl);
  if (!apiResponse) return false;

  // Update payload
  newLoan = updatePayload(newLoan, apiResponse);

  // Map the response to FlexlifeResponse object
  const mappedData = mapResponse(apiResponse, inputs);

  // Convert to array of TableItem
  const tableItems = convertToTableItems(mappedData, loan, inputs);

  // Get amortisation data
  const amortisationData = mapAmortisationData(mappedData.amortisation.cashFlow);

  return {
    ...newLoan,
    apiResponse,
    tableItems,
    stickyValues: {
      monthlyGrossPaymentYearOne: mappedData.monthlyGrossPaymentYearOne,
      monthlyNetPaymentYearOne: mappedData.monthlyNetPaymentYearOne,
    },
    amortisationData,
    mappedData,
  };
};
