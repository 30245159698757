import { LoanStateItem } from '../../types/LoanStateItem';

/**
 * This function is responsible for updating the internal payload we have in the state for the loan.
 * The values coming from the API response should override the values we have internally, so
 * that the next API request has the latest updated and most correct values.
 * @param loan The loan
 * @param apiResponse The API response
 */
export function updatePayload(loan: LoanStateItem, apiResponse: any): LoanStateItem {
  // TODO: if needed, update payload, if not delete this file

  return loan;
}
