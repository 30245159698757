import { API_LOCAL_VALUE } from '../../config';
import { callApi } from '../../helpers/api';
import { sleep } from '../../helpers/sleep';
import { CalculatorInputs } from '../../types/CalculatorInputs';
import { LoanStateItem } from '../../types/LoanStateItem';
import localData from './flexkortResponseExample.json';

export async function requestApi(loan: LoanStateItem, inputs: CalculatorInputs, apiUrl: string): Promise<any> {
  if (apiUrl === API_LOCAL_VALUE) {
    await sleep(300);
    return localData.data;
  }

  const response = await callApi(apiUrl, loan.payload);
  if (response === false) {
    return false;
  }

  // HCM-838: Check if 2nd request for "wantedAdditionalAmount" is necessary
  const loanAmount = inputs.propertyValue - inputs.ownPayment;
  const netProceedsAmount = parseFloat(response?.loanKeyFigures?.netProceedsAmount) || 0;

  if (loan.payload.basic.calculationType === 'Proceeds') {
    return response;
  } else {
    if (loanAmount >= netProceedsAmount) {
      return response;
    } else {
      loan.payload.basic.wantedAdditionalAmount = loanAmount;
      loan.payload.basic.calculationType = 'Proceeds';
      const response = await callApi(apiUrl, loan.payload);
      return response;
    }
  }
}
