import { Container, Effects, Icons, mediaQuery, RddkTheme } from 'db-npm-rdui';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { DESKTOP_LABEL_COLUMN_WIDTH, MAX_LOANS_MOBILE } from '../../config';
import { useInputsState } from '../../state/inputsState';
import { useLoansState } from '../../state/loansState';

export const CalculatorDetailsLinks: React.FC = () => {
  const { t } = useTranslation();
  const { loans } = useLoansState();
  const { setActiveLoan, setShowModal } = useInputsState();
  const displayModal = React.useCallback(
    (val) => {
      setActiveLoan(val);
      setShowModal(true);
    },
    [setShowModal, setActiveLoan]
  );

  return (
    <>
      <Container>
        <Table>
          <tbody>
            <tr>
              <td style={{ width: DESKTOP_LABEL_COLUMN_WIDTH }}></td>
              {loans.map((loan, index) => {
                if (loan.status === 'done') {
                  return (
                    <td key={index}>
                      <ArrowLink onClick={() => displayModal(loan)}>
                        {t('calculator-detail-link-label')} <Icons.ArrowLongRight />
                      </ArrowLink>
                    </td>
                  );
                }
                return <td key={index}></td>;
              })}
            </tr>
          </tbody>
        </Table>
      </Container>
    </>
  );
};

const Table = styled.table`
  width: 100%;
  table-layout: fixed; /* IMPORTANT */

  ${mediaQuery.lgDown} {
    td:first-child,
    td:nth-of-type(1n + ${MAX_LOANS_MOBILE + 2}) {
      display: none;
    }
  }

  td {
    position: relative;
    padding: 12px;
    color: #212121;
    vertical-align: top;
    text-align: left;
  }
`;

const ArrowLink = styled.a`
  display: inline-block;
  position: relative;
  font-size: 14px;
  letter-spacing: 0.3px;
  text-decoration: none;
  white-space: nowrap;
  transition: color 0.5s;
  font-weight: 600;
  cursor: pointer;

  ${Effects.Underline(RddkTheme.colors.grey20)}

  &:hover,
  &:focus {
    ${Effects.UnderlineHover}
  }
  svg {
    width: 24px;
    height: 18px;
    vertical-align: bottom;

    path {
      fill: ${RddkTheme.colors.grey90};
    }
  }

  ${mediaQuery.lgDown} {
    white-space: normal;
  }
`;
