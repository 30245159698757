export const getQueryString = (key: string): string => {
  if (typeof window !== 'undefined') {
    return new URLSearchParams(window.location?.search || '').get(key) || '';
  }
  return '';
};

export const getQueryFloatOrNull = (key: string): number | null => {
  return parseFloat(getQueryString(key)) || null;
};
