type FormConfig = {
  /**
   * The API endpoint URL where the form data is submitted to
   */
  apiUrl: string;
  /**
   * The API key to use when requesting the API
   */
  apiKey: string;
  /**
   * The DSID key for the form in EGAIN system.
   * Please provide the string value WITHOUT brackets "{" and "}"
   */
  egainDsid: string;

  /**
   * The Form name which is shown in dashboard
   */
  webFormName: string;
};

export async function submitFormDataV4(formConfig: FormConfig, formData: any): Promise<any> {
  if (formData && formData.phone && formData.phone.indexOf('+') !== -1) {
    const modifiedPhone = formData.phone.replace('+', '00');
    formData.phone = modifiedPhone;
  }
  if (formData && formData.cpr && formData.cpr.indexOf('-') !== -1) {
    const modifiedCPR = formData.cpr.replace('-', '');
    formData.cpr = modifiedCPR;
  }
  const arrayOfFields = Object.keys(formData).reduce((result, key) => {
    const element = document.getElementsByName(key)[0];
    if (element) {
      return [
        ...result,
        {
          fieldname: element.getAttribute('data-egainfield') || key,
          fieldlabel: element.getAttribute('data-label') || key,
          fieldvalue: formData[key],
          maptoegain: element.getAttribute('data-maptoegain') || 'true',
          includeinmailboxemail: element.getAttribute('data-includeinmailboxemail') || 'true',
        },
      ];
    }
    return result;
  }, [] as any[]);

  const formBody = {
    email: formData.email || '', // should be empty if there is no email field in the form
    name: formData.fullname ? formData.fullname : formData.fname ? formData.fname : '',
    firstname: formData.fname ? formData.fname : formData.fullname ? formData.fullname : '',
    dsid: `{${formConfig.egainDsid}}`,
    mailboxId: formData.mailboxId ? formData.mailboxId : '',
    language: 'da',
    version: 'v4',
    IncludePageUrlInMail: 'true',
    emailsubject: formData.emailsubject,
    webformname: formConfig.webFormName,
    formfieldsdata: {
      formfields: arrayOfFields,
    },
  };

  let formattedData = new FormData();
  formattedData.append('FormPostData', JSON.stringify(formBody));
  const response = await fetch(formConfig.apiUrl, {
    method: 'POST',
    headers: {
      Accept: 'application/json',

      'Access-Control-Allow-Origin': '*',
      sc_apikey: formConfig.apiKey,
    },
    mode: 'cors',
    body: formattedData,
  })
    .then((response) => response.json())
    .then((response) => {
      const json = response;
      if (json.statusCode.toLowerCase() === 'ok') {
        if (json.content.toLowerCase() === 'success') {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    })
    .catch((error) => {
      console.warn('Form submit error:', error);
      return false;
    });
  return response;
}
