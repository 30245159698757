import { Link } from '@sitecore-jss/sitecore-jss-react';
import { LinkAnimatedUnderlineProps, linkAnimatedUnderlineStyle, linkAnimatedUnderlineStyleHover } from 'db-npm-rdui';
import React from 'react';
import styled from 'styled-components';
import { cleanLinkData } from '../../shared/utils/field-validation';

type LinkProps = {
  field?: any;
};

export const LinkAnimatedUnderline: React.FC<LinkAnimatedUnderlineProps & LinkProps> = ({ field }) => {
  field.value = cleanLinkData(field.value);

  return (
    <StyledLinkAnimatedUnderline
      data-tracking-id={field?.value?.trackingcode}
      field={field}
    ></StyledLinkAnimatedUnderline>
  );
};

const StyledLinkAnimatedUnderline = styled(Link)<LinkAnimatedUnderlineProps>`
  ${({ textColor, underlineColorPrimary, underlineColorSecondary }) =>
    linkAnimatedUnderlineStyle(textColor, underlineColorPrimary, underlineColorSecondary)}
  ${linkAnimatedUnderlineStyleHover}
:empty {
    display: none;
  }
`;
