import { CalculatorInputs } from '../../types/CalculatorInputs';

export interface FlexlaanKResponse {
  monthlyGrossPaymentYearOne: number;
  monthlyNetPaymentYearOne: number;
  maturity: number;
  rateType: string;
  refinancingFrequency: number;
  interestOnlyYearsStart: number;
  loanToValuePercentage: number;
  loanProvider: string;
  netProceedsAmount: number;
  propertyValue: number;
  bankloan: number;
  ownpayment: number;
  principalAmount: number;
  interestRatePercentage: number;
  debtorInterestRate: number;
  annualPercentageRate: number;
  totalRepaymentAmount: number;
  numberOfPayments: number;
  weightedBondPrice: number;
  totalAdministrationFee: number;
  taxRate: number;
  amortisation: any;
  firstYearInterestOnlyMonthlyPayment: number;
  firstYearInstalmentMonthlyPaymentGross: number;
  firstYearInstalmentMonthlyPaymentNet;
  firstYearWithOutInstalmentMonthlyPaymentNet;
  totalInterest: number;
  paymentTotalAdministrationFee: number;
  settlementFeeAmount: number;
  loanCaseFee: number;
  registrationHandlingFee: number;
  variableRegistrationFee: number;
  fixedRegistrationFee: number;
  originationFeeAmount: number;
  paymentsPerYear: string;
}

export function mapResponse(data: any, inputs: CalculatorInputs): FlexlaanKResponse {
  const monthlyGrossPaymentYearOne = parseFloat(data.payment?.monthlyGrossPaymentYearOne) || 0; // 1 års månedlig ydelse før skat

  const monthlyNetPaymentYearOne = parseFloat(data.payment?.monthlyNetPaymentYearOne) || 0; // 1 års månedlig ydelse efter skat

  const maturity =
    parseInt(data.maturity?.calculatedTimeToMaturityYears) +
      (parseInt(data.maturity?.calculatedTimeToMaturityMonths) > 0 ? 1 : 0) || 0; //Løbetid i år

  const rateType = 'Variabel rente'; //Rentetype

  const refinancingFrequency = parseInt(data.refinancing?.refinancingFrequency) || 1; //Periode Hvor Renten Bindes i år

  const interestOnlyYearsStart =
    parseInt(data.interestOnly?.interestOnlyYears) + (parseInt(data.interestOnly?.interestOnlyMonths) > 0 ? 1 : 0) || 0; //Afdragsfrihed år

  const loanToValuePercentage =
    parseFloat(data.property?.loanToValuePercentage) > 0 ? parseFloat(data.property?.loanToValuePercentage) : 80 || 0; //LTV

  const loanProvider = 'Realkredit Danmark';

  // TODO: tjek at det her er "Udbetalt kreditbeløb"
  const netProceedsAmount = parseFloat(data.loanKeyFigures?.netProceedsAmount) || 0; // Udbetalt kreditbeløb

  const propertyValue = parseInt(data.property?.propertyValue) || 0;

  const bankloan = Math.ceil(propertyValue - netProceedsAmount - inputs.ownPayment);

  const ownpayment = inputs.ownPayment;

  const paymentsPerYear = data.payment?.paymentsPerYear;

  const principalAmount = parseFloat(data.basic?.principalAmount) || 0; //Hovedstol

  const interestRatePercentage = parseFloat(data.interestRate?.interestRatePercentage) || 0; //Rente

  const debtorInterestRate = parseFloat(data.interestRate?.debtorInterestRate) || 0; //Debitorrente

  const annualPercentageRate = parseFloat(data.loanKeyFigures?.annualPercentageRate) || 0; //ÅOP

  const totalRepaymentAmount = parseFloat(data.payment?.totalRepaymentAmount) || 0; //Tilbagebetaling

  const numberOfPayments = parseInt(data.amortisation?.numberOfPayments) || 0; //Antal ydelser

  const weightedBondPrice = parseFloat(data.bonds?.weightedBondPrice) || 0; //Kurs

  const totalAdministrationFee =
    parseFloat(data.payment?.totalAdministrationFee) +
      parseFloat(data.payment?.totalInterest) +
      parseFloat(data.feeLoanCaseCosts?.costsTotal) || 0; //Omkostninger

  const taxRate = parseFloat(data.customer?.appliedTaxRate) || 0; //Used taxrate

  const amortisation =
    typeof data.amortisation !== 'undefined'
      ? {
          numberOfPayments: data.amortisation.numberOfPayments,
          cashFlow: data.amortisation.cashFlow,
        }
      : []; //Ydelsesforløb (Object)

  //Find first term with installment payment if interest only is chosen
  let firstYearInterestOnlyMonthlyPayment = 0;
  let firstYearInstalmentMonthlyPaymentGross = monthlyGrossPaymentYearOne;

  let firstYearWithOutInstalmentMonthlyPaymentNet = 0;
  let firstYearInstalmentMonthlyPaymentNet = monthlyNetPaymentYearOne;

  if (data.interestOnly?.isInterestOnlyOption && parseInt(data.interestOnly?.interestOnlyYears) > 0) {
    const instalmentTerms = data.amortisation.cashFlow.filter(
      (term) => !isNaN(parseFloat(term.instalment)) && parseFloat(term.instalment) !== 0
    );
    firstYearInterestOnlyMonthlyPayment = monthlyGrossPaymentYearOne;
    firstYearWithOutInstalmentMonthlyPaymentNet = monthlyNetPaymentYearOne;

    let firstYearOfInstalmentPayment = 0;
    let firstYearOfInstalmentPaymentNet = 0;
    for (let x = 0; x < 12; x++) {
      firstYearOfInstalmentPayment = firstYearOfInstalmentPayment + parseFloat(instalmentTerms[x].grossPayment);
      firstYearOfInstalmentPaymentNet = firstYearOfInstalmentPaymentNet + parseFloat(instalmentTerms[x].netPayment);
    }
    firstYearInstalmentMonthlyPaymentGross = firstYearOfInstalmentPayment / 12;
    firstYearInstalmentMonthlyPaymentNet = firstYearOfInstalmentPaymentNet / 12;
  }

  // Values for "total administration fee" tooltip content
  const totalInterest = parseFloat(data.payment?.totalInterest) || 0;
  const paymentTotalAdministrationFee = parseFloat(data.payment?.totalAdministrationFee) || 0;
  const settlementFeeAmount = parseFloat(data.feeLoanCaseCosts?.settlementFeeAmount) || 0;
  const loanCaseFee = parseFloat(data.feeLoanCaseCosts?.loanCaseFee) || 0;
  const registrationHandlingFee = parseFloat(data.feeLoanCaseCosts?.registrationHandlingFee) || 0;
  const variableRegistrationFee = parseFloat(data.feeLoanCaseCosts?.variableRegistrationFee) || 0;
  const fixedRegistrationFee = parseFloat(data.feeLoanCaseCosts?.fixedRegistrationFee) || 0;
  const originationFeeAmount = parseFloat(data.feeLoanCaseCosts?.originationFeeAmount) || 0;

  return {
    monthlyGrossPaymentYearOne,
    monthlyNetPaymentYearOne,
    maturity,
    rateType,
    refinancingFrequency,
    interestOnlyYearsStart,
    loanToValuePercentage,
    loanProvider,
    netProceedsAmount,
    propertyValue,
    bankloan,
    ownpayment,
    principalAmount,
    interestRatePercentage,
    debtorInterestRate,
    annualPercentageRate,
    totalRepaymentAmount,
    numberOfPayments,
    weightedBondPrice,
    totalAdministrationFee,
    taxRate,
    amortisation,
    firstYearInstalmentMonthlyPaymentGross,
    firstYearInterestOnlyMonthlyPayment,
    firstYearInstalmentMonthlyPaymentNet,
    firstYearWithOutInstalmentMonthlyPaymentNet,
    totalInterest,
    paymentTotalAdministrationFee,
    settlementFeeAmount,
    loanCaseFee,
    registrationHandlingFee,
    variableRegistrationFee,
    fixedRegistrationFee,
    originationFeeAmount,
    paymentsPerYear,
  };
}
