import { ConsentLevel, SearchResultType, useCookieConsent } from 'db-npm-rdui';
import { useCallback, useLayoutEffect, useRef } from 'react';
import { LaunchData } from './launchDataType';

type FormikHandlerFunction = (e: React.ChangeEvent<any>) => void;
type TrackingHandlerFunction = (e: React.ChangeEvent<any>, originalHandler: FormikHandlerFunction) => void;
type AdobleTrackingFunction = (data: string, customEvent?: any) => void;
type SetLaunchDataFunction = (data: LaunchData) => void;
type GetLaunchDataFunction = (key: keyof LaunchData) => LaunchData[keyof LaunchData];
type CleanLaunchDataFunction = (keyNames: string[]) => void;
type TrackNavigatedToStep = (stepName: string) => void;
type UpdatePageName = (tabName: string) => void;
type TrackCalculatorSuccess = (data?: LaunchData) => void;
type TrackCalculatorFailed = (service_msg?: string) => void;
type TrackCalculatorReCalculation = (data?: LaunchData) => void;
type TrackCalculatorCustomEvent = (data?) => void;
type TrackSearchPerformed = (
  search_term: LaunchData['search_term'],
  search_results: LaunchData['search_results']
) => void;
type TrackSearchResultClick = (result: SearchResultType) => void;
type TrackSearchLoadMoreResults = (pageTitle: string, pageNumber: number | string) => void;
type TrackSearchOpened = (pageTitle: string) => void;

/**
 * @param {LaunchData} launchData Optinal object containing data to be tracked when tracking function is called.
 */
export function useAdobeTracking(
  launchData?: LaunchData
): {
  track: AdobleTrackingFunction;
  setLaunchData: SetLaunchDataFunction;
  getLaunchData: GetLaunchDataFunction;
  cleanLaunchData: CleanLaunchDataFunction;
  trackFormSubmitSuccess: Function;
  trackFormSubmitFail: Function;
  trackNavigatedToStep: TrackNavigatedToStep;
  updatePageNameFromTab: UpdatePageName;
  onFormInteraction: TrackingHandlerFunction;
  trackCalculatorInteraction: Function;
  trackCalculatorSuccess: TrackCalculatorSuccess;
  trackCalculatorReCalculation: TrackCalculatorReCalculation;
  trackCalculatorFailed: TrackCalculatorFailed;
  trackCalculatorCustomEvent: TrackCalculatorCustomEvent;
  trackSearchOpened: TrackSearchOpened;
  trackSearchPerformed: TrackSearchPerformed;
  trackSearchLoadMoreResults: TrackSearchLoadMoreResults;
  trackSearchResultClick: TrackSearchResultClick;
} {
  enum TrackingTypes {
    FORM_TRACKING_TYPE = 'form interaction',
    SEARCH_TRACKING_TYPE = 'internal_search',
    PAGE_VIEW_TRACKING_TYPE = 'page_view',
  }

  const changeEventFired = useRef(false);
  const initiated = useRef(false);
  const cookieConsent = useCookieConsent();

  const track: AdobleTrackingFunction = useCallback(
    (data, customEvent) => {
      if (!cookieConsent.isAllowed(ConsentLevel.Statistics)) return;
      const w = window as any;
      if (w && w._satellite && w._satellite.track) {
        if (!customEvent) w._satellite.track(data);
        else w._satellite.track(data, customEvent);
      }
    },
    [cookieConsent]
  );

  const setLaunchData: SetLaunchDataFunction = useCallback((data) => {
    const w = window as any;
    if (w && w.launch_data) {
      w.launch_data = { ...w.launch_data, ...data };
    } else {
      console.warn('error setting launch data: launch_data is not present');
    }
  }, []);

  const getLaunchData: GetLaunchDataFunction = useCallback((key) => {
    const w = window as any;
    if (w && w.launch_data) {
      return w.launch_data[key];
    }
  }, []);

  const cleanLaunchData: CleanLaunchDataFunction = useCallback((keyNames) => {
    const w = window as any;
    if (w && w.launch_data) {
      Object.keys(w.launch_data).forEach((k) => {
        keyNames.forEach((keyName) => {
          if (k.search(keyName) >= 0) delete w.launch_data[k];
        });
      });
    }
  }, []);

  // Form tracking functions
  const onFormInteraction: TrackingHandlerFunction = (e, originalHandler) => {
    if (!changeEventFired.current) {
      changeEventFired.current = true;
      const formStatus = 'initiated';
      setLaunchData({ form_status: [formStatus], form_step: formStatus });
      track(TrackingTypes.FORM_TRACKING_TYPE);
    }
    originalHandler(e);
  };

  const trackFormSubmitSuccess = (): void => {
    setLaunchData({ form_status: ['completed', 'view step'] });
    track(TrackingTypes.FORM_TRACKING_TYPE);
  };

  const trackFormSubmitFail = (): void => {
    setLaunchData({ form_status: 'failed' });
    track(TrackingTypes.FORM_TRACKING_TYPE);
  };

  const trackNavigatedToStep: TrackNavigatedToStep = (stepName) => {
    setLaunchData({ form_step: stepName, form_status: ['view step'] });
    track(TrackingTypes.FORM_TRACKING_TYPE);
  };

  //Kurser Renter tracking function

  // updating pagename on clicking on tab
  const updatePageNameFromTab: UpdatePageName = (tabName) => {
    setLaunchData({ page_name: tabName });
    track('page_view');
  };

  // Calculator tracking functions

  // Only send interaction tracking event once
  const trackCalculatorInteraction = useCallback(() => {
    if (getLaunchData('calculator_status') === 'loaded') {
      setLaunchData({ calculator_status: 'initiated' });
      track('calculator interaction');
    }
  }, [getLaunchData, setLaunchData, track]);

  const trackCalculatorCustomEvent = useCallback(
    (data) => {
      track('custom event', data);
    },
    [track]
  );

  const trackCalculatorSuccess = useCallback(
    (data) => {
      setLaunchData({ calculator_status: 'completed' });
      if (typeof data !== 'undefined') setLaunchData(data);
      track('calculator interaction');
    },
    [setLaunchData, track]
  );

  const trackCalculatorReCalculation = useCallback(
    (data) => {
      setLaunchData({ calculator_status: 're-calculated' });
      if (typeof data !== 'undefined') setLaunchData(data);
      track('calculator interaction');
    },
    [setLaunchData, track]
  );

  const trackCalculatorFailed = useCallback(
    (service_msg) => {
      if (!service_msg) service_msg = 'Unknown error';
      setLaunchData({ calculator_status: 'failed', service_msg });
      track('calculator interaction');
    },
    [setLaunchData, track]
  );

  // Search tracking functions
  const trackSearchOpened: TrackSearchOpened = (pageTitle) => {
    cleanLaunchData(['calculator_']);
    setLaunchData({
      page_name: `Search | Realkredit Danmark | ${pageTitle}`,
      page_type: 'search page',
      search_area: 'RD',
      search_type: 'google',
    });
    track(TrackingTypes.PAGE_VIEW_TRACKING_TYPE);
  };

  const trackSearchPerformed: TrackSearchPerformed = (search_term, search_results) => {
    cleanLaunchData(['calculator_']);
    setLaunchData({ search_area: 'RD', search_type: 'google', search_term, search_results });
    track(TrackingTypes.SEARCH_TRACKING_TYPE);
  };

  const trackSearchLoadMoreResults: TrackSearchLoadMoreResults = (pageTitle, pageNumber) => {
    cleanLaunchData(['calculator_', 'search_term']);
    setLaunchData({
      page_name: `Search | Realkredit Danmark | ${pageTitle} - page ${pageNumber}`,
      page_type: 'search page',
      search_area: 'RD',
      search_type: 'google',
    });
    track(TrackingTypes.SEARCH_TRACKING_TYPE);
  };

  const trackSearchResultClick: TrackSearchResultClick = (result) => {
    cleanLaunchData(['calculator_']);
    setLaunchData({ search_result_clicked: result.title });
    track('search result clicked');
  };

  useLayoutEffect(() => {
    if (typeof launchData === 'undefined' || initiated.current === true) return;
    initiated.current = true;
    setLaunchData(launchData);
  }, [launchData, setLaunchData]);
  return {
    track,
    setLaunchData,
    getLaunchData,
    cleanLaunchData,
    trackFormSubmitSuccess,
    trackFormSubmitFail,
    trackNavigatedToStep,
    updatePageNameFromTab,
    onFormInteraction,
    trackCalculatorInteraction,
    trackCalculatorSuccess,
    trackCalculatorFailed,
    trackCalculatorReCalculation,
    trackCalculatorCustomEvent,
    trackSearchOpened,
    trackSearchPerformed,
    trackSearchLoadMoreResults,
    trackSearchResultClick,
  };
}
