import { mediaQuery, Slider } from 'db-npm-rdui';
import * as React from 'react';
import styled from 'styled-components';
import { TableItemSlider } from '../../types/TableItem';

type CalculatorSliderFieldProps = {
  tableItem: TableItemSlider;
  onChangeValue: (value: any) => void;
};

const shouldComponentUpdate = (prevProps: CalculatorSliderFieldProps, nextProps: CalculatorSliderFieldProps) => {
  return prevProps.tableItem.value !== nextProps.tableItem.value;
};

export const CalculatorSliderField: React.FC<CalculatorSliderFieldProps> = React.memo(
  ({ tableItem, onChangeValue }) => {
    const [value, setValue] = React.useState(tableItem.value);

    const onChangeHandler = (val: any) => {
      const newValue = parseFloat(val) || 0;

      // Set value internally in this component for faster re-render
      setValue(newValue);

      // Trigger the change event for recalculation
      onChangeValue(newValue);
    };

    const suffix = tableItem.suffix ? tableItem.suffix : '';

    return (
      <Container>
        <Value>
          {value}
          {suffix}
        </Value>
        <Slider
          onChange={onChangeHandler}
          min={tableItem.min}
          max={tableItem.max}
          value={value}
          selectSuffix={suffix}
          selectWidth="auto"
          breakpoint={992}
        />
      </Container>
    );
  },
  shouldComponentUpdate
);

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  select {
    width: auto;
    padding-right: 20px;
    cursor: pointer;
    font-size: 14px;

    ${mediaQuery.sm} {
      font-size: 16px;
    }
  }

  ${mediaQuery.md} {
    padding-right: 10px;
  }
`;

const Value = styled.div`
  width: 100px;

  ${mediaQuery.lgDown} {
    display: none;
  }
`;
