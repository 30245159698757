import { QUERY_KEY_LOANS } from '../config';
import { getQueryString } from './query';

export const getInitialLoansFromQueryOrSettings = (settings: any): string[] => {
  // Get loan keys from settings
  const loanKeys = Object.keys(settings.loans);

  // Get query parameters
  const queryTypes = getQueryString(QUERY_KEY_LOANS)
    .split(',')
    .filter((item) => item !== '');

  // Get default loans from settings
  const defaultLoans: string[] = Array.isArray(
    settings?.table?.defaultLoans[Object.keys(settings?.table?.defaultLoans)[0]]
  )
    ? settings?.table?.defaultLoans[Object.keys(settings?.table?.defaultLoans)[0]]
    : [];

  // Filter loans by allowed keys in settings
  const allowedLoans = queryTypes.filter((key) => loanKeys.indexOf(key) >= 0);

  // Get final loans
  const finalLoans = allowedLoans.length > 0 ? allowedLoans : defaultLoans;

  return finalLoans;
};
