import { RichText as SitecoreRichText } from '@sitecore-jss/sitecore-jss-react';
import { Color, ErrorMessage, Icons, InputField, Select, Tooltip, Typography } from 'db-npm-rdui';
import { Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FormFooter, FormLayout, FormRow } from '../../shared/components/Form';
import { richTextStyles } from '../RichText/richTextStyles';
import { getSchema } from './form-schema';

const StyledRichText = styled(SitecoreRichText)`
  ${richTextStyles}
`;

type Props = {
  fields: any;
  formData: any;
  goToNextPage: () => void;
  goToPrevPage: () => void;
  setFormData: (data: Object) => void;
  setSelectedMailBox: (data: string) => void;
  selectedTopic: string;
  setTopic: (data: string) => void;
  mailToBoxList: any;
  setMailSubject: (data: string) => void;
  page: number;
  isFromCVR?: boolean;
};

const TOPIC_OPTIONS = [
  {
    label: 'Vælg emne',
    value: '',
  },
  {
    label: 'Legitimation',
    value: 'Legitimation',
  },
  {
    label: 'Udarbejdelse af lånetilbud',
    value: 'Udarbejdelse af lånetilbud',
  },
  {
    label: 'Opfyldelse af forbehold',
    value: 'Opfyldelse af forbehold',
  },
  {
    label: 'Sletning af pantebrev',
    value: 'Sletning af pantebrev',
  },
  {
    label: 'Opsigelse / indfrielse',
    value: 'Opsigelse / indfrielse',
  },
  {
    label: 'Henvendelser til inkassoafdeling',
    value: 'Henvendelser til inkassoafdeling',
  },
  {
    label: 'Andet',
    value: 'Andet',
  },
];

const topicList = [
  'Legitimation',
  'Udarbejdelse af lånetilbud',
  'Opfyldelse af forbehold',
  'Sletning af pantebrev',
  'Opsigelse / indfrielse',
  'Henvendelser til inkassoafdeling',
  'Andet',
];

const Page1Form: React.FC<Props> = ({
  fields = {},
  formData,
  goToNextPage,
  goToPrevPage,
  setFormData,
  setSelectedMailBox,
  mailToBoxList,
  setMailSubject,
  selectedTopic,
  setTopic,
  page,
  isFromCVR,
}) => {
  const { t } = useTranslation();
  const schema = getSchema(t);
  const mailTopicList = mailToBoxList.reduce(function (result, field, index) {
    result[topicList[index]] = field;
    return result;
  }, {});

  const cpr: string = formData?.cpr || '';
  const firstname: string = formData?.firstname || '';
  const lastname: string = formData?.lastname || '';
  const email: string = formData?.email || '';
  const phone: string = formData?.phone || '';
  const casenumber: string = formData?.casenumber || '';
  const adviser: string = formData?.adviser || '';

  return (
    <Formik
      initialValues={{
        topic: selectedTopic,
        cpr,
        firstname,
        lastname,
        email,
        phone,
        casenumber,
        adviser,
      }}
      validationSchema={schema}
      onSubmit={(values) => {
        goToNextPage();
        if (values.casenumber.trim() === '') {
          setMailSubject(values.topic + ' - Intet pantnr - ' + values.firstname + ' ' + values.lastname);
        } else {
          setMailSubject(values.topic + ' - ' + values.casenumber + ' - ' + values.firstname + ' ' + values.lastname);
        }
        setFormData(values);
      }}
    >
      {({ values, errors, touched, handleChange, setFieldValue }) => {
        const setMailbox = (e) => {
          if (e.currentTarget.value) {
            setTopic(e.currentTarget.value);
            setFieldValue('topic', e.currentTarget.value);
            setSelectedMailBox(mailTopicList[e.currentTarget.value]);
          }
        };

        const setPreviousPage = () => {
          goToPrevPage();
          setFormData(values);
        };

        return (
          <Form noValidate>
            <FormLayout
              headline={fields['Headline']?.value}
              description={
                isFromCVR ? (
                  <StyledRichText field={fields['Choose CPR Description']} />
                ) : (
                  fields['Form Description']?.value
                )
              }
            >
              <FormRow>
                <LabelHeader type="label" as="label" htmlFor="topic">
                  {fields['Form Title']?.value}
                </LabelHeader>
                <WhiteBg>
                  <Select
                    name="topic"
                    id="topic"
                    onChange={setMailbox}
                    options={TOPIC_OPTIONS}
                    hasBorder
                    value={values.topic}
                    isBold
                  />
                </WhiteBg>
                {touched.topic && errors.topic && <ErrorDiv>{errors.topic}</ErrorDiv>}
              </FormRow>

              <FormRow>
                <InputField
                  name="cpr"
                  id="cpr"
                  label={t('form-input-cpr-label')}
                  layout="white"
                  variant="numeric"
                  format="######-####"
                  value={values.cpr}
                  onChange={handleChange}
                  data-label={t('form-input-cpr-label')}
                  errorMessage={touched.cpr && errors.cpr ? errors.cpr : ''}
                  required
                />
              </FormRow>

              <FormRow>
                <InputField
                  name="firstname"
                  id="firstname"
                  label={t('form-input-first-name-label')}
                  layout="white"
                  value={values.firstname}
                  onChange={handleChange}
                  data-label={t('form-input-cpr-label')}
                  errorMessage={touched.firstname && errors.firstname ? errors.firstname : ''}
                  required
                />
              </FormRow>

              <FormRow>
                <InputField
                  name="lastname"
                  id="lastname"
                  label={t('form-input-last-name-label')}
                  layout="white"
                  value={values.lastname}
                  onChange={handleChange}
                  data-label={t('form-input-last-name-label')}
                  errorMessage={touched.lastname && errors.lastname ? errors.lastname : ''}
                  required
                />
              </FormRow>

              <FormRow>
                <InputField
                  name="email"
                  id="email"
                  label={t('form-input-email-label')}
                  layout="white"
                  value={values.email}
                  onChange={handleChange}
                  data-label={t('form-input-email-label')}
                  errorMessage={touched.email && errors.email ? errors.email : ''}
                  required
                />
              </FormRow>

              <FormRow>
                <InputField
                  name="phone"
                  id="phone"
                  label={t('form-input-phone-label')}
                  layout="white"
                  value={values.phone}
                  onChange={handleChange}
                  data-label={t('form-input-phone-label')}
                  errorMessage={touched.phone && errors.phone ? errors.phone : ''}
                />
              </FormRow>

              <FieldContainer>
                <InputField
                  name="casenumber"
                  id="casenumber"
                  label={t('form-input-casenumber-label')}
                  layout="white"
                  value={values.casenumber}
                  onChange={handleChange}
                  data-label={t('form-input-casenumber-label')}
                  errorMessage={touched.casenumber && errors.casenumber ? errors.casenumber : ''}
                />
                <TooltipWrapper>
                  <Tooltip
                    element={<Icons.Question />}
                    heading={t('CPR-form-casenumber-tooltip-header')}
                    content={t('CPR-form-casenumber-tooltip-text')}
                  />
                </TooltipWrapper>
              </FieldContainer>

              <FieldContainer>
                <InputField
                  name="adviser"
                  id="adviser"
                  label={t('form-input-adviser-label')}
                  layout="white"
                  value={values.adviser}
                  onChange={handleChange}
                  data-label={t('form-input-casenumber-label')}
                  errorMessage={touched.adviser && errors.adviser ? errors.adviser : ''}
                />
                <TooltipWrapper>
                  <Tooltip
                    element={<Icons.Question />}
                    heading={t('CPR-form-adviser-tooltip-header')}
                    content={t('CPR-form-adviser-tooltip-text')}
                  />
                </TooltipWrapper>
              </FieldContainer>
            </FormLayout>
            <FormFooter
              step1Label={isFromCVR ? t('CVR-footer-step1-title') : t('CPR-footer-step1-title')}
              step2Label={isFromCVR ? t('CVR-footer-step2-title') : t('CPR-footer-step2-title')}
              step3Label={isFromCVR ? t('CVR-footer-step3-title') : t('CPR-footer-step3-title')}
              setPreviousPage={setPreviousPage}
              page={page}
              isFromCVR={isFromCVR}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default Page1Form;

const WhiteBg = styled.div`
  background: #fff;
`;

const FieldContainer = styled(FormRow)`
  position: relative;
`;

const ErrorDiv = styled(ErrorMessage)`
  margin-top: 4px;
  margin-left: 20px;
`;

const TooltipWrapper = styled.span`
  position: absolute;
  top: 20px;
  right: 15px;
  svg {
    width: 16px;
    height: 16px;
  }
  [data-popper-placement^='bottom'] div {
    border-color: transparent transparent ${Color.red40};
  }
`;

const LabelHeader = styled(Typography)`
  display: block;
  margin-bottom: 10px;
`;
