import styled from 'styled-components';

const EmptyOutline = styled.div`
  padding: 10px;
  color: #b0b0b0;
  border: 1px dotted #c0c0c0;
  position: relative;
`;

const MinHeight = styled.div`
  min-height: 45px;
`;

export const EditorWrapper = {
  EmptyOutline,
  MinHeight,
};
