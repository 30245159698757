import { useEffect, useState } from 'react';
import { filterClosedBonds, filterDataOnPrices, groupDrillSort, showClosedBonds } from '../Utils';
import { CashLoanInterestRates, OpenOffer, VariableLoans } from '../types';

import demoData from '../demo-data/getOpenOffers.json';

const useFormatFixedLoan = (useFormatFixedLoanProps) => {
  const { inputdata, isBusinessPage, selectedTab, showAllLoans } = useFormatFixedLoanProps;
  const [formattedAPIData, setFormattedAPIData] = useState<any>('');

  const formatFixedLoanData = (fixedLoansAPIResponse, isBusinessPage, showAllLoans, selectedTab) => {
    let filteredData: (OpenOffer | VariableLoans | CashLoanInterestRates)[] = demoData;

    if (fixedLoansAPIResponse?.length > 0) {
      let dataToProcess: (OpenOffer | CashLoanInterestRates)[] = fixedLoansAPIResponse;
      filteredData = [...dataToProcess];
      //for closed bond tab-> closed loans are shown for fixed bond tab->show all button is processed
      if ((isBusinessPage && selectedTab === 3) || (!isBusinessPage && selectedTab === 2)) {
        let closedBondsdata = showClosedBonds(dataToProcess);
        closedBondsdata.length > 0 ? (filteredData = closedBondsdata) : (filteredData = demoData);
      } else {
        if (!showAllLoans) dataToProcess = filterDataOnPrices(dataToProcess, 90, 99.75);
        filteredData = filterClosedBonds(dataToProcess);
      }
      const sortedData = groupDrillSort(filteredData, [
        { key: 'termToMaturityYears', desc: true },
        { key: 'nominelInterestRate', desc: true },
        { key: 'numberOfTermsWithoutRepayment', desc: false },
      ]);
      filteredData = sortedData;
    }

    return filteredData as OpenOffer[];
  };

  useEffect(() => {
    if (inputdata && inputdata.length > 0) {
      setFormattedAPIData(formatFixedLoanData(inputdata, isBusinessPage, showAllLoans, selectedTab));
    }
  }, [inputdata, selectedTab, isBusinessPage, showAllLoans]);
  return formattedAPIData;
};

export default useFormatFixedLoan;
