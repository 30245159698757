import { Image, Text } from '@sitecore-jss/sitecore-jss-react';
import {
  BottomShadow,
  Box,
  Col,
  Container,
  FifthElement,
  Hidden,
  mediaQuery,
  PopoutBox,
  Row,
  Typography,
} from 'db-npm-rdui';
import * as React from 'react';
import styled from 'styled-components';
import { FieldChecker, LinkAnimatedUnderline, Section } from '../../shared/components';

const Wrapper = styled.section`
  overflow: hidden;
  position: relative;
  padding: 50px 0;
  background: rgb(246, 243, 244);
  background: linear-gradient(0deg, rgba(246, 243, 244, 1) 0%, rgba(252, 249, 250, 1) 100%);

  ${mediaQuery.md} {
    padding: 90px 0;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: -990px;
  left: 60%;
  background: #fff;
  transform: skew(-12deg);
`;

const Features = styled.div`
  margin-top: 20px;
  border-top: 3px solid #f2f2f2;
`;

const FeatureItem = styled.div`
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 3px solid #f2f2f2;
`;

const Icon = styled.div`
  flex: 0 0 auto;
  margin-right: 30px;

  ${mediaQuery.mdDown} {
    display: none;
  }
`;

export type ProductFeatureProps = {
  fields: any;
};

const ProductFeature: React.FC<ProductFeatureProps> = ({ fields = {} }) => {
  return (
    <Section componentName="ProductFeature" margin="large">
      <Wrapper>
        <Hidden xs sm md>
          <Overlay>
            <FifthElement position="top-left" variant="small" skew={false} />
          </Overlay>
        </Hidden>

        <Container>
          <Row>
            <Col lg={6}>
              <FieldChecker type="text" field={fields['Headline']}>
                <Typography type="h5">
                  <Text field={fields['Headline']} />
                </Typography>
              </FieldChecker>

              <FieldChecker type="text" field={fields['Label']}>
                <Typography type="manchetSmall">
                  <Text field={fields['Label']} />
                </Typography>
              </FieldChecker>

              <Features>
                <FeatureItem>
                  <FieldChecker type="image" field={fields['Feature Icon 1']}>
                    <Icon>
                      <Image field={fields['Feature Icon 1']} />
                    </Icon>
                  </FieldChecker>
                  <div>
                    <FieldChecker type="text" field={fields['Feature Title 1']}>
                      <Typography type="h7">
                        <Text field={fields['Feature Title 1']} />
                      </Typography>
                    </FieldChecker>
                    <FieldChecker type="text" field={fields['Feature Description 1']}>
                      <Typography type="manchetSmall">
                        <Text field={fields['Feature Description 1']} />
                      </Typography>
                    </FieldChecker>
                  </div>
                </FeatureItem>

                <FeatureItem>
                  <FieldChecker type="image" field={fields['Feature Icon 2']}>
                    <Icon>
                      <Image field={fields['Feature Icon 2']} />
                    </Icon>
                  </FieldChecker>
                  <div>
                    <FieldChecker type="text" field={fields['Feature Title 2']}>
                      <Typography type="h7">
                        <Text field={fields['Feature Title 2']} />
                      </Typography>
                    </FieldChecker>
                    <FieldChecker type="text" field={fields['Feature Description 2']}>
                      <Typography type="manchetSmall">
                        <Text field={fields['Feature Description 2']} />
                      </Typography>
                    </FieldChecker>
                  </div>
                </FeatureItem>

                <FeatureItem>
                  <FieldChecker type="image" field={fields['Feature Icon 3']}>
                    <Icon>
                      <Image field={fields['Feature Icon 3']} />
                    </Icon>
                  </FieldChecker>
                  <div>
                    <FieldChecker type="text" field={fields['Feature Title 3']}>
                      <Typography type="h7">
                        <Text field={fields['Feature Title 3']} />
                      </Typography>
                    </FieldChecker>
                    <FieldChecker type="text" field={fields['Feature Description 3']}>
                      <Typography type="manchetSmall">
                        <Text field={fields['Feature Description 3']} />
                      </Typography>
                    </FieldChecker>
                  </div>
                </FeatureItem>
              </Features>
            </Col>

            <Col lg={6} xl={5} offset={{ xl: 1 }}>
              <Box marginTop={{ _: 6, lg: 0 }}>
                <BottomShadow>
                  <PopoutBox>
                    <FieldChecker type="text" field={fields['Box Headline']}>
                      <Typography type="h4">
                        <Text field={fields['Box Headline']} />
                      </Typography>
                    </FieldChecker>

                    <FieldChecker type="text" field={fields['Box Text']}>
                      <Typography type="manchetSmall" mt={4} mb={4}>
                        <Text field={fields['Box Text']} />
                      </Typography>
                    </FieldChecker>

                    <FieldChecker type="link" field={fields['Box Link']}>
                      <LinkAnimatedUnderline field={fields['Box Link']} />
                    </FieldChecker>
                  </PopoutBox>
                </BottomShadow>
              </Box>
            </Col>
          </Row>
        </Container>
      </Wrapper>
    </Section>
  );
};

export default ProductFeature;
