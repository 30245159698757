import * as React from 'react';

type MonitoringProps = {
  appKey: string;
  beaconUrl: string;
};

export const Monitoring: React.FC<MonitoringProps> = ({ appKey, beaconUrl }) => {
  if (!appKey || !beaconUrl) {
    return null;
  }

  return (
    <span
      dangerouslySetInnerHTML={{
        __html: `<script charset='UTF-8'>
                    window['adrum-start-time'] = new Date().getTime();
                    (function(config){
                        config.appKey = '{appKey}';
                        config.adrumExtUrlHttp = '/js/adrum/';
                        config.adrumExtUrlHttps = '/js/adrum/';
                        config.beaconUrlHttps = '{beaconUrl}';
                        config.xd = {enable : false};
                    })(window['adrum-config'] || (window['adrum-config'] = {}));
                    document.write(unescape('%3Cscript')
                        + " src='/js/adrum/adrum-4.3.2.0.js' "
                        + " type='text/javascript' charset='UTF-8'"
                        + unescape('%3E%3C/script%3E'));
                </script>`
          .replace('{appKey}', appKey)
          .replace('{beaconUrl}', beaconUrl),
      }}
    ></span>
  );
};
