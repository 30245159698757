import { RichText } from '@sitecore-jss/sitecore-jss-react';
import {
  Box,
  BoxShadow,
  Button,
  Col,
  Color,
  Container,
  Icons,
  mediaQuery,
  RddkTheme,
  Row,
  ScrollToTop,
  TableSelector,
  Tooltip,
} from 'db-npm-rdui';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ContentParser, Section } from '../../shared/components';
import { useAdobeTracking } from '../../shared/hooks/useAdobeTracking';
import { richTextStyles } from '../RichText/richTextStyles';
import demodata from './demo-data/getOpenOffers.json';
import useFormatCashLoan from './hooks/useFormatCashLoan';
import useFormatFixedLoan from './hooks/useFormatFixedLoans';
import useFormatVariableLoan from './hooks/useFormatVariableLoan';
import useLastUpdateTimeStamp from './hooks/useLastUpdateTimeStamp';
import { TableCashLoan } from './TableCashLoan';
import { TableClosedBonds } from './TableClosedBonds';
import { TableFastforrentet } from './TableFastforrentet';
import { TableVariabel } from './TableVariabel';
import { CashLoanInterestRates, OpenOffer, VariableLoans } from './types';
export type RatesTablesProps = {
  fields: any;
};

const RatesTables: React.FC<RatesTablesProps> = ({ fields = {} }) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [tabValue, setTabValue] = React.useState(1);
  const [tracking, setTracking] = React.useState('fixed');
  const [loading, setLoading] = React.useState(true);
  const [showAllLoans, setShowAllLoans] = React.useState<boolean>(false);
  const [isBusinessPage, setIsBusinessPage] = React.useState<boolean>(false);
  const [baseURL, setBaseURL] = React.useState('');
  const loanSettings = JSON.parse(fields['Settings']?.value || '{}');
  const variableLoanSortOrder = loanSettings?.variableLoanSortOrder;
  const { updatePageNameFromTab } = useAdobeTracking();
  //data received from API call
  const [cashLoanAPIResponse, setCashLoanAPIResponse] = React.useState<CashLoanInterestRates[]>();
  const [fixedLoanAPIResponse, setFixedLoanAPIResponse] = React.useState<OpenOffer[]>(demodata);
  const [variableLoanAPIResponse, setVariableLoanAPIResponse] = React.useState<VariableLoans[]>();
  //props for formatting API response
  const formatFixedLoanProps = {
    inputdata: fixedLoanAPIResponse,
    isBusinessPage,
    selectedTab,
    showAllLoans,
  };
  const formatVariableLoanProps = {
    inputdata: variableLoanAPIResponse,
    isBusinessPage,
    sortOrder: variableLoanSortOrder,
  };
  const formatCashLoanProps = {
    inputdata: cashLoanAPIResponse,
    showAllLoans,
  };
  //Format the data received from API call
  const formatFixedLoanData = useFormatFixedLoan(formatFixedLoanProps);
  const formatedVariableLoanData = useFormatVariableLoan(formatVariableLoanProps);
  const formatedCashLoanData = useFormatCashLoan(formatCashLoanProps);
  // Get the "last updated" timestamp from the fixed loan data - hook
  const lastUpdate: any = useLastUpdateTimeStamp(fixedLoanAPIResponse, selectedTab);
  const lastUpdateVariableLoan: any = useLastUpdateTimeStamp(formatedVariableLoanData, selectedTab);
  const lastUpdateCashLoan: any = useLastUpdateTimeStamp(formatedCashLoanData, selectedTab);

  React.useEffect(() => {
    // Check origin of the call and Fetch data on page load
    setIsBusinessPage(window.location.pathname.includes('erhverv'));
    let hostname = window.location.hostname;
    let hosturl = hostname === 'localhost' ? 'syst-www.rd.dk' : hostname;
    setBaseURL(hosturl);
    const apiUrl = `https://${hosturl}${fields['Fixed Rate - API Endpoint URL']?.value}`;
    if (fields['Fixed Rate - API Endpoint URL']?.value !== '') {
      const fetch = async () => {
        let apiResponse: any = await fetchData(apiUrl);
        if (apiResponse.success) {
          setFixedLoanAPIResponse(apiResponse.data);
        } else {
          console.log(`error in calling ${apiUrl}, error is ${apiResponse.error}`);
        }
      };
      fetch();
      setLoading(false);
    } else {
      console.warn('RatesTables > Fetch Fixed Loan API Endpoint URL not defined');
    }
  }, [fields, setTracking]);

  // Update Adobe analytics for Fixed loans
  const setTrackingState = () => {
    setSelectedTab(0);
    setTracking('fixed');
  };

  //Common method to call APIs
  const fetchData = async (apiurl) => {
    let response;
    try {
      response = await fetch(apiurl, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      });
      if (!response.ok) {
        return {
          success: false,
          error: await response,
        };
      }
      return {
        success: true,
        data: await response.json(),
      };
    } catch (error) {
      console.log(`error in calling ${apiurl}. Error is ${error}`);
    }
  };

  // Get Variable Loans data and Update Adobe analytics for Variable loans table
  const getVariableLoans = async () => {
    if (!formatedVariableLoanData || formatedVariableLoanData.length <= 0) {
      setLoading(true);
      const apiUrl = `https://${baseURL}${fields['Variable Rate - API Endpoint URL']?.value}`;
      if (fields['Variable Rate - API Endpoint URL']?.value !== '') {
        let apiResponse: any = await fetchData(apiUrl);
        if (apiResponse.success) {
          setVariableLoanAPIResponse(apiResponse.data);
        } else {
          console.log(`error in calling ${apiUrl}, error is ${apiResponse.error}`);
        }
      } else {
        console.warn('RatesTables > Fetch Variable Loan API Endpoint URL not defined');
      }
    }
    setLoading(false);
    setSelectedTab(1);
    setTracking('variable');
  };
  //Get Cash Loans data and Update Adobe analytics for for cash loans table
  const getCashLoansData = async () => {
    if (!formatedCashLoanData || formatedCashLoanData.length <= 0) {
      setLoading(true);
      const apiUrl = `https://${baseURL}${fields['Cashloan Fixed Interest Rate - API Endpoint URL']?.value}`;
      if (fields['Cashloan Fixed Interest Rate - API Endpoint URL']?.value !== '') {
        let apiResponse: any = await fetchData(apiUrl);
        if (apiResponse.success) {
          setCashLoanAPIResponse(apiResponse.data);
        } else {
          console.log(`error in calling ${apiUrl}, error is ${apiResponse.error}`);
        }
      } else {
        console.warn('RatesTables > Fetch Cashloan Fixed Interest Rate API Endpoint URL not defined');
      }
    }
    setLoading(false);
    setSelectedTab(2);
    setTracking('cashloan');
  };

  //Handle Click on Isin Code to show Details on ISIN on Nasdaq page
  const openNasdaqSite = async (isinCode) => {
    setLoading(true);
    let newWindow = window.open('about:blank', '_blank');
    const apiUrl = `https://${baseURL}${fields['GetIsinShortCode - API Endpoint URL']?.value}?isinCode=${isinCode}`;
    if (fields['GetIsinShortCode - API Endpoint URL']?.value !== '') {
      let apiResponse: any = await fetchData(apiUrl);
      if (apiResponse.success) {
        const isinObject = apiResponse.data;
        const shortName = encodeURIComponent(isinObject?.isinShortCode).replace(/%2C/, '-').toLowerCase();
        const fondsKodeShortName = encodeURIComponent(isinObject?.isinShortCode).replace(/%2C/, '_');
        setLoading(false);
        newWindow?.location.assign(
          `https://www.nasdaq.com/da/european-market-activity/mortgage-bonds/${shortName}?id=XCSE${fondsKodeShortName}`
        );
      } else {
        console.log(`error in calling ${apiUrl}, error is ${apiResponse.error}`);
      }
      setLoading(false);
    } else {
      console.warn('RatesTables > Fetch IsinShortCode Endpoint URL not defined');
    }
  };

  //Set tracking State for Closed Bonds
  const setTrackingStateForClosedBonds = () => {
    isBusinessPage ? setSelectedTab(3) : setSelectedTab(2);
    setTracking('closedBonds');
  };

  //set Adobe analytics details for all tabs
  React.useEffect(() => {
    if (tabValue !== selectedTab) {
      if (tracking === 'fixed') {
        updatePageNameFromTab('Kurser og renter > ' + t('kurser-tabs-fixed'));
      }
      if (tracking === 'variable') {
        updatePageNameFromTab('Kurser og renter > ' + t('kurser-tabs-variable'));
      }
      if (tracking === 'cashLoans') {
        updatePageNameFromTab('Kurser og renter > ' + t('kurser-tabs-cashloan'));
      }
      if (tracking === 'closedBonds') {
        updatePageNameFromTab('Kurser og renter > ' + t('kurser-tabs-closedBonds'));
      }
      setTabValue(selectedTab);
    }
  }, [tracking, tabValue, selectedTab, t, updatePageNameFromTab, setTabValue]);

  //Toggle the data-to show all bonds or show only default bonds
  const handleOnClick = (e) => {
    e.preventDefault();
    setLoading(true);
    setTimeout(() => {
      setShowAllLoans((value) => !value);
      setLoading(false);
    }, 750);
  };

  // If the state is "loading=true" then display loading UI
  if (loading) {
    return (
      <Section componentName="RatesTables" margin="large">
        <LoadingWrapper>
          <Icons.Spinner />
        </LoadingWrapper>
      </Section>
    );
  }

  return (
    <Section componentName="RatesTables" margin="large">
      <Container>
        <Row>
          <Col>
            <Box marginBottom={3}>
              <TableSelector selectedIndex={selectedTab} size="small">
                <div onClick={() => setTrackingState()}>{t('kurser-tabs-fixed')}</div>
                <div onClick={() => getVariableLoans()}>{t('kurser-tabs-variable')}</div>
                {isBusinessPage && <div onClick={() => getCashLoansData()}>{t('kurser-tabs-cashloan')}</div>}
                <div onClick={() => setTrackingStateForClosedBonds()}>{t('kurser-tabs-closedBonds')}</div>
              </TableSelector>
            </Box>
          </Col>
        </Row>
        {/* If FixedRate Table is selected, show extra button to select all bonds
        If other tables are selected, hide the button and align lastupdated wrapper with text above table */}
        {selectedTab === 0 || (isBusinessPage && selectedTab === 2) ? (
          <>
            <Row justify="between">
              <Col md={6}>
                <Description>
                  {selectedTab === 0 && fields['Fixed Rate - Text Above Table']?.value}
                  {isBusinessPage
                    ? selectedTab === 2 && fields['Cashloan Fixed Interest Rate - Text Above Table']?.value
                    : selectedTab === 2 && fields['Redemption Rate - Text Above Table']?.value}
                </Description>
              </Col>
            </Row>
            <ButtonWrapper>
              <ShowAllWrapper variant="secondary" onClick={handleOnClick}>
                <IconWrapper>{showAllLoans ? <Icons.Action.Remove /> : <Icons.Action.Add />}</IconWrapper>
                <TextWrapper>{showAllLoans ? 'SE FÆRRE LÅN' : 'SE FLERE LÅN'}</TextWrapper>
              </ShowAllWrapper>
              <Col xs="content">
                {(lastUpdate !== null || lastUpdateCashLoan !== null) && (
                  <LastUpdateWrapper>
                    <LastUpdateBox>
                      {t('kurser-lastUpdated-label')}{' '}
                      {selectedTab === 0 || (!isBusinessPage && selectedTab === 2)
                        ? lastUpdate?.replace(/-/g, '.')
                        : lastUpdateCashLoan?.replace(/-/g, '.')}
                      {}
                    </LastUpdateBox>
                    <TooltipWrapper>
                      <Tooltip
                        element={<Icons.Action.Question />}
                        heading={t('kurser-lastupdated-tooltip-header')}
                        content={t('kurser-lastupdated-tooltip-text')}
                      />
                    </TooltipWrapper>
                  </LastUpdateWrapper>
                )}
              </Col>
            </ButtonWrapper>
          </>
        ) : (
          <>
            <Row justify="between">
              <Col md={6}>
                <Description>
                  {selectedTab === 1 && fields['Variable Rate - Text Above Table']?.value}
                  {selectedTab === 2 && fields['Redemption Rate - Text Above Table']?.value}
                  {isBusinessPage && selectedTab === 3 && fields['Redemption Rate - Text Above Table']?.value}
                </Description>
              </Col>
              <Col xs="content">
                {(lastUpdate !== null || lastUpdateVariableLoan !== null) && (
                  <LastUpdateWrapper>
                    <LastUpdateBox>
                      {t('kurser-lastUpdated-label')}{' '}
                      {selectedTab === 1 ? lastUpdateVariableLoan?.replace(/-/g, '.') : lastUpdate?.replace(/-/g, '.')}
                      {}
                    </LastUpdateBox>
                    <TooltipWrapper>
                      <Tooltip
                        element={<Icons.Action.Question />}
                        heading={t('kurser-lastupdated-tooltip-header')}
                        content={t('kurser-lastupdated-tooltip-text')}
                      />
                    </TooltipWrapper>
                  </LastUpdateWrapper>
                )}
              </Col>
            </Row>
          </>
        )}
        <Row>
          <Col>
            {selectedTab === 0 && (
              <TableFastforrentet
                key={formatFixedLoanData + ''}
                data={formatFixedLoanData}
                handleClick={openNasdaqSite}
              />
            )}
            {selectedTab === 1 && <TableVariabel data={formatedVariableLoanData} />}
            {isBusinessPage
              ? selectedTab === 2 && (
                  <TableCashLoan key={showAllLoans + ''} data={formatedCashLoanData} handleClick={openNasdaqSite} />
                )
              : selectedTab === 2 && (
                  <TableClosedBonds
                    key={formatFixedLoanData + ''}
                    data={formatFixedLoanData}
                    handleClick={openNasdaqSite}
                  />
                )}
            {selectedTab === 3 && (
              <TableClosedBonds
                key={formatFixedLoanData + ''}
                data={formatFixedLoanData}
                handleClick={openNasdaqSite}
              />
            )}
          </Col>
        </Row>

        <Row>
          <Col>
            <Box marginTop={3}>
              {selectedTab === 0 && <TextBelowTable field={fields['Fixed Rate - Text Below Table']} />}
              {selectedTab === 1 && <TextBelowTable field={fields['Variable Rate - Text Below Table']} />}
              {isBusinessPage
                ? selectedTab === 2 && (
                    <TextBelowTable field={fields['Cashloan Fixed Interest Rate - Text Below Table']} />
                  )
                : selectedTab === 2 && <TextBelowTable field={fields['Redemption Rate - Text Below Table']} />}
              {selectedTab === 3 && <TextBelowTable field={fields['Redemption Rate - Text Below Table']} />}
            </Box>
          </Col>
        </Row>

        <Row justify="center">
          <Col md={8} xl={6}>
            <Box marginTop={10}>
              <ContentParser>
                {selectedTab === 0 && <StyledRichText field={fields['Fixed Rate - Text Section']} />}
                {selectedTab === 1 && <StyledRichText field={fields['Variable Rate - Text Section']} />}
                {isBusinessPage
                  ? selectedTab === 2 && (
                      <StyledRichText field={fields['Cashloan Fixed Interest Rate - Text Section']} />
                    )
                  : selectedTab === 2 && <StyledRichText field={fields['Redemption Rate - Text Section']} />}
                {selectedTab === 3 && <StyledRichText field={fields['Redemption Rate - Text Section']} />}
              </ContentParser>
            </Box>
          </Col>
        </Row>
      </Container>
      <ScrollToTop horizontalPosition="center" threshold={300} />
    </Section>
  );
};

export default RatesTables;

const LoadingWrapper = styled.div`
  text-align: center;
  svg {
    width: 50px;
    height: 50px;
  }
`;

const Description = styled.div`
  font-size: 14px;
  font-style: italic;
  color: ${Color.rdBlackTint2};
  margin-bottom: 15px;
`;

const LastUpdateWrapper = styled.div`
  display: flex;
  margin-bottom: 15px;
  align-items: center;
`;

const LastUpdateBox = styled.div`
  font-size: 13px;
  font-style: italic;
  font-weight: 600;
  color: #35a47a;
  padding: 9px 19px 9px 20px;
  border-radius: 5px;
  border: solid 1px #35a47a;
  background-color: rgba(53, 164, 122, 0.22);
  white-space: nowrap;
`;

const TextBelowTable = styled(RichText)`
  font-size: 13px;
  color: ${RddkTheme.colors.warmGrey};
  line-height: 1.4;
`;

const TooltipWrapper = styled.span`
  margin-left: 8px;
  top: 2px;
  position: relative;
  cursor: help;
  white-space: normal;
  svg {
    width: 24px;
    height: 24px;
  }
`;
const StyledRichText = styled(RichText)`
  ${richTextStyles}
`;
const ButtonWrapper = styled.div`
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  ${mediaQuery.mdDown} {
    flex-direction: column-reverse;
  }
`;

const ShowAllWrapper = styled(Button)`
  font-size: 12px;
  font-weight: 600;
  display: flex;
  justify-content: space-evenly;
  border-radius: 3px;
  box-shadow: ${BoxShadow.large};
  padding: 13px 16px 12px;
  letter-spacing: 1px;
  height: 41px;
  margin-bottom: 8px;
  ${mediaQuery.mdDown} {
    width: 50%;
    justify-content: flex-start;
  }
`;
const IconWrapper = styled.span`
  display: flex;
  justify-content: space-between;
  ${mediaQuery.mdDown} {
    flex-direction: column;
  }
`;
const TextWrapper = styled.span`
  display: flex;
  justify-content: space-between;
  margin-left: 8px;
  ${mediaQuery.mdDown} {
    flex-direction: column;
  }
`;
