import { Image, Text } from '@sitecore-jss/sitecore-jss-react';
import {
  Col,
  Color,
  linkAnimatedUnderlineStyle,
  linkAnimatedUnderlineStyleHover,
  mediaQuery,
  RddkTheme,
  Typography,
} from 'db-npm-rdui';
import * as React from 'react';
import styled from 'styled-components';
import { FieldChecker } from '../../shared/components';

const ColumnLink = styled.a`
  position: relative;
  display: flex;
  flex: 0 1 auto;
  ${mediaQuery.ie11} {
    flex: 0 0 auto;
  }
  flex-direction: row;
  margin: 1px 0;
  padding: 12px 0;
  border-top: 3px solid #faf7f8;
  border-bottom: 3px solid #faf7f8;
  align-items: center;
  text-decoration: none;

  div:first-child > & {
    border-top: 0;
  }
  div:last-child > & {
    border-bottom: 0;
  }

  ${mediaQuery.md} {
    padding: 0 10px;
    flex-direction: column;
    text-align: center;
    border: 0;

    ::before,
    ::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      width: 3px;
      background: #faf7f8;
    }
    ::before {
      left: -10px;
    }
    ::after {
      right: -10px;
    }

    div:first-child > &::before,
    div:last-child > &::after {
      display: none;
    }
  }

  ${mediaQuery.lg} {
    padding: 0 20px;
  }
`;

const Icon = styled.span`
  width: 54px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #faf7f8;
  border-radius: 50%;
  transition: all 0.3s;

  ${mediaQuery.md} {
    width: 64px;
    height: 64px;

    ${ColumnLink}:hover &,
    ${ColumnLink}:focus & {
      transform: translateY(-5px);
    }
  }
`;

const ColumnText = styled.span`
  display: block;
  flex: 1 1 0%;
  text-align: left;
  margin: 0 0 0 12px;

  ${mediaQuery.md} {
    text-align: center;
    margin: 20px 0 0;
  }
`;

const UnderLinedText = styled.span`
  ${linkAnimatedUnderlineStyle()}

  ${ColumnLink}:hover &,
  ${ColumnLink}:focus & {
    ${linkAnimatedUnderlineStyleHover}
  }

  display: none;
  ${mediaQuery.md} {
    display: inline-block;
  }
`;

type HeroFrontpageColumnItemProps = {
  fields: any;
};

const StyledText = styled.div`
  font-family: ${RddkTheme.fonts.sans};
  font-size: 12px;
  line-height: 1.08;
  color: ${Color.rdBlackTint2};
  margin: 3px 0 0;

  ${mediaQuery.md} {
    font-size: 14px;
    line-height: 1.15;
    margin: 0 0 16px;
  }

  ${mediaQuery.ie11} {
    max-width: 60%;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
`;

const HeroFrontpageColumnItem: React.FC<HeroFrontpageColumnItemProps> = ({ fields = {} }) => {
  return (
    <Col md={3}>
      <ColumnLink href={fields['Link']?.value} className="column">
        <FieldChecker type="image" field={fields['Image']}>
          <Icon>
            <Image field={fields['Image']} width="30" height="30" />
          </Icon>
        </FieldChecker>
        <ColumnText>
          <FieldChecker type="text" field={fields['Headline']}>
            <Typography type="h7" color="#000">
              <Text field={fields['Headline']} />
            </Typography>
          </FieldChecker>

          <FieldChecker type="text" field={fields['Text']}>
            <StyledText>
              <Text field={fields['Text']} />
            </StyledText>
          </FieldChecker>

          <FieldChecker type="text" field={fields['Link Text']}>
            <UnderLinedText>
              <Text field={fields['Link Text']} />
            </UnderLinedText>
          </FieldChecker>
        </ColumnText>
      </ColumnLink>
    </Col>
  );
};

export default HeroFrontpageColumnItem;
