import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { Box, Col, Color, Container, mediaQuery, Row, Typography } from 'db-npm-rdui';
import React from 'react';
import styled from 'styled-components';
import { richTextStyles } from '../../../components/RichText/richTextStyles';

type FormLayoutProps = {
  headline?: string;
  description?: any;
  infoDescription?: any;
  formTitle?: string;
  children?: any;
};

const StyledRichText = styled(RichText)`
  ${richTextStyles}
`;

export const FormLayout: React.FC<FormLayoutProps> = ({
  headline,
  description,
  infoDescription,
  formTitle,
  children,
}) => {
  return (
    <Box backgroundColor={Color.grey10} paddingTop={{ _: 6, md: 8, lg: 10 }} paddingBottom={{ _: 6, md: 8, lg: 10 }}>
      <Container>
        <Row>
          <Col md={6}>
            <Typography type="h3">{headline}</Typography>
            <Typography type="manchetSmall" mt={20}>
              {description.value ? <StyledRichText field={description} editable={false} /> : description}
            </Typography>

            {infoDescription && <InfoDescription>{infoDescription}</InfoDescription>}
          </Col>

          <Col md={5} offset={{ md: 1 }}>
            <FormTitle>{formTitle}</FormTitle>
            {children}
          </Col>
        </Row>
      </Container>
    </Box>
  );
};

const FormTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  color: ${Color.rdBlackTint2};
  letter-spacing: 2px;
  margin-top: 30px;

  ${mediaQuery.md} {
    margin-top: 0;
  }
`;

const InfoDescription = styled.div`
  background-color: #ffea93;
  padding: 5px 25px;
  border-radius: 5px;
  margin-top: 20px;
  font-size: 14px;
  color: ${Color.black};

  li {
    margin: 15px;
    font-size: 16px;
  }

  ${mediaQuery.md} {
    padding: 12px 35px 20px;
  }
`;
