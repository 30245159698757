import i18next from 'i18next';

export function ValidateNetProceedsAmountTop(value, propertyValue, fieldKey) {
  if (value < 100000 || value > 1000000000) {
    return {
      error: true,
      errorMsg: i18next.t(`calculator-row-${fieldKey}-range-error`),
    };
  }
  if (value > propertyValue) {
    return {
      error: true,
      errorMsg: i18next.t(`calculator-row-${fieldKey}-property-error`),
    };
  }
  return {
    error: false,
    errorMsg: '',
  };
}
