import { Box, Button, ButtonGroup, Col, Color, Container, mediaQuery, Row } from 'db-npm-rdui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type FormFooterProps = {
  trackingChangeHandler?: (e: any) => void;
  setPreviousPage?: () => void;
  page: number;
  fileList?: any;
  step1Label: string;
  step2Label: string;
  step3Label: string;
  isFromCVR?: boolean;
  setSubmit?: () => void;
};

export const FormFooter: React.FC<FormFooterProps> = ({
  trackingChangeHandler,
  setPreviousPage,
  page,
  fileList,
  step1Label,
  step2Label,
  step3Label,
  setSubmit,
}) => {
  const { t } = useTranslation();
  return (
    <BoxWrapper>
      <Box backgroundColor={Color.white} paddingTop={{ _: 4, md: 6, lg: 6 }} paddingBottom={{ _: 4, md: 6, lg: 6 }}>
        <Container>
          <Row>
            <ColText md={9}>
              {' '}
              <Text className={page === 0 ? 'active' : ''}>{step1Label}</Text> /{' '}
              <Text className={page === 1 ? 'active' : ''}>{step2Label}</Text> /{' '}
              <Text className={page === 2 ? 'active' : ''}>{step3Label}</Text>
            </ColText>

            <Col md={3}>
              <ButtonGroupDiv>
                {page !== 0 && (
                  <Button type="button" variant="secondary" onClick={setPreviousPage}>
                    {t('form-button-prev-label')}
                  </Button>
                )}
                {page !== 2 && (
                  <Button type="submit" onClick={trackingChangeHandler} id="submitbtn" data-testid="buttonSubmit">
                    {t('form-button-next-label')}
                  </Button>
                )}
                {page === 2 && (
                  <Button type="button" disabled={fileList.length === 0} onClick={setSubmit}>
                    {t('upload-text-submit-label')}
                  </Button>
                )}
              </ButtonGroupDiv>
            </Col>
          </Row>
        </Container>
      </Box>
    </BoxWrapper>
  );
};

const Text = styled.span`
  &.active {
    color: ${Color.red50};
  }
`;

const ColText = styled(Col)`
  text-align: left;
  display: block;
  padding-top: 13px;

  ${mediaQuery.lg} {
    text-align: center;
  }

  ${mediaQuery.smDown} {
    display: none;
  }
`;

const ButtonGroupDiv = styled(ButtonGroup)`
  float: right;
`;

const BoxWrapper = styled.div`
  position: sticky;
  bottom: 0;
  width: 100%;
  z-index: 1000;

  margin-bottom: -70px;

  ${mediaQuery.md} {
    margin-bottom: -90px;
  }

  ${mediaQuery.lg} {
    margin-bottom: -110px;
  }
`;
