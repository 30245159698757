import dayjs from 'dayjs';
import {
  Cell,
  Color,
  DynamicTable,
  Icons,
  IconWrapper,
  LinkAnimatedUnderline,
  RddkTheme,
  TableData,
  Tooltip,
} from 'db-npm-rdui';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { OpenOffer } from './types';

type TableClosedBondsProps = {
  data: OpenOffer[];
  handleClick: any;
};

export const TableClosedBonds: React.FC<TableClosedBondsProps> = ({ data, handleClick }) => {
  const { t } = useTranslation();
  const formatDate = (date: string) => {
    //extract and format data from "dd-mm-yyyy 00:00:00" to "yyyy.mm.dd"
    let datearray = date.slice(0, 10).split('-');
    let newdate = datearray[2] + '-' + datearray[1] + '-' + datearray[0];
    return dayjs(newdate).format('DD.MM.YYYY');
  };

  const tableData = React.useMemo((): TableData => {
    if (!data || data.length <= 0) {
      return [];
    }
    return [
      // Header row:
      [
        {
          style: {
            width: 250,
          },
          value: t(`kurser-closedbonds-header`),
          alignVertically: 'middle',
          minWidth: 170,
        },
        // This column is here match "show details" column so sorting works (sorting functions uses the index of the header as the indes for the data column)
        {
          style: {
            borderLeft: 0,
            width: 120,
          },
          value: null,
          alignVertically: 'middle',
        },
        {
          style: {
            width: 250,
          },
          value: t(`kurser-closedbonds-columnname-maturity`),
          sortable: true,

          alignVertically: 'middle',
          minWidth: 170,
        },
        {
          style: {
            width: 250,
          },
          value: t(`kurser-closedbonds-columnname-interestRate`),
          sortable: true,

          alignVertically: 'middle',
          minWidth: 170,
        },
        {
          style: {
            width: 210,
          },
          value: t(`kurser-closedbonds-columnname-redemptionPrice`),
          alignVertically: 'middle',
          minWidth: 140,
          sortable: false,
        },
        {
          style: {
            width: 20,
          },
          value: (
            <Tooltip
              element={
                <IconWrapper size="24" color="Action">
                  <Icons.Action.Question />
                </IconWrapper>
              }
              content={t('kurser-closedbonds-tooltip-Kursvalue-text')}
            />
          ),
          alignVertically: 'middle',
        },
        {
          style: {
            width: 250,
          },
          value: t(`kurser-closedbonds-columnname-redemptionDate`),
          alignVertically: 'middle',
          minWidth: 170,
        },
      ],
      // Body rows:
      ...data.map((item): Cell[] => {
        const closedBondPriceDate = item?.redemptionDate && formatDate(item?.redemptionDate);
        return [
          {
            style: {
              width: 250,
            },
            prefix:
              item?.bondname.slice(0, 1) === '-'
                ? item?.numberOfTermsWithoutRepayment
                  ? parseFloat(item.numberOfTermsWithoutRepayment) === 0
                    ? t('kurser-termsWithoutRepaymentEQ0-label') + `  (årgang ${item?.bondname.slice(15, 19)})`
                    : parseFloat(item.numberOfTermsWithoutRepayment) === 40
                    ? t('kurser-termsWithoutRepaymentEQ40-label') + `  (årgang ${item?.bondname.slice(15, 19)})`
                    : t('kurser-termsWithoutRepaymentGT40-label') + `  (årgang ${item?.bondname.slice(15, 19)})`
                  : ''
                : item?.numberOfTermsWithoutRepayment
                ? parseFloat(item.numberOfTermsWithoutRepayment) === 0
                  ? t('kurser-termsWithoutRepaymentEQ0-label') + `  (årgang ${item?.bondname.slice(14, 18)})`
                  : parseFloat(item.numberOfTermsWithoutRepayment) === 40
                  ? t('kurser-termsWithoutRepaymentEQ40-label') + `  (årgang ${item?.bondname.slice(14, 18)})`
                  : t('kurser-termsWithoutRepaymentGT40-label') + `  (årgang ${item?.bondname.slice(14, 18)})`
                : '',

            value: '',
            suffix: item?.isinCode || '',
          },
          {
            style: {
              borderLeft: 0,
              minWidth: 110,
              position: 'relative',
            },
            value: (
              <>
                {null}
                <Link
                  href={void 0}
                  textColor={Color.red40}
                  underlineColorPrimary={Color.red40}
                  onClick={(e) => {
                    handleClick(item?.isinCode);
                  }}
                >
                  {t('kurser-showLoanDetails-label')}
                </Link>
              </>
            ),
            alignVertically: 'top',
          },
          {
            value:
              item?.termToMaturityYears === '' || item?.termToMaturityYears === undefined
                ? '-'
                : parseFloat(item.termToMaturityYears),
            alignVertically: 'middle',
          },
          {
            value:
              item?.nominelInterestRate === '' || item?.nominelInterestRate === undefined
                ? '-'
                : isNaN(Number(item?.nominelInterestRate))
                ? `${item?.nominelInterestRate}%`
                : `${item?.nominelInterestRate.replace('.', ',').slice(0, -3)}%`,
            alignVertically: 'middle',
          },
          {
            value:
              item?.prices?.[0]?.price === '' || item?.prices?.[0]?.price === undefined
                ? '-'
                : String(item?.prices?.[0]?.price).slice(0, -1),
            alignVertically: 'middle',
          },
          {
            style: {
              borderLeft: 0,
              maxWidth: '1px',
            },
            value: null,
          },
          {
            value: `${closedBondPriceDate === '' || closedBondPriceDate === 'undefined' ? '-' : closedBondPriceDate}`,
            alignVertically: 'middle',
          },
        ];
      }),
    ];
  }, [data, t, handleClick]);

  return <DynamicTable striped={false} bordered={true} firstRowIsHead={true} data={tableData} />;
};

const Link = styled(LinkAnimatedUnderline)`
  cursor: pointer;
  position: absolute;
  bottom: 21%;
  right: 19%;
  font-size: 12px;
  color: ${RddkTheme.colors.red40};
`;
