/**
 * Shared function to call the API endpoint that calculates the loans.
 * @param apiUrl The API endpoint URL
 * @param payload The payload to send to the API
 */
export async function callApi(apiUrl: string, payload: any) {
  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      console.warn('Error in callApi: response is NOT ok');
      return false;
    }

    const { data, Errors } = await response.json();

    if (Errors) {
      console.log('Business validation error from calculation api:');
      console.table(Errors);
    }

    if (data) {
      return data;
    }

    return false;
  } catch (error) {
    console.error('Error in LoanCalculator callApi:', error);
    return false;
  }
}
