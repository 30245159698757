import { Button, Col, InputField, Row, Typography } from 'db-npm-rdui';
import { Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FormLayout, FormRow } from '../../shared/components/Form';
import { LaunchData, useAdobeTracking } from '../../shared/hooks';
import { getSchema } from './schema';

export type UnsubscribeProps = {
  fields: any;
  onSubmitData: (formData: any) => Promise<void>;
};

const LabelHeader = styled(Typography)`
  display: block;
  margin-bottom: 10px;
`;

const UnsubscribeForm: React.FC<UnsubscribeProps> = ({ fields = {}, onSubmitData }) => {
  const { t } = useTranslation();
  const schema = getSchema(t);
  const mailBoxId = fields['Mailbox ID']?.value;
  const mailSubject = fields['Mail Subject']?.value;
  const launchData: LaunchData = {
    form_product: 'Unsubscribe form',
    form_type: 'Customer unsubscribe from marketing',
    form_name: 'Unsubscribe form',
    form_step: 'Initial step',
    form_status: ['loaded', 'view step'],
  };
  const { onFormInteraction } = useAdobeTracking(launchData);

  return (
    <FormLayout headline={fields['Headline']?.value} description={fields['Description']?.value}>
      <Formik
        enableReinitialize
        initialValues={{
          fname: '',
          lname: '',
          streetname: '',
          housenumber: '',
          floor: '',
          side: '',
          postnumber: '',
          by: '',
          phone: '',
          mailboxId: mailBoxId,
          emailsubject: mailSubject,
        }}
        validationSchema={schema}
        onSubmit={(values) => {
          onSubmitData(values);
        }}
      >
        {({ values, errors, touched, handleChange }) => {
          const trackingChangeHandler = (e) => {
            onFormInteraction(e, handleChange);
          };

          return (
            <Form noValidate>
              <FormRow>
                <LabelHeader type="label" as="label" htmlFor="topic">
                  {fields['Form Title']?.value}
                </LabelHeader>
              </FormRow>
              <FormRow>
                <InputField
                  name="fname"
                  id="firstname"
                  label={t('form-input-first-name-label')}
                  layout="white"
                  value={values.fname}
                  maxLength={30}
                  onChange={trackingChangeHandler}
                  data-maptoegain="true"
                  data-egainfield="first_name"
                  data-label={t('form-input-first-name-label')}
                  data-includeinmailboxemail="true"
                  errorMessage={touched.fname && errors.fname ? errors.fname : ''}
                  required
                />
              </FormRow>
              <FormRow>
                <InputField
                  name="lname"
                  id="lastname"
                  label={t('form-input-last-name-label')}
                  layout="white"
                  value={values.lname}
                  maxLength={30}
                  onChange={trackingChangeHandler}
                  data-maptoegain="true"
                  data-egainfield="last_name"
                  data-label={t('form-input-last-name-label')}
                  data-includeinmailboxemail="true"
                  errorMessage={touched.lname && errors.lname ? errors.lname : ''}
                  required
                />
              </FormRow>
              <FormRow>
                <InputField
                  name="streetname"
                  id="streetname"
                  label={t('form-input-street-name-label')}
                  layout="white"
                  value={values.streetname}
                  maxLength={50}
                  data-maptoegain="true"
                  data-includeinmailboxemail="true"
                  data-egainfield="misc_one"
                  data-label={t('form-input-street-name-label')}
                  onChange={trackingChangeHandler}
                  errorMessage={touched.streetname && errors.streetname ? errors.streetname : ''}
                  required
                />
              </FormRow>

              <FormRow>
                <InputField
                  name="housenumber"
                  id="housenumber"
                  label={t('form-input-house-number-label')}
                  layout="white"
                  value={values.housenumber}
                  data-maptoegain="true"
                  maxLength={50}
                  data-includeinmailboxemail="true"
                  data-egainfield="misc_two"
                  data-label={t('form-input-house-number-label')}
                  onChange={trackingChangeHandler}
                  errorMessage={touched.housenumber && errors.housenumber ? errors.housenumber : ''}
                  required
                />
              </FormRow>

              <FormRow>
                <InputField
                  name="floor"
                  id="floor"
                  label={t('form-input-floor-label')}
                  layout="white"
                  value={values.floor}
                  maxLength={50}
                  data-maptoegain="true"
                  data-includeinmailboxemail="true"
                  data-egainfield="misc_three"
                  data-label={t('form-input-floor-label')}
                  onChange={trackingChangeHandler}
                  errorMessage={touched.floor && errors.floor ? errors.floor : ''}
                />
              </FormRow>

              <FormRow>
                <InputField
                  name="side"
                  id="side"
                  label={t('form-input-side-label')}
                  layout="white"
                  value={values.side}
                  maxLength={50}
                  data-maptoegain="true"
                  data-includeinmailboxemail="true"
                  data-egainfield="misc_four"
                  data-label={t('form-input-side-label')}
                  onChange={trackingChangeHandler}
                  errorMessage={touched.side && errors.side ? errors.side : ''}
                />
              </FormRow>

              <FormRow>
                <Row>
                  <Col lg={6} sm={12}>
                    <InputField
                      name="postnumber"
                      id="postnumber"
                      label={t('form-input-zip-label')}
                      layout="white"
                      value={values.postnumber}
                      maxLength={50}
                      data-maptoegain="true"
                      data-includeinmailboxemail="true"
                      onChange={trackingChangeHandler}
                      data-egainfield="misc_five"
                      data-label={t('form-input-zip-label')}
                      errorMessage={touched.postnumber && errors.postnumber ? errors.postnumber : ''}
                      required
                    />
                  </Col>
                  <Col lg={6} sm={12}>
                    <InputField
                      name="by"
                      id="by"
                      label={t('form-input-city-label')}
                      layout="white"
                      value={values.by}
                      data-maptoegain="true"
                      data-includeinmailboxemail="true"
                      onChange={trackingChangeHandler}
                      maxLength={50}
                      data-egainfield="misc_six"
                      data-label={t('form-input-city-label')}
                      errorMessage={touched.by && errors.by ? errors.by : ''}
                      required
                    />
                  </Col>
                </Row>
              </FormRow>

              <FormRow>
                <InputField
                  name="phone"
                  id="phone"
                  label={t('form-input-phone-label')}
                  layout="white"
                  value={values.phone}
                  maxLength={20}
                  data-maptoegain="true"
                  data-includeinmailboxemail="true"
                  onChange={trackingChangeHandler}
                  data-egainfield="customer_phone"
                  data-label={t('form-input-phone-label')}
                  errorMessage={touched.phone && errors.phone ? errors.phone : ''}
                  required
                />
              </FormRow>

              <FormRow>
                <Button onClick={trackingChangeHandler} id="submit" type="submit">
                  {t('form-button-send-label')}
                </Button>
              </FormRow>
            </Form>
          );
        }}
      </Formik>
    </FormLayout>
  );
};

export default UnsubscribeForm;
