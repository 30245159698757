import { Text } from '@sitecore-jss/sitecore-jss-react';
import * as React from 'react';
import { EditorWrapper } from '../../shared/components/Editor/EditorWrapper';
import { useIsEditor } from '../../shared/hooks';

export type HtmlRendererProps = {
  fields: any;
};

const HtmlRenderer: React.FC<HtmlRendererProps> = ({ fields = {} }) => {
  const htmlField = fields['HTML'];
  const renderedHtmlField = <Text field={htmlField} encode={false} editable={false} />;

  if (useIsEditor()) {
    const strippedHtml = htmlField.value
      .replace(/<script([\S\s]*?)>([\S\s]*?)<\/script>/gi, '')
      .replace(/<style([\S\s]*?)>([\S\s]*?)<\/style>/gi, '');

    return strippedHtml !== '' ? (
      <EditorWrapper.MinHeight>{renderedHtmlField}</EditorWrapper.MinHeight>
    ) : (
      <EditorWrapper.EmptyOutline>
        HTML Renderer
        {renderedHtmlField}
      </EditorWrapper.EmptyOutline>
    );
  } else {
    return <>{renderedHtmlField}</>;
  }
};

export default HtmlRenderer;
