import dayjs from 'dayjs';
import i18next from 'i18next';
import * as React from 'react';
import { formatAsCurrency } from '../../../../shared/utils/currency';
import { CalculatorTooltipAdministrationFee } from '../../components/tooltip/CalculatorTooltipAdministrationFee';
import { CITY_COMMERCIAL } from '../../config';
import {
  formatPayloadDate,
  getNextAvailableBusinessDay,
  isBusinessDay,
  snapDateToQuarter,
} from '../../helpers/dateHelpers';
import { ValidateNetProceedsAmountTop } from '../../helpers/tableItemValidation';
import { CalculatorInputs } from '../../types/CalculatorInputs';
import { LoanStateItem } from '../../types/LoanStateItem';
import { TableItem } from '../../types/TableItem';
import { FlexlifeResponse } from './response-mapper';

export const convertToTableItems = (
  response: FlexlifeResponse,
  loan: LoanStateItem,
  inputs: CalculatorInputs
): TableItem[] => {
  const items: TableItem[] = [];
  const maturityYears = inputs.propertyType === CITY_COMMERCIAL ? loan.settings?.businessCommercialMaturity : 30;
  const displayType = loan.settings?.displayAsLending === true;
  const propertyDepositDisclaimer = inputs.wantedLoanAmount
    ? i18next.t('calculator-row-business-propertyDepositDisclaimer-value')
    : i18next.t('calculator-row-propertyDepositDisclaimer-value');

  const today = new Date();
  let calculationDate = dayjs(today).add(0, 'day').toDate();

  if (!isBusinessDay(calculationDate)) {
    calculationDate = getNextAvailableBusinessDay(calculationDate);
  }
  const nextTermDate = snapDateToQuarter(calculationDate);

  let thisQuater = dayjs(nextTermDate).subtract(3, 'month').toDate();

  const maxInterestOnlyYears =
    response.loanToValuePercentage >= loan.settings.maxInterestOnlyLTV * 100
      ? response.maturity - 1
      : response.maturity;

  if (loan.settings.minPayment === 0) {
    loan.settings.minPayment = response.monthlyGrossPaymentYearOne;
  }

  const minMonthlyPayment = loan.settings.minPayment === 1 ? 1 : loan.settings.minPayment;

  // Table: Fastforretet 1
  if (response.bankloan > 0) {
    items.push({
      fieldKey: 'bankloan',
      inputType: 'static',
      value: formatAsCurrency(response.bankloan),
      suffix: ' kr.',
    });
  }

  items.push({
    fieldKey: 'ownpayment',
    inputType: 'static',
    value: formatAsCurrency(response.ownpayment),
    suffix: ' kr.',
  });

  if (displayType) {
    items.push({
      fieldKey: 'netProceedsAmountTop',
      inputType: 'input',
      value: response.netProceedsAmount,
      validate: true,
      hasError: {
        error: false,
        errorMsg: '',
      },
      suffix: ' kr.',
      onChange(loan, value) {
        const { payload, settings, tableItems } = loan;

        settings.modifiedNetProceeds = true;

        if (value > response.netProceedsAmount - 1000) {
          payload.basic.calculationType = 'Maximum';
          payload.basic.wantedAdditionalAmount = 0;
        } else {
          payload.basic.wantedAdditionalAmount = parseInt(value);
          payload.basic.calculationType = 'Proceeds';
        }

        if (payload.product.changeOption.desiredDebtAtExpiry >= response.approvedDebtAtExpiry) {
          payload.product.changeOption.desiredDebtAtExpiry = response.approvedDebtAtExpiry;
          payload.product.changeOption.desiredPaymentAmount = 1;
          payload.product.changeOption.desiredInterestOnlyStartDate = null;
        }

        const checkError = ValidateNetProceedsAmountTop(value, inputs.propertyValue, 'netProceedsAmountTop');
        if (checkError && checkError.error) {
          tableItems?.forEach((value) => {
            if (value.fieldKey === 'netProceedsAmountTop') {
              value.hasError = {
                error: true,
                errorMsg: checkError.errorMsg,
              };
            }
          });
        } else {
          tableItems?.forEach((value) => {
            if (value.fieldKey === 'netProceedsAmountTop') {
              value.hasError = {
                error: false,
                errorMsg: '',
              };
            }
          });
        }

        return loan;
      },
    });
  } else {
    items.push({
      fieldKey: 'netProceedsAmountTop',
      inputType: 'static',
      value: formatAsCurrency(response.netProceedsAmount),
      suffix: ' kr.',
    });
  }

  if (response.firstYearInterestOnlyMonthlyPayment !== response.monthlyGrossPaymentYearOne) {
    if (response.interestOnlyYearsEnd === maturityYears) {
      items.push({
        fieldKey: 'firstYearInstalmentMonthlyPaymentGrossTop',
        inputType: 'static',
        value: inputs.wantedLoanAmount
          ? formatAsCurrency(response.firstYearInstalmentMonthlyPaymentGross * 12)
          : formatAsCurrency(response.firstYearInstalmentMonthlyPaymentGross),
        suffix: ' kr.',
      });
    } else {
      items.push({
        fieldKey: 'firstYearInstalmentMonthlyPaymentGrossTop',
        inputType: 'input',
        value: inputs.wantedLoanAmount
          ? response.firstYearInstalmentMonthlyPaymentGross * 12
          : response.firstYearInstalmentMonthlyPaymentGross,
        suffix: ' kr.',
        min: minMonthlyPayment,
        max: 1000000,
        onChange(loan, value) {
          const { payload } = loan;
          if (payload.product.changeOption.desiredDebtAtExpiry < response.approvedDebtAtExpiry) {
            payload.product.changeOption.desiredInterestOnlyStartDate = null;
          } else {
            payload.product.changeOption.desiredDebtAtExpiry = null;
          }

          payload.product.changeOption.desiredPaymentAmount = inputs.wantedLoanAmount
            ? parseFloat((parseInt(value) / parseInt(response.paymentsPerYear)).toFixed(2))
            : value;

          payload.product.changeOption.debtAtExpiryOption = 2;
          payload.product.changeOption.interestOnlyPeriodStartOption = 3;
          payload.product.changeOption.paymentAmountOption = 1;
          return payload;
        },
      });
    }
  }

  if (response.firstYearInterestOnlyMonthlyPayment > 0) {
    items.push({
      fieldKey: 'firstYearInterestOnlyMonthlyPaymentTop',
      inputType: 'static',
      value: inputs.wantedLoanAmount
        ? formatAsCurrency(response.firstYearInterestOnlyMonthlyPayment * 12)
        : formatAsCurrency(response.firstYearInterestOnlyMonthlyPayment),
      suffix: ' kr.',
    });
  }

  //Static value only at this time
  items.push({
    fieldKey: 'maturityTop',
    inputType: 'static',
    value: response.maturity,
    suffix: ' år',
  });

  // Might be 2 of these covering 2 periods of interest only, but only 1 at this time
  // Showing the last interest only period for Flexlife
  items.push({
    fieldKey: 'interestOnlyYearsEnd',
    inputType: 'slider',
    value: response.interestOnlyYearsEnd,
    min: 0,
    max: maxInterestOnlyYears,
    suffix: ' år',
    onChange(loan, value) {
      const { payload } = loan;

      const nextTermDatePlusYears = dayjs(thisQuater)
        .add(maturityYears - value, 'year')
        .toDate();

      let amortEndDate = new Date(response.amortisation.cashFlow[response.amortisation.cashFlow.length - 1].termDate);
      if (maturityYears - (amortEndDate.getFullYear() - thisQuater.getFullYear()) < 0) {
        amortEndDate = dayjs(amortEndDate).subtract(3, 'month').toDate();
      }

      const oldDesiredPaymentAmount =
        payload.product.changeOption.desiredPaymentAmount > 0
          ? payload.product.changeOption.desiredPaymentAmount
          : response.firstYearInstalmentMonthlyPaymentGross;

      payload.product.changeOption.desiredPaymentAmount = null;

      if (value === 0) {
        payload.product.changeOption.desiredInterestOnlyStartDate = formatPayloadDate(amortEndDate);
      } else {
        payload.product.changeOption.desiredInterestOnlyStartDate = formatPayloadDate(nextTermDatePlusYears);
      }

      if (payload.product.changeOption.desiredDebtAtExpiry === response.principalAmount && value < maturityYears) {
        payload.product.changeOption.desiredDebtAtExpiry = response.principalAmount / 2;
      }

      if (payload.product.changeOption.desiredDebtAtExpiry === 0 && value > 0 && value < maturityYears) {
        payload.product.changeOption.desiredDebtAtExpiry = null;
        payload.product.changeOption.desiredPaymentAmount = oldDesiredPaymentAmount;
      }

      if (value === maturityYears) {
        payload.product.changeOption.desiredDebtAtExpiry = response.principalAmount;
        payload.product.changeOption.desiredInterestOnlyStartDate = null;
        payload.product.changeOption.desiredPaymentAmount = 1;
      }

      payload.product.changeOption.debtAtExpiryOption = 2;
      payload.product.changeOption.interestOnlyPeriodStartOption = 3;
      payload.product.changeOption.paymentAmountOption = 1;

      return payload;
    },
  });

  items.push({
    fieldKey: 'rateType',
    inputType: 'dropdown',
    value: response.rateType,
    options: [
      {
        label: 'Fast rente',
        value: 'Fixed',
      },
      {
        label: 'Variabel rente',
        value: 'Variable',
      },
    ],
    onChange(loan, value) {
      const { payload } = loan;

      const newProductKey = value === 'Fixed' ? 'FlexLifeKR' : 'FlexLifeIR';

      payload.product.productKey = newProductKey;

      if (response.loanToValuePercentage >= payload.product.changeOption.approvedDebtAtExpiryPercentage) {
        payload.product.changeOption.desiredInterestOnlyStartDate = null;
        payload.product.changeOption.desiredPaymentAmount = 1;
        payload.basic.wantedAdditionalAmount = 0;
        payload.basic.calculationType = 'Maximum';
        payload.product.changeOption.desiredDebtAtExpiry = response.approvedDebtAtExpiry;
      } else {
        payload.product.changeOption.desiredInterestOnlyStartDate = null;
        payload.product.changeOption.desiredPaymentAmount = 1;
        payload.product.changeOption.desiredDebtAtExpiry = response.approvedDebtAtExpiry;
      }

      return payload;
    },
  });

  if (response.rateType === 'Variable') {
    items.push({
      fieldKey: 'refinancingFrequency',
      inputType: 'slider',
      min: 1,
      max: 10,
      suffix: ' år',
      value: response.refinancingFrequency,
      onChange(loan, value) {
        const { payload } = loan;

        payload.product.refinancingFrequency = value;
        payload.product.changeOption.desiredPaymentAmount = null;

        if (
          payload.product.changeOption.desiredDebtAtExpiry === 0 ||
          response.principalAmount > response.approvedDebtAtExpiry
        ) {
          payload.product.changeOption.desiredPaymentAmount = 1;
          payload.product.changeOption.desiredInterestOnlyStartDate = null;
        }

        return payload;
      },
    });
  }
  if (response.interestOnlyYearsEnd === maturityYears) {
    items.push({
      fieldKey: 'calculatedDebtAtExpiry',
      inputType: 'static',
      value: formatAsCurrency(response.calculatedDebtAtExpiry),
      suffix: ' kr.',
    });
  } else {
    items.push({
      fieldKey: 'calculatedDebtAtExpiry',
      inputType: 'input',
      value: response.calculatedDebtAtExpiry,
      min: 0,
      max: response.approvedDebtAtExpiry,
      suffix: ' kr.',
      onChange(loan, value) {
        const { payload } = loan;

        payload.product.changeOption.desiredDebtAtExpiry = value;
        payload.product.changeOption.desiredPaymentAmount = null;
        payload.basic.calculationType = 'Proceeds';

        if (value < 1) {
          payload.product.changeOption.desiredPaymentAmount = 1;
          payload.product.changeOption.desiredInterestOnlyStartDate = null;
        }

        if (value >= response.approvedDebtAtExpiry) {
          payload.product.changeOption.desiredDebtAtExpiry = response.approvedDebtAtExpiry;
          payload.product.changeOption.desiredPaymentAmount = 1;
          payload.product.changeOption.desiredInterestOnlyStartDate = null;
        }

        return payload;
      },
    });
  }

  items.push({
    fieldKey: 'loanToValuePercentage',
    inputType: 'static',
    value: Math.round(response.loanToValuePercentage),
    suffix: ' %',
  });

  items.push({
    fieldKey: 'taxRate',
    inputType: 'static',
    value: formatAsCurrency(response.taxRate, 2),
    suffix: ' %',
  });

  // Table: Fastforrentet 2
  items.push({
    fieldKey: 'loanProvider',
    inputType: 'static',
    value: response.loanProvider,
  });

  items.push({
    fieldKey: 'netProceedsAmount',
    inputType: 'static',
    value: formatAsCurrency(response.netProceedsAmount),
    suffix: ' kr.',
  });

  items.push({
    fieldKey: 'principalAmount',
    inputType: 'static',
    value: formatAsCurrency(response.principalAmount),
    suffix: ' kr.',
  });

  items.push({
    fieldKey: 'maturityBottom',
    inputType: 'static',
    value: response.maturity,
    suffix: ' år',
  });

  items.push({
    fieldKey: 'monthlyGrossPaymentYearOneBottom',
    inputType: 'static',
    value: inputs.wantedLoanAmount
      ? formatAsCurrency(response.monthlyGrossPaymentYearOne * 12)
      : formatAsCurrency(response.monthlyGrossPaymentYearOne),
    suffix: ' kr.',
  });

  items.push({
    fieldKey: 'numberOfPayments',
    inputType: 'static',
    value: response.numberOfPayments,
  });

  const rateText = response.rateType === 'Fixed' ? 'Fast rente' : 'Variabel rente';

  items.push({
    fieldKey: 'debtorInterestRate',
    inputType: 'static',
    value: `(${rateText}) ${formatAsCurrency(response.debtorInterestRate, 2)}`,
    suffix: ' %',
  });

  items.push({
    fieldKey: 'weightedBondPrice',
    inputType: 'static',
    value: formatAsCurrency(response.weightedBondPrice, 10),
  });

  items.push({
    fieldKey: 'annualPercentageRate',
    inputType: 'static',
    value: formatAsCurrency(response.annualPercentageRate, 2),
    suffix: ' %',
  });

  items.push({
    fieldKey: 'totalAdministrationFee',
    inputType: 'static',
    value: formatAsCurrency(response.totalAdministrationFee),
    suffix: ' kr.',
    tooltip: <CalculatorTooltipAdministrationFee {...response} />,
  });

  items.push({
    fieldKey: 'totalRepaymentAmount',
    inputType: 'static',
    value: formatAsCurrency(response.totalRepaymentAmount),
    suffix: ' kr.',
  });

  items.push({
    fieldKey: 'propertyDepositDisclaimer',
    inputType: 'static',
    value: propertyDepositDisclaimer,
  });

  items.push({
    fieldKey: 'paymentsPerYear',
    inputType: 'dropdown',
    value: response.paymentsPerYear,
    options: [
      {
        label: '4',
        value: '4',
      },
      {
        label: '12',
        value: '12',
      },
    ],
    onChange(loan, value) {
      const { payload } = loan;

      payload.product.paymentsPerYear = parseInt(value);

      if (payload.product.changeOption.desiredDebtAtExpiry > response.principalAmount) {
        payload.product.changeOption.desiredDebtAtExpiry = response.principalAmount;
      }
      payload.product.changeOption.desiredPaymentAmount = null;

      return payload;
    },
  });

  items.push({
    fieldKey: 'monthlyPaymentWithInstallment',
    inputType: 'static',
    value: formatAsCurrency(response.firstYearInstalmentMonthlyPaymentNet),
    suffix: ' kr.',
  });
  if (response.firstYearWithOutInstalmentMonthlyPaymentNet > 0) {
    items.push({
      fieldKey: 'monthlyPaymentWithOutInstallment',
      inputType: 'static',
      value: formatAsCurrency(response.firstYearWithOutInstalmentMonthlyPaymentNet),
      suffix: ' kr.',
    });
  }

  if (response.firstYearWithOutInstalmentMonthlyPaymentNet !== response.monthlyNetPaymentYearOne) {
    items.push({
      fieldKey: 'businessMonthlyPaymentWithInstallment',
      inputType: 'static',
      value: formatAsCurrency(response.firstYearInstalmentMonthlyPaymentNet * 12),
      suffix: ' kr.',
    });
  }

  if (response.firstYearWithOutInstalmentMonthlyPaymentNet > 0) {
    items.push({
      fieldKey: 'businessMonthlyPaymentWithOutInstallment',
      inputType: 'static',
      value: formatAsCurrency(response.firstYearWithOutInstalmentMonthlyPaymentNet * 12),
      suffix: ' kr.',
    });
  }

  return items;
};
