import { useEffect, useState } from 'react';
const useLastUpdateTimeStamp = (APIResponseData, selectedTab) => {
  const [lastModified, setLastModified] = useState(null);
  useEffect(() => {
    if (!APIResponseData || APIResponseData.length <= 0) {
      setLastModified(null);
    }
    const date = APIResponseData[0]?.lastModified;
    if (!date) {
      setLastModified(null);
    }
    setLastModified(date);
  }, [APIResponseData, selectedTab]);
  return lastModified;
};
export default useLastUpdateTimeStamp;
