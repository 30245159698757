import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { NameRegex, PhoneRegex } from '../../shared/regex';

export function getSchema(t: TFunction) {
  return Yup.object().shape({
    topic: Yup.string().required(t('form-select-required-error')),
    fullname: Yup.string().required(t('form-required-error')).matches(NameRegex, t('form-input-name-error')),
    phone: Yup.string()
      .required(t('form-required-error'))
      .matches(PhoneRegex, t('form-input-phone-error'))
      .min(8, t('form-input-phone-error')),
    email: Yup.string().required(t('form-required-error')).email(t('form-input-email-error')),
  });
}
