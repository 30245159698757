import { formatPayloadDate } from './dateHelpers';
import dayjs from 'dayjs';

/** Given a year (YYYY), this will return an index of all bank holidays of that year
 * Index form: { 'YYYY-DD-MM': { name: 'holiday name', date: <Date object>}}
 */
export function getBankHolidays(year: number): Record<string, Object> {
  const getEaster = () => {
    let C = Math.floor(year / 100);
    let N = year - 19 * Math.floor(year / 19);
    let K = Math.floor((C - 17) / 25);
    let I = C - Math.floor(C / 4) - Math.floor((C - K) / 3) + 19 * N + 15;
    I = I - 30 * Math.floor(I / 30);
    I = I - Math.floor(I / 28) * (1 - Math.floor(I / 28) * Math.floor(29 / (I + 1)) * Math.floor((21 - N) / 11));
    let J = year + Math.floor(year / 4) + I + 2 - C + Math.floor(C / 4);
    J = J - 7 * Math.floor(J / 7);
    let L = I - J;
    let M = 3 + Math.floor((L + 40) / 44);
    let D = L + 28 - 31 * Math.floor(M / 4);
    const easterDay = new Date(Date.UTC(year, M - 1, D));
    return easterDay;
  };

  const newYearsEve = new Date(Date.UTC(year, 11, 31));
  const newYearsDay = new Date(Date.UTC(year, 0, 1));
  const xMasEve = new Date(Date.UTC(year, 11, 24));
  const xMasDay = new Date(year, 11, 25);
  const secondXmasDay = new Date(Date.UTC(year, 11, 26));
  const constitutionDay = new Date(Date.UTC(year, 5, 5));
  const easterDay = getEaster();
  const maundryThursday = dayjs(easterDay).subtract(3, 'day').toDate();
  const goodFriday = dayjs(easterDay).subtract(2, 'day').toDate();
  const easterMonday = dayjs(easterDay).add(1, 'day').toDate();
  const greatPrayerDay = dayjs(easterDay).add(26, 'day').toDate();
  const ascensionDay = dayjs(maundryThursday)
    .add(7 * 6, 'day')
    .toDate();
  const ascensionDayPlus1 = dayjs(ascensionDay).add(1, 'day').toDate();
  const whitSunday = dayjs(easterDay)
    .add(7 * 7, 'day')
    .toDate();
  const whitMonday = dayjs(whitSunday).add(1, 'day').toDate();

  const holidays = {
    [formatPayloadDate(newYearsEve)]: { name: 'newYearsEve', date: newYearsEve },
    [formatPayloadDate(newYearsDay)]: { name: 'newYearsDay', date: newYearsDay },
    [formatPayloadDate(xMasEve)]: { name: 'xMasEve', date: xMasEve },
    [formatPayloadDate(xMasDay)]: { name: 'xMasDay', date: xMasDay },
    [formatPayloadDate(secondXmasDay)]: { name: 'secondXmasDay', date: secondXmasDay },
    [formatPayloadDate(constitutionDay)]: { name: 'constitutionDay', date: constitutionDay },
    [formatPayloadDate(easterDay)]: { name: 'easterDay', date: easterDay },
    [formatPayloadDate(maundryThursday)]: { name: 'maundryThursday', date: maundryThursday },
    [formatPayloadDate(goodFriday)]: { name: 'goodFriday', date: goodFriday },
    [formatPayloadDate(easterMonday)]: { name: 'easterMonday', date: easterMonday },
    [formatPayloadDate(greatPrayerDay)]: { name: 'greatPrayerDay', date: greatPrayerDay },
    [formatPayloadDate(ascensionDay)]: { name: 'ascensionDay', date: ascensionDay },
    [formatPayloadDate(ascensionDayPlus1)]: { name: 'ascensionDayPlus1', date: ascensionDayPlus1 },
    [formatPayloadDate(whitSunday)]: { name: 'whitSunday', date: whitSunday },
    [formatPayloadDate(whitMonday)]: { name: 'whitMonday', date: whitMonday },
  };

  return holidays;
}
