import { Color } from 'db-npm-rdui';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useLoansState } from '../../state/loansState';

type Props = {};

export const CalculatorAddLoanButtonMobile: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { addLoan } = useLoansState();

  return <AddButton onClick={addLoan}>{t('calculator-table-addloan-button')}</AddButton>;
};

const AddButton = styled.a`
  position: absolute;
  top: 0;
  left: -1px;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px dashed #000;
  font-size: 14px;
  font-weight: normal;
  color: #000;
  transition: all 0.3s;
  background: ${Color.grey20};

  :hover {
    cursor: pointer;
    background: ${Color.grey30};
  }
`;
