import { Image, Text } from '@sitecore-jss/sitecore-jss-react';
import { mediaQuery, SlantedOverlay, Typography } from 'db-npm-rdui';
import React from 'react';
import styled from 'styled-components';
import { FieldChecker, Section } from '../../shared/components';
import imageScaler from '../../shared/utils/imageScaler';

const Wrapper = styled.div`
  width: 95%;
  margin-left: 5%;
`;

const ImageMaxHeightContainer = styled.div`
  overflow: hidden;
  max-height: 600px;
`;

const ImageBlock = styled(Image)`
  display: block;
  max-width: 100%;
  height: auto;
`;

const Description = styled.div`
  padding: 20px 15px 0 30px;

  ${mediaQuery.md} {
    padding-top: 20px;
  }
`;

export type ImageRightProps = {
  fields: any;
};

const ImageRight: React.FC<ImageRightProps> = ({ fields = {} }) => (
  <Section componentName="ImageRight" margin="large">
    <Wrapper>
      <SlantedOverlay slantPosition="left">
        <ImageMaxHeightContainer>
          <ImageBlock field={imageScaler(fields['Image'])} />
        </ImageMaxHeightContainer>

        <FieldChecker type="text" field={fields['Description']}>
          <Description>
            <Typography type="image">
              <Text field={fields['Description']} />
            </Typography>
          </Description>
        </FieldChecker>
      </SlantedOverlay>
    </Wrapper>
  </Section>
);

export default ImageRight;
