import * as React from 'react';
import styled from 'styled-components';

type CalculatorTooltipTableProps = {
  title: string;
  rows: {
    label: string | any;
    value: string | number;
    suffix: string;
  }[];
};

export const CalculatorTooltipTable: React.FC<CalculatorTooltipTableProps> = ({ title, rows }) => {
  return (
    <>
      <strong>{title}</strong>
      <hr />
      <Table>
        <tbody>
          {rows.map((data, index) => {
            return (
              <tr key={index}>
                <td>{data.label}</td>
                <td>
                  <strong>{data.value + data.suffix}</strong>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

const Table = styled.table`
  width: 300px;
  border: none;
  padding: 0;
  margin: 0;
  table-layout: auto !important;

  td {
    position: relative;
    color: #212121;
    vertical-align: top;
    text-align: left;
    border: none;
    padding: 5px 0;
    white-space: nowrap;

    :last-child {
      text-align: right;
    }
  }
`;
