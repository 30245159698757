import { Modal } from 'db-npm-rdui';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '../../shared/components/ErrorMessage/ErrorMessage';
import { ThankYouMessage } from '../../shared/components/ThankYouMessage/ThankYouMessage';
import { submitFormData } from '../../shared/forms/submitFormData';
import { useAdobeTracking } from '../../shared/hooks';
import UnsubscribeForm from './UnsubscribeForm';

export type FormUnsubscribeProps = {
  fields: any;
};

const FormUnsubscribe: React.FC<FormUnsubscribeProps> = ({ fields = {} }) => {
  const [page, setPage] = useState(0);
  const { t } = useTranslation();
  const [showModal, setShowModal] = React.useState(false);
  const toggleModal = () => setShowModal(!showModal);

  const { trackFormSubmitSuccess, trackFormSubmitFail } = useAdobeTracking();
  const goToNextPage = () => {
    setPage(page + 1);
    window.scrollTo(0, 0);
  };

  const onSubmitData = async (formData) => {
    const returndata = await submitFormData(
      {
        apiUrl: fields['API Endpoint URL']?.value,
        apiKey: fields['API Key']?.value,
        egainDsid: fields['Egain DSID']?.value,
      },
      formData
    );
    if (returndata) {
      trackFormSubmitSuccess();
      goToNextPage();
    } else {
      trackFormSubmitFail();

      //Error Handling
      setShowModal(true);
    }
  };

  /**
   * conditional rendering
   */
  switch (page) {
    case 0:
      return (
        <>
          <UnsubscribeForm fields={fields} onSubmitData={onSubmitData} />
          <Modal open={showModal} handleClose={toggleModal} ariaCloseLabel={'Close modal'} overlayColor="#1f2532">
            <ErrorMessage message={t('form-submit-error')} />
          </Modal>
        </>
      );
    case 1: //Thank You Message
      return <ThankYouMessage fields={fields} />;
  }

  return null;
};

export default FormUnsubscribe;
