import { ConsentLevels, useCookieConsent, useOnWindowResize } from 'db-npm-rdui';
import throttle from 'lodash/throttle';
import React, { ReactNode, useCallback, useEffect, useRef } from 'react';
import { ContextSettings, useContextSettings, useIsEditor } from '../../../shared/hooks';
import * as parsers from './parsers/';

type ContentParserProps = {
  children: ReactNode[] | JSX.Element;
  /**
   * Config for customizing which parsers to use
   */
  config?: ContentParserConfig;
  /**
   * Specify dependencies e.g. for content not being rendered initially
   */
  dependencies?: any[];
};

export type ContentParserConfig = {
  /**
   * Parsers to run - currently only videoParser
   */
  useParsers: Parser[];
  /**
   * Throttle (ms) for onResize
   */
  resizeThrottle: number;
};

export type Parser = {
  /**
   * Parser function
   */
  handler: ParserFunctionInterface;
  /**
   * Config for customizing the parser
   */
  config?: any;
  /**
   * Pass current cookie consent level if relevant for parser
   */
  cookieConsentLevels?: ConsentLevels;
};

export interface ParserFunctionInterface {
  (el: any, config?: any, cookieConsentLevels?: ConsentLevels, contextSettings?: ContextSettings): {
    init: () => void;
    onResize?: () => void;
  };
}

export const ContentParser: React.FC<ContentParserProps> = ({
  children,
  config = defaultConfig,
  dependencies = [],
}) => {
  const cookieConsentLevels = useCookieConsent().getConsentLevels();
  const contextSettings = useContextSettings();
  const ref = useRef(null);
  let handlersOnResize: any[] = [];
  const isEditor = useIsEditor();

  useEffect(
    () => {
      if (ref.current === null || isEditor) return;

      config.useParsers.forEach(function (parser) {
        const handler = parser.handler(ref.current, parser.config, cookieConsentLevels, contextSettings);
        handler.init();
        if (typeof handler.onResize === 'function') {
          handlersOnResize.push(handler);
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dependencies
  );

  const onResize = useCallback(
    throttle(() => {
      handlersOnResize.forEach(function (handler) {
        handler.onResize();
      });
    }, config.resizeThrottle),
    []
  );
  useOnWindowResize(onResize);

  return <div ref={ref}>{children}</div>;
};

const defaultConfig: ContentParserConfig = {
  useParsers: [{ handler: parsers.cookiePanel }, { handler: parsers.videoParser }],
  resizeThrottle: 500,
};
