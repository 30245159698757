import { ParserFunctionInterface } from '../../';

type CookiePanelType = {
  level: { title: string; value: string };
  time: { title: string; value: string };
  id: { title: string; value: string };
};

const QUERY_SELECTOR = '[data-module="cookie-consent-status"]';

let CookiePanel: ParserFunctionInterface;
CookiePanel = (el: any) => {
  const arrCookieConsent = el.querySelectorAll(QUERY_SELECTOR);

  function init() {
    if (arrCookieConsent.length > 0) {
      processCookieDetails(arrCookieConsent);
    }
  }

  function processCookieDetails(arrCookieConsent) {
    const apiUrl = '/api/ccstatus';
    fetch(apiUrl, {
      method: 'GET',
      mode: 'no-cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    })
      .then((response) => response.json())
      .then((json: CookiePanelType) => {
        const node = arrCookieConsent[0];

        node.innerHTML = `<div>   
<span>{levelTitle} : </span>
<span>{levelValue}</span>
</div>
<div>
<span>{IDTitle}: </span>
<span>{IDValue}</span>
</div>
<div>
<span>{TimeTitle}: </span>
<span>{TimeValue}</span>
</div> `
          .replace('{levelTitle}', json?.level?.title)
          .replace('{levelValue}', json?.level?.value)
          .replace('{IDTitle}', json?.id?.title)
          .replace('{IDValue}', json?.id?.value)
          .replace('{TimeTitle}', json?.time?.title)
          .replace('{TimeValue}', json?.time?.value);
      })
      .catch((error) => {
        console.warn('cookie status api failed: ', error);
      });
  }
  return {
    init,
  };
};

export default CookiePanel;
