import { useLayoutEffect } from 'react';

export function useAdobePageLoadTracking(viewName: string) {
  useLayoutEffect(() => {
    const w = window as any;

    if (w && w.launch_data) {
      // Make a breadcrumb array from the current location.pathname split by forward-slash
      const breadcrumbArray: string[] = (w.location?.pathname || '').split('/');
      // Remove empty strings from the array, eg. if the path ends with a forward-slash
      const breadcrumbs = breadcrumbArray.filter((i) => !!i);

      // Don't override global launch_data object - TODO: check with team tracking if the date coming is alright using an object that's not overridden
      w.launch_data = {
        ...w.launch_data,
        page_name: viewName,
        breadcrumbs,
      };
      if (w._satellite && w._satellite.track) {
        w._satellite.track('page_view');
      }

      console.log('useAdobePageLoadTracking: launch_data updated:', w.launch_data);
    } else {
      console.warn('useAdobePageLoadTracking: launch_data not present');
    }
  }, [viewName]);
}
