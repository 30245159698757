import i18next from 'i18next';
import * as React from 'react';
import { formatAsCurrency } from '../../../../shared/utils/currency';
import { CalculatorTooltipAdministrationFee } from '../../components/tooltip/CalculatorTooltipAdministrationFee';
import { ValidateNetProceedsAmountTop } from '../../helpers/tableItemValidation';
import { CalculatorInputs } from '../../types/CalculatorInputs';
import { LoanStateItem } from '../../types/LoanStateItem';
import { TableItem } from '../../types/TableItem';
import { FlexlaanTResponse } from './response-mapper';

export const convertToTableItems = (
  response: FlexlaanTResponse,
  loan: LoanStateItem,
  inputs: CalculatorInputs
): TableItem[] => {
  const items: TableItem[] = [];
  const displayType = loan.settings?.displayAsLending === true;
  const propertyDepositDisclaimer = inputs.wantedLoanAmount
    ? i18next.t('calculator-row-business-propertyDepositDisclaimer-value')
    : i18next.t('calculator-row-propertyDepositDisclaimer-value');

  // Table: Fastforrentet 1
  if (response.bankloan > 0) {
    items.push({
      fieldKey: 'bankloan',
      inputType: 'static',
      value: formatAsCurrency(response.bankloan),
      suffix: ' kr.',
    });
  }

  items.push({
    fieldKey: 'ownpayment',
    inputType: 'static',
    value: formatAsCurrency(response.ownpayment),
    suffix: ' kr.',
  });

  if (displayType) {
    items.push({
      fieldKey: 'netProceedsAmountTop',
      inputType: 'input',
      validate: true,
      hasError: {
        error: false,
        errorMsg: '',
      },
      value: Math.floor(response.netProceedsAmount),
      suffix: ' kr.',
      onChange(loan, value) {
        const { payload, settings, tableItems } = loan;

        settings.modifiedNetProceeds = true;

        if (value > response.netProceedsAmount - 1000) {
          payload.basic.calculationType = 'Maximum';
          payload.basic.wantedAdditionalAmount = 0;
        } else {
          payload.basic.wantedAdditionalAmount = parseInt(value);
          payload.basic.calculationType = 'Proceeds';
        }

        const checkError = ValidateNetProceedsAmountTop(value, inputs.propertyValue, 'netProceedsAmountTop');
        if (checkError && checkError.error) {
          tableItems?.forEach((value) => {
            if (value.fieldKey === 'netProceedsAmountTop') {
              value.hasError = {
                error: true,
                errorMsg: checkError.errorMsg,
              };
            }
          });
        } else {
          tableItems?.forEach((value) => {
            if (value.fieldKey === 'netProceedsAmountTop') {
              value.hasError = {
                error: false,
                errorMsg: '',
              };
            }
          });
        }

        return loan;
      },
    });
  } else {
    items.push({
      fieldKey: 'netProceedsAmountTop',
      inputType: 'static',
      value: formatAsCurrency(response.netProceedsAmount),
      suffix: ' kr.',
    });
  }

  items.push({
    fieldKey: 'firstYearInstalmentMonthlyPaymentGrossTop',
    inputType: 'input',
    value: inputs.wantedLoanAmount
      ? response.firstYearInstalmentMonthlyPaymentGross * 12
      : response.firstYearInstalmentMonthlyPaymentGross,
    suffix: ' kr.',
    min: 50,
    max: 1000000,
    onChange(loan, value) {
      const { payload } = loan;

      payload.product.flexType.desiredPaymentAmount = inputs.wantedLoanAmount
        ? parseFloat((parseInt(value) / parseInt(response.paymentsPerYear)).toFixed(2))
        : value;

      return payload;
    },
  });

  items.push({
    fieldKey: 'maturityTop',
    inputType: 'static',
    value: response.maturity,
    suffix: ' år',
  });

  items.push({
    fieldKey: 'rateType',
    inputType: 'static',
    value: response.rateType,
  });

  items.push({
    fieldKey: 'refinancingFrequency',
    inputType: 'slider',
    min: 1,
    max: 10,
    suffix: ' år',
    value: response.refinancingFrequency,
    onChange(loan, value) {
      const { payload } = loan;

      payload.product.flexType.refinancingFrequency = value;
      payload.product.flexType.desiredPaymentAmount = inputs.wantedLoanAmount
        ? parseFloat(
            ((response.firstYearInstalmentMonthlyPaymentGross * 12) / parseInt(response.paymentsPerYear)).toFixed(2)
          )
        : response.firstYearInstalmentMonthlyPaymentGross;

      return payload;
    },
  });

  items.push({
    fieldKey: 'loanToValuePercentage',
    inputType: 'static',
    value: Math.round(response.loanToValuePercentage),
    suffix: ' %',
  });

  items.push({
    fieldKey: 'taxRate',
    inputType: 'static',
    value: formatAsCurrency(response.taxRate, 2),
    suffix: ' %',
  });

  // Table: Fastforrentet 2
  items.push({
    fieldKey: 'loanProvider',
    inputType: 'static',
    value: response.loanProvider,
  });

  items.push({
    fieldKey: 'netProceedsAmount',
    inputType: 'static',
    value: formatAsCurrency(response.netProceedsAmount),
    suffix: ' kr.',
  });

  items.push({
    fieldKey: 'principalAmount',
    inputType: 'static',
    value: formatAsCurrency(response.principalAmount),
    suffix: ' kr.',
  });

  items.push({
    fieldKey: 'maturityBottom',
    inputType: 'static',
    value: response.maturity,
    suffix: ' år',
  });

  items.push({
    fieldKey: 'monthlyGrossPaymentYearOneBottom',
    inputType: 'static',
    value: inputs.wantedLoanAmount
      ? formatAsCurrency(response.monthlyGrossPaymentYearOne * 12)
      : formatAsCurrency(response.monthlyGrossPaymentYearOne),
    suffix: ' kr.',
  });

  items.push({
    fieldKey: 'numberOfPayments',
    inputType: 'static',
    value: response.numberOfPayments,
  });

  items.push({
    fieldKey: 'debtorInterestRate',
    inputType: 'static',
    value: `(${response.rateType}) ${formatAsCurrency(response.debtorInterestRate, 2)}`,
    suffix: ' %',
  });

  items.push({
    fieldKey: 'weightedBondPrice',
    inputType: 'static',
    value: formatAsCurrency(response.weightedBondPrice, 10),
  });

  items.push({
    fieldKey: 'annualPercentageRate',
    inputType: 'static',
    value: formatAsCurrency(response.annualPercentageRate, 2),
    suffix: ' %',
  });

  items.push({
    fieldKey: 'totalAdministrationFee',
    inputType: 'static',
    value: formatAsCurrency(response.totalAdministrationFee),
    suffix: ' kr.',
    tooltip: <CalculatorTooltipAdministrationFee {...response} />,
  });

  items.push({
    fieldKey: 'totalRepaymentAmount',
    inputType: 'static',
    value: formatAsCurrency(response.totalRepaymentAmount),
    suffix: ' kr.',
  });

  items.push({
    fieldKey: 'propertyDepositDisclaimer',
    inputType: 'static',
    value: propertyDepositDisclaimer,
  });

  items.push({
    fieldKey: 'paymentsPerYear',
    inputType: 'dropdown',
    value: response.paymentsPerYear,
    options: [
      {
        label: '4',
        value: '4',
      },
      {
        label: '12',
        value: '12',
      },
    ],
    onChange(loan, value) {
      const { payload } = loan;

      payload.product.flexType.paymentsPerYear = parseInt(value);

      payload.product.flexType.desiredPaymentAmount = parseFloat(
        (
          (parseInt(payload.product.flexType.desiredPaymentAmount) * parseInt(response.paymentsPerYear)) /
          parseInt(value)
        ).toFixed(2)
      );

      return payload;
    },
  });

  items.push({
    fieldKey: 'monthlyPaymentWithInstallment',
    inputType: 'static',
    value: formatAsCurrency(response.firstYearInstalmentMonthlyPaymentNet),
    suffix: ' kr.',
  });

  items.push({
    fieldKey: 'businessMonthlyPaymentWithInstallment',
    inputType: 'static',
    value: formatAsCurrency(response.firstYearInstalmentMonthlyPaymentNet * 12),
    suffix: ' kr.',
  });

  return items;
};
