export const fieldReferences = {
  basePage: {
    metaTitle: 'Meta Title',
    metaDescription: 'Meta Description',
    metaKeywords: 'Meta Keywords',
    metaNoindex: 'Meta Noindex',
    canonicalUrl: 'Canonical Url',
    sharingImage: 'Sharing Image',
    greyBackground: 'Grey Background',
    hideStickyBookMeeting: 'Hide Sticky Book Meeting',
    hideStickyCalculator: 'Hide Sticky Calculator',
  },
  droplinkFieldValue: {
    value: 'Value',
  },
};
