import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Box, Col, Container, Row, Typography } from 'db-npm-rdui';
import * as React from 'react';
import { FieldChecker, Section } from '../../shared/components';

export type HeadlineProps = {
  fields: any;
};

const Headline: React.FC<HeadlineProps> = ({ fields = {} }) => (
  <Section componentName="Headline" margin="large">
    <Container>
      <Row justify="center">
        <Col md={6}>
          <Box textAlign="center" marginBottom={5}>
            <FieldChecker type="text" field={fields['Label']}>
              <Typography type="label">
                <Text field={fields['Label']} />
              </Typography>
            </FieldChecker>
          </Box>

          <Box textAlign="center">
            <FieldChecker type="text" field={fields['Headline']}>
              <Typography type="h2">
                <Text field={fields['Headline']} />
              </Typography>
            </FieldChecker>
          </Box>
        </Col>
      </Row>
    </Container>
  </Section>
);

export default Headline;
