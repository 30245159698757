import { RichText as SitecoreRichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { Box, Col, Container, Row, Typography } from 'db-npm-rdui';
import React from 'react';
import styled from 'styled-components';
import { ContentParser, FieldChecker, Section } from '../../shared/components';
import { hrStyle, richTextStyles } from './richTextStyles';

const HorizontalRule = styled.hr`
  ${hrStyle}
`;

const StyledRichText = styled(SitecoreRichText)`
  ${richTextStyles}
`;

type Params = {
  richTextWidth?: string;
};

export type RichTextProps = {
  fields: any;
  params: Params;
};

const RichText: React.FC<RichTextProps> = ({ fields = {}, params = {} }) => {
  const richTextWidth = Number(params?.richTextWidth) || 50;
  const colsFromPercent = Math.round(12 * (richTextWidth / 100));
  const columnsXl = colsFromPercent > 12 ? 12 : colsFromPercent;
  const columnsMd = columnsXl + 2 > 12 ? 12 : columnsXl + 2;

  return (
    <Section componentName="RichText" margin={fields['Headline']?.length ? 'large' : 'small'}>
      <Container>
        <Row justify="center">
          <Col md={columnsMd} xl={columnsXl}>
            <FieldChecker type="text" field={fields['Headline']}>
              <Box textAlign="center" marginBottom={5}>
                <Typography type="h2">
                  <Text field={fields['Headline']} />
                </Typography>
              </Box>
              <HorizontalRule />
            </FieldChecker>

            <ContentParser>
              <StyledRichText field={fields['Text']} />
            </ContentParser>
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

export default RichText;
