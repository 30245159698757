import unescape from 'lodash/unescape';

export function parseExcelData(data: string, unescapeText = true): string[][] {
  // Split data into rows on linebreaks
  const rows = data.split('\n') || data.split('\rn');

  return rows.reduce((result, row) => {
    // Split row into cells on tab character
    const cells = row.split('\t');
    if (cells.length < 1 || (cells.length === 1 && cells[0] === '')) {
      return result;
    }

    // Map all cells and prepare the data
    const cellsArr = cells.map(function (cell) {
      const valueTrimmed = cell.trim();
      if (unescapeText) {
        return unescape(valueTrimmed);
      }
      return valueTrimmed;
    });

    // Combine and return
    return [...result, cellsArr];
  }, [] as string[][]);
}
