import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Box, ButtonGroup, Col, Container, mediaQuery, Row, SlantedBackgroundImage, Typography } from 'db-npm-rdui';
import * as React from 'react';
import styled from 'styled-components';
import { Button, FieldChecker, LabelGrey, Section } from '../../shared/components';
import imageScaler from '../../shared/utils/imageScaler';

const BgImage = styled(SlantedBackgroundImage)`
  height: 300px;

  ${mediaQuery.md} {
    width: 50%;
    height: auto;
    position: absolute !important;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
`;

export type HeroProductProps = {
  fields: any;
};

const HeroProduct: React.FC<HeroProductProps> = ({ fields = {} }) => (
  <Section componentName="HeroProduct">
    <Container>
      <Row>
        <Col md={5}>
          <Box textAlign="center" paddingTop={{ _: 6, md: 10, xl: 12 }} paddingBottom={{ _: 6, md: 10, xl: 12 }}>
            <FieldChecker type="text" field={fields['Label']}>
              <Box marginBottom={5}>
                <LabelGrey field={fields['Label']} />
              </Box>
            </FieldChecker>

            <FieldChecker type="text" field={fields['Headline']}>
              <Typography type="h2">
                <Text field={fields['Headline']} />
              </Typography>
            </FieldChecker>

            <FieldChecker type="text" field={fields['Description']}>
              <Box marginTop={5} marginBottom={6}>
                <Typography type="manchetBig">
                  <Text field={fields['Description']} />
                </Typography>
              </Box>
            </FieldChecker>

            <ButtonGroup>
              <FieldChecker type="link" field={fields['Button Primary']}>
                <Button field={fields['Button Primary']} />
              </FieldChecker>
              <FieldChecker type="link" field={fields['Button Secondary']}>
                <Button variant="secondary" field={fields['Button Secondary']} />
              </FieldChecker>
            </ButtonGroup>
          </Box>
        </Col>
      </Row>
    </Container>

    <FieldChecker type="image" field={fields['Image']}>
      <BgImage src={imageScaler(fields['Image']?.value?.src)} slantPosition="left" />
    </FieldChecker>
  </Section>
);

export default HeroProduct;
