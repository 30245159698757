import { Image, Text } from '@sitecore-jss/sitecore-jss-react';
import { BottomShadow, Color, mediaQuery, RddkTheme, Typography } from 'db-npm-rdui';
import React from 'react';
import styled from 'styled-components';
import { FieldChecker } from '../../shared/components';
import imageScaler from '../../shared/utils/imageScaler';

const ListItem = styled.a`
  display: block;
  margin: 10px 10px 30px 10px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
  text-decoration: none;
`;

const StyledImage = styled(Image)`
  display: block;
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
`;

const Content = styled.span`
  display: flex;
  flex-direction: column;
  height: 200px;
  padding: 18px 23px;

  ${mediaQuery.md} {
    padding: 27px 30px;
  }
`;

const StyledText = styled.span`
  flex: 1;
  display: block;
  overflow: hidden;
  font-family: ${RddkTheme.fonts.sans};
  font-size: 14px;
  line-height: 1.36;
  color: ${Color.rdBlackTint2};
  margin: 5px 0;

  ${mediaQuery.sm} {
    margin: 10px 0 5px;
  }
`;

const Date = styled.span`
  font-weight: 600;
  font-size: 12px;
  color: #000;
`;

type NewsListHorizontalItemProps = {
  fields: any;
};

const NewsListHorizontalItem: React.FC<NewsListHorizontalItemProps> = ({ fields = {} }) => (
  <BottomShadow forceMobileStyle>
    <ListItem href={fields['Link']?.value?.href}>
      <FieldChecker type="image" field={fields['Image']}>
        <StyledImage field={imageScaler(fields['Image'], 350)} />
      </FieldChecker>

      <Content>
        <FieldChecker type="text" field={fields['Headline']}>
          <Typography type="h8" color={Color.rdPureBlack}>
            <Text field={fields['Headline']} />
          </Typography>
        </FieldChecker>

        <FieldChecker type="text" field={fields['Text']}>
          <StyledText>
            <Text field={fields['Text']} />
          </StyledText>
        </FieldChecker>

        <FieldChecker type="text" field={fields['Date']}>
          <Date>
            <Text field={fields['Date']} />
          </Date>
        </FieldChecker>
      </Content>
    </ListItem>
  </BottomShadow>
);

export default NewsListHorizontalItem;
