import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { breakpointsBootstrap, FileUpload, FileWithMeta } from 'db-npm-rdui';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormFooter, FormLayout } from '../../shared/components/Form';
import { attachFileGetUploadParams, removeFile } from '../../shared/forms/secureUploadHandling';

type Props = {
  fields: any;
  topic: string;
  subject: string;
  setFileList: any;
  fileList: any;
  attachId: string;
  setAttachId: any;
  page: number;
  goToPrevPage: () => void;
  onSubmitFiles: (files: FileWithMeta[], attachId) => void;
};

const CVRUpload: React.FC<Props> = ({
  fields = {},
  topic,
  subject,
  fileList,
  setFileList,
  attachId,
  setAttachId,
  page,
  goToPrevPage,
  onSubmitFiles,
}) => {
  const { t } = useTranslation();
  const [fileInputLabel, setFileInputLabel] = useState('');

  const getWidth = useCallback(() => {
    const label =
      window.innerWidth > breakpointsBootstrap.lg
        ? t('upload-text-input-box-label')
        : t('upload-text-input-box-smaller-device-label');
    setFileInputLabel(label);
  }, [setFileInputLabel, t]);

  useEffect(() => {
    getWidth();
    window.addEventListener('resize', getWidth);
    return () => window.removeEventListener('resize', getWidth);
  }, [getWidth]);

  /**
   * This function is called for each file upload.
   * Use this function to specify the upload request information, like url and headers.
   */
  const getUploadParams = attachFileGetUploadParams(
    {
      apiUrl: fields['API Endpoint URL Attach']?.value,
      egainDsid: fields['Egain DSID']?.value,
    },
    attachId,
    'false',
    subject
  );

  /**
   * This function parses the "AttachId" from the uploaded file,
   * and stores it in our state variable.
   */
  const onUploadSuccess = (file: any, response: any) => {
    const responseObj = JSON.parse(response);
    file.status = 1;
    file.percent = 100;
    file.response = responseObj;

    if (responseObj.ActionResponse !== '0' || (responseObj && responseObj?.FileId?.length === 0)) {
      return 'invalid-file';
    }

    setAttachId(responseObj.AttachId);
    setFileList([...fileList, { attachId: responseObj.AttachId, file: file }]);
  };

  /**
   * This function runs when user clicks the "remove" button on a file.
   * It must return a boolean whether the file was removed succesfully or not.
   */
  const onRemove = async (file: FileWithMeta) => {
    const modifiedList = fileList.filter((item) => item.file.id !== file.id);
    setFileList(modifiedList);
    const onRemoved = await removeFile(
      file,
      {
        apiUrl: fields['API Endpoint URL Remove']?.value,
        egainDsid: fields['Egain DSID']?.value,
      },
      attachId
    );
    return onRemoved;
  };

  /**
   * This function runs when the user submits the upload form.
   */
  const onSubmit = async (files: FileWithMeta[]) => {
    setFileList(files);
  };

  const setPreviousPage = () => {
    goToPrevPage();
  };

  const setSubmit = () => {
    const fileData = fileList.map((a) => a.file);
    const id = attachId !== '' ? attachId : fileList[0].attachId;
    onSubmitFiles(fileData, id);
  };

  return (
    <>
      <FormLayout
        headline={fields['Headline']?.value}
        description={fields['Upload Description']?.value}
        infoDescription={topic === 'Legitimation' && <RichText field={fields['Upload Info Legitimation']} />}
      >
        <FileUpload
          fullscreenOnWindowDragEnter={true}
          maxSize={1024 * 1024 * 4} // 4mb
          maxFiles={10}
          accept="application/pdf, image/jpeg, image/gif, image/png, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/plain"
          multiple={false}
          fileList={fileList.map((a) => a.file)}
          formDataFileKey="DocumentFile0"
          getUploadParams={getUploadParams}
          onUploadSuccess={onUploadSuccess}
          onRemove={onRemove}
          onSubmit={onSubmit}
          hideSubmitButton={true}
          textBeforeInput={fields['Upload Title']?.value}
          textInputBox={fileInputLabel}
          textInputButton={t('upload-text-input-button-label')}
          textAfterInput={t('upload-text-after-input-label')}
          textBeforeFiles={t('upload-text-before-files-label')}
          textSubmit={t('upload-text-submit-label')}
          textOverlay={t('upload-text-overlay-label')}
          errorTexts={{
            'upload-cancelled': t('upload-cancelled-error-text'),
            'upload-failed': t('upload-failed-error-text'),
            'file-too-large': t('upload-file-too-large-error-text'),
            'file-too-small': t('upload-file-too-small-error-text'),
            'too-many-files': t('upload-too-many-files-error-text'),
            'file-invalid-type': t('upload-file-invalid-type-error-text'),
            'invalid-upload-params': t('upload-invalid-upload-params-text'),
            'invalid-file': t('upload-invalid-file-error-text'),
          }}
        />
      </FormLayout>
      <FormFooter
        step1Label={t('CVR-footer-step1-title')}
        step2Label={t('CVR-footer-step2-title')}
        step3Label={t('CVR-footer-step3-title')}
        page={page}
        setPreviousPage={setPreviousPage}
        fileList={fileList}
        setSubmit={setSubmit}
      />
    </>
  );
};

export default CVRUpload;
