import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { NameRegex, NumberRegex, PhoneRegex } from '../../shared/regex';

export function getSchema(t: TFunction) {
  return Yup.object().shape({
    fname: Yup.string().required(t('form-required-error')).matches(NameRegex, t('form-input-name-error')),
    lname: Yup.string().required(t('form-required-error')).matches(NameRegex, t('form-input-name-error')),
    housenumber: Yup.string().required(t('form-required-error')).matches(NumberRegex, t('form-input-number-error')),
    streetname: Yup.string().required(t('form-required-error')),
    postnumber: Yup.string()
      .required(t('form-required-error'))
      .matches(NumberRegex, t('form-input-number-error'))
      .min(3, t('form-input-number-error')),
    by: Yup.string().required(t('form-required-error')),
    phone: Yup.string()
      .required(t('form-required-error'))
      .matches(PhoneRegex, t('form-input-phone-error'))
      .min(8, t('form-input-phone-error')),
  });
}
