import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Color, mediaQuery, RddkTheme, Typography } from 'db-npm-rdui';
import React from 'react';
import styled from 'styled-components';
import { FieldChecker } from '../../shared/components';

const ListItem = styled.a`
  position: relative;
  display: block;
  overflow: hidden;
  padding: 25px 30px;
  text-decoration: none;
  border-top: 3px solid #eee;

  &:first-child {
    border-top: 0;
  }

  ::before {
    content: '';
    display: block;
    width: 8px;
    position: absolute;
    top: 0;
    left: -8px;
    bottom: 0;
    background: #e2001a;
    transition: all 0.3s;
  }

  :hover,
  :focus {
    ::before {
      left: 0;
    }
  }

  ${mediaQuery.sm} {
    padding: 30px 40px;
  }

  ${mediaQuery.lg} {
    padding: 40px 50px;
  }
`;

const ListItemText = styled.div`
  font-family: ${RddkTheme.fonts.sans};
  font-size: 14px;
  line-height: 1.36;
  color: ${Color.rdBlackTint2};
  margin: 5px 0;

  ${mediaQuery.md} {
    margin: 0 0 5px;
  }
`;

const ListItemDate = styled.span`
  font-weight: 600;
  font-size: 12px;
  color: #000;
`;

type NewsListVerticalItemProps = {
  fields: any;
};

const NewsListVerticalItem: React.FC<NewsListVerticalItemProps> = ({ fields = {} }) => (
  <ListItem href={fields['Link']?.value?.href}>
    <FieldChecker type="text" field={fields['Headline']}>
      <Typography type="h7" color={Color.rdPureBlack}>
        <Text field={fields['Headline']} />
      </Typography>
    </FieldChecker>

    <FieldChecker type="text" field={fields['Text']}>
      <ListItemText>
        <Text field={fields['Text']} />
      </ListItemText>
    </FieldChecker>

    <FieldChecker type="text" field={fields['Date']}>
      <ListItemDate>
        <Text field={fields['Date']} />
      </ListItemDate>
    </FieldChecker>
  </ListItem>
);

export default NewsListVerticalItem;
