import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { mediaQuery, Typography } from 'db-npm-rdui';
import React from 'react';
import styled from 'styled-components';
import { ContentParser } from '../../shared/components';
import { richTextStyles } from '../RichText/richTextStyles';

type ExpandedTextAccordionItemProps = {
  fields: any;
  uid: string;
  innerRef: React.RefObject<HTMLDivElement>;
};

const ExpandedTextAccordionItem: React.FC<ExpandedTextAccordionItemProps> = ({ fields = {}, uid, innerRef }) => {
  return (
    <Container ref={innerRef} data-uid={uid}>
      <Typography type="h6">
        <Text field={fields['Title']} />
      </Typography>
      <Typography type="paragraph">
        <ContentParser>
          <RichTextStyle field={fields['Text']} />
        </ContentParser>
      </Typography>
    </Container>
  );
};

export default ExpandedTextAccordionItem;

const Container = styled.div`
  margin-bottom: 30px;

  ${mediaQuery.md} {
    margin-bottom: 40px;
  }

  ${mediaQuery.lg} {
    margin-bottom: 50px;
  }
`;

const RichTextStyle = styled(RichText)`
  ${richTextStyles}
`;
