import React from 'react';
import { useIsEditor } from '../../hooks';
import { isValidImage, isValidLink, isValidText } from '../../utils/field-validation';

type FieldCheckerProps = {
  field: any;
  type: 'text' | 'image' | 'link';
  children?: any;
};

/**
 * This component can be used for wrapping HTML/markup for rendering a Sitecore field,
 * to avoid rendering empty tags and unnecessary HTML when data is missing.
 * This component checks whether the given field has data, based on the type given for the field.
 * It only renders the child content if the field has data.
 * In "editor mode" it alaways renders the child content, no matter if the field has data or not.
 */
export const FieldChecker: React.FC<FieldCheckerProps> = ({ field, type, children }) => {
  const isEditor = useIsEditor();

  if (
    isEditor ||
    (type === 'text' && isValidText(field)) ||
    (type === 'image' && isValidImage(field)) ||
    (type === 'link' && isValidLink(field))
  ) {
    return children;
  }

  return null;
};
