import { AmortisationDataYear } from '../types/AmortisationData';

export function mapAmortisationData(array: any[]): AmortisationDataYear[] {
  // First we will get all the years from the array data
  const years = array.reduce((result: string[], item: any) => {
    // Get the year from termDate (first 4 chars)
    const year = item.termDate.substr(0, 4);

    // If year is not in array, then add it
    if (!result.find((i) => i === year)) {
      return [...result, year];
    }

    return result;
  }, []);

  // Now we iterate through the years and construct our array of amortisation data years
  return years.map((year: string) => {
    // Filter all the months for this year and map the data correctly
    const months = array
      .filter((i) => i.termDate.startsWith(year))
      .map((item) => {
        return {
          month: item.termDate,
          data: {
            grossPayment: parseFloat(item.grossPayment),
            netPayment: parseFloat(item.netPayment),
            instalment: parseFloat(item.instalment),
            interest: parseFloat(item.interest),
            administrationFeeAmount: parseFloat(item.administrationFeeAmount),
            bondDebt: parseFloat(item.bondDebt),
            cashDebt: parseFloat(item.cashDebt),
          },
        };
      });

    // Construct the year object and sum up the data
    const item: AmortisationDataYear = {
      year,
      data: {
        grossPayment: months.reduce((result, item) => result + item.data.grossPayment, 0),
        netPayment: months.reduce((result, item) => result + item.data.netPayment, 0),
        instalment: months.reduce((result, item) => result + item.data.instalment, 0),
        interest: months.reduce((result, item) => result + item.data.interest, 0),
        administrationFeeAmount: months.reduce((result, item) => result + item.data.administrationFeeAmount, 0),
        bondDebt: months.reduce((result, item) => result + item.data.bondDebt, 0),
        cashDebt: months.reduce((result, item) => result + item.data.cashDebt, 0),
      },
      months,
    };

    return item;
  });
}
