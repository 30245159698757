import { withPlaceholder } from '@sitecore-jss/sitecore-jss-react';
import { PlaceholderProps } from '@sitecore-jss/sitecore-jss-react/types/components/PlaceholderCommon';
import { Carousel, CarouselDefaultContainer, Container, mediaQuery } from 'db-npm-rdui';
import React from 'react';
import LazyLoad from 'react-lazyload';
import styled from 'styled-components';
import { Section, WithConditionWrapper } from '../../shared/components';
import { useIsEditor } from '../../shared/hooks';

const StyledContainer = styled(Container)`
  ${mediaQuery.lgDown} {
    padding-left: 0 !important;
    padding-right: 0 !important;
    max-width: 100% !important;
  }
`;

const ExperienceEditorCarousel = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  .scEmptyPlaceholder {
    flex: auto;
  }
`;

export type NewsListHorizontalProps = PlaceholderProps & {
  newsItemsHorizontal: any;
};

const NewsListHorizontal: React.FC<NewsListHorizontalProps> = ({ newsItemsHorizontal }) => {
  const hasNewsItems = newsItemsHorizontal && newsItemsHorizontal.length > 0;
  const isEditor = useIsEditor();
  const showNewsItems = hasNewsItems || isEditor;

  return (
    <Section componentName="NewsListHorizontal" margin="small">
      <StyledContainer>
        {showNewsItems &&
          (isEditor ? (
            <CarouselDefaultContainer>
              <ExperienceEditorCarousel>{newsItemsHorizontal}</ExperienceEditorCarousel>
            </CarouselDefaultContainer>
          ) : (
            <WithConditionWrapper
              condition={process.env.REACT_APP_CM_BUILD === 'true'}
              Wrapper={LazyLoad}
              props={{ height: 400, once: true }}
            >
              <CarouselDefaultContainer>
                <Carousel>{newsItemsHorizontal}</Carousel>
              </CarouselDefaultContainer>
            </WithConditionWrapper>
          ))}
      </StyledContainer>
    </Section>
  );
};

export default withPlaceholder('newsItemsHorizontal')(NewsListHorizontal);
