import { Cell, DynamicTable, RddkTheme } from 'db-npm-rdui';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { DESKTOP_LABEL_COLUMN_WIDTH, DESKTOP_LOAN_COLUMNS_MIN_WIDTH, MAX_LOANS_DESKTOP } from '../../config';
import { getFieldKeysToDisplay } from '../../helpers/table';
import { useLoansState } from '../../state/loansState';
import { FieldKeys } from '../../types/FieldKey';
import { CalculatorTableBodyCell } from './CalculatorTableBodyCell';
import { CalculatorTableBodyLabelCell } from './CalculatorTableBodyLabelCell';
import { CalculatorTableErrorMessage } from './CalculatorTableErrorMessage';
import { CalculatorTableHeadCell } from './CalculatorTableHeadCell';

type CalculatorTableDesktopProps = {
  fieldKeys: FieldKeys;
  showHeader: boolean;
  tableName?: string;
  tableSlogan?: string;
};

export const CalculatorTableDesktop: React.FC<CalculatorTableDesktopProps> = ({
  fieldKeys,
  showHeader,
  tableName,
  tableSlogan,
}) => {
  const { t } = useTranslation();
  const { loans, lastCalculationDate } = useLoansState();

  const fieldsKeysToDisplay = getFieldKeysToDisplay(fieldKeys, loans);

  /**
   * Configure the table data for desktop viewport
   */
  const desktopTableData = React.useMemo(() => {
    const rows: Cell[][] = [];

    // Add table header row, if "showHeader" is true
    if (showHeader) {
      const headerRow: Cell[] = [];

      headerRow.push({
        value: tableName,
        suffix: tableSlogan,
        width: DESKTOP_LABEL_COLUMN_WIDTH,
      });

      loans.slice(0, MAX_LOANS_DESKTOP).forEach((loan, index) => {
        headerRow.push({
          value: loan.type,
          suffix: !!loan.type && t(`calculator-loan-${loan.type}-text`),
          cellValueRender: () => <CalculatorTableHeadCell loanArrayIndex={index} loan={loan} />,
        });
      });

      rows.push(headerRow);
    }

    // Iterate through all keys and add them as rows
    fieldsKeysToDisplay.forEach((fieldKey, fieldIndex) => {
      const bodyRow: Cell[] = [];

      bodyRow.push({
        value: <CalculatorTableBodyLabelCell fieldKey={fieldKey} />,
        width: DESKTOP_LABEL_COLUMN_WIDTH,
      });

      loans.slice(0, MAX_LOANS_DESKTOP).forEach((loan, loanIndex) => {
        if (loan.status === 'error') {
          // If the loan status is "error", then display the error mesage, but only for the first row
          if (fieldIndex === 0) {
            bodyRow.push({
              value: <CalculatorTableErrorMessage loanArrayIndex={loanIndex} />,
              minWidth: DESKTOP_LOAN_COLUMNS_MIN_WIDTH,
              rowSpan: 99,
              style: {
                verticalAlign: 'middle',
                background: RddkTheme.colors.grey10,
              },
            });
          }
        } else {
          // If no error, then show the tableItem content
          const tableItemForLoan = loan.tableItems?.find((i) => i.fieldKey === fieldKey);
          bodyRow.push({
            value: <CalculatorTableBodyCell loanArrayIndex={loanIndex} tableItem={tableItemForLoan} />,
            minWidth: DESKTOP_LOAN_COLUMNS_MIN_WIDTH,
          });
        }
      });

      rows.push(bodyRow);
    });

    return rows;
  }, [t, loans, tableName, tableSlogan, showHeader, fieldsKeysToDisplay]);

  return (
    <TableContainer>
      <DynamicTable
        key={lastCalculationDate}
        bordered={true}
        striped={true}
        firstRowIsHead={showHeader}
        data={desktopTableData}
      />
    </TableContainer>
  );
};

const TableContainer = styled.div`
  table {
    table-layout: fixed; /* IMPORTANT */
  }
`;
