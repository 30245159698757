/**
 * You're probably thinking: what the hell is even that?
 * Well, it helps converting Sitecore's interpretation of booleans into real booleans! 'Nuff said.
 * @param sitecoreBool The value that a sitecore checkbox can potentially return
 * @returns an actual booloan
 */
export const sitecoreBoolToBool = (sitecoreBool: string | boolean | null | undefined): boolean => {
  if (sitecoreBool === '0') return false;
  if (sitecoreBool === '1') return true;
  if (typeof sitecoreBool === 'string' && sitecoreBool.toLocaleLowerCase() === 'true') return true;
  if (typeof sitecoreBool === 'string' && sitecoreBool.toLocaleLowerCase() === 'false') return false;
  return Boolean(sitecoreBool);
};
