import { useSitecoreContext } from './useSitecoreContext';

export enum EnvironmentName {
  Dev = 'DEV',
  Test = 'TEST',
  Syst = 'SYST',
  Prod = 'PROD',
}

export enum EnvironmentRole {
  ContentManagement = 'CM',
  ContentDelivery = 'CD',
}

export interface ContextEnvironment {
  name: string;
  role: string;
}

export const useContextEnvironment = (): ContextEnvironment => {
  const { environment } = useSitecoreContext();

  return {
    name: environment?.name || EnvironmentName.Prod,
    role: environment?.role || EnvironmentRole.ContentDelivery,
  };
};
