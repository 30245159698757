import { RichText } from '@sitecore-jss/sitecore-jss-react';
import {
  Cell,
  CellRenderProps,
  Col,
  Color,
  Container,
  DynamicTable,
  mediaQuery,
  RddkTheme,
  Row,
  TableData,
} from 'db-npm-rdui';
import * as React from 'react';
import styled from 'styled-components';
import iconCheckmark from '../../assets/images/svg/icon-checkmark-red.svg';
import { Section } from '../../shared/components';
import { parseExcelData } from './parseExcelData';

const DEFAULT_CELL_WIDTH = 150;

const StyledContainer = styled(Container)`
  ${mediaQuery.lgDown} {
    max-width: 100% !important;
    padding: 0 !important;
  }
`;

const Wrapper = styled.section`
  a {
    color: ${Color.rdRed};
    font-style: italic;
    text-decoration: underline;

    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
    }
  }
`;

const StyledRichText = styled(RichText)`
  font-size: 13px;
  color: ${RddkTheme.colors.warmGrey};
  line-height: 1.4;
  margin: 15px 15px 0;

  ${mediaQuery.lg} {
    margin: 15px 0 0;
  }
`;

export type TableProps = {
  fields: any;
};

const Table: React.FC<TableProps> = ({ fields = {} }) => {
  // Extract values from fields and set defaults
  const excelData = fields['Excel Data']?.value || '';
  const showTextBelowTableHeaders = Boolean(fields['Show Text Below Table Headers']?.value);
  const sortableColumns = Boolean(fields['Sortable Columns']?.value);
  const firstColumnWidth = Number(fields['First Column Width In Pixels']?.value) || DEFAULT_CELL_WIDTH;
  const allColumnsMinimumWidth = Number(fields['All Columns Minimum Width In Pixels']?.value) || DEFAULT_CELL_WIDTH;
  const cellAlignment = fields['Cell Alignment']?.fields['Value']?.value || 'left';
  const linkInLastRow = Boolean(fields['Link In Last Row']?.value);
  const linkTextInLastRow = fields['Link Text In Last Row']?.value;

  // Parse data to array of arrays
  const tableData = parseExcelData(excelData);

  // Function to render each cell value
  const cellValueRenderFunction = React.useCallback(
    ({ cell, rowIndex, columnIndex }: CellRenderProps) => {
      // Render as bold if first column
      if (columnIndex === 0) {
        return <strong>{cell?.value}</strong>;
      }

      // Render checkmark if text is TRUE
      if (cell?.value === 'TRUE') {
        return <img src={iconCheckmark} alt="Checkmark" title="Checkmark" />;
      }

      // Render as link if it's the last row and boolean "linkInLastRow" is true and cell is not empty
      const isLastRow = rowIndex === tableData.length - (showTextBelowTableHeaders ? 2 : 1);

      if (linkInLastRow && isLastRow && cell?.value !== '') {
        return (
          <a href={cell?.value} title={linkTextInLastRow}>
            {linkTextInLastRow}
          </a>
        );
      }

      return cell?.value;
    },
    [linkInLastRow, linkTextInLastRow, showTextBelowTableHeaders, tableData.length]
  );

  // Generate table data
  const data = React.useMemo(
    () =>
      tableData.reduce((result, row, rowIndex) => {
        const isFirstRow = rowIndex === 0;

        const addSuffixToHeader = showTextBelowTableHeaders && isFirstRow && tableData.length >= 2;

        // If we are using the 2nd row as header rows suffixes,
        // then we skip rendering the 2nd row.
        if (showTextBelowTableHeaders && rowIndex === 1) {
          return result;
        }

        return [
          ...result,
          row.map((column, columnIndex) => {
            const isFirstColumn = columnIndex === 0;

            const cell: Cell = {
              value: column,
              suffix: addSuffixToHeader ? tableData[1][columnIndex] : undefined,
              width: isFirstColumn ? firstColumnWidth : undefined,
              minWidth: isFirstColumn ? undefined : allColumnsMinimumWidth,
              alignHorizontally: cellAlignment,
              alignVertically: 'top',
              sortable: !isFirstColumn && isFirstRow ? sortableColumns : false,
              cellValueRender: cellValueRenderFunction,
            };

            return cell;
          }),
        ];
      }, [] as TableData),
    [
      allColumnsMinimumWidth,
      cellAlignment,
      cellValueRenderFunction,
      firstColumnWidth,
      showTextBelowTableHeaders,
      sortableColumns,
      tableData,
    ]
  );

  return (
    <Section componentName="Table" margin="small">
      <StyledContainer>
        <Row justify="center">
          <Col xl={10}>
            <Wrapper>
              <DynamicTable
                bordered={true}
                striped={true}
                firstRowIsHead={true}
                firstColumnIsSticky={false}
                data={data}
              />

              <StyledRichText field={fields['Text Below Table']} />
            </Wrapper>
          </Col>
        </Row>
      </StyledContainer>
    </Section>
  );
};

export default Table;
