import { useSitecoreContext } from './useSitecoreContext';

export interface ContextSettings {
  googleSiteVerification: string;
  facebookDomainVerification: string;
  videoAccountId: string;
  cookiePolicyEnabled: boolean;
  cookieBannerDisabled: boolean;
  monitoring: ContextMonitoring;
}

interface ContextMonitoring {
  appKey: string;
  beaconUrl: string;
}

export const useContextSettings = (): ContextSettings => {
  const { settings } = useSitecoreContext();

  return {
    googleSiteVerification: settings?.googleSiteVerification,
    facebookDomainVerification: settings?.facebookDomainVerification,
    videoAccountId: settings?.videoAccountId,
    cookiePolicyEnabled: settings?.cookiePolicyEnabled === true,
    cookieBannerDisabled: settings?.cookieBannerDisabled === true,
    monitoring: {
      appKey: settings?.monitoring?.appKey,
      beaconUrl: settings?.monitoring?.beaconUrl,
    },
  };
};
