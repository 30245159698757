import { Text, withPlaceholder } from '@sitecore-jss/sitecore-jss-react';
import { PlaceholderProps } from '@sitecore-jss/sitecore-jss-react/types/components/PlaceholderCommon';
import { BottomShadow, Box, Col, Container, mediaQuery, Row, Typography } from 'db-npm-rdui';
import React from 'react';
import styled from 'styled-components';
import { FieldChecker, LinkAnimatedUnderline, Section } from '../../shared/components';
import { useIsEditor } from '../../shared/hooks';

const HorizontalLines = styled.div`
  &,
  ::before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: #f1f1f1;

    ${mediaQuery.md} {
      top: 50px;
    }
  }

  ::before {
    content: '';
    display: block;
    top: 7px;
  }
`;

const List = styled(BottomShadow)`
  border-radius: 5px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
  background: #fff;
`;

export type NewsListVerticalProps = PlaceholderProps & {
  fields: any;
  newsItemsVertical: any;
};

const NewsListVertical: React.FC<NewsListVerticalProps> = ({ fields = {}, newsItemsVertical }) => {
  const hasNewsItems = newsItemsVertical && newsItemsVertical.length > 0;
  const isEditor = useIsEditor();
  const showNewsItems = hasNewsItems || isEditor;

  return (
    <Section componentName="NewsListVertical" margin="large">
      <Container>
        <HorizontalLines />

        <Row>
          <Col md={5}>
            <Box paddingTop={{ _: 6, md: 10 }} paddingBottom={6}>
              <FieldChecker type="text" field={fields['Headline']}>
                <Typography type="h3">
                  <Text field={fields['Headline']} />
                </Typography>
              </FieldChecker>

              <FieldChecker type="text" field={fields['Description']}>
                <Typography type="manchetSmall" mt={4} mb={4}>
                  <Text field={fields['Description']} />
                </Typography>
              </FieldChecker>

              <FieldChecker type="link" field={fields['Link']}>
                <LinkAnimatedUnderline field={fields['Link']} />
              </FieldChecker>
            </Box>
          </Col>

          <Col md={6} offset={{ md: 1 }}>
            {showNewsItems && <List>{newsItemsVertical}</List>}
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

export default withPlaceholder('newsItemsVertical')(NewsListVertical);
