import { useLayoutEffect } from 'react';

export function useAdobeTrackingData(metaTitle, trackingAllowed) {
  useLayoutEffect(() => {
    const w = window as any;
    let dataTrackingDetails = document.querySelectorAll('a[data-tracking-id]');

    if (trackingAllowed) {
      dataTrackingDetails.forEach((el, index) => {
        if (!el.getAttribute('data-tracked')) {
          el.addEventListener('click', function () {
            w._satellite.track('custom event', {
              event: el.getAttribute('data-tracking-id'),
            });
            el.setAttribute('data-tracked', '');
          });
        }
      });
    }
  }, [metaTitle, trackingAllowed]);
}
