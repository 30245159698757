import { RddkTheme } from 'db-npm-rdui';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { handleTranslation } from '../../../../shared/utils/handleTranslation';
import { InputsValidationResult } from '../../helpers/inputs';
import { useInputsState } from '../../state/inputsState';
import { useSettingsState } from '../../state/settingsState';

export type CalculatorInputsValidationMessageProps = {
  result: InputsValidationResult;
};

export const CalculatorInputsValidationMessage: React.FC<CalculatorInputsValidationMessageProps> = ({ result }) => {
  const { t } = useTranslation();
  const { loanType, propertyType, propertyValue, propertyCategory, remainingDebt, ownPayment } = useInputsState();
  const { settings } = useSettingsState();
  const showLendingType = settings?.inputs?.showLendingType === true;

  // Check if the inputs are valid
  const showError = showLendingType
    ? ((propertyValue !== null && remainingDebt !== null) || (propertyValue !== null && remainingDebt == null)) &&
      !result.isValid
    : propertyValue !== null && ownPayment !== null && !result.isValid;

  const showOwnPaymentNotice = result.errorCode === 'invalid-inputs' || result.isValid;
  const checkText =
    showLendingType && propertyCategory === ''
      ? t(`calculator-inputs-lending-calculator-notice`)
      : handleTranslation(`calculator-inputs-ownPayment-${propertyType}-notice`) || null;
  const ownPaymentNotice = loanType
    ? t(`calculator-inputs-ownPayment-notice`).replace('{PERCENTAGE}', t(`calculator-setting-ownPaymentProcentage`))
    : checkText !== null
    ? checkText.replace('{PERCENTAGE}', t(`calculator-setting-ownPaymentProcentage-${propertyType}`))
    : '';
  const errorMessage = t(`calculator-inputs-error-${result.errorCode}`);

  return <Text showError={showError}>{showOwnPaymentNotice ? ownPaymentNotice : errorMessage}</Text>;
};

const Text = styled.div<{ showError?: boolean }>`
  margin-top: 15px;
  margin-left: 20px;
  margin-bottom: 30px;
  font-size: 11px;
  font-style: italic;
  color: ${(props) => (props.showError ? RddkTheme.colors.red40 : RddkTheme.colors.grey80)};
`;
