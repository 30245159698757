import { Button, InputField, mediaQuery, RadioButtonBox, RadioButtonBoxGroup } from 'db-npm-rdui';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FormLayout, FormRow } from '../../shared/components/Form';
import { useAdobeTracking } from '../../shared/hooks';
import { getSchema } from './schema';

type Props = {
  onSubmit: (formData: any) => Promise<void>;
  fields: any;
};

const RadioButtonWrapper = styled.div`
  display: flex;
  width: 100%;

  ${mediaQuery.maxWidth.tablet} {
    display: block;
  }
`;

const RadioButtonCantainerSmall = styled.div`
  width: 40%;
  margin: 8px;

  ${mediaQuery.maxWidth.tablet} {
    width: 95%;
  }
`;

const RadioButtonCantainerBig = styled.div`
  width: 60%;
  margin: 8px;

  ${mediaQuery.maxWidth.tablet} {
    width: 95%;
  }
`;

type Custtype = 'private' | 'company';

const NemKontoForm: React.FC<Props> = ({ fields = {}, onSubmit }) => {
  const { t } = useTranslation();
  const [custtype, setCusttype] = useState<Custtype>('private');
  const schema = getSchema(t);
  const mailBoxId = fields['Mailbox ID']?.value;
  const mailSubject = fields['Mail Subject']?.value;
  const { onFormInteraction } = useAdobeTracking();

  useEffect(() => {
    const url = new URL(window.location.href);
    const isBusiness = url.searchParams.get('formtype')?.toLowerCase() === 'business';
    if (isBusiness) {
      setTimeout(() => {
        document.getElementById('custtype_company')?.click();
      }, 300);
    }
  }, []);

  return (
    <FormLayout
      headline={fields['Headline']?.value}
      description={fields['Description']?.value}
      formTitle={fields['Form Title']?.value}
    >
      <Formik
        initialValues={{
          custtype: 'private' as Custtype,
          fname: '',
          lname: '',
          fullname: '',
          cpr: '',
          cvr: '',
          phone: '',
          email: '',
          mailboxId: mailBoxId,
          emailsubject: mailSubject,
        }}
        validationSchema={schema}
        onSubmit={(values) => {
          onSubmit(values);
        }}
      >
        {({ values, errors, touched, handleChange }) => {
          const trackingChangeHandler = (e) => {
            onFormInteraction(e, handleChange);
          };
          return (
            <Form noValidate>
              <FormRow>
                <RadioButtonBoxGroup>
                  <RadioButtonWrapper>
                    <RadioButtonCantainerSmall>
                      <RadioButtonBox
                        label={t('form-input-private-label')}
                        id="custtype_private"
                        name="custtype"
                        value="private"
                        checked={custtype === 'private'}
                        variant="secondary"
                        onChange={(e) => {
                          setCusttype(e.currentTarget.value as Custtype);
                          values.custtype = e.currentTarget.value as Custtype;
                          values.cvr = '';
                        }}
                        data-label="Customer type"
                        data-egainfield="misc_one"
                        data-maptoegain="true"
                        data-includeinmailboxemail="true"
                      />
                    </RadioButtonCantainerSmall>
                    <RadioButtonCantainerBig>
                      <RadioButtonBox
                        label={t('form-input-company-label')}
                        id="custtype_company"
                        name="custtype"
                        value="company"
                        checked={custtype === 'company'}
                        variant="secondary"
                        onChange={(e) => {
                          setCusttype(e.currentTarget.value as Custtype);
                          values.custtype = e.currentTarget.value as Custtype;
                          values.cpr = '';
                          values.lname = '';
                        }}
                        data-label="Customer type"
                        data-egainfield="misc_one"
                        data-maptoegain="true"
                        data-includeinmailboxemail="true"
                      />
                    </RadioButtonCantainerBig>
                  </RadioButtonWrapper>
                </RadioButtonBoxGroup>
              </FormRow>

              <FormRow>
                <InputField
                  name={values.custtype === 'company' ? 'fullname' : 'fname'}
                  id={values.custtype === 'company' ? 'fullname' : 'fname'}
                  label={values.custtype === 'company' ? t('form-input-name-label') : t('form-input-first-name-label')}
                  layout="white"
                  value={values.custtype === 'company' ? values.fullname : values.fname}
                  onChange={trackingChangeHandler}
                  errorMessage={
                    touched.fname && errors.fname
                      ? values.custtype === 'private'
                        ? errors.fname
                        : errors.fullname
                      : ''
                  }
                  required
                  data-testid="fname"
                  data-label={
                    values.custtype === 'company' ? t('form-input-name-label') : t('form-input-first-name-label')
                  }
                  data-egainfield={values.custtype === 'company' ? 'customer_name' : 'first_name'}
                  data-maptoegain="true"
                  maxLength={values.custtype === 'company' ? 50 : 30}
                  data-includeinmailboxemail="true"
                />
              </FormRow>

              {values.custtype === 'private' && (
                <FormRow>
                  <FormRow>
                    <InputField
                      name="lname"
                      id="lname"
                      label={t('form-input-last-name-label')}
                      layout="white"
                      value={values.lname}
                      onChange={trackingChangeHandler}
                      errorMessage={touched.lname && errors.lname ? errors.lname : ''}
                      required
                      maxLength={30}
                      data-testid="lname"
                      data-label={t('form-input-last-name-label')}
                      data-egainfield="last_name"
                      data-maptoegain="true"
                      data-includeinmailboxemail="true"
                    />
                  </FormRow>

                  <FormRow>
                    <InputField
                      name="cpr"
                      id="cpr"
                      label={t('form-input-cpr-label')}
                      layout="white"
                      variant="numeric"
                      format="######-####"
                      value={values.cpr}
                      onChange={trackingChangeHandler}
                      errorMessage={touched.cpr && errors.cpr ? errors.cpr : ''}
                      required
                      data-testid="cpr"
                      data-label={t('form-input-cpr-label')}
                      data-egainfield="customer_number"
                      data-maptoegain="true"
                      data-includeinmailboxemail="true"
                    />
                  </FormRow>
                </FormRow>
              )}

              <FormRow>
                {values.custtype === 'company' && (
                  <InputField
                    name="cvr"
                    id="cvr"
                    label={t('form-input-cvr-label')}
                    layout="white"
                    variant="numeric"
                    format="########"
                    value={values.cvr}
                    maxLength={20}
                    onChange={trackingChangeHandler}
                    errorMessage={touched.cvr && errors.cvr ? errors.cvr : ''}
                    required
                    data-testid="cvr"
                    data-label={t('form-input-cvr-label')}
                    data-egainfield="customer_number"
                    data-maptoegain="true"
                    data-includeinmailboxemail="true"
                  />
                )}
              </FormRow>

              <FormRow>
                <InputField
                  name="phone"
                  id="phone"
                  label={t('form-input-phone-label')}
                  layout="white"
                  value={values.phone}
                  onChange={trackingChangeHandler}
                  errorMessage={touched.phone && errors.phone ? errors.phone : ''}
                  required
                  maxLength={20}
                  data-testid="phone"
                  data-label={t('form-input-phone-label')}
                  data-egainfield="customer_phone"
                  data-maptoegain="true"
                  data-includeinmailboxemail="true"
                />
              </FormRow>

              <FormRow>
                <InputField
                  name="email"
                  id="email"
                  label={t('form-input-email-label')}
                  layout="white"
                  value={values.email}
                  onChange={trackingChangeHandler}
                  errorMessage={touched.email && errors.email ? errors.email : ''}
                  required
                  maxLength={100}
                  data-testid="email"
                  data-label={t('form-input-email-label')}
                  data-egainfield="customer_email"
                  data-maptoegain="true"
                  data-includeinmailboxemail="true"
                />
              </FormRow>

              <FormRow>
                <Button onClick={trackingChangeHandler} type="submit" data-testid="buttonSubmit">
                  {t('form-button-next-label')}
                </Button>
              </FormRow>
            </Form>
          );
        }}
      </Formik>
    </FormLayout>
  );
};

export default NemKontoForm;
