import { mediaQuery, Select } from 'db-npm-rdui';
import * as React from 'react';
import styled from 'styled-components';
import { TableItemDropdown } from '../../types/TableItem';

type CalculatorDropdownFieldProps = {
  tableItem: TableItemDropdown;
  onChangeValue: (value: any) => void;
};

const shouldComponentUpdate = (prevProps: CalculatorDropdownFieldProps, nextProps: CalculatorDropdownFieldProps) => {
  return prevProps.tableItem.value !== nextProps.tableItem.value;
};

export const CalculatorDropdownField: React.FC<CalculatorDropdownFieldProps> = React.memo(
  ({ tableItem, onChangeValue }) => {
    const [value, setValue] = React.useState(tableItem.value);

    const onChangeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
      const newValue = event.target.value;

      // Set value internally in this component for faster re-render
      setValue(newValue);

      // Trigger the change event for recalculation
      onChangeValue(newValue);
    };

    return (
      <Container>
        <Select value={value} options={tableItem.options || []} onChange={onChangeHandler} />
      </Container>
    );
  },
  shouldComponentUpdate
);

const Container = styled.div`
  > select {
    width: auto;
    padding-right: 20px;
    cursor: pointer;
    font-size: 14px;

    ${mediaQuery.sm} {
      font-size: 16px;
    }
  }
`;
