import * as React from 'react';
import { Section } from '../../shared/components';
import { ImageContainer } from '../../shared/components/ImageContainer/ImageContainer';

export type EntranceSingleLeftProps = {
  fields: any;
};

const EntranceSingleLeft: React.FC<EntranceSingleLeftProps> = ({ fields = {} }) => {
  return (
    <Section componentName="EntranceSingleLeft" margin="large">
      <ImageContainer fields={fields} direction="row-reverse" />
    </Section>
  );
};

export default EntranceSingleLeft;
