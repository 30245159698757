import { breakpoints } from 'db-npm-rdui';

interface ImageField {
  value: {
    src: string;
    alt?: string;
  };
}

const getQuerySymbol = (url: string) => (url.search(/\?+/) === -1 ? '?' : '&');
const paramCleaner = (url: string) => {
  const urlParts = url.split('?');
  let loc = urlParts[0];
  let queryString = '';
  /** Copy of replacement regexp from JSS https://github.com/Sitecore/jss/blob/master/packages/sitecore-jss/src/mediaApi.ts */
  const mediaUrlPrefixRegex = /\/([-~]{1})\/media\//i;
  const match = mediaUrlPrefixRegex.exec(loc);
  if (match && match.length > 1) {
    loc = loc.replace(mediaUrlPrefixRegex, `/${match[1]}/jssmedia/`);
  }
  /** JSS regexp end */
  if (urlParts.length === 2) {
    queryString = urlParts[1].replace(/(&|^)+(h|w|mh|mw)+=\d+/g, '');
    if (queryString.length > 0) {
      return loc + queryString.replace('&', '?');
    }
  }
  return loc;
};

const imageScaler = (image: string | ImageField | undefined, maxWidth: number = 0): any => {
  let imageUrl: string | undefined = typeof image === 'object' ? image.value.src : image;

  if (imageUrl === undefined) {
    return image;
  }

  const widthParameter = 'mw=';

  if (typeof window !== 'undefined' && maxWidth === 0) {
    const pixelRatio = 1; //window.devicePixelRatio || 1; //hard coding this to 1 for now. Revisit after performance vs niceness testing.
    const scalingSteps = Object.values(breakpoints);
    const largestStep = Math.max(...scalingSteps);
    if (window.innerWidth < largestStep + 200) {
      maxWidth = scalingSteps.reduce((a, c) => (c > window.innerWidth && c < a ? c : a), largestStep);
    }
    maxWidth = Math.ceil(maxWidth * pixelRatio);
  }
  imageUrl = paramCleaner(imageUrl); //sometimes images already have params from sitecore - if not removed, images may be way taller (padded) than needed
  const scaledImageSuffix = getQuerySymbol(imageUrl) + widthParameter + maxWidth;
  if (typeof image === 'object') {
    image.value.src = imageUrl + scaledImageSuffix;
  } else {
    image = imageUrl + scaledImageSuffix;
  }
  return image;
};

export default imageScaler;
