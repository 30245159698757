import { Color, mediaQuery, RddkTheme } from 'db-npm-rdui';
import { css } from 'styled-components';

export const tooltipPsudoStyle = css`
  ::before {
    content: '';
    background: ${Color.rdBrightWhite};
    position: absolute;
    z-index: 20;
    width: 22px;
    height: 22px;
    transform: rotate(45deg);
  }
  ::after {
    content: '';
    z-index: 22;
    background: ${Color.rdBrightWhite};
    position: absolute;
  }
`;

export const tooltipPositionRight = css`
  left: calc(100% + 15px);
  top: -15px;
  ${mediaQuery.lg} {
    top: -10px;
  }
  ::before {
    left: -10px;
    top: 15px;
    border-radius: 0 0 0 3px;
    box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.25);
  }
  ::after {
    height: 40px;
    width: 20px;
    left: 0;
    top: 10px;
  }
`;

export const tooltipPositionBottom = css`
  left: 0;
  top: 45px;
  ::before {
    left: 20px;
    top: -10px;
    border-radius: 0 3px 0 0;
    box-shadow: -1px -1px 6px rgba(0, 0, 0, 0.1);
  }
  ::after {
    height: 20px;
    width: 40px;
    left: 10px;
    top: 0px;
  }
`;

export const tooltipLinkStyle = css`
  color: ${Color.rdPureBlack};
  border-bottom: 2px dashed ${Color.rdPureBlack};
  text-decoration: none;
  cursor: help;
  position: relative;
  :hover {
    .tooltip-bubble {
      opacity: 1;
      z-index: 20;
    }
  }
  ${mediaQuery.mdDown} {
    position: static;
    display: inline-flex;
    justify-content: space-around;
    ::after {
      content: '';
      opacity: 0;
      z-index: -1;
      background: ${Color.rdBrightWhite};
      width: 22px;
      height: 22px;
      position: absolute;
      transform: rotate(45deg);
      margin-top: 30px;
      left: inherit;
      border-radius: 0 3px 0 0;
      box-shadow: -1px -1px 6px rgba(0, 0, 0, 0.1);
      transition: opacity 0.5s ease;
    }
    ::before {
      content: '';
      background: ${Color.rdBrightWhite};
      margin-top: 40px;
      left: inherit;
      height: 20px;
      position: absolute;
      width: 40px;
      transition: opacity 0.5s ease;
      z-index: -1;
      opacity: 0;
    }
    :hover {
      ::after {
        opacity: 1;
        z-index: 20;
      }
      ::before {
        opacity: 1;
        z-index: 22;
      }
    }
  }
`;

export const tooltipBubble = css`
  cursor: auto;
  opacity: 0;
  z-index: -1;
  transition: opacity 0.5s ease;
  position: absolute;
  width: 320px;
  height: auto;
  background: ${Color.rdBrightWhite};
  border-radius: 3px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
  ${tooltipPsudoStyle}
  ${tooltipPositionRight}
  
  ${mediaQuery.mdDown} {
    ${tooltipPositionBottom}
    ::before, ::after {
      content: none;
    }
    top: inherit;
    left: 5%;
    margin-top: 40px;
    width: 90vw;
  }
`;

export const tooltipHeading = css`
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  font-family: ${RddkTheme.fonts.sans};
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.2px;
  color: ${Color.rdPureBlack};
  display: inline-block;
  width: 100%;
  font-size: 13px;
  ${mediaQuery.lg} {
    font-size: 0.7em;
  }
`;

export const tooltipContent = css`
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 25px;
  font-family: ${RddkTheme.fonts.sans};
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.23;
  letter-spacing: normal;
  color: ${Color.darkGrey};
  display: inline-block;
  font-size: 12px;
  ${mediaQuery.lg} {
    font-size: 0.65em;
  }
`;
